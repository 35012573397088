import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useSurveyQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  return {
    details: ({ surveyId }: { surveyId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.SURVEY_DETAILS(projectId, surveyId),
        queryFn: () => api.getSurveysDetailsV1(surveyId),
        select: commonAPIDataSelector,
      }),
  };
};
