import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AddressDto } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (address: AddressDto) => api.deleteAddressesByIdV1(address.id),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.addresses.actions.delete.notifications.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.addresses.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });
};

const useRefreshLocationCode = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (addressId: string) => api.putAddressesRegenerateLocationCodeV1(addressId),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.addresses.actions.refresh-location-code.notifications.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.addresses.actions.refresh-location-code.notifications.failed"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });
};

export const addressMutations = {
  useDeleteAddress,
  useRefreshLocationCode,
};
