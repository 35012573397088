import { useConfig } from "providers/ConfigProvider";

import { Layout } from "./Layout";
import { Layout_LEGACY } from "./Layout_LEGACY";
import { Loader_LEGACY } from "./Loader_LEGACY";

export function AdminCreatePost(): React.ReactNode {
  const isAdminCreatePostV2Available = useConfig("isAdminCreatePostV2Available");

  if (isAdminCreatePostV2Available) {
    return <Layout />;
  }

  return <Loader_LEGACY>{(data) => <Layout_LEGACY {...data} />}</Loader_LEGACY>;
}
