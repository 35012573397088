import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormVideoInput } from "components/Form/FormVideoInput";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import {
  createDocumentSizeRule,
  createDocumentTypeRule,
  createImageSizeRule,
  createImageTypeRule,
  createVideoSizeRule,
  createVideoTypeRule,
} from "helpers/rules";
import { MAX_AMOUNT_IMAGES_PER_POST } from "modules/messages/constants";
import type { AdminCreatePostFormValues_LEGACY } from "modules/messages/pages/AdminCreatePost/Layout_LEGACY";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

export function AdminCreatePostAttachmentField_LEGACY(): React.ReactNode {
  const { t } = useTranslation();
  const postHog = usePostHog();

  const form = useFormContext<AdminCreatePostFormValues_LEGACY>();
  const selectedImages = useWatch<AdminCreatePostFormValues_LEGACY, "images">({
    control: form.control,
    name: "images",
  });
  const selectedVideos = useWatch<AdminCreatePostFormValues_LEGACY, "videos">({
    control: form.control,
    name: "videos",
  });
  const selectedDocuments = useWatch<AdminCreatePostFormValues_LEGACY, "documents">({
    control: form.control,
    name: "documents",
  });

  const isAnyAttachmentSelected =
    selectedDocuments.length > 0 || selectedImages.length > 0 || selectedVideos.length > 0;

  return (
    <div className="flex flex-wrap gap-2">
      {(!isAnyAttachmentSelected || selectedImages.length > 0) && (
        <FormImageInput<AdminCreatePostFormValues_LEGACY, "images">
          name="images"
          texts={{
            add: t("page.create-post.form.file.image"),
          }}
          nOfImages={MAX_AMOUNT_IMAGES_PER_POST}
          rules={{
            validate: {
              size: createImageSizeRule({ t }),
              type: createImageTypeRule({ t }),
            },
          }}
        />
      )}
      {(!isAnyAttachmentSelected || selectedVideos.length > 0) && (
        <FormVideoInput<AdminCreatePostFormValues_LEGACY, "videos">
          name="videos"
          texts={{
            add: t("page.create-post.form.file.video"),
          }}
          rules={{
            validate: {
              size: createVideoSizeRule({ t, onError: () => postHog.capture("form_error_video_size_exceeded") }),
              type: createVideoTypeRule({ t }),
            },
          }}
          shouldProcessVideo
        />
      )}
      {(!isAnyAttachmentSelected || selectedDocuments.length > 0) && (
        <FormDocumentInput<AdminCreatePostFormValues_LEGACY, "documents">
          name="documents"
          accept={ALLOWED_FILE_TYPES.DOCUMENT}
          texts={{
            add: t("page.create-post.form.file.document"),
          }}
          rules={{
            validate: {
              size: createDocumentSizeRule({
                t,
              }),
              type: createDocumentTypeRule({
                t,
              }),
            },
          }}
        />
      )}
    </div>
  );
}
