import { Menu as ArkMenu } from "@ark-ui/react";
import type React from "react";
import { twJoin } from "tailwind-merge";

type ContextMenuContentProps = {
  children: React.ReactNode;
};

export function ContextMenuContent({ children }: ContextMenuContentProps): React.ReactNode {
  return (
    <ArkMenu.Content
      data-testid="context-menu-action-list"
      className={twJoin(
        "min-w-36 rounded border border-grey-100 bg-white text-black shadow-md focus:outline-none",
        "repeat-1 data-[placement=left-end]:origin-bottom-right data-[placement=left-start]:origin-top-right data-[placement=right-end]:origin-bottom-left data-[placement=right-start]:origin-top-left",
        "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:slide-in-from-top-0.5",
        "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:slide-out-to-top-0.5",
      )}
    >
      {children}
    </ArkMenu.Content>
  );
}
