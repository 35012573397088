import type { MassMessageCreatedEntityDto } from "api/types";
import MailManImage from "assets/images/mail-man.svg?react";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import type React from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { Trans, useTranslation } from "translations";

type CreateMassPostSuccessModalProps = ModalBaseProps & {
  audienceReached: MassMessageCreatedEntityDto | null;
};

export function CreateMassPostSuccessModal({
  isOpened,
  onOpenChange,
  audienceReached,
}: CreateMassPostSuccessModalProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal.Root
      title={
        audienceReached?.minutesExpectedToFinish
          ? t("page.create-mass-message.success-modal.batched.title", {
              count: Math.ceil(audienceReached.minutesExpectedToFinish / 60),
            })
          : t("page.create-mass-message.success-modal.title")
      }
      description={
        audienceReached?.expectedFinishDateTime &&
        t("page.create-mass-message.success-modal.batched.subtitle", {
          date: formatDate(i18n, "datetime", audienceReached.expectedFinishDateTime),
        })
      }
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col items-center gap-4">
        <MailManImage />
        {audienceReached?.projectCount !== undefined ||
        audienceReached?.addressCount !== undefined ||
        audienceReached?.audienceResidentsCount !== undefined ? (
          <div className="flex flex-col items-center gap-1">
            <span className="text-body">
              {t("page.create-mass-message.success-modal.audience-reached.description.delivered-to")}
            </span>
            {audienceReached.projectCount !== undefined && audienceReached.projectCount > 0 && (
              <span className="text-body">
                <Trans
                  i18nKey="page.create-mass-message.success-modal.audience-reached.description.projects"
                  values={{
                    count: audienceReached.projectCount,
                    projectCount: audienceReached.projectCount,
                  }}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {audienceReached.addressCount !== undefined && audienceReached.addressCount > 0 && (
              <span className="text-body">
                <Trans
                  i18nKey="page.create-mass-message.success-modal.audience-reached.description.addresses"
                  values={{
                    addressCount: audienceReached.addressCount,
                    count: audienceReached.addressCount,
                  }}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {
              // eslint-disable-next-line no-nested-ternary
              audienceReached.audienceResidentsCount !== undefined ? (
                audienceReached.audienceResidentsCount === 0 ? (
                  <span className="text-body">
                    <Trans
                      i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents.none"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                      }}
                      components={{
                        b: <b />,
                      }}
                    />
                  </span>
                ) : (
                  <span className="text-body">
                    <Trans
                      i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                        count: audienceReached.audienceResidentsCount,
                      }}
                      components={{
                        b: <b />,
                      }}
                    />
                  </span>
                )
              ) : null
            }
          </div>
        ) : (
          <span className="text-body">{t("page.create-mass-message.success-modal.description")}</span>
        )}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="tertiary">{t("page.create-mass-message.success-modal.button.another-message")}</Button>
          </Modal.Close>
          <Button styling="primary" onClick={() => void navigate(routes.portfolio.overview())}>
            {t("page.create-mass-message.success-modal.button.done")}
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Root>
  );
}
