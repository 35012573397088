import { Label } from "components/Label/Label";
import { useTranslation } from "translations";

export function UserDeletedTag(): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Label theme="red" size="sm">
      {t("component.user.removed")}
    </Label>
  );
}
