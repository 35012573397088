import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AssetBookingRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { addDays, format, isSameDay, parse } from "date-fns";
import { stripTime } from "helpers/date";
import { isHttpError } from "helpers/Network/errors";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { getMinutesOfDayFromTime, getTimeFromMinutesOfDay } from "modules/bookings/helpers";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import type { CreateOrEditBookingFormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { aid: assetId, bid: bookingId } = useParams<{ aid: string; bid: string }>();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [date, setDate] = useState<Date | null>(null);

  const {
    data: assetDetails,
    isPending: isPendingAssetDetails,
    error: errorAssetDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsDetailsV1(assetId!),
    select: commonAPIDataSelector,
  });
  const query = date ? { Date: format(date, "yyyy-MM-dd") } : undefined;
  const {
    data: assetSlots,
    isPending: isPendingAssetSlots,
    error: errorAssetSlots,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, assetId!, query),
    queryFn: () => api.getBookableAssetsSlotsV1(assetId!, query),
    select: commonAPIDataSelector,
  });
  const {
    data: bookingDetails,
    isLoading: isLoadingBookingDetails,
    error: errorBookingDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, assetId!, bookingId!),
    queryFn: () => api.getBookableAssetsBookingsDetailsV1(bookingId!, assetId!),
    select: commonAPIDataSelector,
    enabled: isDefined(assetId) && isDefined(bookingId),
    refetchOnMount: true,
  });

  const { data: assetStatistics, error: assetStatisticsError } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsStatisticsV1(assetId!, { includeToday: true }),
    select: commonAPIDataSelector,
    enabled: assetDetails?.canDelete === true,
  });

  const deleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });
      void navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });

  const createBookingMutation = useMutation({
    mutationFn: (req: AssetBookingRequest) => api.postBookableAssetsBookingsV1(assetId!, req).then((x) => x.data),
    onError: (error) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_BOOKINGS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId) });
      if (isHttpError(error) && error.status === 409) {
        showFlashToast({ type: "error", title: t("page.bookings.book-asset.error.already-booked") });
      } else {
        showFlashToast({ type: "error", title: t("page.bookings.book-asset.error.generic") });
      }
    },
  });

  const editBookingMutation = useMutation({
    mutationFn: (req: AssetBookingRequest) =>
      api.putBookableAssetsBookingsV1(bookingId!, assetId!, req).then((x) => x.data),
    onError: (error) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_BOOKINGS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId) });
      if (isHttpError(error) && error.status === 409) {
        showFlashToast({ type: "error", title: t("page.bookings.book-asset.error.already-booked") });
      } else {
        showFlashToast({ type: "error", title: t("page.bookings.book-asset.error.generic") });
      }
    },
  });

  async function createOrEditBooking(data: CreateOrEditBookingFormValues) {
    const req: AssetBookingRequest = {
      date: format(data.date, "yyyy-MM-dd"),
      startTime: getTimeFromMinutesOfDay(data.startTime!),
      endTime: getTimeFromMinutesOfDay(data.endTime!),
      reason: data.reason,
    };

    if (isDefined(bookingId)) {
      return await editBookingMutation.mutateAsync(req);
    }

    return await createBookingMutation.mutateAsync(req);
  }

  const minBookingDate = useMemo(() => {
    const now = new Date();

    if (!assetDetails) {
      // Should never be used (as we require asset details to be loaded before render), but just in case
      return stripTime(now);
    }

    const publishAt = assetDetails?.publishAt ? new Date(assetDetails.publishAt) : now;
    let availableFrom = assetDetails?.availableFrom ? new Date(assetDetails.availableFrom) : now;

    availableFrom = publishAt > availableFrom ? publishAt : availableFrom;

    if (availableFrom < now) {
      availableFrom = now;
    }

    if (!assetDetails.canBookSameDay && isSameDay(now, availableFrom)) {
      return stripTime(addDays(now, 1));
    }

    return stripTime(availableFrom);
  }, [assetDetails]);

  const maxBookingDate = useMemo(() => {
    if (!assetDetails) {
      // Should never be used (as we require asset details to be loaded before render), but just in case
      return stripTime(new Date());
    }

    const unpublishedAt = assetDetails?.unpublishAt ? new Date(assetDetails.unpublishAt) : undefined;
    const maxDaysInAdvance = addDays(
      new Date(),
      // We subtract 1 day because we must consider the present day
      assetDetails.maxDaysInAdvance ? assetDetails.maxDaysInAdvance - 1 : 365 - 1,
    );

    if (unpublishedAt && unpublishedAt < maxDaysInAdvance) {
      return stripTime(unpublishedAt);
    }

    return stripTime(maxDaysInAdvance);
  }, [assetDetails]);

  const defaultDate = useMemo(() => {
    if (!assetDetails) {
      // Should never be used (as we require asset details to be loaded before render), but just in case
      return new Date();
    }

    if (bookingDetails?.date) {
      return parse(bookingDetails.date, "yyyy-MM-dd", new Date());
    }

    return minBookingDate;
  }, [assetDetails, bookingDetails?.date, minBookingDate]);

  const defaultStartTime = useMemo(() => {
    if (bookingDetails) {
      return getMinutesOfDayFromTime(bookingDetails.startTime, "start");
    }

    return null;
  }, [bookingDetails]);

  const defaultEndTime = useMemo(() => {
    if (bookingDetails) {
      return getMinutesOfDayFromTime(bookingDetails.endTime, "end");
    }

    return null;
  }, [bookingDetails]);

  useEffect(() => {
    if (assetDetails?.id) {
      setDate(defaultDate);
    }
  }, [assetDetails?.id, defaultDate]);

  const error =
    !isDefined(assetId) || errorAssetDetails || assetStatisticsError || errorAssetSlots || errorBookingDetails;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isPendingAssetDetails || isLoadingBookingDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    assetDetails,
    assetSlots: assetSlots?.slots || [],
    isLoadingSlots: isPendingAssetSlots,
    bookingDetails,
    defaultValues: {
      date: defaultDate,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      reason: bookingDetails?.bookingReason,
      isRegulationAccepted: !!bookingDetails,
    },
    minDate: minBookingDate,
    maxDate: maxBookingDate,
    onDateChange: setDate,
    onSubmit: createOrEditBooking,
    isSubmitting: createBookingMutation.isPending || deleteAsset.isPending,
    futureBookings: assetStatistics?.futureBookingsCount ?? 0,
    onDelete: deleteAsset.mutateAsync,
  });
}
