import type { AdminTicketDetailsDto } from "api/types";
import iconClock from "assets/icons/clock.svg";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { useBool } from "hooks/useBool";
import { TicketReminderModal } from "modules/tickets/components/TicketReminderModal";
import { useTranslation } from "translations";

export function TicketReminderButton({ ticket }: { ticket: AdminTicketDetailsDto }): React.ReactNode {
  const [isReminderModalOpened, reminderModalOpenHandlers] = useBool(false);
  const { i18n, t } = useTranslation();

  if (!ticket.canSetReminder) {
    return null;
  }

  return (
    <>
      {!ticket.remindAt ? (
        <Button
          styling="secondary"
          onClick={reminderModalOpenHandlers.setTrue}
          title={t("page.tickets.details.remind-me-later.tooltip")}
          icon={<Icon name={iconClock} size={16} />}
        >
          {t("page.tickets.details.remind-me-later.create")}
        </Button>
      ) : (
        <Button
          styling="primary"
          onClick={reminderModalOpenHandlers.setTrue}
          title={t("page.tickets.details.remind-me-later.tooltip")}
          icon={<Icon name={iconClock} size={16} />}
        >
          {t("page.tickets.details.remind-me-later.edit", {
            when: formatDate(i18n, "datetimeShort", ticket.remindAt),
          })}
        </Button>
      )}
      <TicketReminderModal
        ticketId={ticket.id}
        isOpened={isReminderModalOpened}
        onOpenChange={reminderModalOpenHandlers.set}
        reminderDate={ticket.remindAt}
        daysToAutoClose={ticket.category.closeAfterInactiveDayAmount}
      />
    </>
  );
}
