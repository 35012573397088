import { ImageModal } from "components/Carousel/Carousel";
import { type FormImage } from "components/ImageInput/useImageInput";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import type React from "react";
import { useState } from "react";

import { CommunityItemMultiImage, CommunityItemSingleImage } from "./CommunityItemImage";

const MAX_AMOUNT_IMAGE_SHOWN = 5;

interface CommunityItemGalleryProps {
  images: FormImage[];
  shouldLoad?: boolean;
}

export function CommunityItemGallery({ images, shouldLoad = true }: CommunityItemGalleryProps): React.ReactNode {
  const [isZoomModalOpened, zoomModalHandler] = useBool(false);
  const [zoomedImageIndex, setZoomedImageIndex] = useState<number>();

  useKey("Escape", zoomModalHandler.setFalse);

  let partitionedImages = [images];
  if (images.length > 3) {
    partitionedImages = [images.slice(0, 2), images.slice(2, MAX_AMOUNT_IMAGE_SHOWN)];
  }

  return (
    <>
      {/* Single image setup */}
      {images.length === 1 && (
        <CommunityItemSingleImage
          image={images[0]}
          shouldLoad={shouldLoad}
          onClick={() => {
            setZoomedImageIndex(0);
            zoomModalHandler.setTrue();
          }}
        />
      )}

      {/* Multiple images grid setup */}
      {images.length > 1 && (
        <div className="flex w-full flex-col gap-2">
          {partitionedImages.map((imageGroup, imageGroupIndex) => (
            <div key={imageGroup.map((image) => image.url).toString()} className="flex gap-2">
              {imageGroup.map((currImage, imageIndex) => {
                const isLast = imageGroupIndex === partitionedImages.length - 1 && imageIndex === imageGroup.length - 1;

                return (
                  <CommunityItemMultiImage
                    key={currImage.url}
                    image={currImage}
                    amountImagesAfter={images.length - MAX_AMOUNT_IMAGE_SHOWN}
                    onClick={() => {
                      setZoomedImageIndex(images.indexOf(currImage));
                      zoomModalHandler.setTrue();
                    }}
                    {...{ shouldLoad, isLast }}
                  />
                );
              })}
            </div>
          ))}
        </div>
      )}
      <ImageModal
        images={images}
        zoomedImageIndex={zoomedImageIndex}
        isOpened={isZoomModalOpened}
        onOpenChange={zoomModalHandler.set}
      />
    </>
  );
}
