import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProjectHeaderName } from "api/ApiClientProvider";
import { useApi } from "api/hooks/useApi";
import type { KeywordAlertConfigurationCreateRequest, ThresholdAlertConfigurationCreateRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const { t } = useTranslation();
  const api = useApi();
  const query = useQueryClient();
  const showFlashToast = useFlashToast();

  const {
    data: alerts,
    isPending: isLoadingAlerts,
    error: alertsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ALERTS_LIST(),
    queryFn: () =>
      api.getAlertsConfigurationsV1({
        // Don't send projectId header, because we want to get all alerts, not only for the current project
        // null values are filtered out by the customFetch function
        headers: {
          [ProjectHeaderName]: "",
        },
      }),
    select: commonAPIDataSelector,
  });

  const createNumberOfLikesAlert = useMutation({
    mutationFn: (payload: ThresholdAlertConfigurationCreateRequest) =>
      api.postAlertsConfigurationsNumberOfLikesV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.create.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.create.error") });
    },
  });

  const createNumberOfCommentsAlert = useMutation({
    mutationFn: (payload: ThresholdAlertConfigurationCreateRequest) =>
      api.postAlertsConfigurationsNumberOfCommentsV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.create.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.create.error") });
    },
  });

  const createPostKeywordsAlert = useMutation({
    mutationFn: (payload: KeywordAlertConfigurationCreateRequest) =>
      api.postAlertsConfigurationsPostKeywordsV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.create.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.create.error") });
    },
  });

  const createCommentKeywordsAlert = useMutation({
    mutationFn: (payload: KeywordAlertConfigurationCreateRequest) =>
      api.postAlertsConfigurationsCommentKeywordsV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.create.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.create.error") });
    },
  });

  const editNumberOfLikesAlert = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: ThresholdAlertConfigurationCreateRequest }) =>
      api.putAlertsConfigurationsNumberOfLikesV1(id, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.edit.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.edit.error") });
    },
  });

  const editNumberOfCommentsAlert = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: ThresholdAlertConfigurationCreateRequest }) =>
      api.putAlertsConfigurationsNumberOfCommentsV1(id, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.edit.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.edit.error") });
    },
  });

  const editPostKeywordsAlert = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: KeywordAlertConfigurationCreateRequest }) =>
      api.putAlertsConfigurationsPostKeywordsV1(id, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.edit.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.edit.error") });
    },
  });

  const editCommentKeywordsAlert = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: KeywordAlertConfigurationCreateRequest }) =>
      api.putAlertsConfigurationsCommentKeywordsV1(id, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.alerts.edit.success") });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.ALERTS_LIST() });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.alerts.edit.error") });
    },
  });

  const isLoading = isLoadingAlerts;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = alertsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    alerts: alerts ?? {
      numberOfCommentsAlerts: [],
      numberOfLikesAlerts: [],
      postKeywordAlerts: [],
      commentKeywordAlerts: [],
    },
    createLikeAlert: createNumberOfLikesAlert.mutateAsync,
    createCommentAlert: createNumberOfCommentsAlert.mutateAsync,
    createPostKeywordsAlert: createPostKeywordsAlert.mutateAsync,
    createCommentKeywordsAlert: createCommentKeywordsAlert.mutateAsync,
    editLikeAlert: editNumberOfLikesAlert.mutateAsync,
    editCommentAlert: editNumberOfCommentsAlert.mutateAsync,
    editPostKeywordsAlert: editPostKeywordsAlert.mutateAsync,
    editCommentKeywordsAlert: editCommentKeywordsAlert.mutateAsync,
  });
}
