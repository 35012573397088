import linkExternalIcon from "assets/icons/link-external-01.svg";
import { Icon } from "components/Icon/Icon";
import { Link as RouterLink, type LinkProps } from "react-router";
import { twJoin } from "tailwind-merge";

interface ExternalProps {
  isExternal: true;
  to: string;
  withIcon?: boolean;
  inNewTab?: boolean;
}

interface InternalProps {
  isExternal?: false;
  state?: LinkProps["state"];
  to: LinkProps["to"];
  withIcon?: never;
  inNewTab?: never;
}

type Props = {
  children?: React.ReactNode;
  style?: "blue" | "inherit";
  isBold?: boolean;
  isItalic?: boolean;
  "data-testid"?: string;
  // If the parent is a clickable object this will prevent the click from propagating to the parent
  noPropagation?: boolean;
} & (ExternalProps | InternalProps);

export function Anchor({
  to,
  children,
  isExternal,
  style = "blue",
  isBold,
  isItalic,
  noPropagation,
  withIcon,
  inNewTab,
  ...props
}: Props): React.ReactNode {
  if (isExternal) {
    return (
      <a
        href={to}
        onClick={noPropagation ? (e) => e.stopPropagation() : undefined}
        {...props}
        rel={inNewTab ? "noopener noreferrer" : undefined}
        target={inNewTab ? "_blank" : undefined}
        className={twJoin(
          "inline-flex flex-row items-center gap-2 hocus:underline",
          style === "blue" ? "text-aop-basic-blue-500 hocus:text-aop-basic-blue-600" : undefined,
          isBold ? "font-old-semibold" : undefined,
          isItalic ? "italic" : undefined,
        )}
      >
        {children}
        {withIcon && <Icon name={linkExternalIcon} size={16} />}
      </a>
    );
  } else {
    return (
      <RouterLink
        to={to}
        onClick={noPropagation ? (e) => e.stopPropagation() : undefined}
        {...props}
        className={twJoin(
          "hocus:underline",
          style === "blue" ? "text-aop-basic-blue-500 hocus:text-aop-basic-blue-600" : undefined,
          isBold ? "font-old-semibold" : undefined,
          isItalic ? "italic" : undefined,
        )}
      >
        {children}
      </RouterLink>
    );
  }
}

export function AnchorButton({
  onClick,
  children,
  style = "blue",
  isBold,
  isItalic,
  ...props
}: Omit<Props, "to" | "className" | "isExternal" | "noPropagation"> & {
  onClick: () => void;
  disabled?: boolean;
}): React.ReactNode {
  return (
    <button
      onClick={onClick}
      type="button"
      {...props}
      className={twJoin(
        "hocus:underline disabled:pointer-events-none disabled:text-grey-300",
        style === "blue" ? "text-aop-basic-blue-500 hocus:text-aop-basic-blue-600" : undefined,
        isBold ? "font-old-semibold" : undefined,
        isItalic ? "italic" : undefined,
      )}
    >
      {children}
    </button>
  );
}
