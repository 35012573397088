import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useProjectQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  return {
    details: () =>
      queryOptions({
        queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId),
        queryFn: () => api.getProjectDetailsV1(),
        select: commonAPIDataSelector,
      }),
  };
};
