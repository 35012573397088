import iconUndo from "assets/icons/reverse-left.svg";
import iconRedo from "assets/icons/reverse-right.svg";
import { AIButton } from "components/Button/AIButton";
import { IconButton } from "components/Button/IconButton";
import { useBool } from "hooks/useBool";
import { useContentFieldManager } from "providers/ContentFieldProvider";
import { useTranslation } from "translations";

import { TicketAICopilotModal } from "./TicketAICopilotModal";

interface TicketCommentFieldAIActionsProps {
  ticketId: string;
}

export function TicketCommentFieldAIActions({ ticketId }: TicketCommentFieldAIActionsProps): React.ReactNode {
  const { t } = useTranslation();
  const [isCopilotModalOpened, copilotModalOpenHandlers] = useBool(false);

  const { currValue, isUndoable, isRedoable, onReplaceContent, onUndo, onRedo } = useContentFieldManager();

  return (
    <>
      <div className="flex shrink-0 flex-row gap-1 self-start @4xl:gap-2">
        <AIButton data-testid="ticket-response-ai-button" onClick={copilotModalOpenHandlers.setTrue}>
          {isUndoable
            ? t("page.tickets.details.copilot.button.regenerate")
            : t("page.tickets.details.copilot.button.generate")}
        </AIButton>
        {(isUndoable || isRedoable) && (
          <>
            <IconButton
              styling="secondary"
              disabled={!isUndoable}
              title={t("common.action.undo")}
              icon={iconUndo}
              onClick={onUndo}
            />
            <IconButton
              styling="secondary"
              disabled={!isRedoable}
              title={t("common.action.redo")}
              icon={iconRedo}
              onClick={onRedo}
            />
          </>
        )}
      </div>
      <TicketAICopilotModal
        isOpened={isCopilotModalOpened}
        onOpenChange={copilotModalOpenHandlers.set}
        onGenerated={onReplaceContent}
        ticketId={ticketId}
        defaultInput={{ input: currValue }}
      />
    </>
  );
}
