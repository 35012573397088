import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { useBool } from "hooks/useBool";
import { useConfig } from "providers/ConfigProvider";
import { useEffect } from "react";
import SuperTokens from "supertokens-web-js";
import EmailPassword from "supertokens-web-js/recipe/emailpassword";
import EmailVerification from "supertokens-web-js/recipe/emailverification";
import Passwordless from "supertokens-web-js/recipe/passwordless";
import Session from "supertokens-web-js/recipe/session";
import { useTranslation } from "translations";

import { useLogout } from "./useLogout";

// SuperTokens should only be initialized once. Even complete re-rendering of the app should not re-initialize it.
let superTokensInitialized: Date | undefined = undefined;
let sessionExpiredRef: { setSessionExpired?: () => void } = {};

export function SuperTokensProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const logout = useLogout();
  const authenticationServerRootUrl = useConfig("authenticationServerRootUrl");
  const newCoreApiRootUri = useConfig("newCoreApiRootUri");
  const [sessionExpired, sessionExpiredHandlers] = useBool();
  const [initialized, initializedHandlers] = useBool();

  useEffect(() => {
    sessionExpiredRef = {
      setSessionExpired: sessionExpiredHandlers.setTrue,
    };
  }, [sessionExpiredHandlers.setTrue]);

  useEffect(() => {
    initializedHandlers.setTrue();

    if (superTokensInitialized) {
      return;
    }

    SuperTokens.init({
      appInfo: {
        appName: "Area of People",
        apiDomain: authenticationServerRootUrl,
      },
      recipeList: [
        EmailPassword.init(),
        EmailVerification.init(),
        Session.init({
          maxRetryAttemptsForSessionRefresh: 1,
          tokenTransferMethod: "header",
          sessionTokenBackendDomain: newCoreApiRootUri,
          onHandleEvent(event) {
            if (event.action === "UNAUTHORISED") {
              // SuperTokens triggers this event when the session is expired, or on fresh page load when the user has never logged in.
              // On fresh page load we don't need to show the session expired modal.
              if (!superTokensInitialized || new Date().getTime() - superTokensInitialized.getTime() > 5000) {
                sessionExpiredRef.setSessionExpired?.();
              }

              void logout({ keepUrl: true });
            }
          },
        }),
        Passwordless.init(),
      ],
    });

    superTokensInitialized = new Date();
  }, [authenticationServerRootUrl, initializedHandlers, logout, newCoreApiRootUri, sessionExpiredHandlers]);

  if (!initialized) {
    return null;
  }

  return (
    <>
      {children}
      <SessionExpiredModal sessionExpired={sessionExpired} onClose={sessionExpiredHandlers.setFalse} />
    </>
  );
}

function SessionExpiredModal({
  sessionExpired,
  onClose,
}: {
  sessionExpired: boolean;
  onClose: () => void;
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      isOpened={sessionExpired}
      onOpenChange={(state) => {
        if (!state) {
          onClose();
        }
      }}
    >
      <article className="grid grid-cols-1 gap-y-2 px-8 py-4">
        <h2 className="max-w-sm justify-self-center text-headline3 leading-old-headline4">
          {t("component.session-expired-modal.title")}
        </h2>
        <p className="max-w-sm justify-self-center">{t("component.session-expired-modal.description")}</p>
        <div className="mt-4 flex flex-wrap justify-center gap-x-4 gap-y-2">
          <Button onClick={onClose} className="w-full sm:w-auto" styling="secondary">
            {t("component.session-expired-modal.log-in")}
          </Button>
        </div>
      </article>
    </Modal.Root>
  );
}
