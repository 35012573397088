import { autoUpdate, useFloating } from "@floating-ui/react";
import homesIcon from "assets/icons/project-homes-filled.svg";
import searchIcon from "assets/icons/search-sm.svg";
import { Icon } from "components/Icon/Icon";
import { useRecentProjects } from "components/ProjectSwitcher/useRecentProjects";
import { SearchInput } from "components/SearchInput/SearchInput";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useSidebarManager } from "hooks/useSidebar";
import { useProjectContext } from "providers/ProjectContext";
import type React from "react";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

import { PortfolioSidebarProjectSwitcher } from "./PortfolioSidebarProjectSwitcher";
import { PortfolioSidebarRecentProjectsList } from "./PortfolioSidebarRecentProjectsList";

const inProject = false;

export function PortfolioSidebarProjectsSection(): React.ReactNode {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isProjectSwitcherVisible, projectSwitcherVisibilityHandlers] = useBool();

  const { isCollapsed } = useSidebarManager();
  const { t } = useTranslation();
  const { projectId } = useProjectContext();
  const { isSearchAvailable } = useRecentProjects(projectId, !inProject, 3);
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: isCollapsed ? "right" : "top",
    open: isProjectSwitcherVisible,
  });

  useKey("Escape", projectSwitcherVisibilityHandlers.setFalse, isProjectSwitcherVisible);

  return (
    <>
      <div className="relative flex w-full flex-col items-center justify-start">
        {isCollapsed && isSearchAvailable && (
          <button
            className={twJoin(
              "flex w-full items-center justify-start rounded-lg p-3 outline-none focus-visible:bg-aop-basic-blue-100 focus-visible:text-black hover:bg-aop-basic-blue-100 hover:text-black",
              inProject && "text-grey-500",
            )}
            onClick={projectSwitcherVisibilityHandlers.setTrue}
            ref={refs.setReference}
          >
            <Icon name={searchIcon} size={16} />
          </button>
        )}
        {!isCollapsed && (
          <div className="flex w-full flex-col gap-2">
            <h2 className="flex items-center text-headline3">
              <span className="p-2">
                <Icon name={homesIcon} size={16} className="fill-black" />
              </span>
              <span>{t("navigation.portfolio.projects")}</span>
            </h2>

            {isSearchAvailable && (
              <SearchInput
                className="bg-transparent"
                placeholder={t("component.project-switcher.search.placeholder")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={() => projectSwitcherVisibilityHandlers.setTrue()}
                ref={refs.setReference}
              />
            )}
          </div>
        )}
        <PortfolioSidebarRecentProjectsList />
      </div>
      <PortfolioSidebarProjectSwitcher
        isOpened={isProjectSwitcherVisible}
        onClose={projectSwitcherVisibilityHandlers.setFalse}
        ref={refs.setFloating}
        style={floatingStyles}
      />
    </>
  );
}
