import type { AdminTicketDetailsDto, TicketStatusDto, UserDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconUser01 from "assets/icons/user-01.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Select } from "components/Select/Select";
import { TicketLeaveCommentModal } from "components/Ticket/TicketLeaveCommentModal";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { changingAssigneeWillResultIn403, useTicketCommentHandler } from "modules/tickets/helpers";
import { ticketMutations } from "queries/tickets";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import { Trans, useTranslation } from "translations";

interface TicketInfoHeaderProps {
  ticket: AdminTicketDetailsDto;
  showProject: boolean;
}

export function TicketInfoHeader({ ticket, showProject }: TicketInfoHeaderProps): React.ReactNode {
  const [changedAssignee, setChangedAssignee] = useState<UserDto>();
  const [isChangeStatusModalOpened, changeStatusModalHandler] = useBool();
  const [isLeaveCommentModalOpened, leaveCommentModalHandler] = useBool(false);
  const [warningBeforeAssigneeChangeUser, setWarningBeforeAssigneeChangeUser] = useState<UserDto>();

  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const { createComment } = useTicketCommentHandler(ticket.id);

  const changeAssigneeMutation = ticketMutations.useUpdateAssignee();
  const changeStatusMutation = ticketMutations.useUpdateStatus();

  const handleChangeStatus = async (status: TicketStatusDto) => {
    if (status.id === ticket.status.id) {
      return;
    }

    await changeStatusMutation.mutateAsync({
      ticketId: ticket.id,
      data: {
        statusId: status.id,
        silent: false,
      },
    });

    if (status.type === "inProgress" && ticket?.canAddInternalNote && ticket?.canAddPublicComment) {
      leaveCommentModalHandler.setTrue();
    }
  };

  const handleChangeAssignee = async (assignee: UserDto, note?: string) => {
    if (changingAssigneeWillResultIn403(ticket, assignee, sessionUser)) {
      setWarningBeforeAssigneeChangeUser(assignee);

      return;
    }

    await changeAssigneeMutation.mutateAsync({
      ticketId: ticket.id,
      data: {
        assigneeId: assignee.id,
        note,
      },
    });

    setChangedAssignee(undefined);
  };

  const handleLeaveComment = async (value: string, note: boolean) => {
    await createComment({
      content: value,
      images: [],
      documents: [],
      videos: [],
      type: note ? "internal" : "public",
    });

    leaveCommentModalHandler.setFalse();
  };

  const possibleStatuses =
    ticket?.possibleStatuses.map((status) => ({
      id: status.id,
      description: status.name,
      labelColor: status.color,
      defaultStatusId: status.type,
    })) || [];

  return (
    <>
      <div className="flex flex-col gap-6 rounded-lg border-b border-grey-100 bg-white p-4">
        <div className="flex flex-wrap gap-x-6 gap-y-2">
          {ticket.visibility === "private" && (
            <TicketHeaderInfoBlock title={t("page.tickets.details.info.type")}>
              <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
                <Icon name={iconUser01} />
                <span className="text-caption-bold">{t("page.tickets.details.info.type.private")}</span>
              </div>
            </TicketHeaderInfoBlock>
          )}
          {ticket.visibility !== "private" && (
            <TicketHeaderInfoBlock title={t("page.tickets.details.info.type")}>
              <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
                <Icon name={iconUsers01} />
                <span className="text-caption-bold">{t("page.tickets.details.info.type.collective")}</span>
              </div>
            </TicketHeaderInfoBlock>
          )}
          <TicketHeaderInfoBlock title={t("page.tickets.details.info.id")}>
            <div className="gap-1 rounded bg-blue-100 px-2 py-1.5">
              <span className="text-caption-bold">{ticket.id}</span>
            </div>
          </TicketHeaderInfoBlock>
          <TicketHeaderInfoBlock title={t("page.tickets.details.info.open-since")}>
            <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
              <span className="text-caption-bold">
                {t("page.tickets.details.info.open-since.content", {
                  count: daysBetween(parseISO(ticket.createdAt), new Date()),
                })}
              </span>
            </div>
          </TicketHeaderInfoBlock>
          <TicketHeaderInfoBlock title={t("page.tickets.details.info.status")} smallGap>
            {changeStatusMutation.isPending ? (
              <LoadingIcon className="my-1.5 ml-3 w-5" />
            ) : (
              <Select
                disabled={ticket.possibleStatuses.length === 0 || !ticket.canChangeStatus}
                selected={ticket.status}
                items={ticket.possibleStatuses}
                keySelector={(x) => x.id}
                renderOption={(x) => <TicketStatus description={x.name} labelColor={x.color} />}
                onChange={handleChangeStatus}
              />
            )}
          </TicketHeaderInfoBlock>
          <TicketHeaderInfoBlock title={t("page.tickets.details.info.assignee")} smallGap>
            {changeAssigneeMutation.isPending ? (
              <LoadingIcon className="my-1.5 ml-3 w-5" />
            ) : (
              <Select
                placeholder={t("page.tickets.details.info.assignee.unassigned")}
                disabled={!ticket.canChangeAssignee}
                selected={changedAssignee || ticket.assignee}
                items={ticket.possibleAssignees}
                keySelector={(x) => x.id}
                renderOption={(x) => x.fullName}
                onChange={(newAssignee) => {
                  if (newAssignee.id === ticket.assignee?.id) {
                    return;
                  }

                  if (ticket.canAddInternalNote) {
                    setChangedAssignee(newAssignee);
                  } else {
                    void handleChangeAssignee(newAssignee);
                  }
                }}
              />
            )}
          </TicketHeaderInfoBlock>
          {showProject && (
            <TicketHeaderInfoBlock title={t("page.tickets.details.info.project")}>
              <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
                <span className="text-caption-bold">{sessionUser.project.name}</span>
              </div>
            </TicketHeaderInfoBlock>
          )}
          <TicketHeaderInfoBlock title={t("page.tickets.details.info.category")}>
            <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
              <span className="text-caption-bold">{ticket.category.name}</span>
            </div>
          </TicketHeaderInfoBlock>
          {isDefined(ticket.rating) && (
            <TicketHeaderInfoBlock title={t("page.tickets.details.info.rating")}>
              <span>
                <span className="inline-flex items-center gap-1 rounded bg-yellow-100 px-2 py-1.5">
                  <span className="flex gap-0.5">
                    <Icon name={iconStar01} className="fill-current text-yellow-500" />
                  </span>
                  <span className="text-body-bold">{ticket.rating ?? "-"}</span>
                </span>
              </span>
            </TicketHeaderInfoBlock>
          )}
        </div>
        <TicketCreationInfo {...{ ticket }} />
      </div>

      <ConfirmModal
        title={t("page.tickets.before-assignee-change-modal.title")}
        description={t("page.tickets.before-assignee-change-modal.description")}
        isLoading={changeAssigneeMutation.isPending}
        isOpened={!!warningBeforeAssigneeChangeUser}
        onReject={() => setWarningBeforeAssigneeChangeUser(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setWarningBeforeAssigneeChangeUser(undefined);
          }
        }}
        onResolve={() => {
          if (!warningBeforeAssigneeChangeUser) {
            return;
          }

          setWarningBeforeAssigneeChangeUser(undefined);
          if (ticket.canAddInternalNote) {
            setChangedAssignee(warningBeforeAssigneeChangeUser);
          } else {
            changeAssigneeMutation.mutate({
              ticketId: ticket.id,
              data: {
                assigneeId: warningBeforeAssigneeChangeUser.id,
              },
            });
          }
        }}
      />
      <TicketLeaveCommentModal
        ticketId={ticket.id}
        title={t("page.tickets.after-status-change-modal.title")}
        description={
          <Trans
            i18nKey="page.tickets.after-status-change-modal.description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={ticket.canAddPublicComment}
        isOpened={isLeaveCommentModalOpened}
        onOpenChange={leaveCommentModalHandler.set}
        onSubmit={handleLeaveComment}
      />
      <TicketLeaveCommentModal
        ticketId={ticket.id}
        title={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.title"
            values={{ name: changedAssignee?.fullName || "" }}
            components={{
              bold: <strong className="font-old-semibold" />,
            }}
          />
        }
        description={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={false}
        isOpened={!!changedAssignee}
        onOpenChange={async (state) => {
          if (!state) {
            await handleChangeAssignee(changedAssignee!);
            setChangedAssignee(undefined);
          }
        }}
        onSubmit={(note) => {
          if (changedAssignee) {
            void handleChangeAssignee(changedAssignee, note);
          }
        }}
        assignee={changedAssignee}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpened={isChangeStatusModalOpened}
        onOpenChange={changeStatusModalHandler.set}
        onSubmit={async (status) => {
          await changeStatusMutation.mutateAsync({
            ticketId: ticket.id,
            data: {
              statusId: status.id,
              silent: false,
            },
          });
        }}
        targetStatus={possibleStatuses[0]}
        possibleStatuses={possibleStatuses}
      />
    </>
  );
}

interface TicketCreationInfoProps {
  ticket: AdminTicketDetailsDto;
}

function TicketCreationInfo({ ticket }: TicketCreationInfoProps) {
  const { i18n } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <Icon name={iconCalendar} size={16} />
      <span className="text-caption">
        <Trans
          i18nKey="page.tickets.details.created-at"
          values={{
            date: formatDate(i18n, "datetime", ticket.createdAt),
            name: ticket.createdBy.fullName,
          }}
          components={{
            bold: <b />,
          }}
        />
      </span>
    </div>
  );
}

function TicketHeaderInfoBlock({
  title,
  children,
  smallGap,
}: {
  title: string;
  children: React.ReactNode;
  smallGap?: boolean;
}) {
  return (
    <div className={twJoin("flex flex-col", smallGap ? "gap-1" : "gap-1.5")}>
      <span className="text-overline font-old-semibold text-grey-700">{title}</span>
      <div className="flex">{children}</div>
    </div>
  );
}
