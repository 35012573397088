import { ApiClientProvider } from "api/ApiClientProvider";
import { ErrorPage } from "components/Error/ErrorPage";
import { isDefined } from "helpers/util";
import { jwtDecode } from "jwt-decode";
import React, { useContext } from "react";
import { useParams } from "react-router";

import { QuickReplyUserLoader } from "./QuickReplyUserLoader";

type JwtPayload = {
  entityId?: string;
  projectId?: string;
  exp?: number;
};

type QuickReplyContextValues = {
  entityId: string;
};

const QuickReplyContext = React.createContext<QuickReplyContextValues>(null!);

type QuickReplyProviderProps = {
  children: React.ReactNode;
};

// Making sure a valid token is present and pass payloads to the children
export const QuickReplyProvider = ({ children }: QuickReplyProviderProps): React.ReactNode => {
  const { token: rawToken } = useParams<{ token: string }>();

  const token = rawToken ? rawToken.replace("V2-", "") : "";

  if (!token) {
    return <ErrorPage error={404} />;
  }

  let entityId: string | undefined;
  let projectId: string | undefined;
  let isTokenExpired = false;
  let isTokenInvalid = false;
  try {
    const tokenPayload = jwtDecode<JwtPayload>(token);

    entityId = tokenPayload.entityId;
    projectId = tokenPayload.projectId;
    isTokenExpired = isDefined(tokenPayload.exp) ? tokenPayload.exp < Math.ceil(Date.now() / 1000) : true;
  } catch (e) {
    isTokenInvalid = true;
  }

  if (isTokenInvalid || isTokenExpired) {
    return <ErrorPage error={404} />;
  }

  if (!entityId || !projectId) {
    return <ErrorPage error={500} />;
  }

  return (
    <QuickReplyContext.Provider
      value={{
        entityId,
      }}
    >
      <ApiClientProvider {...{ token }}>
        <QuickReplyUserLoader {...{ projectId }}>{children}</QuickReplyUserLoader>
      </ApiClientProvider>
    </QuickReplyContext.Provider>
  );
};

export const useQuickReplyManager = (): QuickReplyContextValues => {
  return useContext(QuickReplyContext);
};
