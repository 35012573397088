import type { QuestionDto, ResponseDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { avgBy } from "helpers/math";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { round } from "lodash-es";
import { BarChart } from "modules/analytics/components/BarChart";
import { PieChart } from "modules/analytics/components/PieChart";
import { usePostHog } from "posthog-js/react";
import { useParams } from "react-router";
import { useTranslation } from "translations";

import { SurveyOpenQuestionDetailsModal } from "./SurveyOpenQuestionDetailsModal";
import { SurveyOpenQuestionResponseSentimentModal } from "./SurveyOpenQuestionResponseSentimentModal";

export function YesNoWidget({ question }: { question: QuestionDto }): React.ReactNode {
  const { t } = useTranslation();
  const yes = question.responses?.filter((r) => r.pollAnswer === true).length ?? 0;
  const no = question.responses?.filter((r) => r.pollAnswer === false).length ?? 0;
  const total = yes + no;

  if (total === 0) {
    return null;
  }

  return (
    <div>
      <PieChart
        size={300}
        slices={[
          { label: t("page.survey-details.poll.answer.yes"), amount: yes },
          { label: t("page.survey-details.poll.answer.no"), amount: no },
        ]}
        hoverLabel={(x) => (
          <div className="flex flex-col">
            <strong>{x.label}</strong>
            <span>{t("page.survey-details.pie-chart.label.responses", { count: x.amount })}</span>
            <span>
              {t("page.survey-details.pie-chart.label.percentage", { percentage: round((x.amount / total) * 100) })}
            </span>
          </div>
        )}
      />
      <span className="align-bottom text-grey-600">{t("page.survey-details.poll.responses", { count: total })}</span>
    </div>
  );
}

const MAX_OPEN_ANSWERS = 5;

export function OpenAnswerWidget({ question }: { question: QuestionDto }): React.ReactNode {
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);
  const [isDetailsModalOpened, detailsModalOpenHandlers] = useBool();
  const { t } = useTranslation();
  const postHog = usePostHog();
  const { id: surveyId } = useParams<{ id: string }>();

  const responses = question.responses?.filter((response) => response.text);
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  if (!responses) {
    return null;
  }

  const onClickSentimentAnalysis = () => {
    postHog?.capture("clicked_survey_sentiment_analysis", {
      surveyId: surveyId,
    });

    sentimentModalOpenHandlers.setTrue();
  };

  const isAnyResponsesOmitted = responses.length > MAX_OPEN_ANSWERS;

  return (
    <>
      <div className="flex w-full flex-col gap-8">
        <div className="flex w-full flex-col gap-2">
          {responses.slice(0, MAX_OPEN_ANSWERS).map((response) => (
            <Answer key={response.id} response={response} />
          ))}
        </div>
        <div className=" flex w-full flex-col justify-center gap-2 md:flex-row md:items-center md:justify-end">
          {isAIToolingAvailable && (
            <AIButton onClick={onClickSentimentAnalysis}>{t("page.automated-surveys.sentiment.btn")}</AIButton>
          )}
          {isAnyResponsesOmitted && (
            <>
              <Button styling="primary" onClick={detailsModalOpenHandlers.setTrue}>
                {t("page.survey-details.open-answers.show-more")}
              </Button>
              <SurveyOpenQuestionDetailsModal
                question={question}
                responses={responses}
                isOpened={isDetailsModalOpened}
                onOpenChange={detailsModalOpenHandlers.set}
              />
            </>
          )}
        </div>
      </div>
      {isAIToolingAvailable && (
        <SurveyOpenQuestionResponseSentimentModal
          questionId={question.id}
          isOpened={isSentimentModalOpen}
          onOpenChange={sentimentModalOpenHandlers.set}
        />
      )}
    </>
  );
}

export function Answer({ response }: { response: ResponseDto }): React.ReactNode {
  return (
    <div className="flex items-center gap-2 py-2">
      <div className="mr-2 size-8">
        <UserAvatarLink user={response.respondent} />
      </div>
      <div className="flex flex-col">
        <span className="text-caption-bold text-black">
          <UserNameLink user={response.respondent}>{response.respondent.fullName}</UserNameLink>
        </span>
        <p className="text-grey-900">{response.text ?? "-"}</p>
      </div>
    </div>
  );
}

export function RatingWidget({ question }: { question: QuestionDto }): React.ReactNode {
  const { t } = useTranslation();

  if (!question.responses) {
    return null;
  }

  const validResponses = question.responses.filter((r) => r.rating != null);
  const averageRating = avgBy(
    validResponses,
    (r) => r.rating,
    () => 1,
  );

  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <Icon name={iconStar01} size={32} className="text-yellow-500" />
        <div className="text-headline1">
          <span>{averageRating ? round(averageRating, 1) : "-"}</span>
          <span className="text-headline3 font-old-normal">/10</span>
        </div>
      </div>
      <span className="align-bottom text-grey-600">
        {t("page.survey-details.rating.responses", { count: validResponses.length })}
      </span>
    </div>
  );
}

export function MultipleChoiceWidget({ question }: { question: QuestionDto }): React.ReactNode {
  const { t } = useTranslation();

  if (!question.answers) {
    return null;
  }

  const total = question.responses?.filter((x) => x.offeredAnswerId).length ?? 0;

  const slices = question.answers.map((a) => ({
    x: a.text,
    y: question.responses?.filter((r) => r.offeredAnswerId === a.id)?.length ?? 0,
  }));

  if (question.answers?.length === 2) {
    return (
      <PieChart
        size={300}
        slices={slices.map((x) => ({ amount: x.y, label: x.x }))}
        hoverLabel={(x) => (
          <div className="flex max-w-[200px] flex-col">
            <strong>{x.label}</strong>
            <span>{t("page.survey-details.pie-chart.label.responses", { count: x.amount })}</span>
            <span>
              {t("page.survey-details.pie-chart.label.percentage", { percentage: round((x.amount / total) * 100) })}
            </span>
          </div>
        )}
      />
    );
  }

  return (
    <div className="max-w-md">
      <BarChart
        primaryData={slices}
        formatYTick={(x) => (x % 1 === 0 ? x : "")}
        type="text"
        labelOrientation="vertical"
        formatXTick={(x) => (x.length > 10 ? x.slice(0, 10) + "..." : x)}
        renderLabel={(x) => (
          <div className="flex max-w-[200px] flex-col">
            {x.primary ? <span className="font-old-semibold">{x.primary?.x}</span> : null}
            <span>{t("page.survey-details.bar-chart.label.responses", { count: x.primary?.y || 0 })} </span>
            <span>
              {t("page.survey-details.bar-chart.label.percentage", {
                percentage: x.primary?.y && total ? round((x.primary?.y / total) * 100) : 0,
              })}
            </span>
          </div>
        )}
      />
    </div>
  );
}
