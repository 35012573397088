import { Button } from "components/Button/Button";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

type ServicesListActionProps = {
  entityType: "service" | "asset";
};

export function ServicesListAction({ entityType }: ServicesListActionProps): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();

  if (entityType === "service") {
    return (
      <Button
        type="link"
        className="w-full"
        href={routes.servicePartners.create({ slug })}
        data-testid="service-create-page-link"
      >
        {t("model.service.action.new")}
      </Button>
    );
  } else {
    return (
      <Button type="link" href={routes.bookings.createAsset({ slug })} styling="primary">
        {t("page.bookings.list-assets.actions.new")}
      </Button>
    );
  }
}
