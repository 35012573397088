import { usePrompt } from "hooks/usePrompt";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "translations";

interface Props<TFormValues extends FieldValues, TContext extends DefaultContext> {
  className?: string;
  formMethods: UseFormReturn<TFormValues, TContext>;
  onSubmit: (values: TFormValues) => void;
  noPrompt?: boolean;
  id?: string;
  "data-testid"?: string;
}

export function Form<TFormValues extends FieldValues, TContext extends DefaultContext = DefaultContext>({
  className,
  formMethods,
  onSubmit,
  children,
  noPrompt,
  id,
  ...props
}: React.PropsWithChildren<Props<TFormValues, TContext>>): React.ReactNode {
  const { t } = useTranslation();

  usePrompt(
    t("component.promt.confirmation"),
    !noPrompt && Object.keys(formMethods.formState.dirtyFields).length > 0 && !formMethods.formState.isSubmitted,
  );

  return (
    <FormProvider {...formMethods}>
      <form
        className={className}
        id={id}
        onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}
        data-testid={props["data-testid"]}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
type DefaultContext = object;
