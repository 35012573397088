import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";

export const usePortfolioQueries = () => {
  const api = useApi();

  return {
    list: ({ date }: { date: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.PORTFOLIO_LIST(date),
        queryFn: () =>
          api.getProjectOverviewV1({
            month: date,
            Offset: 0,
            Limit: 1000,
          }),
        select: commonAPIDataSelector,
      }),
    benchmarkStates: ({ date }: { date: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.PORTFOLIO_BENCHMARK(date),
        queryFn: () =>
          api.getProjectOverviewBenchmarkV1({
            month: date,
          }),
        select: commonAPIDataSelector,
      }),
    massMessagePermission: () =>
      queryOptions({
        queryKey: QUERY_KEYS.MASS_MESSAGES_PERMISSION(),
        queryFn: api.getMassMessagesV1,
        select: commonAPIDataSelector,
      }),
  };
};
