import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { QuickReplyMessagePage } from "./QuickReplyMessagePage";
import { QuickReplyTicketPage } from "./QuickReplyTicketPage";
import { quickReplyRoutes } from "./routes";

export const quickReplyRoute = [
  {
    path: quickReplyRoutes.message,
    element: <QuickReplyMessagePage />,
  },
  {
    path: quickReplyRoutes.ticket,
    element: <QuickReplyTicketPage />,
  },

  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
