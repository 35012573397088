import type { OnboardingScreenDto } from "api/types";
import { Button } from "components/Button/Button";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { OnboardingScreenPreview } from "modules/onboarding-screens/components/OnboardingScreenPreview";
import { canManageProjectManagement } from "modules/project/permissions";
import { routes } from "routes";
import { useTranslation } from "translations";

export interface LayoutProps {
  logoLight?: string;
  onboardingScreens: OnboardingScreenDto[];
  styling: {
    bgColor?: string;
    textColor?: string;
  };
  onDelete: (building: OnboardingScreenDto) => Promise<unknown>;
}

export function Layout({ logoLight, styling, onboardingScreens, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<OnboardingScreenDto>(
    "onboarding-screen-delete-modal",
  );
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.onboarding-screens.title")}
      subTitle={t("page.onboarding-screens.subtitle")}
      actions={
        hasPermission(canManageProjectManagement) && (
          <Button
            type="link"
            className="flex justify-center"
            href={routes.onboardingScreens.new({ slug })}
            data-testid="onboarding-screens-create-link"
          >
            {t("page.onboarding-screens.actions.create")}
          </Button>
        )
      }
    >
      <DeleteModal
        title={t("page.onboarding-screens.actions.delete.title")}
        description={t("page.onboarding-screens.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "onboarding-screens-action-delete",
        }}
        {...deleteModalProps}
      />
      {onboardingScreens.length === 0 ? (
        <div className="rounded-lg bg-white p-5">
          <p>{t("page.onboarding-screens.list.empty")}</p>
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-x-4 gap-y-6 md:justify-start">
          {onboardingScreens.map((x) => (
            <div data-testid="onboarding-screen-previewer-container" className="flex w-52 flex-col gap-2" key={x.id}>
              <OnboardingScreenPreview
                logoLight={logoLight}
                imageUrl={x.image.url}
                title={x.title}
                subtitle={x.subtitle}
                styling={styling}
              />
              {hasPermission(canManageProjectManagement) && (
                <div className="flex justify-center">
                  <Button
                    type="link"
                    styling="tertiary"
                    data-testid="edit-btn"
                    href={routes.onboardingScreens.edit({ slug, id: x.id })}
                  >
                    {t("page.onboarding-screens.preview.actions.edit")}
                  </Button>
                  <Button styling="tertiary" data-testid="delete-btn" onClick={() => openDeleteModal(x)}>
                    {t("page.onboarding-screens.preview.actions.delete")}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </DocumentPaper>
  );
}
