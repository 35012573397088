import type { MessageV2Dto } from "api/types";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { addMinutes } from "date-fns";
import { createRequiredStringRule } from "helpers/rules";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { communityFeedMutations } from "queries/communityFeed";
import type React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

import { PromotedGroupCard } from "../PromotedGroupCard";
import { CommunityPostAttachmentField } from "./CommunityPostAttachmentField";

export interface CommunityPostEditFormValues {
  title: string;
  body: string;
  images: FormImage[];
  videos: FormVideo[];
  documents: FormDocument[];
  scheduled?: Date;
}

interface CommunityPostEditFormProps {
  message: MessageV2Dto;
  onCompleteEdit: () => void;
  onCancelEdit: () => void;
}

export function CommunityPostEditForm({
  message,
  onCompleteEdit,
  onCancelEdit,
}: CommunityPostEditFormProps): React.ReactNode {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const form = useForm<CommunityPostEditFormValues>({
    defaultValues: {
      title: message.title ?? "",
      body: message.content ?? "",
      images: message.images || [],
      videos: message.videos || [],
      documents: message.documents || [],
      scheduled: message.scheduledAt ? new Date(message.scheduledAt) : undefined,
    },
  });

  const { uploadFormImage } = useUploadImage();
  const { uploadFormDocument } = useUploadDocument();
  const { uploadFormVideo, abortUploadVideos } = useUploadVideo({});
  const updateMessageMutation = communityFeedMutations.useUpdateMessage();

  const onSubmit = async () => {
    setIsSubmitting(true);
    const formValues = form.getValues();

    const imageUploadPromises = formValues.images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = formValues.documents.map((document) => uploadFormDocument(document));
    // Curently only 1 video is allowed
    const videoUploadPromises = formValues.videos.map((video) => uploadFormVideo(video));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];
    const videoIds = (await Promise.allSettled(videoUploadPromises.map((video) => video)))
      .map((video) => (video.status === "fulfilled" ? video.value?.id : ""))
      .filter(Boolean) as string[];

    if (
      imageIds.length === formValues.images.length &&
      documentIds.length === formValues.documents.length &&
      videoIds.length === formValues.videos.length
    ) {
      await updateMessageMutation.mutateAsync({
        messageId: message.id,
        payload: {
          title: formValues.title,
          content: formValues.body,
          scheduledAt: formValues.scheduled ? formValues.scheduled.toISOString() : undefined,
          imageIds,
          videoIds,
          documentIds,
        },
        successMessage: t("page.message-feed.edit.notifications.success"),
        failureMessage: t("page.message-feed.edit.notifications.error"),
      });
    }

    setIsSubmitting(false);
    onCompleteEdit();
  };

  const onClickCancel = () => {
    abortUploadVideos(form.getValues("videos"));
    onCancelEdit();
  };

  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  return (
    <Form formMethods={form} onSubmit={onSubmit}>
      <FormContent>
        <FormInput<CommunityPostEditFormValues>
          name="title"
          placeholder={t("page.message-feed.create.title.placeholder")}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.message-feed.create.title"),
            },
          }}
        />
        <FormTextArea<CommunityPostEditFormValues>
          className="max-h-[300px]"
          name="body"
          placeholder={t("page.message-feed.create.body.placeholder")}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.message-feed.create.body"),
            },
          }}
        />
        {message.relatedGroup ? (
          <PromotedGroupCard
            id={message.relatedGroup.id}
            name={message.relatedGroup.name}
            description={message.relatedGroup.description}
            image={message.relatedGroup.image}
            isReadonly
            isResidentGroup={message.relatedGroup.isResidentGroup}
          />
        ) : (
          <CommunityPostAttachmentField />
        )}
        {message.scheduledAt ? (
          <FormDateAndTimePicker<CommunityPostEditFormValues>
            name="scheduled"
            type="datetime"
            min={minScheduled}
            rules={{
              validate: {
                laterThanMin: (date) => {
                  if (date == null) {
                    return undefined;
                  }

                  return date < minScheduled
                    ? t("page.message-feed.create.schedule.datetime.error.must-be-in-future")
                    : undefined;
                },
              },
              required: t("components.form.error.required", {
                inputName: t("page.message-feed.create.schedule.datetime"),
              }),
            }}
          />
        ) : null}
      </FormContent>
      <div className="mt-4 flex justify-end gap-2">
        <Button styling="secondary" onClick={onClickCancel}>
          {t("common.action.cancel")}
        </Button>
        <Button styling="primary" type="submit" isLoading={isSubmitting}>
          {t("common.action.save")}
        </Button>
      </div>
    </Form>
  );
}
