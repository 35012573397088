import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CreateSurveyRequestDto, UpdateSurveyRequestDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { FormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const navigate = useNavigate();
  const slug = useSlug();
  const query = useQueryClient();
  const showFlashToast = useFlashToast();
  const { id: surveyId } = useParams<{ id: string }>();

  const {
    data: surveyDetails,
    isFetching: isFetchingSurveyDetails,
    error: surveyDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.SURVEY_DETAILS(projectId, surveyId!),
    queryFn: () => api.getSurveysDetailsV1(surveyId!),
    select: commonAPIDataSelector,
    enabled: !!surveyId,
  });

  const queryArgs = {
    Limit: 10000,
  } satisfies ApiQueryParams<"getGroupsV2">;
  const {
    data: groupsData = [],
    isLoading: isLoadingGroups,
    error: groupsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUPS_QUERY(projectId, queryArgs),
    queryFn: () => api.getGroupsV2(queryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const createSurvey = useMutation({
    mutationFn: (payload: CreateSurveyRequestDto) => api.postSurveysV1(payload).then((x) => x.data),
    onSuccess: (survey) => {
      showFlashToast({
        type: "success",
        title: t("page.surveys-create-or-edit.new.notification.success"),
      });
      void navigate(routes.surveys.details({ slug, id: survey.id }));
      void query.invalidateQueries({ queryKey: QUERY_KEYS.SURVEYS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.surveys-create-or-edit.new.notification.error") });
    },
  });

  const editSurvey = useMutation({
    mutationFn: (payload: UpdateSurveyRequestDto) => api.putSurveysV1(surveyId!, payload).then((x) => x.data),
    onSuccess: (survey) => {
      showFlashToast({
        type: "success",
        title: t("page.surveys-create-or-edit.edit.notification.success"),
      });
      void navigate(routes.surveys.details({ slug, id: survey.id }));
      void query.invalidateQueries({ queryKey: QUERY_KEYS.AUTOMATED_SURVEY_DETAILS(surveyId!) });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.SURVEYS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.surveys-create-or-edit.edit.notification.error") });
    },
  });

  const groups = useMemo(
    () => [
      { id: undefined, name: t("page.surveys.create-or-edit.form.group.general") },
      ...groupsData
        .filter((group) => group.canCreatePost || group.canCreateAnnouncement)
        .filter((group) => group.postingLevel !== "projectConnection")
        .map((group) => ({ id: group.id, name: group.name })),
    ],
    [t, groupsData],
  );

  const defaultFormValues: FormValues = useMemo(() => {
    if (surveyDetails) {
      return {
        type: surveyDetails.type,
        category: surveyDetails.category,
        startsAt: surveyDetails.startsAt ? parseISO(surveyDetails.startsAt) : "",
        endsAt: surveyDetails.endsAt ? parseISO(surveyDetails.endsAt) : "",
        group: surveyDetails.group ?? groups[0],
        title: surveyDetails.title,
        description: surveyDetails.description,
        thankYouMessage: surveyDetails.completedMessage,
        questions: surveyDetails.questions.map((question) => ({
          qId: question.id,
          type: question.type,
          question: question.question,
          options: question.answers?.map((option) => ({ oId: option.id, option: option.text })),
          useForAnalysis: question.useForAnalysis,
        })),
        internal: surveyDetails.internal,
      };
    }

    return {
      type: "survey",
      category: "other",
      startsAt: "",
      endsAt: "",
      group: groups[0],
      title: "",
      description: "",
      thankYouMessage: "",
      questions: [{ type: "open", question: "" }],
      internal: false,
    };
  }, [surveyDetails, groups]);

  const loading = isFetchingSurveyDetails || isLoadingGroups;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  const error = surveyDetailsError || groupsError || surveyDetails?.type === "poll";
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    defaultFormValues,
    groups,
    createSurvey: createSurvey.mutateAsync,
    editSurvey: editSurvey.mutateAsync,
    isSubmitting: createSurvey.isPending || editSurvey.isPending,
    isPublished: surveyDetails?.isPublished ?? false,
  });
}
