import { Menu as ArkMenu } from "@ark-ui/react";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { Link } from "react-router";

import type { ContextMenuItem } from "./ContextMenu";

type ContextMenuListItemProps = {
  item: ContextMenuItem;
};

export function ContextMenuListItem({ item }: ContextMenuListItemProps): React.ReactNode {
  const getLabel = (): string => {
    if (item.isDisabled && item.labels.disabled) {
      return item.labels.disabled;
    }

    return item.labels.default;
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (item.isDisabled) {
      return;
    }

    if (item.callbackFn) {
      return item.callbackFn();
    }
  };

  const hasSubItems = item.subItems && item.subItems.length > 0;
  const ItemComponent = hasSubItems ? ArkMenu.TriggerItem : ArkMenu.Item;

  return (
    <ItemComponent
      value={item.value}
      disabled={item.isDisabled}
      data-testid={item["data-testid"]}
      onClick={handleClick}
      className={twResolve(
        "flex w-full cursor-default items-center gap-2 px-3 py-1 text-left transition-colors duration-100 focus-visible:outline-none data-[disabled]:cursor-default data-[disabled]:bg-white data-[highlighted]:bg-blue-100 data-[disabled]:text-grey-400 hover:bg-blue-100 disabled:[&_svg]:stroke-grey-400",
        item.type === "trigger" && "pr-2",
      )}
      asChild={item.type === "link"}
    >
      {item.type === "link" ? (
        <Link to={item.href}>
          {item.icon && <Icon name={item.icon} />}
          <span className="text-body">{getLabel()}</span>
        </Link>
      ) : (
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            {item.icon && <Icon name={item.icon} />}
            <span className="text-body">{getLabel()}</span>
          </div>
          {item.type === "trigger" && <Icon name={iconChevronRight} className="text-grey-500" />}
        </div>
      )}
    </ItemComponent>
  );
}
