import iconCamera01 from "assets/icons/camera-01.svg";
import iconFileAttachment01 from "assets/icons/file-attachment-01.svg";
import iconPlayCircle from "assets/icons/play-circle.svg";
import { Icon } from "components/Icon/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { useId, useRef } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

interface CommentFieldActionBaseProps {
  isSubmitting?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

interface CommentFieldAddImageActionProps extends CommentFieldActionBaseProps {
  onAddImages: (images: FileList) => void;
}

function CommentFieldAddImageAction({
  disabled,
  tooltip,
  isSubmitting,
  onAddImages,
}: CommentFieldAddImageActionProps): React.ReactNode {
  const id = useId();
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    onAddImages(e.target.files);
    e.target.value = "";
  };

  let formattedTooltip: string | null = tooltip || t("component.comment-field.upload-image");
  if (disabled) {
    formattedTooltip = null;
  }

  return (
    <Tooltip tooltip={formattedTooltip}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full transition-colors",
          disabled
            ? "opacity-30"
            : "focus-within:text-grey-900 focus:outline-none hover:bg-blue-100 hover:text-grey-900",
          isSubmitting && "pointer-events-none opacity-30",
        )}
        htmlFor={`comment-image-input-${id}`}
        data-testid="add-image-btn"
      >
        <input
          data-testid="image-input"
          className="sr-only"
          ref={fileInput}
          type="file"
          id={`comment-image-input-${id}`}
          name="img"
          accept={ALLOWED_FILE_TYPES.IMAGE}
          onChange={onSelectImage}
          disabled={disabled}
        />
        <Icon name={iconCamera01} size={20} />
      </label>
    </Tooltip>
  );
}

interface CommentFieldAddVideoActionProps extends CommentFieldActionBaseProps {
  onAddVideo: (video: FileList) => void;
}

function CommentFieldAddVideoAction({
  disabled,
  tooltip,
  isSubmitting,
  onAddVideo,
}: CommentFieldAddVideoActionProps): React.ReactNode {
  const id = useId();
  const { t } = useTranslation();

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    onAddVideo(event.target.files);
    event.target.value = "";
  };

  let formattedTooltip: string | null = tooltip || t("component.comment-field.upload-video");
  if (disabled) {
    formattedTooltip = null;
  }

  return (
    <Tooltip tooltip={formattedTooltip}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full",
          disabled
            ? "opacity-30"
            : "transition-colors focus-within:text-grey-900 focus:outline-none hover:bg-blue-100 hover:text-grey-900",
          isSubmitting && "pointer-events-none opacity-30",
        )}
        htmlFor={`comment-video-input-${id}`}
        data-testid="add-video-btn"
      >
        <input
          data-testid="video-input"
          className="sr-only"
          type="file"
          id={`comment-video-input-${id}`}
          name="video"
          accept={ALLOWED_FILE_TYPES.VIDEO}
          onChange={onSelectFile}
          {...{ disabled }}
        />
        <Icon name={iconPlayCircle} size={20} />
      </label>
    </Tooltip>
  );
}

interface CommentFieldAddDocumentActionProps extends CommentFieldActionBaseProps {
  onAddDocument: (document: FileList) => void;
}

function CommentFieldAddDocumentAction({
  disabled,
  tooltip,
  isSubmitting,
  onAddDocument,
}: CommentFieldAddDocumentActionProps): React.ReactNode {
  const id = useId();
  const { t } = useTranslation();

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    onAddDocument(event.target.files);
    event.target.value = "";
  };

  let formattedTooltip: string | null = tooltip || t("component.comment-field.upload-document");
  if (disabled) {
    formattedTooltip = null;
  }

  return (
    <Tooltip tooltip={formattedTooltip}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full",
          disabled
            ? "opacity-30"
            : "transition-colors focus-within:text-grey-900 focus:outline-none hover:bg-blue-100 hover:text-grey-900",
          isSubmitting && "pointer-events-none opacity-30",
        )}
        htmlFor={`comment-document-input-${id}`}
        data-testid="add-document-btn"
      >
        <input
          data-testid="document-input"
          className="sr-only"
          type="file"
          id={`comment-document-input-${id}`}
          name="document"
          accept={ALLOWED_FILE_TYPES.DOCUMENT}
          onChange={onSelectFile}
          {...{ disabled }}
        />
        <Icon name={iconFileAttachment01} size={20} />
      </label>
    </Tooltip>
  );
}

export const CommentFieldActions = {
  AddImage: CommentFieldAddImageAction,
  AddVideo: CommentFieldAddVideoAction,
  AddDocument: CommentFieldAddDocumentAction,
};
