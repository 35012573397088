import type { AssetBookingDto, EventCategoryDto, EventDto } from "api/types";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import { Checkbox } from "components/Checkbox/Checkbox";
import { ContentTabs, type Tab } from "components/ContentTabs/ContentTabs";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import type { CalendarData, EventsFilters, UpdatableEventFilter } from "modules/calendar/constants";
import { type CalendarTab, EVENTS_TAB } from "modules/calendar/constants";
import { canManageEvents } from "modules/events/permissions";
import { getEventCategoryName } from "modules/events/util";
import { ReservationCard } from "modules/reservations/pages/ReservationsList/components/ReservationsCard";
import { usePostHog } from "posthog-js/react";
import { useDeferredValue, useEffect, useState } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

import { EventCard } from "./components/EventCard";

export interface LayoutProps {
  tabs: Tab<CalendarTab>[];
  activeTab: CalendarTab;
  onTabChange: (tab: CalendarTab) => void;
  eventsFilters: Omit<EventsFilters, "TimeFrame" | "Connection">;
  eventCategories: EventCategoryDto[];
  onEventFilterChange: (filter: UpdatableEventFilter, value: string | boolean | undefined) => void;
  events: { upcoming: CalendarData<EventDto>; past: CalendarData<EventDto> };
  reservationsSearch: string;
  onReservationsSearch: (query: string) => void;
  reservations: { upcoming: CalendarData<AssetBookingDto>; past: CalendarData<AssetBookingDto> };
}

const DEBOUNCE_WAIT = 250;

export function Layout({
  tabs,
  activeTab,
  onTabChange,
  eventsFilters,
  eventCategories,
  onEventFilterChange,
  events,
  reservationsSearch,
  onReservationsSearch,
  reservations,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const posthog = usePostHog();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.calendar.title")}
      subTitle={t("page.calendar.subtitle")}
      actions={
        activeTab === EVENTS_TAB ? (
          hasPermission(canManageEvents) && (
            <Button
              type="link"
              styling="primary"
              href={routes.events.create({ slug })}
              onClick={() => posthog?.capture("clicked_create_event")}
            >
              {t("page.calendar.button.new-event")}
            </Button>
          )
        ) : (
          <Button type="link" styling="primary" href={routes.bookings.list({ slug })}>
            {t("page.calendar.button.new-reservation")}
          </Button>
        )
      }
    >
      <ContentTabs tabs={tabs} activeTabId={activeTab} onTabChange={onTabChange}>
        {activeTab === EVENTS_TAB ? (
          <EventsFilterTab categories={eventCategories} onFilterUpdate={onEventFilterChange} filters={eventsFilters} />
        ) : (
          <ReservationsFilterTab onSearch={onReservationsSearch} />
        )}
      </ContentTabs>
      {activeTab === EVENTS_TAB ? (
        <EventsContentTab search={eventsFilters.Search} upcoming={events.upcoming} past={events.past} />
      ) : (
        <ReservationsContentTab upcoming={reservations.upcoming} past={reservations.past} search={reservationsSearch} />
      )}
    </DocumentPaper>
  );
}

interface EventsFiltersProps {
  onFilterUpdate: (filter: UpdatableEventFilter, value: string | boolean | undefined) => void;
  filters: Omit<EventsFilters, "TimeFrame" | "Connection">;
  categories: EventCategoryDto[];
}

function EventsFilterTab({ categories, onFilterUpdate, filters }: EventsFiltersProps): React.ReactNode {
  const { t } = useTranslation();

  const [showEventsFilters, eventsFiltersHandler] = useBool(false);
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onFilterUpdate("Search", deferredSearch);
  }, [deferredSearch, onFilterUpdate]);

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center gap-2">
        <SearchInput
          data-testid="search-input"
          className="lg:w-96"
          placeholder={t("page.calendar.search-events.placeholder")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button styling="secondary" onClick={eventsFiltersHandler.toggle}>
          <span className="flex items-center gap-2">
            <Icon name={iconFilterFunnel01} size={16} />
            {t("page.tickets.header.button.filter")}
          </span>
        </Button>
      </div>
      {showEventsFilters ? (
        <div className="flex flex-col gap-6">
          <span className="text-body-bold">{t("page.calendar.tabs.events.filter.label")}</span>
          <div className="flex flex-wrap gap-6">
            <label className="flex items-center gap-2">
              <Checkbox name="CreatedByMe" onChange={(e) => onFilterUpdate("CreatedByMe", e.target.checked)} />
              <span className="text-body">{t("page.calendar.tabs.events.filter.my-events")}</span>
            </label>
            <label className="flex items-center gap-2">
              <Checkbox name="IsSignedUp" onChange={(e) => onFilterUpdate("IsSignedUp", e.target.checked)} />
              <span className="text-body">{t("page.calendar.tabs.events.filter.going")}</span>
            </label>
            <Select
              placeholder={t("page.calendar.tabs.events.filter.type.placeholder")}
              renderOption={(category) => getEventCategoryName(t, category.id)}
              keySelector={(category) => category.id}
              items={[{ id: "all", name: t("model.event.category.all") } as const, ...categories]}
              selected={filters.Category ? categories.find((x) => x.id === filters.Category) : undefined}
              onChange={(x) => onFilterUpdate("Category", x.id === "all" ? undefined : x.id)}
            />
          </div>
          <div className="self-center justify-self-end">
            <Button styling="tertiary" onClick={eventsFiltersHandler.setFalse}>
              <Icon name={iconX} size={16} />
              <span className="text-caption">{t("page.calendar.tabs.events.filter.close")}</span>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface EventsContentTabProps {
  search?: string;
  upcoming: CalendarData<EventDto>;
  past: CalendarData<EventDto>;
}

function EventsContentTab({ search, upcoming, past }: EventsContentTabProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 pt-4">
      <span className="text-body-bold">{t("page.calendar.events.section.upcoming")}</span>
      {
        // eslint-disable-next-line no-nested-ternary
        upcoming.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : upcoming.items.length === 0 ? (
          <span className="text-caption">
            {search ? t("page.calendar.events.search.no-results") : t("page.calendar.events.no-upcoming-events")}
          </span>
        ) : (
          <>
            <CardGrid>
              {upcoming.items.map((event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </CardGrid>
            {
              // eslint-disable-next-line no-nested-ternary
              upcoming.isLoadingMore ? (
                <div className="flex w-full justify-center">
                  <LoadingIcon className="size-4" />
                </div>
              ) : upcoming.total! > upcoming.items.length ? (
                <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={upcoming.loadMore}>
                  {t("page.calendar.events.section.show-remaining", { count: upcoming.total! - upcoming.items.length })}
                </Button>
              ) : null
            }
          </>
        )
      }
      <span className="text-body-bold">{t("page.calendar.events.section.past")}</span>
      {
        // eslint-disable-next-line no-nested-ternary
        past.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : past.items.length === 0 ? (
          <span className="text-caption">
            {search ? t("page.calendar.events.search.no-results") : t("page.calendar.events.no-past-events")}
          </span>
        ) : (
          <>
            <CardGrid>
              {past.items.map((event) => (
                <EventCard key={event.id} event={event} isPast />
              ))}
            </CardGrid>
            {
              // eslint-disable-next-line no-nested-ternary
              past.isLoadingMore ? (
                <div className="flex w-full justify-center">
                  <LoadingIcon className="size-4" />
                </div>
              ) : past.hasMore ? (
                <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={past.loadMore}>
                  {t("page.calendar.events.section.show-more")}
                </Button>
              ) : null
            }
          </>
        )
      }
    </div>
  );
}

interface ReservationsFilterTabProps {
  onSearch: (query: string) => void;
}

function ReservationsFilterTab({ onSearch }: ReservationsFilterTabProps): React.ReactNode {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onSearch(deferredSearch);
  }, [deferredSearch, onSearch]);

  return (
    <div className="p-4">
      <SearchInput
        className="lg:w-96"
        placeholder={t("page.calendar.search-reservations.placeholder")}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

interface ReservationsContentTabProps {
  search?: string;
  upcoming: CalendarData<AssetBookingDto>;
  past: CalendarData<AssetBookingDto>;
}

function ReservationsContentTab({ upcoming, past, search }: ReservationsContentTabProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 pt-4">
      <span className="text-body-bold">{t("page.calendar.reservations.section.upcoming")}</span>
      {
        // eslint-disable-next-line no-nested-ternary
        upcoming.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : upcoming.items.length === 0 ? (
          <span className="text-caption">
            {search
              ? t("page.calendar.reservations.search.no-results")
              : t("page.calendar.reservations.no-upcoming-reservations")}
          </span>
        ) : (
          <>
            <CardGrid>
              {upcoming.items.map((reservation) => (
                <ReservationCard key={reservation.id} reservation={reservation} />
              ))}
            </CardGrid>
            {
              // eslint-disable-next-line no-nested-ternary
              upcoming.isLoadingMore ? (
                <div className="flex w-full justify-center">
                  <LoadingIcon className="size-4" />
                </div>
              ) : upcoming.hasMore ? (
                <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={upcoming.loadMore}>
                  {t("page.calendar.reservations.section.show-more")}
                </Button>
              ) : null
            }
          </>
        )
      }
      <span className="text-body-bold">{t("page.calendar.reservations.section.past")}</span>
      {
        // eslint-disable-next-line no-nested-ternary
        past.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingIcon className="size-4" />
          </div>
        ) : past.items.length === 0 ? (
          <span className="text-caption">
            {search
              ? t("page.calendar.reservations.search.no-results")
              : t("page.calendar.reservations.no-past-reservations")}
          </span>
        ) : (
          <>
            <CardGrid>
              {past.items.map((reservation) => (
                <ReservationCard key={reservation.id} reservation={reservation} isPast />
              ))}
            </CardGrid>
            {
              // eslint-disable-next-line no-nested-ternary
              past.isLoadingMore ? (
                <div className="flex w-full justify-center">
                  <LoadingIcon className="size-4" />
                </div>
              ) : past.hasMore ? (
                <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={past.loadMore}>
                  {t("page.calendar.events.section.show-more")}
                </Button>
              ) : null
            }
          </>
        )
      }
    </div>
  );
}
