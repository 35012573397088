import { Switch } from "components/Switch/Switch";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { chatMutations } from "queries/chats";
import type React from "react";
import { useTranslation } from "translations";

export function ChatListHeader(): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const mutationUpdateChatsState = chatMutations.useUpdateChatState();

  return (
    <div className="flex items-center justify-end gap-2">
      <span className="text-caption-bold">{t("page.chats.switch.chat")}</span>
      <Switch
        onChange={() => mutationUpdateChatsState.mutateAsync({ state: !sessionUser.chatEnabled })}
        isChecked={!!sessionUser.chatEnabled}
        labels={{ on: t("page.chats.switch.on"), off: t("page.chats.switch.off") }}
      />
    </div>
  );
}
