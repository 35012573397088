import type { AssetBookingDto } from "api/types";
import { Button } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { useDebounce } from "hooks/useDebounce";
import { useSlug } from "hooks/useSlug";
import { useDeferredValue, useEffect, useState } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

import { ReservationCard } from "./components/ReservationsCard";

interface ReservationData {
  items: AssetBookingDto[];
  total?: number;
  isLoading: boolean;
  hasMore?: boolean;
  loadMore: () => void;
  isLoadingMore: boolean;
}

export interface LayoutProps {
  onSearch: (search: string) => void;
  upcoming: ReservationData;
  past: ReservationData;
}

const DEBOUNCE_WAIT = 250;

export function Layout({ onSearch, upcoming, past }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(useDebounce(search.toLowerCase().trim(), DEBOUNCE_WAIT));

  useEffect(() => {
    onSearch(deferredSearch);
  }, [deferredSearch, onSearch]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.reservations.title")}
      subTitle={t("page.bookings.reservations.subtitle")}
      actions={
        <Button type="link" href={routes.bookings.list({ slug })}>
          {t("page.bookings.reservations.button.new-reservation")}
        </Button>
      }
      header={
        <SearchInput
          className="lg:w-96"
          placeholder={t("page.bookings.reservations.search.placeholder")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <span className="text-body-bold">{t("page.bookings.reservations.section.upcoming")}</span>
        {
          // eslint-disable-next-line no-nested-ternary
          upcoming.isLoading ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : upcoming.items.length === 0 ? (
            <span className="text-caption">
              {search
                ? t("page.bookings.reservations.search.no-results")
                : t("page.bookings.reservations.no-upcoming-reservations")}
            </span>
          ) : (
            <>
              <CardGrid>
                {upcoming.items.map((reservation) => (
                  <ReservationCard key={reservation.id} reservation={reservation} />
                ))}
              </CardGrid>
              {
                // eslint-disable-next-line no-nested-ternary
                upcoming.isLoadingMore ? (
                  <div className="flex w-full justify-center">
                    <LoadingIcon className="size-4" />
                  </div>
                ) : upcoming.hasMore ? (
                  <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={upcoming.loadMore}>
                    {t("page.bookings.reservations.section.show-more")}
                  </Button>
                ) : null
              }
            </>
          )
        }
        <span className="text-body-bold">{t("page.bookings.reservations.section.past")}</span>
        {
          // eslint-disable-next-line no-nested-ternary
          past.isLoading ? (
            <div className="flex w-full justify-center">
              <LoadingIcon className="size-4" />
            </div>
          ) : past.items.length === 0 ? (
            <span className="text-caption">
              {search
                ? t("page.bookings.reservations.search.no-results")
                : t("page.bookings.reservations.no-past-reservations")}
            </span>
          ) : (
            <>
              <CardGrid>
                {past.items.map((reservation) => (
                  <ReservationCard key={reservation.id} reservation={reservation} isPast />
                ))}
              </CardGrid>
              {
                // eslint-disable-next-line no-nested-ternary
                past.isLoadingMore ? (
                  <div className="flex w-full justify-center">
                    <LoadingIcon className="size-4" />
                  </div>
                ) : past.hasMore ? (
                  <Button styling="tertiary" className="text-aop-basic-blue-500" onClick={past.loadMore}>
                    {t("page.bookings.reservations.section.show-more")}
                  </Button>
                ) : null
              }
            </>
          )
        }
      </div>
    </DocumentPaper>
  );
}
