import checkCircleIcon from "assets/icons/aop/check-circle-filled.svg";
import xIcon from "assets/icons/aop/clear-input.svg";
import checkIcon from "assets/icons/check.svg";
import { Icon } from "components/Icon/Icon";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export function PasswordHelper({
  password,
  hasSubmittedPassword,
}: {
  password: string;
  hasSubmittedPassword: boolean;
}): React.ReactNode {
  const { t } = useTranslation();

  const rules = [
    {
      translation: t("page.login.errors.password-minimum-chars", { chars: 8 }),
      fn: (e) => e.length >= 8,
    },
    {
      translation: t("page.login.errors.password-lowercase"),
      fn: (e) => e.toUpperCase() !== e,
    },
    {
      translation: t("page.login.errors.password-number"),
      fn: (e) => /\d/.test(e),
    },
  ] satisfies { translation: string; fn: (e: string) => boolean }[];

  const anyFailed = hasSubmittedPassword && rules.some((rule) => !rule.fn(password));

  return (
    <div className="flex flex-col text-overline">
      <p className={twJoin("mb-1 mt-2 text-left text-overline", anyFailed ? "text-red-600" : "")}>
        {t("page.login.errors.password-requirements")}
      </p>
      <div className="flex flex-col gap-1">
        {rules.map((rule) => {
          const match = rule.fn(password);
          // eslint-disable-next-line no-nested-ternary
          const state = match ? "success" : !hasSubmittedPassword ? "notMatchingYet" : "failed";

          return (
            <div key={rule.translation} className="flex items-center gap-1">
              <span className="flex size-4 items-center justify-center">
                {state === "success" && <Icon name={checkCircleIcon} size={14} className="text-green-600" />}
                {state === "notMatchingYet" && <Icon name={checkIcon} size={16} className="text-grey-900" />}
                {state === "failed" && <Icon name={xIcon} size={14} className="text-red-600" />}
              </span>
              <span
                className={twJoin(
                  state === "success" && "text-green-600",
                  state === "notMatchingYet" && "text-grey-900",
                  state === "failed" && "text-red-600",
                )}
              >
                {rule.translation}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
