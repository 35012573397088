import { Icon } from "components/Icon/Icon";
import type React from "react";
import { useId } from "react";

import type { ButtonProps } from "./Button";
import { buttonVariants } from "./Button";

export type UploadFileButtonProps = Pick<ButtonProps, "styling" | "disabled" | "size"> &
  Pick<React.InputHTMLAttributes<HTMLInputElement>, "accept" | "onChange"> & {
    icon?: string;
    children: React.ReactNode;
    name?: string;
  };

export function UploadFileButton({
  children,
  icon,
  name,
  accept,
  onChange,
  disabled,
  ...buttonProps
}: UploadFileButtonProps): React.ReactNode {
  const id = useId();

  return (
    <>
      <label
        className={buttonVariants({
          styling: buttonProps.styling,
          size: buttonProps.size,
        })}
        htmlFor={id}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
      >
        {icon && <Icon name={icon} />}
        {children}
      </label>
      <input
        data-testid="upload-input"
        className="sr-only"
        type="file"
        disabled={disabled}
        {...{ id, name, accept, onChange }}
      />
    </>
  );
}
