import { Button } from "components/Button/Button";
import type { FormInputProps } from "components/Form/FormInput";
import { getCharFromCharCode, ICON_54 } from "components/Icon54/Icon54";
import { IconPicker } from "components/IconPicker/IconPicker";
import { IconMedia } from "components/Media/IconMedia";
import { Modal } from "components/Modal/Modal";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface FormIconPickerProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends FormInputProps<TFormValues, TName> {
  titleText?: string;
  openText?: string;
  cancelText?: string;
  selectText?: string;
}

export function FormIconPicker<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, ...props }: FormIconPickerProps<TFormValues, TName>): React.ReactNode {
  const { t } = useTranslation();
  const { register, setValue } = useFormContext<TFormValues>();
  const { errors } = useFormState<TFormValues>();
  const selectedIcon = (useWatch<TFormValues, TName>({ name }) as string) || "";
  const error = get(errors, name);
  const [modalCharCode, setModalCharCode] = useState<number | null>(null);
  const [isOpen, modalOpenHandlers] = useBool(false);

  useEffect(() => {
    setModalCharCode(selectedIcon === "" || isNaN(+selectedIcon) ? null : +selectedIcon);
  }, [selectedIcon]);

  useEffect(() => {
    function listener(event: ClipboardEvent) {
      const clipboardContent = event.clipboardData?.getData?.("text")?.trim();
      if (clipboardContent) {
        const charCode = clipboardContent.charCodeAt?.(0);
        if (!Number.isNaN(charCode) && charCode >= ICON_54.min && charCode <= ICON_54.max) {
          setModalCharCode(charCode);
          setValue(name, getCharFromCharCode(charCode == null ? 0 : charCode) as any, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
          modalOpenHandlers.setFalse();
        }
      }
    }

    if (isOpen) {
      window.addEventListener("paste", listener as EventListener);

      return () => {
        return window.removeEventListener("paste", listener as EventListener);
      };
    }
  }, [isOpen, modalOpenHandlers, name, setValue]);

  function openModal() {
    modalOpenHandlers.setTrue();
    const charCode = selectedIcon.charCodeAt(0);
    setModalCharCode(isNaN(charCode) || selectedIcon === "" ? null : charCode);
  }

  const title = props.titleText || t("component.group-icon-picker-input.title");

  return (
    <FormErrorWrapper className="flex items-start gap-4" name={name} data-testid={props["data-testid"]}>
      <IconMedia
        charCode={selectedIcon !== "" ? selectedIcon.charCodeAt(0) : undefined}
        onAdd={openModal}
        onEdit={openModal}
        aria-invalid={isDefined(error)}
        data-testid="icon-char-media"
        data-edit-testid="icon-char-action-open"
        data-add-testid="icon-char-action-open"
      />
      <input type="hidden" readOnly {...register(name, props.rules)} className="hidden" />
      <Modal.Root title={title} isOpened={isOpen} onOpenChange={modalOpenHandlers.set}>
        <div className="relative flex h-[295px] w-full overflow-y-auto rounded-md border border-grey-300">
          <div className="h-fit w-full p-3">
            <IconPicker selectedCharCode={modalCharCode} onChange={setModalCharCode} />
          </div>
        </div>
        <Modal.Actions>
          <Button
            type="button"
            styling="secondary"
            onClick={() => {
              setModalCharCode(null);
              modalOpenHandlers.setFalse();
            }}
          >
            {props.cancelText || t("common.action.cancel")}
          </Button>
          <Button
            type="button"
            styling="primary"
            disabled={modalCharCode == null}
            onClick={() => {
              if (modalCharCode != null) {
                setValue(name, getCharFromCharCode(modalCharCode == null ? 0 : modalCharCode) as any, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });

                modalOpenHandlers.setFalse();
              }
            }}
          >
            {props.selectText || t("common.action.select")}
          </Button>
        </Modal.Actions>
      </Modal.Root>
    </FormErrorWrapper>
  );
}
