import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CompanyRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

const useCreateCompany = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: CompanyRequest) => api.postCompaniesV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.companies.new.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.companies.new.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
};

const useEditCompany = (companyId: string) => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: CompanyRequest) => api.putCompaniesV1(companyId, data),
    onSuccess() {
      showFlashToast({ title: t("page.companies.edit.notification.success"), type: "success" });
    },
    onError() {
      showFlashToast({ title: t("page.companies.edit.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
};

const useDeleteCompany = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (companyId: string) => api.deleteCompaniesByIdV1(companyId),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.companies.actions.delete.notifications.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.companies.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
};

const useRefreshLocationCode = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (companyId: string) => api.putCompaniesRegenerateLocationCodeV1(companyId),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.companies.actions.refresh-location-code.notifications.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.companies.actions.refresh-location-code.notifications.failed"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
};

export const companyMutations = {
  useCreateCompany,
  useEditCompany,
  useDeleteCompany,
  useRefreshLocationCode,
};
