import { Tooltip } from "components/Tooltip/Tooltip";

export function Stat({
  title,
  value,
  tooltip,
}: {
  title: string;
  value: number | string;
  tooltip?: string;
}): React.ReactNode {
  return (
    <div className="flex flex-1 flex-col justify-between rounded-lg border border-grey-100 px-3 py-2">
      <span className="text-caption text-grey-700">{title}</span>
      <Tooltip tooltip={tooltip}>
        <span className="w-min text-headline1">{value}</span>
      </Tooltip>
    </div>
  );
}
