import { Tooltip } from "components/Tooltip/Tooltip";
import { twResolve, twVariants } from "helpers/tw-resolve";
import type React from "react";
import { type LinkProps, NavLink } from "react-router";
import type { VariantProps } from "tailwind-variants";

import { ButtonLoader } from "./ButtonLoader";

export type ButtonBaseProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  children: React.ReactNode;
  title?: string;
  "data-testid"?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isPressed?: boolean;
  isLoading?: boolean;
  isCircular?: boolean;
};

export type ButtonProps = ButtonBaseProps & {
  styling?: ButtonVariants["styling"];
  size?: ButtonVariants["size"];
  icon?: React.ReactNode;
} & (
    | {
        type: "link";
        href: string;
        state?: LinkProps["state"];
        target?: LinkProps["target"];
        isExternal?: boolean;
      }
    | {
        type?: "button" | "submit";
        href?: never;
        state?: never;
        target?: never;
        isExternal?: never;
      }
  );

export function Button({
  children,
  title,
  styling = "primary",
  type,
  "data-testid": dataTestId,
  className,
  href,
  target,
  state,
  icon,
  size = "md",
  disabled,
  isPressed,
  isLoading,
  isExternal,
  isCircular,
  onClick,
  ...otherProps
}: ButtonProps): React.ReactNode {
  const propsCommon = {
    onClick,
    "data-testid": dataTestId,
    "aria-pressed": isPressed,
    className: twResolve(
      buttonVariants({
        styling,
        size,
      }),
      isCircular && "rounded-full",
      className,
    ),
  };

  const Content = (
    <>
      {icon}
      {children}
    </>
  );

  if (type === "link") {
    if (isExternal) {
      return (
        <Tooltip tooltip={title}>
          <a rel="noopener noreferrer" {...{ ...propsCommon, target, href }}>
            {Content}
          </a>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip={title}>
        <NavLink to={href} {...{ ...propsCommon, state, target }}>
          {Content}
        </NavLink>
      </Tooltip>
    );
  }

  return (
    <Tooltip tooltip={title}>
      <button type={type || "button"} disabled={disabled || isLoading} {...{ ...propsCommon, ...otherProps }}>
        {Content}
        <ButtonLoader isVisible={isLoading} />
      </button>
    </Tooltip>
  );
}

export const buttonVariants = twVariants({
  base: [
    "group relative flex w-fit shrink-0 cursor-pointer items-center justify-center gap-2 truncate rounded-lg text-center focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1",
    "text-btn-label no-underline transition-colors duration-200 disabled:cursor-not-allowed",
  ],
  variants: {
    styling: {
      primary:
        "bg-aop-basic-blue-500 text-btn-label-bold text-white focus-visible:ring-blue-600 hover:bg-aop-basic-blue-600 disabled:bg-grey-300 disabled:text-white",
      secondary:
        "border border-grey-300 bg-white text-black focus-visible:ring-blue-600 hover:border-aop-basic-blue-100 hover:bg-aop-basic-blue-100 disabled:border-grey-100 disabled:bg-white disabled:text-grey-300",
      tertiary:
        "border border-transparent bg-transparent text-aop-basic-blue-500 focus-visible:ring-blue-600 hover:border-grey-100 hover:bg-aop-basic-blue-100 disabled:border-transparent disabled:bg-transparent disabled:text-grey-300",
      danger:
        "border border-red-500 bg-white text-red-500 focus-visible:ring-red-600 hover:bg-red-100 disabled:border-red-200 disabled:bg-white disabled:text-red-200",
      ghostPrimary:
        "text-aop-basic-blue-500 focus:text-aop-basic-blue-600 focus-visible:ring-blue-600 hover:text-aop-basic-blue-600 disabled:text-grey-300",
      ghostSecondary:
        "text-black focus-visible:ring-blue-600 hocus:text-aop-basic-blue-600 disabled:border-grey-100 disabled:text-grey-300",
      ai: "border border-bright-purple-500 bg-bright-purple-100 text-btn-label-bold text-bright-purple-500 focus-visible:ring-bright-purple-500 hover:bg-bright-purple-200 disabled:border-grey-300 disabled:bg-grey-100 disabled:text-grey-300",
    },
    size: {
      sm: "h-8 px-2 text-caption",
      md: "h-10 px-4",
    },
  },
  compoundVariants: [
    {
      styling: ["ghostPrimary", "ghostSecondary"],
      className: "h-auto rounded-none px-0",
    },
  ],
  defaultVariants: {
    styling: "primary",
    size: "md",
  },
});

type ButtonVariants = VariantProps<typeof buttonVariants>;
