import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useScrollToHash } from "hooks/useScrollToHash";
import { Suspense, useMemo } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";
import type { NonEmptyArray } from "types/utility-types";

import type { DateRangeOption } from "../../util";
import { ActiveContributorsOverviewCard } from "../Charts/ActiveContributorsOverviewCard";
import { AverageTicketClosingTimeCard } from "../Charts/AverageTicketClosingTimeCard";
import { AverageTicketRatingCard } from "../Charts/AverageTicketRatingCard";
import { BucketLeadTimeCard } from "../Charts/BucketLeadTimeCard";
import { CommunicationsChart } from "../Charts/CommunicationsChart";
import { ConversationsStartedCard } from "../Charts/ConversationsStartedCard";
import { EngagedResidentsCard } from "../Charts/EngagedResidentsCard";
import { FeelingAtHomeSurveyChart } from "../Charts/FeelingAtHomeSurveyChart";
import { MessageTypesCard } from "../Charts/MessageTypesCard";
import { PlatformAdoptionCard, PlatformAdoptionCompanyCard } from "../Charts/PlatformAdoptionCard";
import { TicketOverviewCard } from "../Charts/TicketOverviewCard";

export function Layout(): React.ReactNode {
  return (
    <Suspense fallback={<FullSizeLoader withPadding />}>
      <PageContent />
    </Suspense>
  );
}

/**
 * Content in a separate component so that useScrollToHash runs after the content is loaded.
 */
function PageContent() {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const dateRangeOptions = useMemo<NonEmptyArray<DateRangeOption>>(
    () => [
      {
        name: t("common.date-range.last-months", { count: 6 }),
        value: "last6Months",
      },
      {
        name: t("common.date-range.last-year"),
        value: "lastYear",
      },
    ],
    [t],
  );

  useScrollToHash();

  return (
    <DocumentPaper
      title={t("page.analytics-overview.overview.title")}
      subTitle={t("page.analytics-overview.overview.subtitle")}
      theme="minimal"
    >
      <PageGrid.Grid>
        <PageGrid.Item size="100%">
          <PageContentSection
            id="sentiment-section"
            title={t("page.analytics-overview.overview.sentiment.title")}
            description={t("page.analytics-overview.overview.note")}
          >
            <FeelingAtHomeSurveyChart />
          </PageContentSection>
        </PageGrid.Item>

        <PageGrid.Item size="100%">
          <PageContentSection title={t("page.analytics-overview.overview.platform-adoption.title")}>
            {sessionUser.project.type === "companyBased" ? <PlatformAdoptionCompanyCard /> : <PlatformAdoptionCard />}
          </PageContentSection>
        </PageGrid.Item>

        <PageGrid.Item size="100%">
          <PageContentSection title={t("page.analytics-overview.overview.esg.title")}>
            <EngagedResidentsCard />
            <ActiveContributorsOverviewCard />
            <CommunicationsChart />
            <ConversationsStartedCard dateRangeOptions={dateRangeOptions} />
            <MessageTypesCard dateRangeOptions={dateRangeOptions} />
          </PageContentSection>
        </PageGrid.Item>

        <PageGrid.Item size="100%">
          <PageContentSection title={t("page.analytics-overview.overview.tickets.title")}>
            <div className="flex flex-col gap-6">
              <TicketOverviewCard />
              <AverageTicketRatingCard dateRangeOptions={dateRangeOptions} />
            </div>
            <div className="flex flex-col gap-6">
              <BucketLeadTimeCard />
              <AverageTicketClosingTimeCard dateRangeOptions={dateRangeOptions} />
            </div>
          </PageContentSection>
        </PageGrid.Item>
      </PageGrid.Grid>
    </DocumentPaper>
  );
}

interface PageContentSectionProps {
  id?: string;
  title: string;
  description?: string;
  children: React.ReactElement | React.ReactElement[];
}

function PageContentSection({ id, title, description, children }: PageContentSectionProps): React.ReactNode {
  return (
    <div className="flex size-full flex-col items-start gap-4">
      <div className="flex w-full items-center justify-between">
        <h1 className="text-headline3 leading-old-headline4 text-grey-900" {...{ id }}>
          {title}
        </h1>
        <span className="text-overline text-grey-500">{description}</span>
      </div>
      <div
        className={twJoin(
          "grid w-full grid-cols-1 flex-col items-start gap-6",
          Array.isArray(children) ? "lg:grid-cols-2" : "@lg:grid-cols-1 @6xl:grid-cols-2",
        )}
      >
        {children}
      </div>
    </div>
  );
}
