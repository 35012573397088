import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ConnectionFailurePage } from "components/Error/ConnectionFailurePage";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";

import { I18nProvider } from "./implementation/I18nProvider";
import type { Languages, Translations } from "./implementation/types";

const SUPPORTED_LANGUAGES = ["en", "nl", "de"] as const;
const navigatorLanguage = window.navigator.language.split("-")[0];
const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.includes(navigatorLanguage as Languages)
  ? (navigatorLanguage as Languages)
  : "en";

interface Props {
  children: React.ReactNode;
}

// Utility for showing translation keys in the UI for ease of debugging
const disableTranslations = getLocalStorageValue<boolean>("disableTranslations", false);

export function TranslationsProvider({ children }: Props): React.ReactNode {
  const [language, setLanguage] = useState(() => getLocalStorageValue<Languages>("language", DEFAULT_LANGUAGE));
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    setLanguage(DEFAULT_LANGUAGE);
  }

  useUpdateLocalStorage("language", language);

  const api = useApi();
  const { data, error } = useQuery({
    queryKey: QUERY_KEYS.TRANSLATIONS(language),
    queryFn: async () => {
      if (disableTranslations) {
        return {};
      }

      const result = await api.getTranslationsDetailsV1("dashboard", language);

      // Load local translations in development
      if (import.meta.env.DEV) {
        const devTranslations = await import("./english.json");
        const translationHelpers = await import("translations/implementation/parseTranslations");
        const devData = translationHelpers.parseTranslations(devTranslations.default);
        result.data = { ...devData, ...result.data };
      }

      return result.data;
    },
    retry: 3,
    retryDelay: (x) => x * 1000,
  });

  const missingKeyHandler = useCallback((key: string, languageId: string) => {
    if (!disableTranslations) {
      Sentry.captureException(`Missing translation for ${languageId}: ${key}`);
    }
  }, []);

  const missingVariableHandler = useCallback((key: string, value: string, languageId: string) => {
    if (!disableTranslations) {
      Sentry.captureException(`Missing variable in ${value} in translation for ${languageId}: ${key}`);
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("lang", language);
  }, [language]);

  if (error) {
    return <ConnectionFailurePage poEditorCode={language} />;
  }

  if (data) {
    return (
      <I18nProvider
        language={language}
        translations={data as Translations}
        onChangeLanguage={setLanguage}
        onMissingKey={missingKeyHandler}
        onMissingVariable={missingVariableHandler}
      >
        {children}
      </I18nProvider>
    );
  }
}
