import { useImageResolver } from "api/hooks/useImageResolver";
import iconX from "assets/icons/x.svg";
import iconZoomIn from "assets/icons/zoom-in.svg";
import { ImageModal } from "components/Carousel/Carousel";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Tooltip } from "components/Tooltip/Tooltip";
import { preloadImage } from "helpers/image";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { AnimatePresence, motion } from "motion/react";
import { memo, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export interface GalleryProps {
  images: FormImage[];
  size?: "small" | "normal";
  isDeleted?: boolean;
  onRemove?: (image: FormImage) => void;
}

export const Gallery = memo(function Gallery({ images, isDeleted, onRemove, size }: GalleryProps) {
  const [isImageModalOpened, imageModalHandler] = useBool(false);
  const [zoomedImageIndex, setZoomedImageIndex] = useState<number | undefined>(undefined);

  const { t } = useTranslation();
  const resolveImage = useImageResolver();

  useKey("Escape", () => imageModalHandler.setFalse());

  if (images.length === 0) {
    return null;
  }

  return (
    <>
      <div className={twJoin("flex flex-wrap items-end gap-2", onRemove ? "pt-6" : undefined)}>
        <AnimatePresence>
          {images.map((image, i) => (
            <button
              type="button"
              key={image.url}
              className={twJoin("group relative cursor-pointer", isDeleted ? "opacity-30" : undefined)}
              onClick={() => {
                setZoomedImageIndex(i);
                imageModalHandler.setTrue();
              }}
              onMouseEnter={() => {
                if ("id" in image) {
                  preloadImage(image.url);
                }
              }}
            >
              <motion.div className="absolute inset-0" layoutId={image.url} />
              <img
                className={twJoin(
                  "max-w-full select-none rounded bg-grey-100 shadow",
                  size === "small" ? "max-h-[50px]" : "max-h-36 sm:max-w-xs",
                )}
                loading="lazy"
                src={"id" in image ? resolveImage(image, "intrinsic") : image.url}
                alt={("description" in image && image.description) || ""}
              />

              {onRemove ? (
                <div className="absolute -right-4 -top-4">
                  <Tooltip tooltip={t("component.gallery.remove-image")}>
                    <motion.button
                      className="flex size-8 items-center justify-center rounded-full bg-grey-900 shadow transition-opacity focus:bg-grey-600 focus:outline-none hover:bg-grey-700 hover:shadow-md"
                      type="button"
                      layout
                      exit={{ opacity: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        onRemove(image);
                      }}
                    >
                      <Icon name={iconX} size={20} className="text-white" />
                    </motion.button>
                  </Tooltip>
                </div>
              ) : null}

              <div className="absolute right-2 top-2 flex size-8 items-center justify-center rounded-full bg-black/40 opacity-0 shadow transition-opacity group-hover:opacity-100">
                <Tooltip tooltip={t("component.gallery.zoom-in-image")}>
                  <Icon name={iconZoomIn} size={20} className="text-white" />
                </Tooltip>
              </div>
            </button>
          ))}
        </AnimatePresence>
      </div>
      <ImageModal
        isOpened={isImageModalOpened}
        onOpenChange={imageModalHandler.toggle}
        images={images}
        zoomedImageIndex={zoomedImageIndex}
      />
    </>
  );
});
