import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageReportRequest } from "api/types";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

type ReportReason =
  | "other"
  | "explicitLanguage"
  | "spam"
  | "sexuallyExplicit"
  | "discrimination"
  | "bullying"
  | "impersonating"
  | "promotingViolence";

interface FormValues {
  reason: ReportReason;
  description?: string;
}

const MIN_LENGTH_CONTENT = 8;
const MAX_LENGTH_CONTENT = 500;

const reportReasons: ReportReason[] = [
  "explicitLanguage",
  "spam",
  "sexuallyExplicit",
  "discrimination",
  "bullying",
  "impersonating",
  "promotingViolence",
  "other",
];

type ReportPostModalProps = ModalBaseProps & {
  messageId: string;
};

export function ReportPostModal({ messageId, isOpened, onOpenChange }: ReportPostModalProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const api = useApi();
  const query = useQueryClient();
  const form = useForm<FormValues>();
  const reason = useWatch({ control: form.control, name: "reason" });
  const description = useWatch({ control: form.control, name: "description" });

  const reportPost = useMutation({
    mutationFn: (payload: MessageReportRequest) => api.postMessagesReportV1(messageId, payload),
    onSuccess: () => {
      void query.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, messageId) });
      showFlashToast({ type: "success", title: t("component.community-post.report.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.report.error") });
    },
  });

  async function handleReport() {
    const formValues = form.getValues();

    await reportPost.mutateAsync({
      reason: formValues.reason,
      description: formValues.description,
    });
    onOpenChange(false);
  }

  return (
    <Modal.Root
      size="sm"
      title={t("component.community-post.report.modal.title")}
      description={t("component.community-post.report.modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-8">
        <Form formMethods={form} onSubmit={handleReport}>
          <FormContent>
            <FormField label={t("component.community-post.report.modal.input.reason.title")} required>
              <FormSelect<FormValues, ReportReason>
                name="reason"
                placeholder={t("component.community-post.report.modal.input.reason.placeholder")}
                items={reportReasons}
                keySelector={(x) => x}
                renderOption={(x) => {
                  switch (x) {
                    case "explicitLanguage":
                      return t("component.community-post.report.modal.options.explicit-language");
                    case "spam":
                      return t("component.community-post.report.modal.options.spam");
                    case "sexuallyExplicit":
                      return t("component.community-post.report.modal.options.sexually-explicit");
                    case "discrimination":
                      return t("component.community-post.report.modal.options.discrimination");
                    case "bullying":
                      return t("component.community-post.report.modal.options.bullying");
                    case "impersonating":
                      return t("component.community-post.report.modal.options.impersonating");
                    case "promotingViolence":
                      return t("component.community-post.report.modal.options.promoting-violence");
                    case "other":
                      return t("component.community-post.report.modal.options.other");
                  }
                }}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("component.community-post.report.modal.input.reason.title"),
                  }),
                }}
              />
            </FormField>
            {reason === "other" && (
              <FormField label={t("component.community-post.report.modal.input.description.title")} required>
                <FormTextArea<FormValues>
                  name="description"
                  placeholder={t("component.community-post.report.modal.input.description.placeholder")}
                  rules={{
                    minLength: {
                      message: t("components.form.error.min-length", { length: MIN_LENGTH_CONTENT }),
                      value: MIN_LENGTH_CONTENT,
                    },
                    maxLength: {
                      message: t("components.form.error.max-length", { length: MAX_LENGTH_CONTENT }),
                      value: MAX_LENGTH_CONTENT,
                    },
                    validate: {
                      required: createRequiredStringRule(
                        t,
                        "component.community-post.report.modal.input.description.title",
                      ),
                    },
                  }}
                />
              </FormField>
            )}
          </FormContent>
          <Modal.Actions>
            <Modal.Close>
              <Button styling="secondary">{t("common.action.cancel")}</Button>
            </Modal.Close>
            <Button
              data-testid="modal-report-button"
              styling="danger"
              type="submit"
              isLoading={reportPost.isPending}
              disabled={!reason || (reason === "other" && !description)}
            >
              {t("component.community-post.report.modal.report-button")}
            </Button>
          </Modal.Actions>
        </Form>
      </div>
    </Modal.Root>
  );
}
