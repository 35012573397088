import { useQuery } from "@tanstack/react-query";
import iconDownload01 from "assets/icons/download-01.svg";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { formatAddress } from "helpers/address";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { convertToCsv, downloadCsvAsExcel } from "modules/admin/helpers/sheet";
import { useAddressQueries } from "queries/addresses/queryOptions";
import type React from "react";
import { useCallback } from "react";
import { Async } from "react-async";
import { useTranslation } from "translations";

type AddressesExportModalProps = ModalBaseProps & {};

export function AddressesExportModal({ isOpened, onOpenChange }: AddressesExportModalProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const addressQueries = useAddressQueries();
  const { data: addresses, isLoading } = useQuery({ ...addressQueries.getAddresses(), enabled: isOpened });

  const csv = useCallback(async () => {
    return {
      all: await convertToCsv(
        addresses?.items.flatMap((a) =>
          a.users.length === 0
            ? [
                {
                  building: a.building.name,
                  address: formatAddress(a),
                  locationCode: a.locationCode,
                  fullName: "",
                  email: "",
                  registered: "",
                },
              ]
            : a.users.map((u) => ({
                building: a.building.name,
                address: formatAddress(a),
                locationCode: a.locationCode,
                fullName: u.fullName,
                email: u.email,
                registered: u.registeredAt ? t("common.yes") : t("common.no"),
              })),
        ) || [],
      ),
      activated: await convertToCsv(
        addresses?.items.flatMap((a) =>
          a.users
            .filter((u) => u.registeredAt)
            .map((u) => ({
              building: a.building.name,
              address: formatAddress(a),
              locationCode: a.locationCode,
              fullName: u.fullName,
              email: u.email,
            })),
        ) || [],
      ),
      notActivated: await convertToCsv(
        addresses?.items.flatMap((a) =>
          a.users
            .filter((u) => !u.registeredAt)
            .map((u) => ({
              building: a.building.name,
              address: formatAddress(a),
              locationCode: a.locationCode,
              fullName: u.fullName,
              email: u.email,
            })),
        ) || [],
      ),
    };
  }, [addresses?.items, t]);

  return (
    <Modal.Root isScrollable {...{ isOpened, onOpenChange }}>
      {isLoading && <FullSizeLoader withPadding />}
      {!isLoading && (
        <Async promiseFn={csv} onReject={console.error}>
          <Async.Resolved<{ all: string; activated: string; notActivated: string }>>
            {(x) => (
              <div className="mb-10 flex flex-col gap-10">
                <div>
                  <h2 className="mb-4 text-headline3">{t("page.addresses.list.export-group.addresses")}</h2>
                  <div className="flex flex-col gap-2">
                    <span className="text-body-bold text-grey-600 ">{t("page.addresses.list.all-addresses")}</span>
                    <Button
                      onClick={() => downloadCsvAsExcel(`addresses-${sessionUser.project.name}`, x.all)}
                      icon={<Icon name={iconDownload01} size={16} />}
                    >
                      {t("page.addresses.list.export-as-xlsx")}
                    </Button>
                  </div>
                </div>
                <div>
                  <h2 className="mb-4 text-headline3">{t("page.addresses.list.export-group.users")}</h2>
                  <div className="flex flex-wrap gap-8">
                    <div className="flex flex-col gap-2">
                      <span className="text-body-bold text-grey-600 ">{t("page.addresses.list.onboarded-users")}</span>
                      <Button
                        onClick={() => downloadCsvAsExcel(`activated-users-${sessionUser.project.name}`, x.activated)}
                        icon={<Icon name={iconDownload01} size={16} />}
                      >
                        {t("page.addresses.list.export-as-xlsx")}
                      </Button>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="text-body-bold text-grey-600 ">{t("page.addresses.list.invited-users")}</span>
                      <Button
                        onClick={() =>
                          downloadCsvAsExcel(`non-activated-users-${sessionUser.project.name}`, x.notActivated)
                        }
                        icon={<Icon name={iconDownload01} size={16} />}
                      >
                        {t("page.addresses.list.export-as-xlsx")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Async.Resolved>
          <Async.Pending>
            <div className="w-52 max-w-sm py-12">
              <FullSizeLoader />
            </div>
          </Async.Pending>
          <Async.Rejected>
            <p className="p-8">{t("common.generic-error")}</p>
          </Async.Rejected>
        </Async>
      )}
    </Modal.Root>
  );
}
