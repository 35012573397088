import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { TicketStatusRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined, parseAsNumber } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import type { FormValues, LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const { id: statusId } = useParams<{ id: string }>();
  const showFlashToast = useFlashToast();
  const isEditMode = isDefined(statusId);
  const navigate = useNavigate();
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: ticketStatusList,
    error: ticketStatusListError,
    isFetching: ticketStatusListIsFetching,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_STATUSES(projectId),
    queryFn: () => api.getTicketStatusesV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: ticketStatus,
    isFetching: ticketStatusIsFetching,
    error: ticketStatusError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_STATUS(projectId, statusId!),
    queryFn: () => api.getTicketStatusesDetailsV1(statusId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });

  const createTicketStatus = useMutation({
    mutationFn: api.postTicketStatusesV1,
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.ticket-status.action.create.notification.success"),
      });
      void navigate(routes.ticketStatuses.list({ slug }));
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.ticket-status.action.create.notification.error") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_STATUSES(projectId) });
    },
  });

  const editTicketStatus = useMutation({
    mutationFn: (payload: TicketStatusRequest) => api.putTicketStatusesV1(statusId!, payload),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.ticket-status.action.edit.notification.success"),
      });
      void navigate(routes.ticketStatuses.list({ slug }));
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.ticket-status.action.edit.notification.error") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_STATUSES(projectId) });
    },
  });

  function onSubmit(formData: FormValues) {
    const payload: TicketStatusRequest = {
      nameTranslations: toTranslationsRequest(formData.translations),
      notificationIntervalInHours: parseAsNumber(formData.notificationIntervalInHours),
      color: formData.color,
      isDefault: formData.isDefault,
      type: formData.type,
    };

    if (isEditMode) {
      editTicketStatus.mutate(payload);
    } else {
      createTicketStatus.mutate(payload);
    }
  }

  const isSubmitting = editTicketStatus.isPending || createTicketStatus.isPending;

  const error = ticketStatusError || ticketStatusListError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = ticketStatusIsFetching || ticketStatusListIsFetching || languagesIsFetching;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {children({
        languages,
        isEditMode,
        isSubmitting,
        onSubmit,
        ticketStatuses: ticketStatusList?.items || [],
        defaultFormValues: {
          translations: createFormTranslations({ languages, translations: ticketStatus?.nameTranslations }),
          color: ticketStatus?.color,
          isDefault: ticketStatus?.isDefault ?? false,
          notificationIntervalInHours:
            ticketStatus?.notificationIntervalInHours?.toString() || (isEditMode ? undefined : "168"),
          type: ticketStatus?.type,
        },
      })}
    </>
  );
}
