import type { CommunityGroupV2Dto, SelfProjectDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Paper } from "components/Paper/Paper";
import { useSlug } from "hooks/useSlug";
import { NextButton } from "modules/onboarding-flow/components/NextButton";
import { OnboardingGroup } from "modules/onboarding-flow/components/OnboardingGroup";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

export interface LayoutProps {
  groups: CommunityGroupV2Dto[];
  total: number;
  project: SelfProjectDto;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
}

export function Layout({ groups, total, project, onFollow, onUnfollow }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col">
      <div className="flex-auto md:flex md:h-full md:flex-row">
        <main className="z-10 min-h-full w-full overflow-auto">
          <Paper theme="no-header-minimal">
            <div className="flex size-full items-center justify-center">
              <div className="flex min-h-[700px] w-[320px] flex-col justify-between gap-3 overflow-y-auto rounded-lg bg-white shadow-md">
                <div className="flex flex-col gap-4 p-4">
                  <Button
                    type="link"
                    styling="tertiary"
                    className="p-0 text-aop-basic-blue-500 underline"
                    href={routes.onboardingFlow.userProfile({ slug })}
                  >
                    <Icon name={iconChevronLeft} size={16} />
                    {t("common.action.back")}
                  </Button>
                  <span className="text-headline3 leading-old-headline4">{t("page.onboarding.groups.title")}</span>
                  <span className="text-body">{t("page.onboarding.groups.description")}</span>
                  <span className="text-caption-bold text-grey-600">
                    {t("page.onboarding.groups.subtext", { count: total })}
                  </span>
                </div>
                {groups.map((group) => (
                  <OnboardingGroup
                    key={group.id}
                    group={group}
                    projectDefaultImage={project.backgroundImage}
                    onFollow={onFollow}
                    onUnfollow={onUnfollow}
                  />
                ))}
                <div className="flex justify-end justify-self-end">
                  <NextButton onClick={() => void navigate(routes.onboardingFlow.helpCategories({ slug }))}>
                    {t("common.action.next")}
                  </NextButton>
                </div>
              </div>
            </div>
          </Paper>
        </main>
      </div>
    </div>
  );
}
