import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { ChatReplyDto, ChatReplyRequest } from "api/types";
import iconSettings01 from "assets/icons/settings-01.svg";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { canEditOtherUserNotificationSettings } from "modules/admin-notifications/permissions";
import type { ChatReplyPayload } from "modules/chats/components/ChatWindow";
import { ChatWindow } from "modules/chats/components/ChatWindow";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost/CommunityPost";
import { UserInfoCard } from "modules/users/components/UserInfoCard";
import { chatMutations, useChatQueries } from "queries/chats";
import { useUserQueries } from "queries/users";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import { UserDeletionModal } from "./components/UserDeletionModal";

export function UserDetailsPage(): React.ReactNode {
  const [chatReplies, setChatReplies] = useState<ChatReplyDto[]>([]);
  const [isDeleteModalOpened, deleteModalOpenHandlers] = useBool(false);

  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();
  const { id: userId } = useParams<{ id: string }>();
  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});

  const chatQueries = useChatQueries();
  const userQueries = useUserQueries();
  const {
    data: chat,
    isPending: isPendingChat,
    error: errorChat,
  } = useQuery({
    ...chatQueries.userDetails({ userId: userId! }),
    enabled: Boolean(userId),
  });
  const {
    data: userDetails,
    isPending: isPendingUserDetails,
    error: errorUserDetails,
  } = useQuery({
    ...userQueries.details({ userId: userId! }),
    enabled: Boolean(userId),
  });
  const { isLoading: isLoadingUserProjects } = useQuery({
    ...userQueries.listProjects({ userId: userId! }),
    enabled: Boolean(userId) && sessionUser.isSuperAdmin,
  });
  const { isLoading: isLoadingSignUpReminders } = useQuery({
    ...userQueries.signUpReminders({ userId: userId! }),
    enabled: Boolean(userId) && !userDetails?.registeredAt && !userDetails?.project,
  });
  const { isLoading: isLoadingDeletionUser } = useQuery({
    ...userQueries.details({ userId: userId! }),
    enabled:
      Boolean(userId) &&
      sessionUser.isSuperAdmin &&
      Boolean(userDetails?.deletedAt) &&
      Boolean(userDetails?.deletedByUserId),
  });
  const {
    data: repliesData,
    hasNextPage: hasMoreReplies,
    fetchNextPage: fetchMoreReplies,
    isFetchingNextPage: isLoadingMoreReplies,
    error: errorLoadingReplies,
  } = useInfiniteQuery({
    ...chatQueries.listChatReplies({ chatId: chat?.id as string }),
    enabled: Boolean(chat),
  });
  const { data: userMessages, isPending: isPendingUserMessages } = useQuery({
    ...userQueries.listMessages({
      userId: userId!,
    }),
    enabled: Boolean(userId),
  });

  // SignalR connection for the chat between the current user and the user being viewed
  const { signalRConnection } = useSignalRHub("chat-hub", {
    query: `userId=${sessionUser.id}&chatId=${chat?.id}`,
    disabled: !chat,
  });
  const { invoke: onReadMessage } = useSignalRInvocation(signalRConnection, "ChatMessageRead");

  const onNewChatMessage = useCallback(
    (...args: [{ chatId: string; isGroupChat: boolean; latestReply: ChatReplyDto }]) => {
      const latestChatReply = args[0].latestReply;
      setChatReplies((prev) => [...prev, latestChatReply]);

      void onReadMessage({ chatId: chat?.id, userId: sessionUser.id });
    },
    [setChatReplies, onReadMessage, chat, sessionUser.id],
  );

  useSignalRSubscription(signalRConnection, "NewChatMessage", onNewChatMessage);

  useEffect(() => {
    setChatReplies(repliesData?.pages.flatMap((x) => x.items) ?? []);
  }, [repliesData]);

  const addChatMutation = chatMutations.useAddChat();
  const addChatReplyMutation = chatMutations.useAddChatReply();

  const handleAddChatReply = async ({ message, images, videos }: ChatReplyPayload) => {
    if (!userId) {
      return;
    }

    let chatId = chat?.id;
    if (!chatId) {
      chatId = await addChatMutation
        .mutateAsync({
          userId,
        })
        .then((x) => x.id);
    }

    const payload: ChatReplyRequest = {
      text: message,
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    const uploadedVideoIds: string[] = [];
    if (videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    if (chatId) {
      await addChatReplyMutation.mutateAsync({
        chatId,
        data: payload,
      });
    }
  };

  const allMessages = useMemo(() => userMessages?.items ?? [], [userMessages]);

  const error = errorUserDetails || errorChat || errorLoadingReplies || !userId;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading =
    isPendingUserDetails || isPendingChat || isLoadingDeletionUser || isLoadingSignUpReminders || isLoadingUserProjects;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.user-detail.title")}
      subTitle={t("page.user-detail.subtitle")}
      actions={
        userDetails.canBeEdited || userDetails.canBeDeleted || hasPermission(canEditOtherUserNotificationSettings) ? (
          <>
            {sessionUser.isAdmin && userDetails.canBeDeleted && (
              <Button data-testid="delete-user-btn" styling="danger" onClick={deleteModalOpenHandlers.setTrue}>
                {t("page.user-detail.actions.delete-user")}
              </Button>
            )}
            {hasPermission(canEditOtherUserNotificationSettings) && userDetails.isAdmin && !userDetails.deletedAt && (
              <Button
                type="link"
                data-testid="notification-settings-btn"
                href={routes.adminNotifications.otherUserSettings({ slug, userId: userId })}
                state={{ fromPage: routes.users.details({ id: userId, slug }) }}
                styling="secondary"
                icon={<Icon name={iconSettings01} />}
              >
                {t("page.user-detail.actions.edit-user-notifications")}
              </Button>
            )}
            {sessionUser.isAdmin && userDetails.canBeEdited && (
              <Button type="link" data-testid="edit-user-btn" href={routes.users.edit({ slug, id: userDetails.id })}>
                {t("page.user-detail.actions.edit-user-details")}
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="66%" isDesktopIndiffernt>
          <div className="flex w-full grow flex-col gap-10">
            {sessionUser.id !== userId && (
              <ChatWindow
                canChat={userDetails.chatEnabled && !userDetails.deletedAt}
                chatReplies={chatReplies}
                hasMoreReplies={hasMoreReplies}
                fetchMoreReplies={fetchMoreReplies}
                isLoadingMoreReplies={isLoadingMoreReplies}
                sendReply={handleAddChatReply}
              />
            )}
            <div className="flex flex-col gap-3">
              {isPendingUserMessages && <FullSizeLoader />}
              {!isPendingUserMessages && (
                <>
                  <span className="text-caption-bold">{t("page.user-detail.posts-section")}</span>
                  {allMessages.length > 0 && (
                    <div className="flex flex-col gap-5">
                      {allMessages.map((message) => (
                        <CommunityPost key={message.id} message={message} />
                      ))}
                    </div>
                  )}
                  {allMessages.length == 0 && <span className="text-caption">{t("page.user-detail.no-posts")}</span>}
                </>
              )}
            </div>
          </div>
        </PageGrid.Item>
        <PageGrid.Item size="33%" isDesktopIndiffernt>
          <UserInfoCard userId={userId} />
        </PageGrid.Item>
      </PageGrid.Grid>
      <UserDeletionModal
        userId={userDetails.id}
        deletionDate={userDetails.deleteAfter}
        isOpened={isDeleteModalOpened}
        onOpenChange={deleteModalOpenHandlers.set}
      />
    </DocumentPaper>
  );
}
