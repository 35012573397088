import { useQuery } from "@tanstack/react-query";
import type { MessageCreatedEntityDto, MessageCreateRequest } from "api/types";
import iconFileQuestion01 from "assets/icons/file-question-01.svg";
import iconMessageSmileSquare from "assets/icons/message-smile-square.svg";
import iconPencilLine from "assets/icons/pencil-line.svg";
import iconSettings04 from "assets/icons/settings-04.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { CreatePostAppPreview } from "components/CreatePostAppPreview/CreatePostAppPreview";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { Form } from "components/Form/Form";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canCreateAnnouncementMessage } from "modules/messages/permissions";
import { communityFeedMutations, useCommunityFeedQueries } from "queries/communityFeed";
import { generalMutations } from "queries/general";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import { AdminCreatePostFormAIHelper } from "./components/AdminCreatePostFormAIHelper";
import { AdminCreatePostFormAudienceSelector } from "./components/AdminCreatePostFormAudienceSelector";
import { AdminCreatePostFormEditor } from "./components/AdminCreatePostFormEditor";
import { AdminCreatePostFormScheduleSelector } from "./components/AdminCreatePostFormScheduleSelector";
import { AdminCreatePostFormTypeSelector } from "./components/AdminCreatePostFormTypeSelector";
import { AdminCreatePostSection } from "./components/AdminCreatePostSection";
import { AdminCreatePostSuccessModal } from "./components/AdminCreatePostSuccessModal";
import { AdminCreatePostTipsModal } from "./components/AdminCreatePostTipsModal";

export type AdminCreatePostFormValues = {
  postType: MessageCreateRequest["type"];
  title: string;
  content: string;
  images: FormImage[];
  documents: FormDocument[];
  videos: FormVideo[];
  audienceGroupId: string | undefined;
  audienceType: "project" | "group" | undefined;
  scheduleDate: Date | undefined;
};

export function Layout(): React.ReactNode {
  // TODO: Replace with a better history-management solution
  const [originalText, setOriginalText] = useState("");
  const [copilotText, setCopilotText] = useState("");
  const [isCopilotText, setIsCopilotText] = useState(false);

  const [audienceReached, setAudienceReached] = useState<MessageCreatedEntityDto | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelModalOpened, cancelModalHandler] = useBool(false);
  const [isSuccessModalOpened, successModalHandler] = useBool(false);
  const [isTipsModalOpened, tipsModalHandler] = useBool(false);

  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const slug = useSlug();
  const hasPermission = usePermission();

  const isPostAnnouncement = hasPermission(canCreateAnnouncementMessage);
  const allowedPostTypes: MessageCreateRequest["type"][] = isPostAnnouncement
    ? ["announcementReadOnly", "announcement"]
    : ["announcement"];
  const form = useForm<AdminCreatePostFormValues>({
    defaultValues: {
      title: "",
      content: "",
      postType: allowedPostTypes.length === 1 ? allowedPostTypes[0] : undefined,
      images: [],
      videos: [],
      documents: [],
      audienceGroupId: undefined,
      audienceType: undefined,
      scheduleDate: undefined,
    },
  });

  const currPostType = useWatch({ name: "postType", control: form.control });
  const currTitle = useWatch({ name: "title", control: form.control });
  const currContent = useWatch({ name: "content", control: form.control });
  const currImages = useWatch({ name: "images", control: form.control });
  const currVideos = useWatch({ name: "videos", control: form.control });
  const currDocuments = useWatch({ name: "documents", control: form.control });
  const currAudienceGroupId = useWatch({ name: "audienceGroupId", control: form.control });
  const currScheduleDate = useWatch({ name: "scheduleDate", control: form.control });

  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo, abortUploadVideos } = useUploadVideo({});
  const { uploadFormDocument } = useUploadDocument();
  const addMessageMutation = communityFeedMutations.useAddMessage();
  const copilotResponseMutation = generalMutations.useGetCopilotResponse();
  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: audience,
    isPending: isPendingAudience,
    isError: isErrorAudience,
    error: errorAudience,
  } = useQuery(
    communityFeedQueries.audience({
      query: {
        ExcludeProjectConnections: true,
        Type: isPostAnnouncement ? "announcement" : "post",
      },
    }),
  );

  // Set default seleceted audience based on location state
  // e.g. Redirected from group detail page
  useEffect(() => {
    if (!audience) {
      return;
    }

    let defaultAudienceGroupId: AdminCreatePostFormValues["audienceGroupId"] = undefined;
    let defaultAudienceType: AdminCreatePostFormValues["audienceType"] = undefined;
    const audienceGroups = [...audience.realEstateGroups, ...audience.interestGroups];
    if (locationState?.groupId) {
      defaultAudienceGroupId = locationState.groupId;
      defaultAudienceType = "group";
    } else if (audience.project) {
      defaultAudienceGroupId = undefined;
      defaultAudienceType = "project";
    } else if (audienceGroups.length === 1) {
      defaultAudienceGroupId = audienceGroups[0].id;
      defaultAudienceType = "group";
    }

    form.reset({
      ...form.getValues(),
      audienceGroupId: defaultAudienceGroupId,
      audienceType: defaultAudienceType,
    });
  }, [audience, form, locationState]);

  const reset = () => {
    form.reset();
    setAudienceReached(undefined);
    setCopilotText("");
    setOriginalText("");
    setIsCopilotText(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async (formValues: AdminCreatePostFormValues) => {
    setIsSubmitting(true);

    const imageUploadPromises = formValues.images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = formValues.documents.map((document) => uploadFormDocument(document));
    // Curently only 1 video is allowed
    const videoUploadPromises = formValues.videos.map((video) => uploadFormVideo(video));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];
    const videoIds = (await Promise.allSettled(videoUploadPromises.map((video) => video)))
      .map((video) => (video.status === "fulfilled" ? video.value?.id : ""))
      .filter(Boolean) as string[];

    try {
      // Only create message when all attachments were uploaded successfully
      if (
        imageIds.length === formValues.images.length &&
        documentIds.length === formValues.documents.length &&
        videoIds.length === formValues.videos.length
      ) {
        // Override post type to standard post if user does not have announcement permissions
        let finalPostType: MessageCreateRequest["type"] = formValues.postType;
        if (!isPostAnnouncement && formValues.postType === "announcement") {
          finalPostType = "undefined";
        }

        const data: MessageCreateRequest = {
          type: finalPostType,
          title: formValues.title,
          content: formValues.content,
          isHiddenFromAdmins: false,
          imageIds,
          videoIds,
          documentIds,
          groupId: formValues.audienceGroupId,
          scheduledFor: formValues.scheduleDate?.toISOString(),
        };

        const res = await addMessageMutation.mutateAsync({
          data,
        });

        setAudienceReached(res);
        successModalHandler.setTrue();
        form.reset();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    // Cancel all ongoing video uploads
    abortUploadVideos(form.getValues("videos"));

    void navigate(routes.messageFeed.list({ slug }));
  };

  const handleGenerateContent = async () => {
    const content = form.getValues("content");
    const result = await copilotResponseMutation.mutateAsync({
      input: content,
    });

    setOriginalText(content);
    setCopilotText(result.generatedText);
    setIsCopilotText(true);

    form.setValue("content", result.generatedText, {
      shouldDirty: true,
    });
  };

  const handleUndoContent = () => {
    form.setValue("content", originalText, {
      shouldDirty: true,
    });

    setIsCopilotText(false);
  };

  const handleRedoContent = () => {
    form.setValue("content", copilotText);

    setIsCopilotText(true);
  };

  const handleSelectPostType = (newPostType: MessageCreateRequest["type"]) => {
    form.setValue("postType", newPostType, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectAudience = (newAudienceGroupId: string | undefined) => {
    const shouldDirty = newAudienceGroupId !== form.getValues("audienceGroupId");

    form.setValue("audienceGroupId", newAudienceGroupId, {
      shouldDirty,
    });
    form.setValue("audienceType", newAudienceGroupId ? "group" : "project", {
      shouldDirty,
      shouldValidate: true,
    });
  };

  const handleSelectScheduleDate = (date: Date | undefined) => {
    form.setValue("scheduleDate", date, {
      shouldDirty: true,
    });
  };

  if (isPendingAudience) {
    return <FullSizeLoader withPadding />;
  }

  if (isErrorAudience) {
    return <ErrorPage error={errorAudience} />;
  }

  const isUndoable = isCopilotText;
  const isRedoable = Boolean(copilotText) && !isCopilotText;
  const isCopilotAvailable = currContent.trim().split(" ").length >= 3;
  const allAudienceGroups = [...audience.realEstateGroups, ...audience.interestGroups];

  return (
    <>
      <DocumentPaper
        theme="minimal"
        title={t("page.admin-create-post.title")}
        subTitle={
          <Breadcrumbs
            pages={[
              {
                name: t("page.message-feed.title"),
                to: routes.messageFeed.list({ slug }),
              },
              {
                name: t("page.admin-create-post.breadcrumbs.self"),
              },
            ]}
          />
        }
      >
        <PageGrid.Grid>
          <PageGrid.Item size="75%" isDesktopIndiffernt>
            <Form formMethods={form} onSubmit={handleSubmit}>
              <div className="flex w-full flex-col gap-6">
                {/* Post type */}
                <AdminCreatePostSection
                  title={t("page.admin-create-post.section.step-1.title")}
                  icon={iconFileQuestion01}
                >
                  <AdminCreatePostFormTypeSelector
                    allowedTypes={allowedPostTypes}
                    selectedType={currPostType}
                    onSelectType={handleSelectPostType}
                  />
                </AdminCreatePostSection>
                {/* Post content */}
                <AdminCreatePostSection title={t("page.admin-create-post.section.step-2.title")} icon={iconPencilLine}>
                  <AdminCreatePostFormAIHelper
                    isUndoable={isUndoable}
                    isRedoable={isRedoable}
                    isCopilotAvailable={isCopilotAvailable}
                    isGenerating={copilotResponseMutation.isPending}
                    onGenerate={handleGenerateContent}
                    onUndo={handleUndoContent}
                    onRedo={handleRedoContent}
                  />
                  <AdminCreatePostFormEditor />
                  {/* App preview (Mobile) */}
                  <div className="mt-4 flex flex-wrap items-center gap-2 xl:hidden">
                    <Button
                      icon={<Icon name={iconMessageSmileSquare} />}
                      styling="tertiary"
                      onClick={tipsModalHandler.setTrue}
                    >
                      {t("page.admin-create-post.form.btn.show-tips")}
                    </Button>
                    <CreatePostAppPreview
                      title={currTitle}
                      content={currContent}
                      images={currImages}
                      documents={currDocuments}
                      videos={currVideos}
                      groupName={allAudienceGroups.find((group) => group.id === currAudienceGroupId)?.name}
                      postType={isPostAnnouncement ? currPostType || "announcement" : "undefined"}
                    />
                  </div>
                </AdminCreatePostSection>
                {/* Post audience */}
                <AdminCreatePostSection icon={iconSettings04} title={t("page.admin-create-post.section.step-3.title")}>
                  <AdminCreatePostFormAudienceSelector
                    audience={audience}
                    selectedGroup={allAudienceGroups.find((group) => group.id === currAudienceGroupId)}
                    onSelectGroup={(group) => handleSelectAudience(group.id)}
                    selectedProject={currAudienceGroupId ? undefined : audience?.project}
                    onSelectProject={() => handleSelectAudience(undefined)}
                    isDisabled={!currPostType}
                    isError={currPostType && Boolean(form.formState.errors.audienceType)}
                  />
                </AdminCreatePostSection>
                {/* Footer */}
                <AdminCreatePostSection>
                  <div className="flex w-full items-center gap-2">
                    <Button styling="secondary" className="mr-auto" onClick={cancelModalHandler.setTrue}>
                      {t("common.action.cancel")}
                    </Button>
                    <AdminCreatePostFormScheduleSelector
                      selectedDate={currScheduleDate}
                      onSelectDate={handleSelectScheduleDate}
                    />
                    <Button type="submit" isLoading={isSubmitting}>
                      {currScheduleDate
                        ? t("page.admin-create-post.form.btn.cta-schedule")
                        : t("page.admin-create-post.form.btn.cta-post")}
                    </Button>
                  </div>
                </AdminCreatePostSection>
              </div>
            </Form>
          </PageGrid.Item>

          {/* App preview (Desktop) */}
          <PageGrid.Item size="25%" isDesktopIndiffernt>
            <div className="hidden xl:block">
              <AdminCreatePostSection>
                <CreatePostAppPreview
                  title={currTitle}
                  content={currContent}
                  images={currImages}
                  documents={currDocuments}
                  videos={currVideos}
                  groupName={allAudienceGroups.find((group) => group.id === currAudienceGroupId)?.name}
                  postType={isPostAnnouncement ? currPostType || "announcement" : "undefined"}
                />
                <Button
                  className="mx-auto"
                  icon={<Icon name={iconMessageSmileSquare} />}
                  styling="tertiary"
                  onClick={tipsModalHandler.setTrue}
                >
                  {t("page.admin-create-post.form.btn.show-tips")}
                </Button>
              </AdminCreatePostSection>
            </div>
          </PageGrid.Item>
        </PageGrid.Grid>
      </DocumentPaper>

      <ConfirmModal
        data-testid="cancel-modal"
        title={t("page.admin-create-post.cancel-modal.title")}
        description={t("page.admin-create-post.cancel-modal.description")}
        isOpened={isCancelModalOpened}
        shouldCloseOnEsc
        onReject={cancelModalHandler.setFalse}
        onOpenChange={cancelModalHandler.set}
        rejectBtnProps={{
          text: t("common.action.cancel"),
        }}
        onResolve={handleCancel}
        resolveBtnProps={{
          text: t("common.action.confirm"),
        }}
        isLoading={false}
      />
      <AdminCreatePostTipsModal isOpened={isTipsModalOpened} onOpenChange={tipsModalHandler.set} />
      <AdminCreatePostSuccessModal
        isPostedInGroup={false}
        audienceReached={audienceReached}
        isOpened={isSuccessModalOpened}
        onOpenChange={(state) => {
          if (!state) {
            reset();
          }

          successModalHandler.set(state);
        }}
      />
    </>
  );
}
