import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AutomatedSurveyQueueDetailsListDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

import type { AutomatedSurveyType } from "../../constants";

export interface LayoutProps {
  surveyTitle: string;
  surveyType: AutomatedSurveyType;
  activeSurveys: AutomatedSurveyQueueDetailsListDto[];
  isLoadingActiveSurveys: boolean;
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

export function Layout({
  surveyTitle,
  surveyType,
  activeSurveys,
  isLoadingActiveSurveys,
  page,
  onPageChange,
  totalPages,
}: LayoutProps): React.ReactNode {
  const connectedProjects = useConnectedProjects();
  const { t } = useTranslation();
  const columns = useMemo(() => {
    const helper = createColumnHelper<AutomatedSurveyQueueDetailsListDto>();

    return [
      helper.accessor("projectName", {
        header: t("page.automated-surveys-active-details.table.project"),
        cell: (cell) => {
          const slug = connectedProjects.data?.find((x) => x.id === cell.row.original.projectId)?.slug;

          return <Anchor to={slug ? routes.automatedSurveyQueues.list({ slug }) : "#"}>{cell.getValue()}</Anchor>;
        },
      }),
      helper.accessor("totalResponses", {
        header: t("page.automated-surveys-active-details.table.n-responses"),
      }),
      helper.accessor("numberLeftInQueue", {
        header: t("page.automated-surveys-active-details.table.n-left-in-queue"),
      }),
      helper.accessor("feelingAtHomeAverage", {
        header: t("page.automated-surveys-active-details.table.score.feel-at-home"),
      }),
      helper.accessor("buildingAverage", {
        header: t("page.automated-surveys-active-details.table.score.building"),
      }),
      helper.accessor("communityAverage", {
        header: t("page.automated-surveys-active-details.table.score.community"),
      }),
      helper.accessor("serviceAverage", {
        header: t("page.automated-surveys-active-details.table.score.service"),
      }),
    ];
  }, [connectedProjects.data, t]);

  const tableInstance = useReactTable<AutomatedSurveyQueueDetailsListDto>({
    columns,
    data: activeSurveys,
    getCoreRowModel: getCoreRowModel(),
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "automated-surveys", status: "details" });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.automated-surveys-active-details.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-2">
          <span className="text-headline3 leading-old-headline4">{`${surveyTitle} (${
            surveyType === "feelAtHome"
              ? t("page.automated-surveys.types.feeling-at-home")
              : t("page.automated-surveys.types.other")
          })`}</span>
          <InfoIcon tooltip={t("page.automated-surveys-active-details.tooltip")} />
        </div>
        {activeSurveys.length ? (
          <Table table={tableInstance} isLoading={isLoadingActiveSurveys}>
            <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
          </Table>
        ) : (
          <div className="rounded-lg bg-white p-5">
            <p>{t("page.automated-surveys-active-details.no-data")}</p>
          </div>
        )}
      </div>
    </DocumentPaper>
  );
}
