import type {
  UserRoleAddressManagementPermissionsRequest,
  UserRoleAssetsPermissionsRequest,
  UserRoleCommunityGroupPermissionsEntityRequest,
  UserRoleCommunityPermissionsRequest,
  UserRoleDocumentFolderPermissionsEntityRequest,
  UserRoleDocumentsPermissionsRequest,
  UserRoleEventsPermissionsRequest,
  UserRoleIntegrationsPermissionsRequest,
  UserRoleProjectManagementPermissionsRequest,
  UserRoleRepairCategoryPermissionsEntityRequest,
  UserRoleRepairManagementPermissionsRequest,
  UserRoleRequest,
  UserRoleServicePagesPermissionsRequest,
  UserRoleServicePartnerPagePermissionsEntityRequest,
  UserRoleSurveysPermissionsRequest,
  UserRoleUserManagementPermissionsRequest,
} from "api/types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormRadio, FormRadioGroup } from "components/Form/FormRadio";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import { createRequiredStringRule } from "helpers/rules";
import { get } from "lodash-es";
import { memo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

import type { PermissionLayoutStructure } from "./Loader";
import type { RoleWizardStep } from "./Manager";

export interface LayoutProps {
  isDuplicate: boolean;
  onSubmit: SubmitHandler<RoleFormFieldValues>;
  defaultFormValues: RoleFormFieldValues;
  isSubmitting: boolean;
  structure: PermissionLayoutStructure[];
  initialStepId?: RoleWizardStep;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const isEdit = !props.isDuplicate && props.defaultFormValues.name !== "";
  const { t } = useTranslation();
  const formMethods = useForm<RoleFormFieldValues>({
    mode: "onChange",
    defaultValues: props.defaultFormValues,
  });
  const canLeave = async () => await formMethods.trigger();

  return (
    <DocumentPaper
      theme="no-gaps"
      title={isEdit ? t("page.role.create-or-edit.edit-mode.title") : t("page.role.create-or-edit.create-mode.title")}
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        <Wizard
          id="role-wizard"
          actionsText={{
            finish: isEdit ? t("component.wizard.action.edit.finish") : t("component.wizard.action.create.finish"),
          }}
          onFinish={formMethods.handleSubmit(props.onSubmit)}
          isSubmitting={props.isSubmitting}
          strictOrder={!isEdit}
          initialStepId={props.initialStepId}
        >
          <WizardStep
            id={1}
            canLeave={canLeave}
            hasFinish={isEdit}
            title={
              <>
                <span>{t("component.wizard.step", { step: 1 })}</span>
                <span>{t("component.wizard.step-general")}</span>
              </>
            }
          >
            <FormContent>
              <FormField htmlFor="name" label={t("model.role.name")} required>
                <FormInput<RoleFormFieldValues>
                  className="max-w-xs"
                  data-testid="role-name"
                  id="name"
                  name="name"
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "model.role.name"),
                    },
                  }}
                />
              </FormField>
              <FormField label={t("model.role.type")}>
                <FormErrorWrapper<RoleFormFieldValues> name="type">
                  <FormRadioGroup isVertical>
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="resident"
                      displayValue={t("model.role.type.resident")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="projectOwner"
                      displayValue={t("model.role.type.project-owner")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="maintenance"
                      displayValue={t("model.role.type.maintenance")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="caretaker"
                      displayValue={t("model.role.type.caretaker")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="readOnly"
                      displayValue={t("model.role.type.readOnly")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                    <FormRadio<RoleFormFieldValues>
                      name="type"
                      value="servicePartner"
                      displayValue={t("model.role.type.service-partner")}
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.role.type"),
                          }),
                          value: true,
                        },
                      }}
                    />
                  </FormRadioGroup>
                </FormErrorWrapper>
              </FormField>
            </FormContent>
          </WizardStep>
          <WizardStep
            id={2}
            canLeave={canLeave}
            hasFinish={isEdit}
            title={
              <>
                <span>{t("component.wizard.step", { step: 2 })}</span>
                <span>{t("component.wizard.step-permissions")}</span>
              </>
            }
          >
            <FormContent maxWidth="none">
              <MemoPermissionLayout structure={props.structure} defaultValues={props.defaultFormValues} />
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}

export interface RoleFormFieldValues {
  name: string;
  type: UserRoleRequest["type"];
  permissions: {
    community: UserRoleCommunityPermissionsRequest;
    communityGroups: Record<string, UserRoleCommunityGroupPermissionsEntityRequest>;
    repairManagement: UserRoleRepairManagementPermissionsRequest;
    repairCategories: Record<string, UserRoleRepairCategoryPermissionsEntityRequest>;
    folders: Record<string, UserRoleDocumentFolderPermissionsEntityRequest>;
    userManagement: UserRoleUserManagementPermissionsRequest;
    addressManagement: UserRoleAddressManagementPermissionsRequest;
    servicePartners: UserRoleServicePagesPermissionsRequest;
    servicePages: Record<string, UserRoleServicePartnerPagePermissionsEntityRequest>;
    surveys: UserRoleSurveysPermissionsRequest;
    events: UserRoleEventsPermissionsRequest;
    assets: UserRoleAssetsPermissionsRequest;
    projectManagement: UserRoleProjectManagementPermissionsRequest;
    documents: UserRoleDocumentsPermissionsRequest;
    integrations: UserRoleIntegrationsPermissionsRequest;
  };
}

interface PermissionLayoutProps {
  structure: PermissionLayoutStructure[];
  defaultValues: RoleFormFieldValues;
  lvl?: number;
}

function PermissionLayout({ structure, defaultValues, lvl = 0 }: PermissionLayoutProps): React.ReactNode {
  return (
    <Accordion allowMultipleExpanded allowZeroExpanded className={lvl > 0 ? "mt-2 pl-8" : undefined}>
      {structure.map((permissionGroup) => (
        <AccordionItem key={permissionGroup.id} uuid={`${permissionGroup.id.replaceAll(" ", "_")}`}>
          <AccordionItemHeading>
            <AccordionItemButton isHighlighted={lvl === 0}>{permissionGroup.groupLabel}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {permissionGroup.items && (
              <PermissionListLayout>
                {permissionGroup.items.map((permission) => (
                  <PermissionWrapper key={permission.modelPath}>
                    <FormCheckbox<RoleFormFieldValues>
                      alignTop
                      memorise={get(defaultValues, permission.modelPath) as boolean}
                      name={permission.modelPath}
                      label={permission.label}
                      tooltip={permission.tooltip}
                    />
                  </PermissionWrapper>
                ))}
              </PermissionListLayout>
            )}

            {permissionGroup.subStructures?.map((s) => (
              <PermissionLayout key={s.id} structure={[s]} defaultValues={defaultValues} lvl={lvl + 1} />
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

const MemoPermissionLayout = memo(PermissionLayout);
