import type { SurveyDetailsDto } from "api/types";
import { Button } from "components/Button/Button";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { isBefore, parseISO } from "date-fns";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { SurveyExportModal } from "modules/surveys/pages/Details/components/SurveyExportModal";
import { SurveyQuestionResponseCard } from "modules/surveys/pages/Details/components/SurveyQuestionResponseCard";
import { useMemo } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

import { SurveyInfo } from "./components/SurveyInfo";

export interface LayoutProps {
  survey: SurveyDetailsDto;
  onDelete: ({ surveyId }: { surveyId: string }) => Promise<unknown>;
}

export function Layout({ survey, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal("survey-delete-modal");
  const [isSurveyExportModalOpened, surveyExportModalOpenHandlers] = useBool();

  const isFinished = useMemo(() => {
    const endDate = survey.endsAt ? parseISO(survey.endsAt) : undefined;

    return !!endDate && isBefore(endDate, new Date());
  }, [survey]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.survey-details.survey-title")}
      subTitle={t("page.survey-details.survey-subtitle")}
      actions={
        <>
          {survey.canDelete && (
            <Button data-testid="survey-delete-btn" styling="danger" onClick={openDeleteModal}>
              {t("page.survey-details.actions.delete")}
            </Button>
          )}
          {isDefined(survey.answerCount) && survey.answerCount > 0 && (
            <Button onClick={surveyExportModalOpenHandlers.setTrue} styling="secondary">
              {t("page.survey-details.actions.export")}
            </Button>
          )}
          {survey.canEdit && (
            <Button data-testid="survey-edit-btn" type="link" href={routes.surveys.edit({ slug, id: survey.id })}>
              {t("page.survey-details.actions.edit")}
            </Button>
          )}
        </>
      }
    >
      <PageGrid.Grid>
        <div className="order-2 col-span-full xl:order-1 xl:col-span-7">
          <div className="flex w-full flex-col gap-4">
            {survey.questions.map((question) => (
              <SurveyQuestionResponseCard
                key={question.id}
                canViewResults={survey.canViewResults}
                isSurveyEnded={isFinished}
                {...{ question }}
              />
            ))}
          </div>
        </div>
        <div className="order-1 col-span-full xl:order-2 xl:col-span-5">
          <SurveyInfo {...{ survey }} />
        </div>
      </PageGrid.Grid>

      <DeleteModal
        title={t("page.surveys.actions.delete.title")}
        description={t("page.surveys.actions.delete.description")}
        onDelete={() => onDelete({ surveyId: survey.id })}
        deleteBtnProps={{
          "data-testid": "survey-action-delete",
        }}
        {...deleteModalProps}
      />
      <SurveyExportModal
        isOpened={isSurveyExportModalOpened}
        onOpenChange={surveyExportModalOpenHandlers.set}
        surveyId={survey.id}
        surveyName={survey.title}
      />
    </DocumentPaper>
  );
}
