import { Dialog as ArkModal, Portal as ArkPortal } from "@ark-ui/react";
import iconX from "assets/icons/x.svg";
import { IconButton } from "components/Button/IconButton";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export type ModalBaseProps = {
  isOpened: boolean;
  onOpenChange: (state: boolean) => void;
};

export type ModalProps = ModalBaseProps & {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  labelComponent?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "fullscreen";
  shouldCloseOnEsc?: boolean;
  shouldCloseOnClickOutside?: boolean;
  isClosable?: boolean;
  isScrollable?: boolean;
  isNested?: boolean;
  "data-testid"?: string;
  ids?: {
    root?: string;
    title?: string;
    description?: string;
  };
  initialFocusRef?: React.RefObject<HTMLElement>;
};

function ModalRoot({
  isOpened,
  onOpenChange,
  children,
  title,
  description,
  labelComponent,
  size = "md",
  shouldCloseOnEsc = true,
  shouldCloseOnClickOutside = true,
  isClosable = true,
  isScrollable,
  "data-testid": dataTestId,
  ids,
  isNested,
}: ModalProps): React.ReactNode {
  const { t } = useTranslation();

  const DescriptionElement = typeof description === "string" ? "p" : "div";

  return (
    <ArkModal.Root
      open={isOpened}
      onOpenChange={(details) => onOpenChange(details.open)}
      closeOnEscape={isClosable && shouldCloseOnEsc}
      closeOnInteractOutside={isClosable && shouldCloseOnClickOutside}
      unmountOnExit
      lazyMount
    >
      <ArkPortal>
        <ArkModal.Backdrop
          className={twJoin(
            "fixed left-0 top-0 h-dvh w-dvw cursor-pointer bg-grey-900/80",
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
            isNested ? "z-50" : "z-40",
          )}
        />
        <ArkModal.Positioner
          className={twJoin(
            "fixed left-0 top-0 flex size-full items-center justify-center px-4",
            isNested ? "z-[60]" : "z-50",
          )}
        >
          <ArkModal.Content
            data-testid={dataTestId}
            id={ids?.root}
            className={twJoin(
              "relative flex max-h-screen-minus-8 w-full rounded-lg bg-white md:max-h-screen-minus-16",
              "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in data-[state=closed]:slide-out-to-bottom-2 data-[state=open]:slide-in-from-bottom-2",
              size === "sm" && "md:w-[420px]",
              size === "md" && "md:w-[640px]",
              size === "lg" && "lg:w-[800px]",
              size === "fullscreen" && "lg:h-full lg:w-[1280px]",
            )}
          >
            <div
              className={twJoin(
                "relative flex w-full flex-col gap-4 rounded-lg p-4",
                isScrollable && "overflow-y-auto overflow-x-hidden",
              )}
            >
              {(isClosable || labelComponent || title || description) && (
                <div className="flex w-full items-start justify-between gap-2">
                  <div className={twJoin("flex min-h-8 w-full flex-col gap-1", isClosable && "pr-12")}>
                    {labelComponent}
                    {title && (
                      <ArkModal.Title asChild>
                        <h4 id={ids?.title} className="text-headline3">
                          {title}
                        </h4>
                      </ArkModal.Title>
                    )}
                    {description && (
                      <ArkModal.Description asChild>
                        <DescriptionElement id={ids?.description} className="text-grey-700">
                          {description}
                        </DescriptionElement>
                      </ArkModal.Description>
                    )}
                  </div>
                  {isClosable && (
                    <ArkModal.CloseTrigger asChild>
                      <IconButton
                        className="absolute right-4 top-4"
                        data-testid="modal-close"
                        styling="secondary"
                        size="sm"
                        title={t("common.action.close")}
                        withTooltip={false}
                        isCircular
                        icon={iconX}
                      />
                    </ArkModal.CloseTrigger>
                  )}
                </div>
              )}
              {children}
            </div>
          </ArkModal.Content>
        </ArkModal.Positioner>
      </ArkPortal>
    </ArkModal.Root>
  );
}

interface ModalActionsProps {
  children: React.ReactNode[] | React.ReactNode;
}

function ModalActions({ children }: ModalActionsProps): React.ReactNode {
  return <div className="mt-4 flex w-full items-center justify-end gap-2">{children}</div>;
}

interface ModalCloseProps {
  children: React.ReactNode;
}

function ModalClose({ children }: ModalCloseProps): React.ReactNode {
  return <ArkModal.CloseTrigger asChild>{children}</ArkModal.CloseTrigger>;
}

export const Modal = {
  Root: ModalRoot,
  Actions: ModalActions,
  Close: ModalClose,
};

type ModalStateData<TData> =
  | (TData extends never
      ? { isOpen: true }
      : {
          data: TData;
          isOpen: true;
        })
  | {
      data?: TData;
      isOpen: false;
    };

interface ModalHelpers<TData> {
  requestClose: () => void;
  afterClose: () => void;
  open: (data: TData) => void;
}

export type ModalState<TData = never> = ModalStateData<TData> & ModalHelpers<TData>;

/**
 * To make sure close animations are smooth we provide a utility with an afterClose callback that resets the data.
 */
export function useModalState<TData = never>(initialData?: ModalStateData<TData>): ModalState<TData> {
  const [state, setState] = useState<ModalStateData<TData>>(
    initialData || {
      isOpen: false,
      data: undefined,
    },
  );

  const requestClose = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const afterClose = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false, data: undefined }));
  }, []);

  const open = useCallback((data: TData) => {
    setState({ isOpen: true as any, data });
  }, []);

  return useMemo(
    () => ({
      isOpen: state.isOpen,
      data: (state as any).data,
      requestClose,
      afterClose,
      open,
    }),
    [afterClose, requestClose, open, state],
  );
}
