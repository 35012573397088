import { Label } from "components/Label/Label";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

interface Project {
  id: string;
  name: string;
  city?: string;
  logoUrl: string;
  activeState: "unknown" | "active" | "closed";
}

interface Props {
  project: Project;
  onClick: () => void;
}

export function ProjectItem({ project, onClick }: Props): React.ReactNode {
  const { t } = useTranslation();

  const isClosed = project.activeState === "closed";

  return (
    <button
      className={twJoin(
        "flex w-full items-center gap-4 rounded-md p-2 outline-none focus-visible:bg-aop-basic-blue-100 focus-visible:ring-grey-900 hover:bg-aop-basic-blue-100",
        isClosed && "text-grey-400",
      )}
      onClick={onClick}
    >
      <ProjectLogo className={twJoin("size-14 shrink-0", isClosed && "opacity-50")} src={project.logoUrl} />
      <div className="flex flex-1 flex-col items-start">
        <span className="line-clamp-1 break-all text-left text-body-bold">{project.name}</span>
        {project.city && (
          <span className={twJoin("text-left text-overline text-grey-700", isClosed && "text-grey-300")}>
            {project.city}
          </span>
        )}
      </div>
      {project.activeState === "closed" && (
        <Label theme="red" size="sm">
          {t("component.project-switcher.project-closed")}
        </Label>
      )}
    </button>
  );
}
