import type { ResidentEngagementMonthlyV2Dto, ResidentTimeEngagementDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { useScrollToPageTop } from "hooks/useScrollToPageTop";
import { useSlug } from "hooks/useSlug";
import { BarChart } from "modules/analytics/components/BarChart";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import { Stat } from "modules/analytics/components/Stat";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { useEffect, useMemo } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";
import type { NonEmptyArray } from "types/utility-types";

export interface LayoutProps {
  engagementDetails: ResidentEngagementMonthlyV2Dto | undefined;
  engagementOverview: ResidentTimeEngagementDto[];
  isFetchingEngagementData: boolean;
  selectedDateRange: DateRangeOption;
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
  onDateRangeChange: (dateRange: DateRangeOption) => void;
}

export function Layout({
  engagementDetails,
  engagementOverview,
  isFetchingEngagementData,
  selectedDateRange,
  dateRangeOptions,
  onDateRangeChange,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  const scrollToTop = useScrollToPageTop();
  useEffect(() => {
    scrollToTop("instant");
  }, [scrollToTop]);

  const adoptionDataPoints = useMemo(
    () => engagementDetails?.data?.map((point) => ({ x: formatChartDate(point.range), y: point.percentage })) || [],
    [engagementDetails],
  );

  const benchmark = useMemo(
    () =>
      engagementDetails?.benchmark?.map((point) => ({ x: formatChartDate(point.range), y: point.percentage })) || [],
    [engagementDetails],
  );

  const noEngagementDataAvailable = !engagementDetails?.data || engagementDetails.data.every((x) => x.percentage === 0);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.analytics-details.engagement.title")}
      actions={
        <Button
          type="link"
          styling="secondary"
          icon={<Icon name={iconChevronLeft} />}
          href={routes.analytics.overview({ slug })}
        >
          {t("page.analytics-details.back")}
        </Button>
      }
      header={
        <div className="flex items-center gap-2 py-4">
          <span className="text-body-bold">{t("page.analytics-details.time-range.label")}</span>
          <Select
            items={dateRangeOptions}
            selected={selectedDateRange}
            onChange={onDateRangeChange}
            renderOption={(x) => x.name}
            keySelector={(x) => x.value}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <Card className="order-1 h-fit lg:col-span-2">
          <CardHeader title={t("page.analytics-details.engagement.chart.engagement.title")} />
          {isFetchingEngagementData && <LoadingIcon className="mx-auto w-8" />}
          {!isFetchingEngagementData && noEngagementDataAvailable && <NoData error={noEngagementDataAvailable} />}
          {!isFetchingEngagementData && !noEngagementDataAvailable && (
            <div className="flex flex-col gap-4">
              <span className="text-caption text-grey-700">
                {t("page.analytics-details.engagement.chart.engagement.description")}
              </span>
              <div className="w-full">
                <BarChart
                  type="date"
                  height={250}
                  primaryData={adoptionDataPoints}
                  benchmark={benchmark}
                  formatYTick={(y) => y}
                  formatXTick={(x) => x}
                  maxY={100}
                  yLabel={t("page.analytics-details.engagement.chart.engagement.y-axis")}
                  showLegend={false}
                  renderLabel={(data) => (
                    <div className="whitespace-pre-line">
                      {t("page.analytics-details.engagement.chart.engagement-benchmark.tooltip", {
                        percentage: data.primary?.y || 0,
                        benchmark: data.benchmark?.y || 0,
                      })}
                    </div>
                  )}
                />
                <Notice type="info" message={t("page.analytics-details.engagement.chart.engagement.notice")} />
              </div>
              <div className="mx-auto mt-2 grid w-1/2 grow grid-cols-2 gap-3">
                <Stat
                  title={t("page.analytics-details.engagement.card.day")}
                  value={`${engagementOverview.filter((x) => x.type === "last24Hours")[0].engagementPercentage}%`}
                />
                <Stat
                  title={t("page.analytics-details.engagement.card.week")}
                  value={`${engagementOverview.filter((x) => x.type === "last7Days")[0].engagementPercentage}%`}
                />
              </div>
            </div>
          )}
        </Card>
        <div className="flex flex-col gap-4">
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.engagement.information.title")} />
            <span className="text-caption-bold">{t("page.analytics-details.engagement.information.subtitle")}</span>
            <ul className="list-disc p-4">
              {t("page.analytics-details.engagement.information.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`information-${idx}`}>{item}</li>)}
            </ul>
          </Card>
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.engagement.best-practices.title")} />
            <span className="text-caption-bold">{t("page.analytics-details.engagement.best-practices.subtitle")}</span>
            <ul className="list-disc p-4">
              {t("page.analytics-details.engagement.best-practices.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`best-practices-${idx}`}>{item}</li>)}
            </ul>
          </Card>
        </div>
      </div>
    </DocumentPaper>
  );
}
