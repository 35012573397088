import { Dialog as ArkDialog, Portal as ArkPortal } from "@ark-ui/react";
import iconX from "assets/icons/x.svg";
import { IconButton } from "components/Button/IconButton";
import type React from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export type DrawerBaseProps = {
  isOpened: boolean;
  onOpenChange: (state: boolean) => void;
};

export type DrawerRootProps = DrawerBaseProps & {
  title: string;
  children: React.ReactNode;
  "data-testid"?: string;
};

function DrawerRoot({
  title,
  children,
  "data-testid": dataTestId,
  isOpened,
  onOpenChange,
}: DrawerRootProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <ArkDialog.Root
      open={isOpened}
      onOpenChange={(details) => onOpenChange(details.open)}
      closeOnEscape={false}
      closeOnInteractOutside={false}
      lazyMount
      unmountOnExit
    >
      <ArkPortal>
        <ArkDialog.Backdrop
          className={twJoin(
            "fixed left-0 top-0 z-40 size-full cursor-pointer bg-grey-900/60 !duration-500",
            "data-[state=closed]:animate-out data-[state=closed]:fade-out",
            "data-[state=open]:animate-in data-[state=open]:fade-in",
          )}
        />
        <ArkDialog.Positioner className="fixed bottom-0 right-0 z-50 h-fit w-full md:top-0 md:h-full md:w-fit">
          <ArkDialog.Content
            className={twJoin(
              "ease flex h-[90svh] w-full flex-col rounded-t-lg bg-white !duration-500 md:h-full md:w-[456px] md:rounded-l-lg md:rounded-tr-none",
              "data-[state=open]:animate-in md:data-[state=open]:slide-in-from-right max-md:data-[state=open]:slide-in-from-bottom",
              "data-[state=closed]:animate-out md:data-[state=closed]:slide-out-to-right max-md:data-[state=closed]:slide-out-to-bottom",
            )}
            data-testid={dataTestId}
          >
            <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-300 p-6">
              <ArkDialog.Title asChild>
                <h2 className="text-headline2 leading-none">{title}</h2>
              </ArkDialog.Title>
              <ArkDialog.CloseTrigger asChild>
                <IconButton
                  title={t("common.action.close")}
                  styling="secondary"
                  withTooltip={false}
                  isCircular
                  icon={iconX}
                />
              </ArkDialog.CloseTrigger>
            </div>
            {children}
          </ArkDialog.Content>
        </ArkDialog.Positioner>
      </ArkPortal>
    </ArkDialog.Root>
  );
}

interface DrawerBodyProps {
  children: React.ReactNode;
}

function DrawerBody({ children }: DrawerBodyProps): React.ReactNode {
  return <div className="flex w-full flex-1 flex-col overflow-y-auto p-6">{children}</div>;
}

interface DrawerCloseProps {
  children: React.ReactNode;
}

function DrawerClose({ children }: DrawerCloseProps): React.ReactNode {
  return <ArkDialog.CloseTrigger asChild>{children}</ArkDialog.CloseTrigger>;
}

interface DrawerActionsProps {
  children: React.ReactNode;
}

function DrawerActions({ children }: DrawerActionsProps): React.ReactNode {
  return <div className="flex w-full items-center justify-end gap-2 border-t border-grey-300 p-6">{children}</div>;
}

export const Drawer = {
  Root: DrawerRoot,
  Body: DrawerBody,
  Actions: DrawerActions,
  Close: DrawerClose,
};
