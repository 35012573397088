import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";

type AdminCreatePostOptionButtonProps = {
  "data-testid"?: string;
  title?: string;
  description?: string;
  icon: string;
  isSelected: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
};

export function AdminCreatePostOptionButton({
  "data-testid": dataTestId,
  title,
  description,
  icon,
  isSelected,
  children,
  onClick,
}: AdminCreatePostOptionButtonProps): React.ReactNode {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      className={twResolve(
        "flex min-h-[60px] w-full cursor-pointer items-center gap-2 rounded-md bg-grey-100 px-4 py-2 transition-colors hover:bg-grey-300/60 [&>*]:text-aop-dark-blue-500",
        isSelected && "bg-aop-dark-blue-500 hover:bg-aop-dark-blue-500 [&>*]:text-white",
      )}
      type="button"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Icon name={icon} size={24} />
      <div className="flex w-full flex-col items-start">
        {title && <p className="text-body-bold">{title}</p>}
        {description && <p className="text-caption">{description}</p>}
        {children}
      </div>
    </Component>
  );
}
