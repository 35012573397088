import type { AdminTicketCommentDto, UserDto } from "api/types";
import iconLock01 from "assets/icons/lock-01.svg";
import { type BaseCommentFieldProps, CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useDocumentInput } from "components/DocumentInput/useDocumentFile";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useContentFieldManager } from "providers/ContentFieldProvider";
import type React from "react";
import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export type CommentType = "internal" | "public";
export type CommentPayload = {
  content: string;
  type: CommentType;
  images: FormImage[];
  videos: FormVideo[];
  documents: FormDocument[];
};

type TicketCommentFieldPureProps = {
  user: UserDto;
  allowedCommentTypes: CommentType[];
  commentInEditing?: AdminTicketCommentDto;
  actionComponent?: React.ReactNode;
  isAttachmentAllowed: boolean;
  onSend: ({ content, type, images, documents }: CommentPayload) => Promise<void>;
  onCancelEdit?: () => void;
};

export function TicketCommentFieldPure({
  user,
  allowedCommentTypes,
  commentInEditing,
  actionComponent,
  isAttachmentAllowed,
  onSend,
  onCancelEdit,
}: TicketCommentFieldPureProps): React.ReactNode {
  const [selectedCommentType, setSelectedCommentType] = useState<CommentType>(allowedCommentTypes[0]);
  // Attachments
  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);
  const [documents, setDocuments] = useState<FormDocument[]>([]);
  // Content
  const { ref, currValue, setCurrValue } = useContentFieldManager();

  const { addImages, removeImage, removeImages } = useImageInput({ selectedImages: images, onChange: setImages });
  const { addDocuments, removeDocument, removeDocuments } = useDocumentInput({
    selectedDocuments: documents,
    maximumFiles: 1,
    onChange: setDocuments,
  });
  const { t } = useTranslation();

  // TODO(jimmy): Refactor this (https://github.com/area-of-people/Dashboard/issues/2486)
  const onVideoUploadProgress = ({ name, progress }: { name: string; progress: number }) => {
    setVideos(
      [...videos].map((video) => {
        if (!isVideoUploaded(video) && video.file.name === name) {
          return { ...video, uploadProgress: progress };
        }

        return video;
      }),
    );
  };
  const { uploadFormVideo } = useUploadVideo({
    onProgress: onVideoUploadProgress,
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: (videos) => {
      setVideos(videos);
    },
    uploadFn: uploadFormVideo,
  });

  useEffect(() => {
    if (commentInEditing) {
      setSelectedCommentType(commentInEditing.internal ? "internal" : "public");
      setCurrValue(commentInEditing.content ?? "");
      setImages(commentInEditing.images ?? []);
      setVideos(commentInEditing.videos ?? []);
      setDocuments(commentInEditing.documents ?? []);
    }
  }, [commentInEditing, setDocuments, setImages, setVideos, setCurrValue]);

  const onResetField = () => {
    removeImages();
    removeVideos();
    removeDocuments();

    setCurrValue("");
    onCancelEdit?.();
  };

  const onSubmit = async () => {
    await onSend({
      content: currValue,
      type: selectedCommentType,
      images,
      videos,
      documents,
    });

    onResetField();
  };

  const getAllowedAttachments = () => {
    if (!isAttachmentAllowed) {
      return [];
    }

    const allowedAttachments: BaseCommentFieldProps["allowedAttachments"] = ["image", "video"];
    if (user.isAdmin) {
      allowedAttachments.push("document");
    }

    return allowedAttachments;
  };

  return (
    <div className="flex flex-col">
      <TicketCommentFieldTypeSelector
        selectedType={selectedCommentType}
        allowedCommentTypes={allowedCommentTypes}
        onSelectType={setSelectedCommentType}
        isDisabled={!!commentInEditing}
      />
      <div
        className={twJoin(
          "rounded-lg rounded-tl-none border p-4",
          selectedCommentType === "public" ? "border-aop-basic-blue-500" : "border-yellow-600",
        )}
      >
        <CommentFieldWithAvatar
          ref={ref}
          allowedAttachments={getAllowedAttachments()}
          tooltips={{
            send:
              selectedCommentType === "internal"
                ? t("page.tickets.details.comment-field.send-note-tooltip")
                : t("page.tickets.details.comment-field.send-message-tooltip"),
          }}
          onAddImages={addImages}
          onRemoveImage={removeImage}
          onAddVideo={addVideos}
          onRemoveVideo={removeVideo}
          onAddDocument={addDocuments}
          onRemoveDocument={removeDocument}
          placeholder={
            selectedCommentType === "internal"
              ? t("page.tickets.details.comment-field.placeholder-note")
              : t("page.tickets.details.comment-field.placeholder-message")
          }
          commentType={selectedCommentType}
          onChange={(value) => {
            setCurrValue(value);
          }}
          isEdit={!!commentInEditing}
          onCancel={onResetField}
          autoFocus
          actionComponent={actionComponent}
          value={currValue}
          {...{ user, images, videos, documents, onSubmit }}
        />
      </div>
    </div>
  );
}

interface TicketCommentFieldTypeSelectorProps {
  selectedType: CommentType;
  allowedCommentTypes: CommentType[];
  isDisabled?: boolean;
  onSelectType: (type: CommentType) => void;
}

function TicketCommentFieldTypeSelector({
  selectedType,
  allowedCommentTypes,
  isDisabled,
  onSelectType,
}: TicketCommentFieldTypeSelectorProps): React.ReactNode {
  const { t } = useTranslation();

  if (allowedCommentTypes.length === 0) {
    return null;
  }

  return (
    <div className="flex">
      {allowedCommentTypes.includes("public") && (
        <button
          data-testid="public-comment-btn"
          className={twJoin(
            "rounded-tl-lg px-4 py-1.5 only:rounded-t-lg",
            selectedType === "public"
              ? "bg-aop-basic-blue-500 text-white"
              : "bg-blue-100 text-grey-600 hocus:bg-blue-200",
          )}
          type="button"
          onClick={() => onSelectType("public")}
          disabled={isDisabled}
        >
          <span className="text-caption">{t("page.tickets.details.comment-field.comment")}</span>
        </button>
      )}
      {allowedCommentTypes.includes("internal") && (
        <button
          data-testid="internal-note-btn"
          className={twJoin(
            "flex items-center gap-1 rounded-tr-lg px-4 py-1.5 only:rounded-t-lg",
            selectedType === "internal"
              ? "bg-yellow-600 text-yellow-200"
              : "bg-yellow-100 text-yellow-800 hocus:bg-yellow-200",
          )}
          type="button"
          onClick={() => onSelectType("internal")}
          disabled={isDisabled}
        >
          <Icon name={iconLock01} />
          <span className="text-caption">{t("page.tickets.details.comment-field.note")}</span>
        </button>
      )}
    </div>
  );
}
