import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { SUPPORT_EMAIL } from "helpers/constants";
import type React from "react";
import { Trans, useTranslation } from "translations";

type AddProjectModalProps = ModalBaseProps;

export function AddProjectModal({ isOpened, onOpenChange }: AddProjectModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      title={t("page.portfolio.add-new-project.modal.title")}
      description={
        <Trans
          i18nKey="page.portfolio.add-new-project.modal.description"
          components={{
            email: (
              <a className="text-aop-basic-blue-500" href={`mailto:${SUPPORT_EMAIL}`}>
                {SUPPORT_EMAIL}
              </a>
            ),
          }}
        />
      }
      {...{ isOpened, onOpenChange }}
      size="md"
    >
      <Modal.Actions>
        <Modal.Close>
          <Button>{t("common.action.close")}</Button>
        </Modal.Close>
      </Modal.Actions>
    </Modal.Root>
  );
}
