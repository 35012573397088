import type { ReactNode } from "react";
import { createContext, useEffect, useMemo, useRef } from "react";

import { createT } from "./createT";
import type { Languages, TranslationFunction, Translations } from "./types";

export interface I18nContextValue<T extends Record<string, string> = Translations> {
  language: string;
  translations: T;
  t: TranslationFunction<T>;
  changeLanguage: (lang: string) => void;
}

export const I18nContext = createContext<I18nContextValue | undefined>(undefined);

interface I18nProviderProps<T extends Record<string, string> = Translations> {
  children: ReactNode;
  language: Languages;
  translations: T;
  onChangeLanguage?: (language: Languages) => void;
  onMissingKey?: (key: string, language: string) => void;
  onMissingVariable?: (key: string, value: string, language: string) => void;
}

export function I18nProvider<T extends Record<string, string> = Translations>({
  children,
  language,
  onChangeLanguage,
  translations,
  onMissingKey,
  onMissingVariable,
}: I18nProviderProps<T>): React.ReactNode {
  const missingKeyRef = useRef(onMissingKey);
  const missingVariableRef = useRef(onMissingVariable);

  useEffect(() => {
    missingKeyRef.current = onMissingKey;
    missingVariableRef.current = onMissingVariable;
  }, [onMissingKey, onMissingVariable]);

  const t = useMemo(
    () =>
      createT({
        translations,
        language,
        onMissingKey: (key) => missingKeyRef.current?.(key, language),
        onMissingVariable: (key, value) => missingVariableRef.current?.(key, value, language),
      }),
    [language, translations],
  );

  const contextValue = useMemo(
    () => ({ language, translations, t, changeLanguage: onChangeLanguage || (() => {}) }),
    [onChangeLanguage, language, t, translations],
  );

  return <I18nContext.Provider value={contextValue as unknown as I18nContextValue}>{children}</I18nContext.Provider>;
}
