import { infiniteQueryOptions, keepPreviousData, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

export const useCompanyQueries = () => {
  const projectId = useProjectId();
  const api = useApi();

  const COMPANY_AMOUNT = 40;

  return {
    getCompanies: () =>
      queryOptions({
        queryKey: QUERY_KEYS.COMPANY_LIST(projectId),
        queryFn: () => api.getCompaniesV1({ Limit: 10000, Offset: 0 }),
        select: commonAPIDataSelector,
      }),
    getCompaniesInfinite: (
      deferredQuery: string,
      sortBy: ApiQueryParams<"getCompaniesV1">["SortBy"],
      sortDescending: boolean,
    ) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.COMPANY_LIST_INFINITE(projectId, deferredQuery, sortBy, sortDescending),
        queryFn: ({ pageParam = 0 }) =>
          api.getCompaniesV1({
            Limit: COMPANY_AMOUNT,
            Offset: pageParam * COMPANY_AMOUNT,
            search: deferredQuery,
            SortBy: sortBy,
            SortDescending: sortDescending,
          }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.data.hasMore) {
            return undefined;
          }

          return pages.length;
        },
        placeholderData: keepPreviousData,
      }),
    getCompanyDetails: (companyId: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.COMPANY_DETAILS(projectId, companyId),
        queryFn: () => api.getCompaniesDetailsV1(companyId),
        select: commonAPIDataSelector,
      }),
  };
};
