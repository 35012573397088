import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconSettings01 from "assets/icons/settings-01.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { LoadingIcon } from "components/Icons/Icons";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useForm } from "react-hook-form";
import { Link } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

type WidgetTypes = "ticketRating" | "ticketSolvingTime" | "engagement" | "contribution";

type DataWidgetModalProps = ModalBaseProps & {
  activeWidgets: WidgetTypes[];
};

interface DataWidgetModalFormValues {
  ticketRating: boolean;
  ticketSolvingTime: boolean;
  engagement: boolean;
  contribution: boolean;
}

function DataWidgetModal({ activeWidgets, isOpened, onOpenChange }: DataWidgetModalProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const query = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const form = useForm<DataWidgetModalFormValues>();

  const activateWidgets = useMutation({
    mutationFn: (widgetTypes: WidgetTypes[]) =>
      api.postWidgetsActiveV1({ widgetDataType: widgetTypes }).then((x) => x.data),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("page.home.data-widgets.save.success") });
      await query.invalidateQueries({ queryKey: QUERY_KEYS.DATA_WIDGETS_LIST(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.home.data-widgets.save.error") });
    },
  });

  const handleSave = async () => {
    const formValues = form.getValues();
    const widgetsToActivate = Object.entries(formValues)
      .filter(([, value]) => value === true)
      .map((x) => x[0] as WidgetTypes);

    await activateWidgets.mutateAsync(widgetsToActivate);

    onOpenChange(false);
  };

  return (
    <Modal.Root
      title={t("components.data-widgets.modal.title")}
      description={t("components.data-widgets.modal.subtitle")}
      {...{ isOpened, onOpenChange }}
    >
      <Form className="grid grid-cols-1 gap-4 lg:grid-cols-2" formMethods={form} onSubmit={handleSave}>
        <FormContent className="items-center">
          <TicketRatingWidget display />
          <FormCheckbox<DataWidgetModalFormValues>
            name="ticketRating"
            label={t("components.data-widgets.ticket-rating.title")}
            defaultValue={activeWidgets.includes("ticketRating")}
          />
        </FormContent>
        <FormContent className="items-center">
          <TicketSolvingTimeWidget display />
          <FormCheckbox<DataWidgetModalFormValues>
            name="ticketSolvingTime"
            label={t("components.data-widgets.ticket-solving-time.title")}
            defaultValue={activeWidgets.includes("ticketSolvingTime")}
          />
        </FormContent>
        <FormContent className="items-center">
          <EngagementWidget display />
          <FormCheckbox<DataWidgetModalFormValues>
            name="engagement"
            label={t("components.data-widgets.engagement.title")}
            defaultValue={activeWidgets.includes("engagement")}
          />
        </FormContent>
        <FormContent className="items-center">
          <ContributionWidget display />
          <FormCheckbox<DataWidgetModalFormValues>
            name="contribution"
            label={t("components.data-widgets.contribution.title")}
            defaultValue={activeWidgets.includes("contribution")}
          />
        </FormContent>
      </Form>
      <Modal.Actions>
        <Modal.Close>
          <Button styling="secondary">{t("common.action.cancel")}</Button>
        </Modal.Close>
        <Button styling="primary" onClick={handleSave} disabled={activateWidgets.isPending}>
          {t("common.action.save")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}

interface DataWidgetInternalProps {
  type: WidgetTypes;
  title: string;
  tooltip: string;
  neutralMessage: string;
  increaseMessage: string;
  decreaseMessage: string;
  display?: boolean;
}

function DataWidget({
  type,
  title,
  tooltip,
  neutralMessage,
  increaseMessage,
  decreaseMessage,
  display = false,
}: DataWidgetInternalProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();

  const {
    data: widgetDetails = { percentage: 0, isIncreasePositive: true },
    error,
    isLoading: isLoadingWidgetDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.DATA_WIDGETS_DETAILS(projectId, type),
    queryFn: () => api.getWidgetsDataDetailsV1(type),
    select: (x) => x.data,
    enabled: !display,
  });

  return (
    <div className="relative flex w-full flex-col gap-2 overflow-hidden rounded-lg border border-grey-100 bg-white px-4 py-3">
      {isLoadingWidgetDetails ? (
        <div className="absolute inset-0 flex w-full items-center justify-center bg-white">
          <LoadingIcon className="w-8" />
        </div>
      ) : null}
      <div className="flex items-center gap-2">
        <Link
          to={
            // eslint-disable-next-line no-nested-ternary
            type === "engagement"
              ? routes.analytics.engagementDetails({ slug })
              : type === "contribution"
                ? routes.analytics.contributionDetails({ slug })
                : routes.analytics.overview({ slug }) + "#tickets-section"
          }
        >
          <span className="text-caption-bold">{title}</span>
        </Link>
        <InfoIcon tooltip={tooltip} />
      </div>
      <div className="flex items-center justify-between gap-2">
        {error ? (
          <span className="text-caption">{t("components.data-widgets.error")}</span>
        ) : (
          <>
            <span className="text-caption">
              {
                // eslint-disable-next-line no-nested-ternary
                display
                  ? neutralMessage
                  : // eslint-disable-next-line no-nested-ternary
                    widgetDetails.percentage === 0
                    ? neutralMessage
                    : // eslint-disable-next-line no-nested-ternary
                      widgetDetails.percentage > 0
                      ? increaseMessage
                      : decreaseMessage
              }
            </span>
            <GrowLabel
              type="percentage"
              percentage={widgetDetails.percentage}
              increasingIsBad={!widgetDetails.isIncreasePositive}
            />
          </>
        )}
      </div>
    </div>
  );
}

interface DataWidgetProps {
  display?: boolean;
}

function TicketRatingWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="ticketRating"
      title={t("components.data-widgets.ticket-rating.title")}
      tooltip={t("components.data-widgets.ticket-rating.tooltip")}
      neutralMessage={t("components.data-widgets.ticket-rating.message.neutral")}
      increaseMessage={t("components.data-widgets.ticket-rating.message.increase")}
      decreaseMessage={t("components.data-widgets.ticket-rating.message.decrease")}
      display={display}
    />
  );
}
function TicketSolvingTimeWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="ticketSolvingTime"
      title={t("components.data-widgets.ticket-solving-time.title")}
      tooltip={t("components.data-widgets.ticket-solving-time.tooltip")}
      neutralMessage={t("components.data-widgets.ticket-solving-time.message.neutral")}
      increaseMessage={t("components.data-widgets.ticket-solving-time.message.increase")}
      decreaseMessage={t("components.data-widgets.ticket-solving-time.message.decrease")}
      display={display}
    />
  );
}

function EngagementWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="engagement"
      title={t("components.data-widgets.engagement.title")}
      tooltip={t("components.data-widgets.engagement.tooltip")}
      neutralMessage={t("components.data-widgets.engagement.message.neutral")}
      increaseMessage={t("components.data-widgets.engagement.message.increase")}
      decreaseMessage={t("components.data-widgets.engagement.message.decrease")}
      display={display}
    />
  );
}

function ContributionWidget({ display }: DataWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DataWidget
      type="contribution"
      title={t("components.data-widgets.contribution.title")}
      tooltip={t("components.data-widgets.contribution.tooltip")}
      neutralMessage={t("components.data-widgets.contribution.message.neutral")}
      increaseMessage={t("components.data-widgets.contribution.message.increase")}
      decreaseMessage={t("components.data-widgets.contribution.message.decrease")}
      display={display}
    />
  );
}

export function HomeDataWidgets(): React.ReactNode {
  const [isWidgetModalOpened, widgetModalHandler] = useBool(false);

  const { t } = useTranslation();
  const analyticsQueries = useAnalyticsQueries();
  const { data: widgets, isPending: isPendingWidgets, error: errorWidgets } = useQuery(analyticsQueries.getWidgets());

  if (errorWidgets) {
    return <ErrorPage error={errorWidgets} />;
  }

  const activeWidgets = widgets?.activeWidgets ?? [];

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        {isPendingWidgets && (
          <div className="mt-20 flex min-w-72 max-w-full justify-around">
            <LoadingIcon className="w-8" />
          </div>
        )}
        {!isPendingWidgets && (
          <>
            <div className="flex flex-row-reverse items-center justify-between">
              <IconButton
                className="justify-self-end"
                title={t("page.home.data-widgets.button.tooltip")}
                onClick={widgetModalHandler.setTrue}
                icon={iconSettings01}
              />
              {activeWidgets.length > 0 && <span className="text-body-bold">{t("page.home.data-widgets.title")}</span>}
            </div>
            {activeWidgets.includes("ticketRating") && <TicketRatingWidget />}
            {activeWidgets.includes("ticketSolvingTime") && <TicketSolvingTimeWidget />}
            {activeWidgets.includes("engagement") && <EngagementWidget />}
            {activeWidgets.includes("contribution") && <ContributionWidget />}
          </>
        )}
      </div>
      <DataWidgetModal
        isOpened={isWidgetModalOpened}
        onOpenChange={widgetModalHandler.set}
        activeWidgets={activeWidgets}
      />
    </>
  );
}
