import iconAlertCircle from "assets/icons/alert-circle.svg";
import { Icon } from "components/Icon/Icon";
import type { InputProps } from "components/Input/Input";
import { Input } from "components/Input/Input";
import { mergeRefs } from "hooks/useCombinedRef";
import { type ChangeEvent } from "react";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormInputProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<InputProps, "name" | "ref"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  onChange?: (value?: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  "data-testid"?: string;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  inputRef?: React.Ref<HTMLInputElement>;
  hideErrorText?: boolean;
}

/**
 * When using input type="number", the value will always be a string. If you need to use a different type, you have to convert it to a string yourself (during submit).
 */
export function FormInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  rules,
  defaultValue,
  className,
  inputRef,
  hideErrorText,
  ...props
}: FormInputProps<TFormValues, TName>): React.ReactNode {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue });

  const maxLengthValue = typeof rules?.maxLength === "object" ? rules.maxLength.value : undefined;
  const isError = error != null;
  const currentValueLength = `${field.value || ""}`.length || 0;

  return (
    <FormErrorWrapper
      subtext={
        maxLengthValue != null && (
          <>
            <span className={maxLengthValue < currentValueLength ? "text-red-600" : undefined}>
              {currentValueLength}
            </span>
            /{maxLengthValue}
          </>
        )
      }
      {...{ hideErrorText, name, className }}
    >
      <Input
        ref={mergeRefs(ref, inputRef)}
        {...props}
        {...field}
        className={isError ? "pr-8" : undefined}
        value={(field.value as any) || ""}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        onFocus={(e) => {
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          field.onBlur();
          props.onBlur?.(e);
        }}
        postfix={
          props.postfix ||
          (isError && (
            <Icon name={iconAlertCircle} size={16} className="absolute right-2 top-1/2 -translate-y-1/2 text-red-600" />
          ))
        }
        {...{ isError }}
      />
    </FormErrorWrapper>
  );
}
