import { Button } from "components/Button/Button";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { List } from "modules/community-groups/components/List/List";
import { canCreateHelpCategory } from "modules/community-groups/permissions";
import { routes } from "routes";
import { useTranslation } from "translations";

export function HelpCategoryListPage(): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme={"minimal"}
      title={t("page.help-category.list.title")}
      subTitle={t("page.help-category.list.sub-title")}
      actions={
        hasPermission(canCreateHelpCategory) ? (
          <Button type="link" href={routes.helpCategories.create({ slug })}>
            {t("model.help-category.action.create")}
          </Button>
        ) : null
      }
    >
      <List
        type="helpCategory"
        followLabels={{
          button: t("model.help-category.action.follow"),
          failedNotification: t("model.help-category.action.follow.notification-error"),
        }}
        unfollowLabels={{
          button: t("model.help-category.action.unfollow"),
          failedNotification: t("model.help-category.action.unfollow.notification-error"),
        }}
        allAudienceLabel={t("model.help-category.audience.placeholder")}
        deleteLabels={{
          confirmation: t("model.help-category.action.delete.confirmation"),
          description: t("model.help-category.action.delete.description"),
          success: t("model.help-category.action.delete.notification.success"),
          error: t("model.help-category.action.delete.notification.error"),
        }}
        statusLabels={{
          disable: {
            confirmation: t("model.platform.help-category.action.disable.confirmation"),
            description: t("model.platform.help-category.action.disable.description"),
            success: t("model.platform.help-category.action.disable.notification.success"),
            error: t("model.platform.help-category.action.disable.notification.error"),
          },
          enable: {
            success: t("model.platform.help-category.action.enable.notification.success"),
            error: t("model.platform.help-category.action.enable.notification.error"),
          },
        }}
      />
    </DocumentPaper>
  );
}
