import type { CommunityFeedAudienceGroupDto, MessageCreatedEntityDto } from "api/types";
import ImagePostbox from "assets/images/postbox.png";
import { Button } from "components/Button/Button";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { isDefined } from "helpers/util";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { Trans, useTranslation } from "translations";

type AdminCreatePostSuccessModalProps = ModalBaseProps & {
  audienceReached: MessageCreatedEntityDto | undefined;
} & (
    | {
        isPostedInGroup: true;
        groupPosted: CommunityFeedAudienceGroupDto;
      }
    | {
        isPostedInGroup?: false;
        groupPosted?: never;
      }
  );

export function AdminCreatePostSuccessModal({
  isPostedInGroup,
  audienceReached,
  groupPosted,
  isOpened,
  onOpenChange,
}: AdminCreatePostSuccessModalProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const slug = useSlug();

  const isAnyAddressReached = isDefined(audienceReached?.addressCount) && audienceReached.addressCount > 0;
  const isAnyUnknownAddressReached =
    isDefined(audienceReached?.unknownAddressCount) && audienceReached.unknownAddressCount > 0;
  const isAnyResidentReached =
    isDefined(audienceReached?.audienceResidentsCount) && audienceReached.audienceResidentsCount > 0;

  return (
    <Modal.Root title={t("page.admin-create-post.success-modal.title")} {...{ isOpened, onOpenChange }}>
      <div className="flex w-full flex-col items-center justify-center gap-4">
        <img alt="Posted" className="py-8" src={ImagePostbox} width={96} height={96} />
        {isAnyResidentReached && (
          <div className="flex flex-col items-center gap-1">
            <span className="text-center text-body">
              {t("page.admin-create-post.success-modal.audience-reached.description.delivered-to")}
            </span>
            {/* Amount of addresses */}
            {isAnyAddressReached && (
              <span className="text-center text-body">
                <Trans
                  i18nKey="page.admin-create.post.success-modal.audience-reached.description.addresses"
                  values={{
                    count: audienceReached.addressCount || 0,
                    addressCount: audienceReached.addressCount || 0,
                  }}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {/* Amount of unknown addresses */}
            {isAnyUnknownAddressReached && (
              <span className="text-center text-body">
                <Trans
                  i18nKey="page.admin-create.post.success-modal.audience-reached.description.unknown-addresses"
                  values={{
                    unknownAddressCount: audienceReached.unknownAddressCount || 0,
                    count: audienceReached.unknownAddressCount || 0,
                  }}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {/* Amount of residents */}
            <span className="text-center text-body">
              <Trans
                i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents"
                values={{
                  residentCount: audienceReached.audienceResidentsCount || 0,
                  count: audienceReached.audienceResidentsCount || 0,
                }}
                components={{
                  b: <b />,
                }}
              />
            </span>
          </div>
        )}
        {!isAnyResidentReached && (
          <span className="text-center text-body">
            {t("page.admin-create-post.success-modal.audience-reached.description.residents.none")}
          </span>
        )}
      </div>
      <Modal.Actions>
        <Modal.Close>
          <Button styling="tertiary"> {t("page.admin-create-post.success-modal.button.another-message")}</Button>
        </Modal.Close>
        <Button
          styling="primary"
          onClick={() =>
            isPostedInGroup
              ? void navigate(
                  groupPosted?.type === "helpCategory"
                    ? routes.helpCategories.details({ slug, id: groupPosted.id })
                    : routes.interestGroups.details({ slug, id: groupPosted.id }),
                )
              : void navigate(routes.messageFeed.list({ slug }))
          }
        >
          {isPostedInGroup
            ? t("page.admin-create-post.success-modal.button.group-done")
            : t("page.admin-create-post.success-modal.button.done")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}
