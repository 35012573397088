import type { PermissionSelector } from "hooks/usePermission";

export const canListMessages: PermissionSelector = (x) => x.canListPosts;

export const canCreateMessage: PermissionSelector = (x) => x.canCreatePosts;

export const canPostInMarketplace: PermissionSelector = (x) => x.canPostInMarketplace;

export const canCraeteStandardMessage: PermissionSelector = (x) => x.canCreateStandardPosts;

export const canCreateAnnouncementMessage: PermissionSelector = (x) => x.canCreateAnnouncements;

export const canCreateGeneralAnnouncementMessage: PermissionSelector = (x) => x.community.canCreateGeneralAnnouncement;

export const canCreateGenearlStandardMessage: PermissionSelector = (x) => x.community.canCreateGeneralPost;
