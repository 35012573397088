import { twResolve } from "helpers/tw-resolve";
import { isDefined } from "helpers/util";
import { get } from "lodash-es";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useFormState } from "react-hook-form";

interface FormErrorWrapperProps<TFormValues extends FieldValues> {
  name: FieldPath<TFormValues>;
  className?: string;
  "data-testid"?: string;
  subtext?: React.ReactNode;
  encircle?: boolean;
  hideErrorText?: boolean;
  children: React.ReactNode;
}

export function FormErrorWrapper<TFormValues extends FieldValues>({
  name,
  children,
  hideErrorText,
  subtext,
  encircle,
  className,
  "data-testid": dataTestId,
}: FormErrorWrapperProps<TFormValues>): React.ReactNode {
  const { errors } = useFormState<TFormValues>({ name: name });

  const error = name ? get(errors, name) : null;
  const isError = error != null;

  return (
    <div
      className={twResolve(
        "relative flex max-w-full flex-col gap-1",
        encircle &&
          isError &&
          "after:pointer-events-none after:absolute after:left-1/2 after:top-1/2 after:size-[calc(100%+8px)] after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-lg after:border after:border-red-600 after:content-['']",
        className,
      )}
      data-testid={dataTestId}
    >
      {children}
      {!hideErrorText && name != null && (isError || subtext) && (
        <div
          className={twResolve("flex w-full justify-between text-grey-600", isError && "text-red-600")}
          data-testid="form-input-meta"
        >
          {isError && (
            <span data-testid="form-input-error" role="alert" className="whitespace-nowrap text-overline">
              {error.message as string}
            </span>
          )}
          {isDefined(subtext) && <span className="ml-auto mr-0 whitespace-nowrap text-overline">{subtext}</span>}
        </div>
      )}
    </div>
  );
}
