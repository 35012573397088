import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto, TranslateRequest } from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { AudienceSelector } from "components/AudienceSelector/AudienceSelector";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormIconPicker } from "components/Form/FormIconPicker";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

import type { FormValues } from "./";

export interface LayoutProps {
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
  defaultValues: FormValues;
  languages: LanguageDto[];
  isEditMode: boolean;
  folderId?: string;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: props.defaultValues,
  });

  const audience = useWatch({ control: formMethods.control, name: "audience" });

  const canLeave = async () => await formMethods.trigger();

  const api = useApi();
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  async function onTranslate(field: "name" | "description", languageId: LanguageDto["id"]) {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await translate({
      languages: props.languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  }

  return (
    <DocumentPaper
      theme="no-gaps"
      title={t("page.document.root.create-or-edit.create-mode.title")}
      subTitle={t("page.document.root.create-or-edit.create-mode.title")}
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        <Wizard
          id="document-root-create-wizard"
          actionsText={{
            finish: props.isEditMode
              ? t("component.wizard.action.edit.finish")
              : t("component.wizard.action.create.finish"),
          }}
          onFinish={formMethods.handleSubmit(props.onSubmit)}
          isSubmitting={props.isSubmitting}
          strictOrder={!props.isEditMode}
        >
          <WizardStep
            id={1}
            canLeave={canLeave}
            hasFinish={props.isEditMode}
            title={
              <>
                <span>{t("component.wizard.step", { step: 1 })}</span>
                <span>{t("component.wizard.step-general")}</span>
              </>
            }
          >
            <FormContent>
              {props.languages.map((lng) => {
                const nameId = `name-${lng.id}`;
                const descriptionId = `description-${lng.id}`;

                return (
                  <React.Fragment key={lng.id}>
                    <FormField
                      htmlFor={nameId}
                      label={`${t("model.document.root.name")} (${lng.poEditorCode})`}
                      required
                      actions={
                        <Button
                          styling="ghostPrimary"
                          onClick={() => onTranslate("name", lng.id)}
                          icon={<Icon name={iconGlobe02} size={16} />}
                        >
                          {t("model.document.root.name.translate")}
                        </Button>
                      }
                    >
                      <FormInput<FormValues>
                        className="max-w-sm"
                        data-testid="documents-root-name"
                        name={`nameTranslations.${lng.id}`}
                        id={nameId}
                        rules={{
                          required: {
                            message: t("components.form.error.required", {
                              inputName: t("model.document.root.name"),
                            }),
                            value: true,
                          },
                          maxLength: {
                            message: t("components.form.error.max-length", {
                              length: NAME_MAX_LENGTH,
                            }),
                            value: NAME_MAX_LENGTH,
                          },
                        }}
                        placeholder={`${t("page.document.root.create-or-edit.create-mode.name.placeholder", {
                          language: lng.description,
                        })}`}
                        disabled={isTranslating}
                      />
                    </FormField>
                    <FormField
                      htmlFor={descriptionId}
                      label={`${t("model.document.root.description")} (${lng.poEditorCode})`}
                      actions={
                        <Button
                          styling="ghostPrimary"
                          onClick={() => onTranslate("description", lng.id)}
                          icon={<Icon name={iconGlobe02} size={16} />}
                        >
                          {t("model.document.root.description.translate")}
                        </Button>
                      }
                    >
                      <FormTextArea<FormValues>
                        className="max-w-sm"
                        data-testid="documents-root-description"
                        name={`descriptionTranslations.${lng.id}`}
                        id={descriptionId}
                        placeholder={`${t("page.document.root.create-or-edit.create-mode.description.placeholder", {
                          language: lng.description,
                        })}`}
                        rules={{
                          maxLength: {
                            message: t("components.form.error.max-length", {
                              length: DESCRIPTION_MAX_LENGTH,
                            }),
                            value: DESCRIPTION_MAX_LENGTH,
                          },
                        }}
                        disabled={isTranslating}
                      />
                    </FormField>
                  </React.Fragment>
                );
              })}
              <FormField
                htmlFor="icon-char"
                label={t("model.document.root.icon")}
                description={t("model.document.root.icon.description")}
              >
                <FormIconPicker<FormValues> id="icon-char" name="iconChar" data-testid="documents-root-icon-char" />
              </FormField>
              <FormField
                label={t("common.entity.audience")}
                description={t("component.audience-section.description", {
                  audienceTarget: t("common.entity.section").toLowerCase(),
                })}
              >
                <AudienceSelector
                  defaultAudience={audience}
                  isEditMode={props.isEditMode}
                  onSaveAudience={(audience) => formMethods.setValue("audience", audience)}
                />
              </FormField>
            </FormContent>
          </WizardStep>
          <WizardStep
            id={2}
            canLeave={canLeave}
            hasFinish={props.isEditMode}
            title={
              <>
                <span>{t("component.wizard.step", { step: 2 })}</span>
                <span>{t("component.wizard.step-permissions")}</span>
              </>
            }
          >
            <FormContent maxWidth="none">
              <Accordion allowMultipleExpanded allowZeroExpanded>
                {Object.entries(props.defaultValues.permissions).map(([roleId, permission]) => (
                  <React.Fragment key={roleId}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{permission.name}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <PermissionListLayout>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={permission.canListDocuments}
                              name={`permissions.${roleId}.canListDocuments`}
                              label={t("model.permission.document.can-list-document")}
                            />
                          </PermissionWrapper>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              memorise={permission.canCreateDocument}
                              alignTop
                              name={`permissions.${roleId}.canCreateDocument`}
                              label={t("model.permission.document.can-create-document")}
                            />
                          </PermissionWrapper>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={permission.canDeleteDocument}
                              name={`permissions.${roleId}.canDeleteDocument`}
                              label={t("model.permission.document.can-delete-document")}
                            />
                          </PermissionWrapper>
                        </PermissionListLayout>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </React.Fragment>
                ))}
              </Accordion>
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}

const NAME_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 300;
