import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ConstraintListItemDto } from "api/types";
import iconAnnotationInfo from "assets/icons/annotation-info.svg";
import { Button } from "components/Button/Button";
import { Drawer } from "components/Drawer/Drawer";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Notice } from "components/Notice/Notice";
import { flattenAudience, getIconByAudienceType, mapAudienceByType, orderAudienceTypes } from "helpers/audience";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "translations";

import { AudiencePreview } from "./AudiencePreview";

type AudienceType = ConstraintListItemDto["type"];

type AudienceSelectorProps = {
  defaultAudience: ConstraintListItemDto[];
  onSaveAudience: (audiences: ConstraintListItemDto[]) => void;
  isEditMode: boolean;
  editWarningMessage?: string;
};

export function AudienceSelector({
  defaultAudience,
  isEditMode,
  editWarningMessage,
  onSaveAudience,
}: AudienceSelectorProps): React.ReactNode {
  const [isDrawerOpened, drawerHandler] = useBool();

  const defaultAudienceGroupByType = mapAudienceByType(defaultAudience);
  const [currAudience, setCurrAudience] =
    useState<Record<AudienceType, ConstraintListItemDto[]>>(defaultAudienceGroupByType);

  const { t } = useTranslation();
  const api = useApi();
  const projectId = useProjectId();
  const {
    data: constraints,
    isPending: isPendingConstraints,
    isError: isErrorConstraints,
  } = useQuery({
    queryKey: QUERY_KEYS.CONSTRAINTS(projectId),
    queryFn: () => api.getConstraintsV1(),
    select: commonAPIDataSelector,
    staleTime: 30 * 1000,
  });

  const onChangeAudienceItems = (type: AudienceType, items: ConstraintListItemDto[]) => {
    setCurrAudience((prevValue) => ({ ...prevValue, [type]: items }));
  };

  // Reset to existing audience when closing the drawer witout saving
  const onClickClose = () => {
    setCurrAudience(defaultAudienceGroupByType);
  };

  const onClickSave = () => {
    onSaveAudience(flattenAudience(currAudience));
  };

  const availableAudience = useMemo(() => mapAudienceByType(constraints?.items || []), [constraints?.items]);
  const availableAudienceTypes = Object.keys(availableAudience) as AudienceType[];
  const configTexts = {
    apartmentType: {
      title: t("model.constraint.address"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.apartment-type").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.apartment-type").toLowerCase(),
      }),
    },
    floor: {
      title: t("model.constraint.floor"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.floor").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.floor").toLowerCase(),
      }),
    },
    building: {
      title: t("model.constraint.building"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.building").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.building").toLowerCase(),
      }),
    },
    company: {
      title: t("model.constraint.company"),
      description: t("component.audience-selector.general-description", {
        audienceTarget: t("common.entity.asset").toLowerCase(),
        audienceEntity: t("model.constraint.type.company").toLowerCase(),
      }),
      placeholder: t("component.audience-selector.general-placeholder", {
        audienceEntity: t("model.constraint.type.company").toLowerCase(),
      }),
    },
  };

  // Hide audience selector if there are no constraints available (aka no address)
  if (!isPendingConstraints && !isErrorConstraints && constraints.items.length === 0) {
    return null;
  }

  return (
    <>
      <AudiencePreview audience={defaultAudience} onClick={drawerHandler.setTrue} truncate />
      <Drawer.Root
        title={t("component.audience-selector.title")}
        isOpened={isDrawerOpened}
        onOpenChange={drawerHandler.set}
      >
        {isPendingConstraints && <FullSizeLoader />}
        {!isPendingConstraints && !isErrorConstraints && (
          <>
            <Drawer.Body>
              <div className="flex w-full flex-col gap-4">
                {isEditMode && editWarningMessage && <Notice icon={iconAnnotationInfo} message={editWarningMessage} />}
                <div data-testid="audience-root" className="flex w-full flex-col gap-4">
                  {/* Order audience sections by a specific ordering */}
                  {orderAudienceTypes(availableAudienceTypes).map((audienceType) => {
                    const icon = getIconByAudienceType(audienceType);

                    return (
                      <div key={audienceType} data-testid="audience-item" className="flex w-full flex-col gap-1">
                        <div className="flex w-full items-center justify-start gap-1">
                          {icon && <Icon name={icon} size={16} />}
                          <h2 className="text-headline4">{configTexts[audienceType].title}</h2>
                        </div>
                        <p className="text-grey-700">{configTexts[audienceType].description}</p>
                        <MultiSelect<ConstraintListItemDto>
                          selected={currAudience[audienceType]}
                          placeholder={configTexts[audienceType].placeholder}
                          items={availableAudience[audienceType]}
                          keySelector={({ id }) => id}
                          renderOption={({ key }) => key}
                          onChange={(items) => onChangeAudienceItems(audienceType, items)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Drawer.Body>
            <Drawer.Actions>
              <Drawer.Close>
                <Button styling="secondary" onClick={onClickClose}>
                  {t("common.action.cancel")}
                </Button>
              </Drawer.Close>
              <Drawer.Close>
                <Button styling="primary" onClick={onClickSave}>
                  {t("common.action.set")}
                </Button>
              </Drawer.Close>
            </Drawer.Actions>
          </>
        )}
      </Drawer.Root>
    </>
  );
}
