import { useQuery } from "@tanstack/react-query";
import type { CompanyRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { isDefined } from "helpers/util";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { companyMutations } from "queries/companies/mutations";
import { useCompanyQueries } from "queries/companies/queryOptions";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const slug = useSlug();
  const { id: companyId } = useParams<{ id: string }>();
  const isEditMode = isDefined(companyId);
  const navigate = useNavigate();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const companyQueries = useCompanyQueries();
  const {
    data: companies,
    isPending: isLoadingCompanies,
    error: companiesError,
  } = useQuery(companyQueries.getCompanies());
  const addressQueries = useAddressQueries();
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: addressesError,
  } = useQuery(addressQueries.getAddresses());
  const {
    data: companyDetails,
    isFetching: isLoadingCompanyDetails,
    error: companyDetailsError,
  } = useQuery({
    ...companyQueries.getCompanyDetails(companyId!),
    enabled: isEditMode,
  });
  const createCompany = companyMutations.useCreateCompany();
  const editCompany = companyMutations.useEditCompany(companyId!);

  const existingCompanies = useMemo(
    () => companies?.items.filter((x) => x.id !== companyId) ?? [],
    [companies, companyId],
  );

  const error = companyDetailsError || addressesError || companiesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingCompanyDetails || isLoadingAddresses || isLoadingCompanies;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    isEditMode: isEditMode,
    defaultValues: isEditMode
      ? {
          name: companyDetails!.name,
          description: companyDetails!.description,
          address: addresses?.items.find((x) => x.id === companyDetails!.address.id),
          background: companyDetails?.backgroundImage ? [companyDetails.backgroundImage] : [],
          logo: companyDetails?.logo ? [companyDetails.logo] : [],
        }
      : {},
    isSubmitting: isUploadingImage || editCompany.isPending || createCompany.isPending,
    existingCompanies: existingCompanies,
    addresses: addresses?.items ?? [],
    async onSubmit(data) {
      const payload: CompanyRequest = {
        addressId: data.address.id,
        name: data.name,
        description: data.description,
      };

      if (data.background.length > 0) {
        const background = await uploadFormImage(data.background[0]);

        payload.backgroundImageId = background?.id;
      }
      if (data.logo.length > 0) {
        const logo = await uploadFormImage(data.logo[0]);

        payload.logoId = logo?.id;
      }

      if (isEditMode) {
        await editCompany.mutateAsync(payload);
      } else {
        await createCompany.mutateAsync(payload);
      }

      void navigate(routes.companies.list({ slug }));
    },
  });
}
