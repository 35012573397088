import type { Breadcrumb } from "components/Breadcrumbs/Breadcrumbs";
import { routes } from "routes";
import { useTranslation } from "translations";

export function useSystemSettingBreadcrumbs({
  module,
  subModule,
  status,
}: {
  module: "automated-surveys" | "project-connections" | "alerts" | "platform-groups";
  subModule?: string;
  status: "list" | "create" | "edit" | "details" | "list-group" | "create-group" | "edit-group";
}): Breadcrumb[] {
  const { t } = useTranslation();

  const crumbs: Breadcrumb[] = [
    {
      name: t("component.breadcrumbs.portfolio"),
      to: routes.portfolio.overview(),
    },
    {
      name: t("component.breadcrumbs.system-settings"),
    },
  ];

  if (module === "project-connections") {
    crumbs.push({
      name: t("component.breadcrumbs.project-connections"),
      to: routes.projectConnections.list(),
    });

    if (subModule) {
      crumbs.push({
        name: subModule,
      });
    }

    if (status === "create") {
      crumbs.push({
        name: t("component.breadcrumbs.project-connections.create"),
      });
    } else if (status === "edit") {
      crumbs.push({
        name: t("component.breadcrumbs.project-connections.edit"),
      });
    } else if (status === "list-group") {
      crumbs.push({
        name: t("component.breadcrumbs.project-connections.list-group"),
      });
    } else if (status === "create-group") {
      crumbs.push({
        name: t("component.breadcrumbs.project-connections.create-group"),
      });
    } else if (status === "edit-group") {
      crumbs.push({
        name: t("component.breadcrumbs.project-connections.edit-group"),
      });
    }
  } else if (module === "automated-surveys") {
    crumbs.push({
      name: t("component.breadcrumbs.automated-surveys"),
      to: routes.automatedSurveys.list(),
    });

    if (subModule) {
      crumbs.push({
        name: subModule,
      });
    }

    if (status === "create") {
      crumbs.push({
        name: t("component.breadcrumbs.automated-surveys.create"),
      });
    } else if (status === "edit") {
      crumbs.push({
        name: t("component.breadcrumbs.automated-surveys.edit"),
      });
    } else if (status === "details") {
      crumbs.push({
        name: t("component.breadcrumbs.automated-surveys.details"),
      });
    }
  } else if (module === "alerts") {
    crumbs.push({
      name: t("component.breadcrumbs.alerts"),
      to: routes.alerts.list(),
    });
    if (subModule) {
      crumbs.push({
        name: subModule,
      });
    }

    if (status === "edit") {
      crumbs.push({
        name: t("component.breadcrumbs.alerts.settings"),
      });
    }
  } else if (module === "platform-groups") {
    crumbs.push({
      name: t("component.breadcrumbs.platform-groups"),
      to: routes.platformGroups.list(),
    });

    if (subModule) {
      crumbs.push({
        name: subModule,
      });
    }

    if (status === "create") {
      crumbs.push({
        name: t("component.breadcrumbs.platform-groups.create"),
      });
    } else if (status === "edit") {
      crumbs.push({
        name: t("component.breadcrumbs.platform-groups.edit"),
      });
    }
  }

  return crumbs;
}
