import { useQuery } from "@tanstack/react-query";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { isDefined } from "helpers/util";
import { useFahHistoryQueries } from "queries/fahHistory/queryOptions";
import type React from "react";
import { useTranslation } from "translations";

import { FahHistoryQuestionGroupCard } from "../components/FahHistoryQuestionGroupCard";
import { FahStatsCard } from "../components/FahHistoryStatsCard";

export function Layout(): React.ReactNode {
  const { t } = useTranslation();

  const fahHistoryQueries = useFahHistoryQueries();
  const {
    data: fahHistoryDetails,
    isPending: isPendingFahHistoryDetails,
    error: errorFahHistoryDetails,
  } = useQuery({
    ...fahHistoryQueries.getFahHistoryDetails(),
  });

  if (isPendingFahHistoryDetails) {
    return <FullSizeLoader withPadding />;
  }

  if (errorFahHistoryDetails) {
    return <ErrorPage error={errorFahHistoryDetails} />;
  }

  const data = fahHistoryDetails;
  const getResponseRateLabel = (amountResponses: number, amountAsked: number) => {
    return `${(amountResponses / amountAsked) * 100}%`;
  };

  return (
    <DocumentPaper theme="minimal" title={t("page.fah-history.title")} subTitle={t("page.fah-history.subtitle")}>
      <div className="flex w-full flex-col gap-5">
        <div className="flex flex-wrap gap-4">
          <FahStatsCard
            label={t("page.fah-history.label.total-responses")}
            value={data.totalResponseCount ?? t("common.label.not-applicable")}
          />
          <FahStatsCard
            label={t("page.fah-history.label.response-rate")}
            value={
              isDefined(data.totalResponseCount) && isDefined(data.totalAskedCount)
                ? getResponseRateLabel(data.totalResponseCount, data.totalAskedCount)
                : t("common.label.not-applicable")
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-6 3xl:w-1/2 3xl:grid-cols-1">
          {data.questions.map((question, index) => (
            <FahHistoryQuestionGroupCard key={`${question.category}-${index}`} order={index + 1} question={question} />
          ))}
        </div>
      </div>
    </DocumentPaper>
  );
}
