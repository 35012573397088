import imageBanner from "assets/images/banner.png";
import imageBanner2x from "assets/images/banner-2x.png";
import logoDark from "assets/images/logo-dark.svg";
import { AnimatePresence, motion } from "motion/react";
import { twJoin } from "tailwind-merge";

type Props = {
  header: React.ReactNode;
  body?: React.ReactNode;
} & ({} | { step: number; totalSteps: number });

export function AuthenticationPage({
  header,
  body,
  children,
  ...props
}: React.PropsWithChildren<Props>): React.ReactNode {
  return (
    <div className="flex h-screen lg:items-center">
      <div className="mx-auto w-full grid-cols-1 sm:max-w-sm lg:grid lg:max-w-none lg:grid-cols-2 lg:px-4">
        <div className="flex w-full flex-col items-center gap-8 rounded-l-lg p-4 lg:max-w-lg lg:justify-self-end lg:bg-white lg:p-8">
          <div className={twJoin("flex aspect-square h-[100px] w-fit items-center justify-start px-[10px]")}>
            <img
              src={logoDark}
              className={twJoin("block w-full transition-[width] duration-200")}
              alt="Area of People logo"
            />
          </div>

          <AnimatePresence>
            {"step" in props && props.step && props.totalSteps ? (
              <motion.div
                className="flex w-full flex-row items-stretch gap-2"
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
              >
                {Array(props.totalSteps)
                  .fill(undefined)
                  .map((_, i) => (
                    <div
                      aria-hidden
                      className={twJoin(
                        "h-1 flex-1 rounded-full transition-colors",
                        props.step > i ? "bg-grey-700" : "bg-grey-300",
                      )}
                      key={i}
                    />
                  ))}
              </motion.div>
            ) : null}
          </AnimatePresence>

          <div className="flex w-full flex-col gap-2 [&>*]:text-center md:[&>*]:text-left">
            <h1 className="text-headline1">{header}</h1>
            {body && (typeof body === "string" ? <p>{body}</p> : body)}
          </div>
          {children && <div className="relative w-full text-center md:text-left">{children}</div>}
        </div>
        <div className="invisible hidden w-full max-w-lg justify-self-start rounded-r-lg bg-aop-basic-blue-100 p-14 lg:visible lg:flex">
          <img
            className="aspect-square w-full object-contain"
            src={imageBanner}
            srcSet={`${imageBanner} 1x, ${imageBanner2x} 2x`}
          />
        </div>
      </div>
    </div>
  );
}
