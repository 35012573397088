import type { UserDetailsWithAddressDto } from "api/types";
import iconBriefcase02 from "assets/icons/briefcase-02.svg";
import iconHome02 from "assets/icons/home-02.svg";
import iconKey02 from "assets/icons/key-02.svg";
import iconLayersThree01 from "assets/icons/layers-three-01.svg";
import iconMail01 from "assets/icons/mail-01.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconPhone from "assets/icons/phone.svg";
import { Icon } from "components/Icon/Icon";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { addressTagsToDictionary } from "helpers/address";
import React from "react";
import { useTranslation } from "translations";

interface Props {
  user: UserDetailsWithAddressDto;
}

export function UserDetails({ user }: Props): React.ReactNode {
  const { t } = useTranslation();
  const { building, apartmentType, company, floor } = addressTagsToDictionary(user.addressTags);

  return (
    <div className="flex max-w-sm flex-col gap-3.5">
      <span className="text-body-bold text-grey-500">{t("page.tickets.create.reporter.title")}</span>
      <div className="rounded-lg border border-grey-300 p-6 shadow-sm">
        <div className="flex gap-5">
          <UserAvatar className="size-20" img={user.avatar} isUserDeleted={!!user.deletedAt} />
          <div className="flex flex-1 flex-col gap-4 text-grey-700">
            <div className="flex flex-col gap-1.5">
              <span className="text-headline3 leading-old-headline4 text-black">{user.fullName}</span>
              {building ? <UserDetail icon={<Icon name={iconHome02} />}>{building}</UserDetail> : null}
              {!company && user.locatedAt ? (
                <UserDetail icon={<Icon name={iconMarkerPin01} />}>{user.locatedAt}</UserDetail>
              ) : null}
              {floor ? <UserDetail icon={<Icon name={iconLayersThree01} />}>{floor}</UserDetail> : null}
              {apartmentType ? <UserDetail icon={<Icon name={iconKey02} />}>{apartmentType}</UserDetail> : null}
            </div>
            {user.email || user.phoneNumber ? (
              <>
                <hr className="h-px w-full border-0 bg-grey-300" />
                <div className="flex flex-col gap-1.5">
                  <span className="text-caption-bold text-black">
                    {t("page.tickets.create.reporter.contact-details")}
                  </span>
                  {user.email ? <UserDetail icon={<Icon name={iconMail01} />}>{user.email}</UserDetail> : null}
                  {user.phoneNumber ? (
                    <UserDetail icon={<Icon name={iconPhone} />}>{user.phoneNumber}</UserDetail>
                  ) : null}
                </div>
              </>
            ) : null}
            {company ? (
              <>
                <hr className="h-px w-full border-0 bg-grey-300" />
                <div className="flex flex-col gap-1.5">
                  <span className="text-caption-bold text-black">{t("page.tickets.create.reporter.company")}</span>
                  <UserDetail icon={<Icon name={iconBriefcase02} />}>{company}</UserDetail>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function UserDetail({ icon, children }: { icon: React.ReactElement; children: string }) {
  return (
    <span className="flex items-center gap-2 text-caption">
      {React.cloneElement(icon, { size: 16 })}
      {children}
    </span>
  );
}
