import { Button } from "components/Button/Button";
import { isHttpError } from "helpers/Network/errors";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

import svgSrc from "../no-data.svg";

interface Props {
  error?: unknown;
  noDataMessage?: string;
  onReload?: () => void;
  horizontal?: boolean;
}

export function NoData({ horizontal, error, noDataMessage: errorMessage, onReload }: Props): React.ReactNode {
  const { t } = useTranslation();

  const isFailure = isHttpError(error) && error.status !== 404;

  return (
    <div
      className={twJoin(
        "my-2 flex items-center gap-6 place-self-center",
        horizontal ? "w-full flex-col px-2 sm:flex-row" : "w-4/5 max-w-md flex-col",
      )}
    >
      <img className={horizontal ? "w-1/2" : "max-w-full"} src={svgSrc} alt="" />
      <div className="flex flex-col gap-1 text-center">
        <h4 className="text-caption-bold">
          {isFailure
            ? t("page.analytics-overview.error.load-failed.title")
            : t("page.analytics-overview.error.no-data.title")}
        </h4>
        <p className="text-caption text-grey-700">
          {isFailure
            ? t("page.analytics-overview.error.load-failed.description")
            : errorMessage || t("page.analytics-overview.error.no-data.description")}
        </p>
      </div>
      {isFailure && onReload ? (
        <Button className="self-center" onClick={onReload}>
          {t("common.action.reload")}
        </Button>
      ) : null}
    </div>
  );
}
