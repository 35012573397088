import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormBadgeMultiSelect } from "components/Form/FormBadgeMultiSelect";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import type { Specification } from "modules/bookings/constants";
import { specificationOptions } from "modules/bookings/constants";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

type ExtraSpecificationsModalProps = ModalBaseProps & {
  availableSpecifications: Specification[];
  onAddSpecifications: (specifications: Specification[]) => void;
};

interface FormValues {
  specifications: Specification[];
}

export function ExtraSpecificationsModal({
  isOpened,
  onOpenChange,
  availableSpecifications,
  onAddSpecifications,
}: ExtraSpecificationsModalProps): React.ReactNode {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    defaultValues: {
      specifications: availableSpecifications,
    },
  });

  function onSubmit() {
    onAddSpecifications(form.getValues("specifications"));
    onOpenChange(false);
  }

  useEffect(() => {
    form.reset({
      specifications: availableSpecifications,
    });
  }, [form, availableSpecifications]);

  return (
    <Modal.Root
      title={t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.title")}
      description={t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <Form formMethods={form} onSubmit={onSubmit}>
        <FormBadgeMultiSelect<FormValues, "specifications">
          name="specifications"
          items={specificationOptions}
          renderOption={(x) => {
            switch (x) {
              case "sizeSpecification":
                return (
                  <span className="text-caption">
                    {t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.size",
                    )}
                  </span>
                );
              case "pricePerHourSpecification":
                return (
                  <span className="text-caption">
                    {t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.price",
                    )}
                  </span>
                );
              case "capacitySpecification":
                return (
                  <span className="text-caption">
                    {t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.capacity",
                    )}
                  </span>
                );
              case "locationSpecification":
                return (
                  <span className="text-caption">
                    {t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.modal.option.location",
                    )}
                  </span>
                );
            }
          }}
          keySelector={(x) => x}
          isGrid
          isBig
          centerText
        />
        <Modal.Actions>
          <Modal.Close>
            <Button styling="secondary">{t("common.action.cancel")}</Button>
          </Modal.Close>
          <Button data-testid="confirm-extra-specifications" styling="primary" type="submit">
            {t("common.action.add")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
