import { useQuery } from "@tanstack/react-query";
import type { AIAnalysisModalState } from "components/AIAnalysisModal/AIAnalysisModal";
import { AIAnalysisModal } from "components/AIAnalysisModal/AIAnalysisModal";
import type { ModalBaseProps } from "components/Modal/Modal";
import { useAutomatedSurveyQueueQueries } from "queries/automatedSurveyQueues/queryOptions";
import type React from "react";
import { useParams } from "react-router";
import { useTranslation } from "translations";

type SurveyQuestionGroupAnswerSentimentModalProps = ModalBaseProps & {
  questionGroupId: string;
};

export function SurveyQuestionGroupAnswerSentimentModal({
  questionGroupId,
  isOpened,
  onOpenChange,
}: SurveyQuestionGroupAnswerSentimentModalProps): React.ReactNode {
  const { t } = useTranslation();
  const { id: queueId } = useParams<{ id: string }>();

  const automatedSurveyQueueQueries = useAutomatedSurveyQueueQueries();
  const { data, isPending, isError } = useQuery({
    ...automatedSurveyQueueQueries.getQuestionSummary(queueId!, questionGroupId),
    enabled: isOpened && !!queueId,
  });

  let state: AIAnalysisModalState = "loading";
  if (!isPending && !isError && data) {
    state = "success";
  } else if (!isPending && isError) {
    state = "error";
  } else if (!isPending && !isError && !data) {
    state = "unavailable";
  }

  return (
    <AIAnalysisModal
      data={{
        sentiment: data?.sentiment || 0,
        summary: data?.summary || "",
        suggestion: data?.suggestion,
        hashtags: data?.hashtags,
      }}
      config={{
        success: {
          title: t("page.automated-surveys.sentiment.modal.title"),
        },
        unavailable: {
          title: t("page.automated-surveys.sentiment.no-data.notification"),
        },
        error: {
          title: t("page.automated-surveys.sentiment.failed.notification"),
        },
      }}
      {...{ state, isOpened, onOpenChange }}
    />
  );
}
