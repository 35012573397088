import { Button } from "components/Button/Button";
import { useBool } from "hooks/useBool";
import { TicketHistoryModal } from "modules/tickets/components/TicketHistoryModal";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export function TicketHistory({ ticketId, withOutline }: { ticketId: string; withOutline?: boolean }): React.ReactNode {
  const { t } = useTranslation();
  const [isTicketHistoryModalOpen, ticketHistoryModalOpenHandlers] = useBool(false);

  return (
    <>
      <div
        className={twJoin(
          "flex flex-col gap-4 rounded-lg bg-white p-4",
          withOutline ? "border border-grey-100" : undefined,
        )}
      >
        <Button className="w-full" styling="secondary" onClick={ticketHistoryModalOpenHandlers.setTrue}>
          {t("page.tickets.details.service-history.button")}
        </Button>
        <div className="flex flex-col gap-0.5">
          <span className="text-overline">{t("page.tickets.details.service-history.description.1")}</span>
          <span className="text-overline">{t("page.tickets.details.service-history.description.2")}</span>
        </div>
      </div>
      <TicketHistoryModal
        ticketId={ticketId}
        isOpened={isTicketHistoryModalOpen}
        onOpenChange={ticketHistoryModalOpenHandlers.set}
      />
    </>
  );
}
