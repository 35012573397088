import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type {
  EngagedResidentsMonthlyPercentageDto,
  MonthlyReportDateRangeProjectEngagementPercentageItemDto,
} from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { useScrollToPageTop } from "hooks/useScrollToPageTop";
import { useSlug } from "hooks/useSlug";
import { BarChart } from "modules/analytics/components/BarChart";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { useEffect, useMemo } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";
import type { NonEmptyArray } from "types/utility-types";

export interface LayoutProps {
  contribution: EngagedResidentsMonthlyPercentageDto | undefined;
  isFetchingContributionData: boolean;
  selectedDateRange: DateRangeOption;
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
  onDateRangeChange: (dateRange: DateRangeOption) => void;
}

export function Layout({
  contribution,
  isFetchingContributionData,
  selectedDateRange,
  dateRangeOptions,
  onDateRangeChange,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { i18n, t } = useTranslation();

  const scrollToTop = useScrollToPageTop();
  useEffect(() => {
    scrollToTop("instant");
  }, [scrollToTop]);

  const contributiontionDataPoints = useMemo(
    () =>
      contribution?.data?.map((point) => ({ x: formatChartDate(point.range), y: point.engagedResidentPercentage })) ||
      [],
    [contribution],
  );

  const benchmark = useMemo(
    () =>
      contribution?.benchmark?.map((point) => ({
        x: formatChartDate(point.range),
        y: point.engagedResidentPercentage,
      })) || [],
    [contribution],
  );

  const noContributionDataAvailable =
    !contribution?.data || contribution.data.every((x) => x.engagedResidentPercentage === 0);

  const columns = useMemo(() => {
    const helper = createColumnHelper<MonthlyReportDateRangeProjectEngagementPercentageItemDto>();

    return [
      helper.accessor("range", {
        header: t("page.analytics-details.contribution.table.date"),
        cell: (cell) => <span>{formatDate(i18n, "monthYearShort", formatChartDate(cell.getValue()))}</span>,
        sortingFn: (a, b) => b.original.range.from.localeCompare(a.original.range.from),
      }),
      helper.accessor("engagedResident", {
        header: t("page.analytics-details.contribution.table.engaged-residents"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("postCount", {
        header: t("page.analytics-details.contribution.table.posts"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("likeCount", {
        header: t("page.analytics-details.contribution.table.reactions"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("commentLikeCount", {
        header: t("page.analytics-details.contribution.table.comment-likes"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("commentCount", {
        header: t("page.analytics-details.contribution.table.comments"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("chatCount", {
        header: t("page.analytics-details.contribution.table.chats"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("eventCount", {
        header: t("page.analytics-details.contribution.table.events"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("eventsAtendeesCount", {
        header: t("page.analytics-details.contribution.table.event-attendees"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("surveyCount", {
        header: t("page.analytics-details.contribution.table.surveys"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("surveysAnsweredCount", {
        header: t("page.analytics-details.contribution.table.survey-responses"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("pollCount", {
        header: t("page.analytics-details.contribution.table.polls"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("pollsAnsweredCount", {
        header: t("page.analytics-details.contribution.table.polls-answered"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("residentGroupsCreatedCount", {
        header: t("page.analytics-details.contribution.table.resident-groups-created"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("privateTicketCount", {
        header: t("page.analytics-details.contribution.table.tickets"),
        cell: (cell) => (
          <span>
            {cell.getValue() + cell.row.original.collectiveTicketCount + (cell.row.original.ratedTicketCount ?? 0)}
          </span>
        ),
      }),
      helper.accessor("reservationCount", {
        header: t("page.analytics-details.contribution.table.reservations"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
    ];
  }, [t, i18n]);

  const tableInstance = useReactTable<MonthlyReportDateRangeProjectEngagementPercentageItemDto>({
    columns,
    data: contribution?.data || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "range.from",
          desc: false,
        },
      ],
    },
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.analytics-details.contribution.title")}
      actions={
        <Button
          type="link"
          styling="secondary"
          icon={<Icon name={iconChevronLeft} />}
          href={routes.analytics.overview({ slug })}
        >
          {t("page.analytics-details.back")}
        </Button>
      }
      header={
        <div className="flex items-center gap-2 py-4">
          <span className="text-body-bold">{t("page.analytics-details.time-range.label")}</span>
          <Select
            items={dateRangeOptions}
            selected={selectedDateRange}
            onChange={onDateRangeChange}
            renderOption={(x) => x.name}
            keySelector={(x) => x.value}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <Card className="order-1 h-fit lg:col-span-2">
          <CardHeader title={t("page.analytics-details.contribution.chart.contribution.title")} />
          {isFetchingContributionData && <LoadingIcon className="mx-auto w-8" />}
          {!isFetchingContributionData && noContributionDataAvailable && <NoData error={noContributionDataAvailable} />}
          {!isFetchingContributionData && !noContributionDataAvailable && (
            <div className="flex flex-col gap-4">
              <span className="text-caption text-grey-700">
                {t("page.analytics-details.contribution.chart.contribution.description")}
              </span>
              <div className="w-full">
                <BarChart
                  type="date"
                  height={250}
                  primaryData={contributiontionDataPoints}
                  benchmark={benchmark}
                  formatYTick={(y) => y}
                  formatXTick={(x) => x}
                  maxY={100}
                  yLabel={t("page.analytics-details.contribution.chart.contribution.y-axis")}
                  showLegend={false}
                  renderLabel={(data) => (
                    <div className="whitespace-pre-line">
                      {t("page.analytics-overview.contribution.chart.contribution-benchmark.tooltip", {
                        percentage: data.primary?.y || 0,
                        benchmark: data.benchmark?.y || 0,
                      })}
                    </div>
                  )}
                />
                <Notice type="info" message={t("page.analytics-details.contribution.chart.contribution.notice")} />
              </div>
            </div>
          )}
        </Card>
        <Card className="order-3 col-span-3 overflow-x-scroll">
          <CardHeader title={t("page.analytics-details.contribution.table.title")} />
          <Table
            table={tableInstance}
            data-testid="contribution-details-table"
            isLoading={isFetchingContributionData}
          />
        </Card>
        <div className="flex flex-col gap-4">
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.contribution.information.title")} />
            <span className="text-caption-bold">{t("page.analytics-details.contribution.information.subtitle")}</span>
            <ul className="list-disc p-4">
              {t("page.analytics-details.contribution.information.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`information-${idx}`}>{item}</li>)}
            </ul>
          </Card>
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.contribution.best-practices.title")} />
            <span className="text-caption-bold">
              {t("page.analytics-details.contribution.best-practices.subtitle")}
            </span>
            <ul className="list-disc p-4">
              {t("page.analytics-details.contribution.best-practices.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`best-practices-${idx}`}>{item}</li>)}
            </ul>
          </Card>
        </div>
      </div>
    </DocumentPaper>
  );
}
