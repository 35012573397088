import type { UserDto } from "api/types";
import { LoadingIcon } from "components/Icons/Icons";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import type { LinkProps } from "react-router";
import { Link } from "react-router";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

type Props = {
  user: UserDto;
  isSmall?: boolean;
} & (
  | {
      loginUrl: string;
      isExternal: boolean;
      isLoading: boolean;
    }
  | {
      loginUrl: LinkProps["to"];
    }
);

export function QuickReplyMenuBar({ user, loginUrl, ...props }: Props): React.ReactNode {
  const { t } = useTranslation();

  const isLoading = "isLoading" in props && props.isLoading;
  const isExternal = "isExternal" in props && props.isExternal;

  return (
    <div className="w-full border-b border-grey-300">
      <div
        className={twJoin("mx-auto flex flex-col items-center sm:flex-row", props.isSmall ? "max-w-3xl" : "max-w-6xl")}
      >
        <div className="flex w-full items-center justify-center border-r border-grey-300 px-4 sm:w-auto sm:justify-start">
          <span className="ml-2 flex h-12 items-center whitespace-nowrap text-body-bold">
            {t("page.messages-email.title")}
          </span>
        </div>
        <div className="flex h-12 w-full">
          {"loading" in props && isLoading ? (
            <LoadingIcon className="ml-auto mr-6 w-6" />
          ) : (
            <span className="ml-auto">
              <LoginLink isExternal={isExternal} url={loginUrl}>
                <span className="ml-auto flex h-full items-center px-6 text-aop-basic-blue-500 ring-inset ring-aop-basic-blue-500 focus:outline-none focus:ring-2 hover:text-blue-800">
                  <span className="whitespace-nowrap text-body-bold">{t("page.quick-reply.log-in")}</span>
                  <div className="ml-4 size-8 shrink-0">
                    <UserAvatar img={user.avatar} isUserDeleted={!!user.deletedAt} />
                  </div>
                </span>
              </LoginLink>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function LoginLink({
  isExternal,
  url,
  children,
}: {
  isExternal: boolean;
  url: Props["loginUrl"];
  children: React.ReactElement;
}) {
  return isExternal ? <a href={String(url)}>{children}</a> : <Link to={url}>{children}</Link>;
}
