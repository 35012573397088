import type { UserDto } from "api/types";
import { AppHeader } from "components/Phone/AppCommon";
import { PhoneInner } from "components/Phone/PhoneInner";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useTranslation } from "translations";

import type { GroupIconType } from "./GroupIcons";
import { GroupIcon } from "./GroupIcons";

export interface AppGroupDetailsViewProps {
  name: string;
  description: string;
  memberCount: number;
  image?: string;
  icon: GroupIconType;
  user: UserDto;
  projectTintColor?: string;
}

export function AppGroupDetailsView({
  name,
  description,
  icon,
  memberCount,
  image,
  user,
  projectTintColor,
}: AppGroupDetailsViewProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <PhoneInner withCog>
      <AppHeader
        bgImage={image}
        profileImage={
          <div className="flex size-full items-center justify-center">
            <GroupIcon size={32} icon={icon} />
          </div>
        }
      />
      <div className="float-right flex justify-end pr-2.5 pt-7">
        <div className="relative z-10 mt-2 flex min-w-[4.75rem] items-center justify-center overflow-hidden rounded px-3.5 py-1 text-[12px]">
          <div
            className="absolute left-0 top-0 size-full opacity-10"
            style={{
              backgroundColor: projectTintColor || "#23175A",
            }}
          />
          <span
            style={{
              color: projectTintColor || "#23175A",
            }}
          >
            {t("model.interest-group.action.follow")}
          </span>
        </div>
      </div>
      <div className="relative bg-white px-2.5 py-3.5">
        <span className="mb-1 mr-24 block break-all text-body-bold">{name || "-"}</span>
        <div className="flex items-center gap-1 font-old-semibold text-app-grey-1 underline">
          <UserAvatar className="size-5" img={user.avatar} />
          <span>{t("model.interest-group.member-count", { count: memberCount })}</span>
        </div>
        <p className="mt-4 whitespace-pre-line break-all">{description}</p>
      </div>
    </PhoneInner>
  );
}
