import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { Tab } from "components/ContentTabs/ContentTabs";
import { ErrorPage } from "components/Error/ErrorPage";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { useQueryParam } from "hooks/useQueryParam";
import type { CalendarTab, EventsFilters, ReservationsFilters, UpdatableEventFilter } from "modules/calendar/constants";
import { EVENTS_TAB, RESERVATIONS_TAB } from "modules/calendar/constants";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const CARD_PAGE = 8;

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();

  const { i18n, t } = useTranslation();
  const hasPermission = usePermission();

  const [reservationsSearch, setReservasionsSearch] = useState("");

  const [eventsFilters, setEventsFilters] = useState<Omit<EventsFilters, "TimeFrame">>({
    Search: undefined,
    CreatedByMe: undefined,
    IsSignedUp: undefined,
    Connection: "included",
  });

  const onEventFilterChange = useCallback(
    (filter: UpdatableEventFilter, value: string | boolean | undefined) => {
      setEventsFilters((prev) => ({ ...prev, [filter]: value }));
    },
    [setEventsFilters],
  );

  const upcomingEventsFilters: EventsFilters = useMemo(
    () => ({
      ...eventsFilters,
      TimeFrame: "future",
    }),
    [eventsFilters],
  );

  const pastEventsFilters: EventsFilters = useMemo(
    () => ({
      ...eventsFilters,
      TimeFrame: "past",
    }),
    [eventsFilters],
  );

  const {
    data: eventCategories = [],
    isPending: isLoadingCategories,
    error: categoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_CATEGORIES(projectId),
    queryFn: () => api.getEventsCategoriesV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: upcomingEventsData,
    error: upcomingEventsError,
    isLoading: isLoadingUpcomingEvents,
    fetchNextPage: fetchMoreUpcomingEvents,
    isFetchingNextPage: isFetchingMoreUpcomingEvents,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.EVENT_LIST(projectId, upcomingEventsFilters),
    queryFn: async ({ pageParam = 0 }) => {
      return await api
        .getEventsV1({
          ...upcomingEventsFilters,
          Offset: pageParam * CARD_PAGE,
          Limit: pageParam === 0 ? CARD_PAGE : undefined,
        })
        .then(({ data }) => data);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const {
    data: pastEventsData,
    error: pastEventsError,
    isLoading: isLoadingPastEvents,
    hasNextPage: hasMorePastEvents,
    fetchNextPage: fetchMorePastEvents,
    isFetchingNextPage: isFetchingMorePastEvents,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.EVENT_LIST(projectId, pastEventsFilters),
    queryFn: async ({ pageParam = 0 }) => {
      return await api
        .getEventsV1({ ...pastEventsFilters, Offset: pageParam * CARD_PAGE, Limit: CARD_PAGE })
        .then(({ data }) => data);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const upcomingReservationsFilters: ReservationsFilters = {
    TimeFrame: "futureOrToday",
  };

  const pastReservationsFilters: ReservationsFilters = {
    TimeFrame: "past",
  };

  const {
    data: upcomingReservationsData,
    hasNextPage: hasMoreUpcomingReservations,
    fetchNextPage: fetchMoreUpcomingReservations,
    isFetchingNextPage: isLoadingMoreUpcomingReservations,
    isLoading: isLoadingUpcomingReservations,
    error: upcomingReservationsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId, upcomingReservationsFilters),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsMyBookingsV1({
          TimeFrame: "futureOrToday",
          Offset: pageParam * CARD_PAGE,
          Limit: pageParam === 0 ? CARD_PAGE : undefined,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const {
    data: pastReservationsData,
    hasNextPage: hasMorePastReservations,
    fetchNextPage: fetchMorePastReservations,
    isFetchingNextPage: isLoadingMorePastReservations,
    isLoading: isLoadingPastReservations,
    error: pastReservationsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId, pastReservationsFilters),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsMyBookingsV1({ TimeFrame: "past", Offset: pageParam * CARD_PAGE, Limit: CARD_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assets = [],
    isFetching: isFetchingAssets,
    error: assetsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS(projectId, assetsQuery),
    queryFn: () => api.getBookableAssetsV1(assetsQuery),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const totalUpcomingEvents = useMemo(() => upcomingEventsData?.pages[0].total ?? 0, [upcomingEventsData]);
  const futureEvents = useMemo(() => upcomingEventsData?.pages.flatMap((x) => x.items) ?? [], [upcomingEventsData]);
  const pastEvents = useMemo(() => pastEventsData?.pages.flatMap((x) => x.items) ?? [], [pastEventsData]);

  const upcomingReservations = useMemo(
    () => upcomingReservationsData?.pages.flatMap((x) => x.items) ?? [],
    [upcomingReservationsData],
  );

  const pastReservations = useMemo(
    () => pastReservationsData?.pages.flatMap((x) => x.items) ?? [],
    [pastReservationsData],
  );

  const mappedUpcomingReservations = useMemo(
    () =>
      upcomingReservations.map((reservation) => ({
        ...reservation,
        searchable:
          `${reservation.asset.name}|||${formatDate(i18n, "month", parseISO(reservation.date))}|||${reservation.date}|||${reservation.startTime}|||${reservation.endTime}|||${reservation.asset.locationSpecification ?? ""}`.toLowerCase(),
      })),
    [upcomingReservations, i18n],
  );

  const mappedPastReservations = useMemo(
    () =>
      pastReservations.map((reservation) => ({
        ...reservation,
        searchable:
          `${reservation.asset.name}|||${formatDate(i18n, "month", parseISO(reservation.date))}|||${reservation.date}|||${reservation.startTime}|||${reservation.endTime}|||${reservation.asset.locationSpecification ?? ""}`.toLowerCase(),
      })),
    [pastReservations, i18n],
  );

  const filteredUpcomingReservations = useMemo(
    () => mappedUpcomingReservations.filter((reservation) => reservation.searchable.includes(reservationsSearch)),
    [reservationsSearch, mappedUpcomingReservations],
  );

  const filteredPastReservations = useMemo(
    () => mappedPastReservations.filter((reservation) => reservation.searchable.includes(reservationsSearch)),
    [reservationsSearch, mappedPastReservations],
  );

  const tabs: Tab<CalendarTab>[] = [
    {
      id: EVENTS_TAB,
      name: t("page.calendar.tabs.events"),
      count: totalUpcomingEvents,
    },
  ];

  if (
    assets.length > 0 ||
    (assets.length === 0 && (upcomingReservations.length !== 0 || pastReservations.length !== 0))
  ) {
    tabs.push({
      id: RESERVATIONS_TAB,
      name: t("page.calendar.tabs.reservations"),
      count: upcomingReservations.filter((reservation) => !reservation.cancelledAt).length,
    });
  }

  const [queryParamTab, setTab] = useQueryParam("tab");
  const activeTab =
    queryParamTab && tabs.map((tab) => tab.id).includes(queryParamTab as any)
      ? (queryParamTab as CalendarTab)
      : EVENTS_TAB;

  const error =
    categoriesError ||
    upcomingEventsError ||
    pastEventsError ||
    assetsError ||
    upcomingReservationsError ||
    pastReservationsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingCategories || isFetchingAssets;
  if (loading) {
    return <FullSizeLoader />;
  }

  return children({
    tabs,
    activeTab,
    onTabChange: setTab,
    eventsFilters,
    onEventFilterChange,
    eventCategories,
    events: {
      upcoming: {
        items: futureEvents,
        total: totalUpcomingEvents,
        isLoading: isLoadingUpcomingEvents,
        loadMore: fetchMoreUpcomingEvents,
        isLoadingMore: isFetchingMoreUpcomingEvents,
      },
      past: {
        items: pastEvents,
        hasMore: hasMorePastEvents,
        isLoading: isLoadingPastEvents,
        loadMore: fetchMorePastEvents,
        isLoadingMore: isFetchingMorePastEvents,
      },
    },
    reservationsSearch,
    onReservationsSearch: setReservasionsSearch,
    reservations: {
      upcoming: {
        items: filteredUpcomingReservations,
        hasMore: hasMoreUpcomingReservations,
        isLoading: isLoadingUpcomingReservations,
        loadMore: fetchMoreUpcomingReservations,
        isLoadingMore: isLoadingMoreUpcomingReservations,
      },
      past: {
        items: filteredPastReservations,
        hasMore: hasMorePastReservations,
        isLoading: isLoadingPastReservations,
        loadMore: fetchMorePastReservations,
        isLoadingMore: isLoadingMorePastReservations,
      },
    },
  });
}
