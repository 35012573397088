import { ToggleGroup as ArkToggleGroup } from "@ark-ui/react";
import type React from "react";

type TagSelectItem<TValue extends string = string> = {
  label: string;
  value: TValue;
};

export type TagSelectProps<TValue extends string = string> = {
  items: TagSelectItem<TValue>[];
  values: TValue[];
  isMultiple?: boolean;
  onChange: (value: TValue[]) => void;
};

export function TagSelect({ values, items, isMultiple, onChange }: TagSelectProps): React.ReactNode {
  return (
    <ArkToggleGroup.Root
      className="flex flex-wrap items-center gap-2"
      value={values}
      onValueChange={(details) => onChange(details.value)}
      multiple={isMultiple}
    >
      {items.map((item) => (
        <ArkToggleGroup.Item
          className="cursor-pointer rounded-full bg-grey-100 px-4 py-2 text-center text-body text-black transition-colors data-[state=on]:bg-aop-dark-blue-500 data-[state=on]:text-white hover:bg-grey-300/60"
          key={item.value}
          value={item.value}
        >
          {item.label}
        </ArkToggleGroup.Item>
      ))}
    </ArkToggleGroup.Root>
  );
}
