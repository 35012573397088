import { Checkbox } from "antd";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { userMutations } from "queries/users/mutations";
import type React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import { UserSetDeletionDateModal } from "./UserSetDeletionDateModal";

type UserDeletionModalProps = ModalBaseProps & {
  userId: string;
  deletionDate: string | undefined;
};

export function UserDeletionModal({
  userId,
  deletionDate,
  isOpened,
  onOpenChange,
}: UserDeletionModalProps): React.ReactNode {
  const [isSetDeletionDateModalOpened, setDeletionModalOpenHandlers] = useBool(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const sessionUser = useSessionUser();
  const slug = useSlug();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const mutationDeleteUser = userMutations.useDeleteUser({
    onSuccess: () => {
      if (!sessionUser.isSuperAdmin && sessionUser.isAdmin) {
        void navigate(routes.users.list({ slug }));
      }

      onOpenChange(false);
    },
  });

  const onClickSetDate = () => {
    onOpenChange(false);
    setDeletionModalOpenHandlers.set(true);
  };

  return (
    <>
      <Modal.Root
        title={t("page.user-detail.delete-or-date-modal.title")}
        description={
          deletionDate
            ? t("page.user-detail.delete-or-date-modal.description-already-set")
            : t("page.user-detail.delete-or-date-modal.description")
        }
        {...{ isOpened, onOpenChange }}
      >
        <label className="inline-flex gap-2" htmlFor="checkbox-confirm">
          <Checkbox
            data-testid="checkbox-confirm-delete"
            id="checkbox-confirm"
            name="isConfirmed"
            checked={isConfirmed}
            onChange={(e) => setIsConfirmed(e.target.checked)}
          />
          <span>{t("page.user-detail.delete-or-date-modal.confirm-checkbox")}</span>
        </label>
        <Modal.Actions>
          <Button onClick={onClickSetDate} styling="secondary">
            {deletionDate
              ? t("page.user-detail.delete-or-date-modal.action.update-deletion-date")
              : t("page.user-detail.delete-or-date-modal.action.set-deletion-date")}
          </Button>
          <Button
            className="w-full sm:w-auto"
            onClick={() => mutationDeleteUser.mutateAsync({ userId: userId })}
            styling="danger"
            disabled={!isConfirmed}
            data-testid="delete-user-modal-confirm"
            isLoading={mutationDeleteUser.isPending}
          >
            {t("page.user-detail.delete-or-date-modal.action.delete")}
          </Button>
        </Modal.Actions>
      </Modal.Root>
      <UserSetDeletionDateModal
        isOpened={isSetDeletionDateModalOpened}
        onOpenChange={setDeletionModalOpenHandlers.set}
        deletionDate={deletionDate}
      />
    </>
  );
}
