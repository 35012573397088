import React, { useEffect, useState } from "react";

type ContentFieldProvider = {
  currValue: string;
  ref: React.RefObject<HTMLTextAreaElement>;
  isUndoable: boolean;
  isRedoable: boolean;
  setCurrValue: (value: string) => void;
  onReplaceContent: (content: string) => void;
  onUndo: () => void;
  onRedo: () => void;
  onReset: () => void;
};

const ContentFieldContext = React.createContext<ContentFieldProvider | null>(null);

type ContentFieldProviderProps = {
  children: React.ReactNode;
};

// Offers utility sets and handle internal states of a content field (e.g. TicketCommentField)
export const ContentFieldProvider: React.FC<ContentFieldProviderProps> = ({ children }) => {
  const [currValue, setCurrValue] = useState<string>("");
  const [history, setHistory] = useState<string[]>([]);
  const [pointer, setPointer] = useState<number>(0);

  const refInput = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (history.length > 0) {
      setCurrValue(history[pointer]);
    }
  }, [history, pointer]);

  const onReset = () => {
    setCurrValue("");
    setHistory([]);
    setPointer(0);
  };

  const onReplaceContent = (content: string) => {
    // If the current value is the same as the last value in the history, we don't want to add it again
    const newHistory = [...history];
    if (currValue !== history[pointer]) {
      newHistory.push(currValue);
    }

    setHistory([...newHistory, content]);
    setPointer((prevPointer) => prevPointer + 1);
    refInput.current?.focus();
  };

  const isUndoable = pointer > 0;
  const isRedoable = pointer < history.length - 1;

  const onUndo = () => {
    if (!isUndoable) return;

    const newPointer = pointer - 1;
    setPointer(newPointer);
  };

  const onRedo = () => {
    if (!isRedoable) return;

    const newPointer = pointer + 1;
    setPointer(newPointer);
  };

  return (
    <ContentFieldContext.Provider
      value={{
        currValue,
        ref: refInput,
        isUndoable,
        isRedoable,
        setCurrValue,
        onReplaceContent,
        onUndo,
        onRedo,
        onReset,
      }}
    >
      {children}
    </ContentFieldContext.Provider>
  );
};

export const useContentFieldManager = (): ContentFieldProvider => {
  const context = React.useContext(ContentFieldContext);

  if (context === null) {
    throw new Error("useContentFieldManager must be used within a ContentFieldProvider");
  }

  return context;
};
