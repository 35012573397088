import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";
import { useTranslation } from "translations";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const PLATFORM_GROUPS_LIMIT = 10;

export function Loader({ children }: LoaderProps): React.ReactNode {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(0);

  const platformGroupsQueryParams = {
    Offset: page * PLATFORM_GROUPS_LIMIT,
    Limit: PLATFORM_GROUPS_LIMIT,
  };

  const {
    data: platformGroups,
    isFetching: isLoadingPlatformGroups,
    error: errorLoadingPlatformGroups,
  } = useQuery({
    queryKey: QUERY_KEYS.PLATFORM_GROUPS_LIST(platformGroupsQueryParams),
    queryFn: () => api.getSystemGroupsV1(platformGroupsQueryParams),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
  });

  const { mutateAsync: deleteGroup } = useMutation({
    mutationFn: (groupId: string) => api.deleteSystemGroupsByIdV1(groupId),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.platform-groups.delete.success"),
      });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PLATFORM_GROUPS_LIST(),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.platform-groups.delete.error"),
      });
    },
  });

  const error = errorLoadingPlatformGroups;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const totalPages = platformGroups ? Math.ceil(platformGroups.total / PLATFORM_GROUPS_LIMIT) : 0;

  return children({
    platformGroups: platformGroups?.items ?? [],
    isLoadingPlatformGroups,
    page,
    onPageChange: setPage,
    totalPages,
    onDelete: deleteGroup,
  });
}
