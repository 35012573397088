import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { SystemGroupDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuAction_LEGACY } from "components/ContextMenu_LEGACY/ContextMenu_LEGACY";
import { ContextMenu_LEGACY } from "components/ContextMenu_LEGACY/ContextMenu_LEGACY";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Icon } from "components/Icon/Icon";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { ProjectList } from "modules/system-settings/components/ProjectList";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

export interface LayoutProps {
  platformGroups: SystemGroupDto[];
  isLoadingPlatformGroups: boolean;
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
  onDelete: (groupId: string) => Promise<unknown>;
}

export function Layout({
  platformGroups,
  isLoadingPlatformGroups,
  page,
  onPageChange,
  totalPages,
  onDelete,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const { componentProps: deleteModalProps, openDeleteModal } =
    useDeleteModal<SystemGroupDto>("platform-group-delete-modal");

  const { data: projects = [] } = useConnectedProjects();

  const columns = useMemo(() => {
    const helper = createColumnHelper<SystemGroupDto>();

    return [
      helper.accessor("translations", {
        header: t("page.platform-groups.list.table.name"),
        cell: (cell) => (
          <Anchor to={routes.platformGroups.edit({ id: cell.row.original.id })}>
            <span className="line-clamp-2">
              {cell.getValue()?.find((l) => l.languageId === sessionUser.language.id)?.name}
            </span>
          </Anchor>
        ),
      }),
      helper.accessor("type", {
        header: t("page.platform-groups.list.table.type"),
        cell: (cell) => {
          switch (cell.getValue()) {
            case "interest":
              return <span className="whitespace-nowrap">{t("page.platform-groups.list.type.interest-group")}</span>;
            case "helpCategory":
              return <span className="whitespace-nowrap">{t("page.platform-groups.list.type.help-category")}</span>;
          }
        },
      }),
      helper.accessor("excludedProjects", {
        header: t("page.platform-groups.list.table.enabled-projects"),
        cell: (cell) => (
          <ProjectList
            projects={projects.filter(
              (p) =>
                !cell
                  .getValue()
                  ?.map((p) => p.id)
                  .includes(p.id),
            )}
            limit={3}
          />
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();

          const actions: ContextMenuAction_LEGACY[] = [
            {
              text: t("page.platform-groups.list.table.edit"),
              icon: <Icon name={iconEdit05} />,
              callback: () => void navigate(routes.platformGroups.edit({ id })),
            },
            {
              text: t("page.platform-groups.list.table.delete"),
              icon: <Icon name={iconTrash02} />,
              callback: () => openDeleteModal(cell.row.original),
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenu_LEGACY actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [t, sessionUser.language.id, projects, navigate, openDeleteModal]);

  const tableInstance = useReactTable<SystemGroupDto>({
    columns,
    data: platformGroups,
    getCoreRowModel: getCoreRowModel(),
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "platform-groups", status: "list" });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.platform-groups.list.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <Button type="link" href={routes.platformGroups.create()}>
          {t("page.platform-groups.list.new-group")}
        </Button>
      }
    >
      {
        // eslint-disable-next-line no-nested-ternary
        platformGroups.length ? (
          <Table table={tableInstance} isLoading={isLoadingPlatformGroups}>
            <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
          </Table>
        ) : isLoadingPlatformGroups ? null : (
          <div className="rounded-lg bg-white p-5">
            <span className="text-caption">{t("page.platform-groups.list.table.no-data")}</span>
          </div>
        )
      }
      <DeleteModal
        title={t("page.platform-groups.modal.delete.title", {
          group:
            deleteModalProps.modalContext?.translations?.find((x) => x.languageId == sessionUser.language.id)?.name ||
            deleteModalProps.modalContext?.translations?.[0]?.name ||
            "-",
        })}
        description={t("page.platform-groups.modal.delete.description")}
        onDelete={(group) => onDelete(group.id)}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
