import { useQuery } from "@tanstack/react-query";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconGrid01 from "assets/icons/grid-01.svg";
import iconList from "assets/icons/list.svg";
import iconPlus from "assets/icons/plus.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ContextMenu, type ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { Paper } from "components/Paper/Paper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { addMonths, format, max, parseISO, startOfMonth } from "date-fns";
import { isTouchDevice } from "helpers/device";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useClickOutside } from "hooks/useClickOutside";
import { useDebounce } from "hooks/useDebounce";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useKey } from "hooks/useKey";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { minBy } from "lodash-es";
import { useConfig } from "providers/ConfigProvider";
import { useProjectContext } from "providers/ProjectContext";
import { usePortfolioQueries } from "queries/portfolio/queryOptions";
import { useCallback, useDeferredValue, useMemo, useRef, useState } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

import { AddProjectModal } from "./components/AddProjectModal";
import { GraphView } from "./components/GraphView";
import { ListView } from "./components/ListView";
import type { PortfolioOverviewFilterValues } from "./components/PortfolioOverviewFilter.";
import { PortfolioOverviewFilter } from "./components/PortfolioOverviewFilter.";
import { TilesView } from "./components/TilesView";
import { getPortfolioFilterOptions } from "./helpers";

const LOCAL_STORAGE_PORTFOLIO_VIEW_KEY = "portfolio-view";
// No analytics exist before this date
const MIN_ANALYTICS_DATE = new Date(2020, 3);

export function Overview(): React.ReactNode {
  const [isFilterOpened, filtersOpenHandlers] = useBool();
  const [addProjectModalOpened, addProjectModalOpenedHandlers] = useBool();
  const [projectSwitcherOpen, projectSwitcherOpenHandlers] = useBool();
  const [systemSettingsMenuOpen, systemSettingsMenuOpenHandlers] = useBool();
  const [alertsMenuOpen, alertsMenuOpenHandlers] = useBool();
  const [ticketsMenuOpen, ticketsMenuOpenHandlers] = useBool();

  const maxDate = startOfMonth(addMonths(new Date(), -1));
  const [selectedView, setSelectedView] = useState<"tiles" | "list">(
    getLocalStorageValue(LOCAL_STORAGE_PORTFOLIO_VIEW_KEY, "tiles"),
  );
  const [selectedDate, setSelectedDate] = useState<Date>(maxDate);
  const [activeFilter, setActiveFilter] = useState<
    PortfolioOverviewFilterValues & {
      name: string;
    }
  >({
    name: "",
    owner: "",
    maintenance: "",
    city: "",
  });

  const { t } = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const projectSwitcherRef = useRef<HTMLDivElement>(null);
  const sessionUser = useSessionUser();
  const { changeProject } = useProjectContext();

  const portfolioQueries = usePortfolioQueries();
  const {
    data: portfolio,
    error: portfolioError,
    isPending: isPendingPortfolio,
  } = useQuery(portfolioQueries.list({ date: format(selectedDate, "yyyy-MM-dd") }));
  const { data: canMassMessage, isPending: isLoadingMassMessagePermission } = useQuery(
    portfolioQueries.massMessagePermission(),
  );

  useUpdateLocalStorage(LOCAL_STORAGE_PORTFOLIO_VIEW_KEY, selectedView);
  useDocumentTitle(t("page.portfolio.title"));
  useClickOutside(projectSwitcherRef, projectSwitcherOpenHandlers.setFalse, projectSwitcherOpen);
  useKey("Escape", projectSwitcherOpenHandlers.setFalse, projectSwitcherOpen);
  useClickOutside(rootRef, alertsMenuOpenHandlers.setFalse, alertsMenuOpen);
  useKey("Escape", alertsMenuOpenHandlers.setFalse, alertsMenuOpen);
  useClickOutside(rootRef, systemSettingsMenuOpenHandlers.setFalse, systemSettingsMenuOpen);
  useKey("Escape", systemSettingsMenuOpenHandlers.setFalse, systemSettingsMenuOpen);
  useClickOutside(rootRef, ticketsMenuOpenHandlers.setFalse, ticketsMenuOpen);
  useKey("Escape", ticketsMenuOpenHandlers.setFalse, ticketsMenuOpen);
  useKey("Escape", filtersOpenHandlers.setFalse, isFilterOpened);

  const showEngagement = useConfig("enablePortfolioEngagementStat");
  const ctaButtonActions: ContextMenuItem[] = [
    {
      labels: {
        default: t("page.portfolio.add-new-project"),
      },
      icon: iconPlus,
      value: "add-new-project",
      callbackFn: addProjectModalOpenedHandlers.setTrue,
    },
    {
      labels: {
        default: t("page.portfolio.create-message-multiple-projects"),
      },
      icon: iconPlus,
      value: "create-message-multiple-projects",
      type: "link",
      href: routes.portfolio.createMassMessage(),
      isHidden: !canMassMessage?.canMassMessageGeneralPost && !canMassMessage?.canMassMessageAnnouncementPost,
    },
  ];
  const deferredQuery = useDeferredValue(useDebounce(activeFilter.name.toLowerCase().trim(), 100));
  const filteredProjects = useMemo(
    () =>
      portfolio?.items.filter((project) => {
        const isNameOrCityMatching =
          !deferredQuery || project.name.toLowerCase().includes(deferredQuery) || project.city.includes(deferredQuery);
        const isOwnerMatching = !activeFilter.owner || (activeFilter.owner && project.owner === activeFilter.owner);
        const isMaintenanceMatching =
          !activeFilter.maintenance || (activeFilter.maintenance && project.maintenance === activeFilter.maintenance);
        const isCityMatching = !activeFilter.city || (activeFilter.city && project.city === activeFilter.city);

        return isNameOrCityMatching && isOwnerMatching && isMaintenanceMatching && isCityMatching;
      }) ?? [],
    [portfolio?.items, deferredQuery, activeFilter.owner, activeFilter.maintenance, activeFilter.city],
  );

  const onChangeProject = useCallback(
    (projectId: string) => {
      changeProject(projectId, true);
    },
    [changeProject],
  );

  const handleClearFilter = () => {
    setActiveFilter({
      name: "",
      owner: "",
      maintenance: "",
      city: "",
    });
  };

  if (portfolioError) {
    return <ErrorPage error={portfolioError} />;
  }

  if (isPendingPortfolio || isLoadingMassMessagePermission) {
    return <FullSizeLoader withPadding />;
  }

  const minProjectCreationDate = minBy(
    portfolio.items.map((x) => parseISO(x.createdAt)),
    (x) => x.valueOf(),
  )!;
  // Only count the active filter fileds within the fold
  const amountActiveFilters = Object.values(activeFilter).filter((filterValue) => Boolean(filterValue)).length;
  const {
    owners: availableProjectOwners,
    maintainers: availableProjectMaintainers,
    cities: availableProjectCities,
  } = getPortfolioFilterOptions(portfolio.items);
  const isFilterAvailable =
    availableProjectOwners.length > 1 || availableProjectMaintainers.length > 1 || availableProjectCities.length > 1;

  return (
    <>
      <div className="mx-auto max-w-7xl">
        <Paper
          title={t("page.portfolio.header.title", { name: sessionUser.fullName })}
          subTitle={t("page.portfolio.header.description")}
          theme="minimal"
          actions={
            <ContextMenu
              triggerComponent={
                <IconButton
                  styling="primary"
                  title={t("component.context-menu.action.open")}
                  icon={iconPlus}
                  isCircular
                />
              }
              items={ctaButtonActions}
            />
          }
          header={
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full flex-col items-start justify-between gap-4 md:flex-row md:flex-wrap md:items-center">
                <div className="flex flex-col items-start gap-3 sm:flex-row sm:items-center">
                  <h2 className="text-nowrap text-body-bold">
                    {t("page.portfolio.overview.title", { count: portfolio.total })}
                  </h2>
                  {portfolio.items.length > 8 && (
                    <SearchInput
                      className="md:w-64"
                      placeholder={t("component.project-switcher.search.placeholder")}
                      value={activeFilter.name}
                      onChange={(e) => setActiveFilter((currFilter) => ({ ...currFilter, name: e.target.value }))}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && filteredProjects.length === 1) {
                          changeProject(filteredProjects[0].id, true);
                        }
                      }}
                      autoFocus={!isTouchDevice()}
                    />
                  )}
                  {isFilterAvailable && (
                    <Button
                      data-testid="portfolio-filter-btn"
                      icon={<Icon name={iconFilterFunnel01} />}
                      styling="secondary"
                      onClick={filtersOpenHandlers.toggle}
                      isPressed={isFilterOpened}
                    >
                      <div className="flex items-center gap-2">
                        {t("page.tickets.header.button.filter")}
                        {amountActiveFilters > 0 && (
                          <span className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue-500 text-caption leading-none text-white">
                            {amountActiveFilters}
                          </span>
                        )}
                      </div>
                    </Button>
                  )}
                  {isFilterOpened && amountActiveFilters > 0 && (
                    <Button
                      data-testid="portfolio-filter-btn"
                      icon={<Icon name={iconX} />}
                      styling="secondary"
                      onClick={handleClearFilter}
                      isPressed={isFilterOpened}
                    >
                      {t("common.action.clear")}
                    </Button>
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                  <div className="flex items-center gap-2">
                    <span className="text-caption text-grey-600">{t("page.portfolio.overview.filters.month")}</span>
                    <MonthPicker
                      value={selectedDate}
                      onChange={setSelectedDate}
                      minDate={max([MIN_ANALYTICS_DATE, minProjectCreationDate])}
                      maxDate={maxDate}
                      placement="left"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <label htmlFor="view" className="text-caption text-grey-600">
                      {t("page.portfolio.overview.filters.view")}
                    </label>
                    <div className="w-28">
                      <Select
                        id="view"
                        items={["tiles", "list"] as const}
                        keySelector={(option) => option}
                        renderOption={(option) => (
                          <span className="flex items-center gap-2">
                            {option === "tiles" ? (
                              <Icon name={iconGrid01} size={16} />
                            ) : (
                              <Icon name={iconList} size={16} />
                            )}
                            <span>
                              {option === "tiles"
                                ? t("page.portfolio.overview.filters.view.tiles")
                                : t("page.portfolio.overview.filters.view.list")}
                            </span>
                          </span>
                        )}
                        selected={selectedView}
                        onChange={setSelectedView}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <PortfolioOverviewFilter
                filter={activeFilter}
                isOpened={isFilterOpened}
                onChangeFilter={(newFilter) =>
                  setActiveFilter({
                    name: activeFilter.name,
                    ...newFilter,
                  })
                }
                projects={portfolio.items}
                onClose={filtersOpenHandlers.setFalse}
              />
            </div>
          }
        >
          {selectedView === "list" && (
            <ListView
              items={filteredProjects}
              showAddProject={addProjectModalOpenedHandlers.setTrue}
              showEngagement={showEngagement}
              onChangeProject={onChangeProject}
            />
          )}
          {selectedView === "tiles" && (
            <TilesView
              items={filteredProjects}
              onShowAddProject={addProjectModalOpenedHandlers.setTrue}
              showEngagement={showEngagement}
              onChangeProject={onChangeProject}
            />
          )}
          <GraphView date={selectedDate} showEngagement={showEngagement} items={portfolio.items} />
        </Paper>
      </div>
      <AddProjectModal isOpened={addProjectModalOpened} onOpenChange={addProjectModalOpenedHandlers.set} />
    </>
  );
}
