import type { MediaPickerButtonProps } from "components/Button/MediaPickerButton";
import { ImagePickerButton } from "components/Button/MediaPickerButton";
import type { FormImage } from "components/ImageInput/useImageInput";
import type React from "react";
import {
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions,
  useController,
} from "react-hook-form";

interface FormImagePickerProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<MediaPickerButtonProps<FormImage[]>, "onChange"> {
  name: TName;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  rules?: RegisterOptions<TFormValues, TName>;
  "data-testid"?: string;
}

export function FormImagePicker<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  defaultValue,
  rules,
  "data-testid": dataTestid,
  ...props
}: FormImagePickerProps<TFormValues, TName>): React.ReactNode {
  const { field, fieldState } = useController<TFormValues, TName>({ name, rules, defaultValue });

  return (
    <ImagePickerButton
      {...props}
      name={name}
      isError={fieldState.invalid}
      onChange={field.onChange}
      value={field.value}
    />
  );
}
