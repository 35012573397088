import { useQuery } from "@tanstack/react-query";
import type { AdminTicketDetailsDto } from "api/types";
import iconThumbsDown from "assets/icons/thumbs-down.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Label } from "components/Label/Label";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { ticketMutations, useTicketQueries } from "queries/tickets";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "translations";

type TicketAIQuickDenialModalProps = ModalBaseProps & {
  description: string;
  ticket: AdminTicketDetailsDto;
  onDisagree: () => void;
};

type TicketAIQuickDenialModalFormValues = {
  reply: string;
};

export function TicketAIQuickDenialModal({
  isOpened,
  onOpenChange,
  ticket,
  description,
  onDisagree,
}: TicketAIQuickDenialModalProps): React.ReactNode {
  const [isRejecting, setIsRejecting] = useState(false);
  const { t } = useTranslation();
  const formMethods = useForm<TicketAIQuickDenialModalFormValues>({
    defaultValues: {
      reply: "",
    },
  });
  const showFlashToast = useFlashToast();

  const ticketQueries = useTicketQueries();
  const { data: denialReplySuggestion, isPending: isPendingRejectReplySuggestion } = useQuery({
    ...ticketQueries.denialReplySuggestion(ticket.id, {
      additionalInfo: description,
    }),
    enabled: isOpened,
  });
  const changeStatusMutation = ticketMutations.useUpdateStatus();
  const ticketResponsibilityMutation = ticketMutations.useUpdateResponsibilitySuggestion(ticket.id);
  const postTicketCommentMutation = ticketMutations.useAddComment();

  useEffect(() => {
    if (isOpened && denialReplySuggestion && !formMethods.getValues("reply")) {
      formMethods.reset({ reply: denialReplySuggestion.response });
    }
  }, [isOpened, formMethods, denialReplySuggestion]);

  const onSubmit = async (data: TicketAIQuickDenialModalFormValues) => {
    setIsRejecting(true);

    // Change ticket to the first available `closed` status
    const closedStatus = ticket.possibleStatuses.find((status) => status.type === "closed")!;
    const promiseChangeTicketStatus = changeStatusMutation.mutateAsync({
      ticketId: ticket.id,
      data: {
        statusId: closedStatus.id,
      },
    });
    const promisePostTicketComment = postTicketCommentMutation.mutateAsync({
      ticketId: ticket.id,
      data: {
        content: data.reply,
        accessType: "public",
      },
    });
    const promisePostTicketResponsibility = ticketResponsibilityMutation.mutateAsync({ rating: "good" });

    await Promise.all([promiseChangeTicketStatus, promisePostTicketComment, promisePostTicketResponsibility]).then(
      () => {
        showFlashToast({
          title: t("page.tickets.details.responsibility-suggestion-tenant-modal.deny-ticket.notification.success"),
          type: "success",
        });
        onOpenChange(false);
        setIsRejecting(false);
      },
    );
  };

  const onClickDisagree = async () => {
    await ticketResponsibilityMutation.mutateAsync({ rating: "bad" });

    onDisagree();
  };

  return (
    <Modal.Root
      data-testid="ai-responsibility-suggestion-modal"
      title={t("page.tickets.details.responsibility-suggestion-tenant-modal.title")}
      description={
        <Trans
          i18nKey="page.tickets.details.responsibility-suggestion-tenant-modal.description"
          values={{
            governmentData: t(
              "page.tickets.details.responsibility-suggestion-tenant-modal.description.government-data",
            ),
          }}
          components={{
            governmentData: (
              <Anchor
                to="https://www.rijksoverheid.nl/onderwerpen/woning-huren/vraag-en-antwoord/welke-kosten-zijn-voor-de-huurder-en-welke-voor-de-verhuurder"
                inNewTab
                isExternal
              />
            ),
          }}
        />
      }
      labelComponent={<Label theme="purple">{t("common.label.ai-driven")}</Label>}
      isNested
      {...{ isOpened, onOpenChange }}
    >
      <Form className="mb-0 flex w-full flex-col" {...{ formMethods, onSubmit }}>
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col items-start gap-1">
            <h3 className="text-headline3">
              {t("page.tickets.details.responsibility-suggestion-tenant-modal.reason.subtitle")}
            </h3>
            <p className="text-body">{description}</p>
          </div>

          <div className="flex w-full flex-col items-start gap-1">
            <h3 className="text-headline3">
              {t("page.tickets.details.responsibility-suggestion-tenant-modal.deny-ticket.subtitle")}
            </h3>
            <p className="text-body">
              {t("page.tickets.details.responsibility-suggestion-tenant-modal.deny-ticket.description")}
            </p>
            <div className="relative mt-2 w-full">
              {isPendingRejectReplySuggestion && (
                <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-white/60">
                  <LoadingIcon className="size-10" />
                </div>
              )}

              <FormField
                label={t("page.tickets.details.responsibility-suggestion-tenant-modal.denial-reply.label")}
                hideLabel
              >
                <FormTextArea<TicketAIQuickDenialModalFormValues>
                  name="reply"
                  className="w-full"
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.tickets.details.responsibility-suggestion-tenant-modal.denial-reply.label"),
                    }),
                  }}
                />
              </FormField>
            </div>
          </div>
        </div>

        <Modal.Actions>
          <Button
            styling="secondary"
            icon={<Icon name={iconThumbsDown} />}
            isLoading={ticketResponsibilityMutation.isPending && !isRejecting}
            disabled={isRejecting}
            onClick={onClickDisagree}
            data-testid="ai-responsibility-suggestion-modal-disagree-btn"
          >
            {t("page.tickets.details.responsibility-suggestion-tenant-modal.disagree.btn")}
          </Button>
          <Button
            type="submit"
            isLoading={isRejecting}
            disabled={ticketResponsibilityMutation.isPending && !isRejecting}
            data-testid="ai-responsibility-suggestion-modal-reply-btn"
          >
            {t("page.tickets.details.responsibility-suggestion-tenant-modal.reply-and-deny.btn")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
