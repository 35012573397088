import { lazy, Suspense } from "react";

const InnerMarkdownRenderer = lazy(() => import("./MarkdownRenderer"));

export function LazyMarkdownRenderer({ markdown }: { markdown: string }): React.ReactNode {
  return (
    <Suspense fallback={null}>
      <InnerMarkdownRenderer markdown={markdown} />
    </Suspense>
  );
}
