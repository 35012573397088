import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto, ProjectConnectionDetailsDto, TranslateRequest } from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import type { FormTranslations } from "helpers/languages";
import { createImageSizeRule, createImageTypeRule, createRequiredStringRule } from "helpers/rules";
import { sortAlphabetically } from "helpers/util";
import { uniq } from "lodash-es";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

import { ProjectList } from "../../../components/ProjectList";

interface Project {
  id: string;
  name: string;
  baseLanguageId: string;
}

export interface LayoutProps {
  isEditMode: boolean;
  defaultFormValues: Partial<FormValues>;
  languages: LanguageDto[];
  projects: Project[];
  otherConnections: ProjectConnectionDetailsDto[];
  isSubmitting: boolean;
  onSubmit: (value: FormValues) => void;
}

const NAME_MAX_LENGTH = 255;
const DESCRIPTION_MAX_LENGTH = 255;

export function Layout({
  defaultFormValues,
  isEditMode,
  isSubmitting,
  onSubmit,
  languages,
  otherConnections,
  projects,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  const breadcrumbs = useSystemSettingBreadcrumbs({
    module: "project-connections",
    status: isEditMode ? "edit" : "create",
  });

  const api = useApi();
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });
  async function onTranslate(field: "content", languageId: LanguageDto["id"]) {
    const value = form.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }
    const result = await translate({
      languages: languages.filter((x) => x.id !== languageId).map((x) => x.id),
      text: value,
    });
    if (result) {
      for (const item of result) {
        form.setValue(`${field}Translations.${item.languages}`, item.text);
      }
    }
  }

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.project-connections.edit.title") : t("page.project-connections.create.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
    >
      <Form formMethods={form} onSubmit={onSubmit}>
        <FormContent>
          <FormField label={t("page.project-connections.form.name")} htmlFor="name">
            <FormInput<FormValues, "name">
              name="name"
              id="name"
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.project-connections.form.name"),
                  isDuplicate(value) {
                    const trimmedValue = value.toLowerCase().trim();
                    if (otherConnections.some((x) => x.name.toLowerCase() === trimmedValue)) {
                      return t("page.project-connections.form.name.error.already-exists");
                    }
                  },
                },
                maxLength: {
                  message: t("components.form.error.max-length", {
                    length: NAME_MAX_LENGTH,
                  }),
                  value: NAME_MAX_LENGTH,
                },
              }}
            />
          </FormField>
          <FormField label={t("page.project-connections.form.description")} htmlFor="description">
            <FormInput<FormValues>
              name="description"
              id="description"
              rules={{
                maxLength: {
                  message: t("components.form.error.max-length", {
                    length: DESCRIPTION_MAX_LENGTH,
                  }),
                  value: DESCRIPTION_MAX_LENGTH,
                },
              }}
            />
          </FormField>
        </FormContent>
        <FormContent maxWidth="lg">
          <FormField label={t("page.project-connections.form.projects")} htmlFor="projects">
            {isEditMode ? (
              <ProjectList projects={defaultFormValues.projects ?? []} />
            ) : (
              <FormMultiSelect<FormValues, "projects">
                name="projects"
                id="projects"
                items={projects}
                sortSelectedItems={sortAlphabetically}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
                placeholder={t("page.project-connections.form.projects.placeholder")}
                rules={{
                  validate: (value) => {
                    if (!isEditMode) {
                      if (!Array.isArray(value) || value.length < 2) {
                        return t("page.project-connections.form.projects.error.need-two-or-more");
                      }

                      if (isDuplicate(value, otherConnections)) {
                        return t("page.project-connections.form.projects.error.duplicate");
                      }

                      if (new Set(value.map((x) => x.baseLanguageId)).size > 1) {
                        return t("page.project-connections.form.projects.error.different-languages");
                      }
                    }
                  },
                }}
              />
            )}
          </FormField>
        </FormContent>
        <FormContent>
          <FormField label={t("page.project-connections.form.background-image")} htmlFor="backgroundImage" required>
            <FormImageInput<FormValues, "backgroundImage">
              name="backgroundImage"
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("page.project-connections.form.background-image"),
                }),
                validate: {
                  size: createImageSizeRule({ t }),
                  type: createImageTypeRule({ t }),
                },
              }}
            />
          </FormField>
          {languages.map((lng) => (
            <FormField
              key={lng.id}
              required
              htmlFor={`content-${lng.id}`}
              label={`${t("page.project-connections.form.explanation")} (${lng.poEditorCode})`}
              actions={
                <Button
                  styling="ghostPrimary"
                  onClick={() => onTranslate("content", lng.id)}
                  icon={<Icon name={iconGlobe02} size={16} />}
                >
                  {t("page.project-connections.form.explanation.translate")}
                </Button>
              }
            >
              <FormTextArea<FormValues>
                className="w-96 max-w-full"
                id={`content-${lng.id}`}
                name={`contentTranslations.${lng.id}`}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.project-connections.form.explanation"),
                  },
                }}
                disabled={isTranslating}
              />
            </FormField>
          ))}
        </FormContent>
        <FormContent>
          <Button type="submit" isLoading={isSubmitting}>
            {isEditMode ? t("page.project-connections.edit.submit") : t("page.project-connections.create.submit")}
          </Button>
        </FormContent>
      </Form>
    </DocumentPaper>
  );
}

interface FormValues {
  name: string;
  description: string;
  projects: Project[];
  contentTranslations: FormTranslations;
  backgroundImage: FormImage[];
}

function isDuplicate(
  newConnection: { id: string; name: string }[],
  existingConnections: ProjectConnectionDetailsDto[],
) {
  const newConnectionString = uniq(newConnection.map((p) => p.id))
    .sort()
    .join("|||");
  const existingConnectionsStrings = existingConnections.map((c) =>
    c.projects
      .map((p) => p.id)
      .sort()
      .join("|||"),
  );

  for (const existingConnectionString of existingConnectionsStrings) {
    if (existingConnectionString === newConnectionString) {
      return true;
    }
  }

  return false;
}
