import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AddressEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: addressId } = useParams<{ id: string }>();
  const isEditMode = isDefined(addressId);
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    data: buildings,
    isFetching: isLoadingBuildings,
    error: buildingsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BUILDING_LIST(projectId),
    queryFn: () => api.getBuildingsV1(),
    select: commonAPIDataSelector,
  });
  const addressQueries = useAddressQueries();
  const {
    data: addresses,
    isPending: isLoadingAddresses,
    error: addressesError,
  } = useQuery(addressQueries.getAddresses());
  const {
    data: addressDetails,
    isFetching: isLoadingAddressDetails,
    error: addressDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ADDRESS_DETAILS(projectId, addressId!),
    queryFn: () => api.getAddressesDetailsV1(addressId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const createAddress = useMutation({
    mutationFn: api.postAddressesV1,
    onSuccess() {
      showFlashToast({ title: t("page.addresses.create.notification.success"), type: "success" });
      void navigate(routes.addresses.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.addresses.create.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });
  const editAddress = useMutation({
    mutationFn: (data: AddressEditRequest) => api.putAddressesV1(addressId!, data),
    onSuccess() {
      showFlashToast({ title: t("page.addresses.edit.notification.success"), type: "success" });
      void navigate(routes.addresses.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.addresses.edit.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ADDRESS_LIST(projectId) });
    },
  });

  const error = addressDetailsError || buildingsError || addressesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingAddressDetails || isLoadingBuildings || isLoadingAddresses;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    isEditMode: isEditMode,
    defaultValues: isEditMode
      ? {
          building:
            buildings?.items.find((x) => x.id === addressDetails?.building.id) ??
            (isEditMode && buildings?.items.length === 1 ? buildings?.items[0] : undefined),
          city: addressDetails?.city,
          floor: addressDetails?.floor,
          housenumber: addressDetails?.houseNumber,
          housenumberSuffix: addressDetails?.houseNumberSuffix,
          postalCode: addressDetails?.postalCode,
          streetName: addressDetails?.streetName,
          type: addressDetails?.typeConstraint?.key,
        }
      : {},
    addressId,
    isSubmitting: editAddress.isPending || createAddress.isPending,
    buildings: buildings?.items ?? [],
    addresses: addresses?.items ?? [],
    onSubmit(data) {
      const payload = {
        type: data.type,
        city: data.city,
        housenumber: Number(data.housenumber),
        postalCode: data.postalCode,
        streetName: data.streetName,
        buildingId: data.building.id,
        floor: data.floor,
        housenumberSuffix: data.housenumberSuffix,
      };

      if (isEditMode) {
        editAddress.mutate(payload);
      } else {
        createAddress.mutate(payload);
      }
    },
  });
}
