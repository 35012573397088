import iconPhone01 from "assets/icons/phone-01.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

interface PreviewLayoutProps {
  preview: React.ReactNode;
  showPreviewButtonText: string;
  previewTitleText: string;
  previewWarning?: string;
}
export function PreviewLayout(props: PropsWithChildren<PreviewLayoutProps>): React.ReactNode {
  const { t } = useTranslation();
  const [isPreviewOpen, previewOpenHandlers] = useBool();
  useKey("Escape", previewOpenHandlers.setFalse, isPreviewOpen);

  const isXl = useScreenIsBiggerThan("xl");

  const previewSection = (
    <section
      className={twJoin(
        "fixed inset-0 z-40 flex-1 items-center justify-center bg-grey-900/60 lg:static lg:flex-auto lg:flex-col lg:justify-start lg:bg-transparent",
        isPreviewOpen ? "flex" : "hidden lg:flex",
      )}
      onClick={previewOpenHandlers.setFalse}
    >
      <div className="flex flex-col items-center justify-center lg:sticky lg:top-6">
        <h2 className="sr-only text-body-bold lg:not-sr-only lg:text-center lg:uppercase lg:text-grey-400">
          {props.previewTitleText}
        </h2>
        <div className="relative w-[70vw] max-w-72 lg:mt-4">
          <IconButton
            styling="secondary"
            className="absolute right-0 top-0 -translate-y-full translate-x-full lg:hidden"
            title={t("common.action.close")}
            onClick={previewOpenHandlers.setFalse}
            icon={iconX}
          />
          {props.preview}
        </div>
        <span className="hidden w-[350px] pt-4 text-caption text-grey-600 lg:block">{props.previewWarning}</span>
      </div>
    </section>
  );

  return (
    <div className="flex gap-8">
      <section className="flex w-full flex-col sm:block sm:w-auto xl:min-w-[500px] 2xl:min-w-[700px]">
        <div className="sticky top-0 z-10 bg-white">
          <Button
            styling="tertiary"
            className="mb-2 grid grid-cols-[1fr_auto] gap-x-1 lg:hidden"
            onClick={previewOpenHandlers.setTrue}
          >
            <Icon name={iconPhone01} />
            {props.showPreviewButtonText}
          </Button>
        </div>
        {props.children}
      </section>
      {!isXl && isPreviewOpen ? createPortal(previewSection, document.body) : previewSection}
    </div>
  );
}
