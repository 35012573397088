import type { QuestionDto } from "api/types";
import type React from "react";
import { useTranslation } from "translations";

import { MultipleChoiceWidget, OpenAnswerWidget, RatingWidget, YesNoWidget } from "./SurveyQuestionResponseWidgets";

interface SurveyQuestionResponseCardProps {
  question: QuestionDto;
  isSurveyEnded: boolean;
  canViewResults: boolean;
}

export function SurveyQuestionResponseCard({
  question,
  canViewResults,
  isSurveyEnded,
}: SurveyQuestionResponseCardProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-6" key={question.id}>
      <span className="text-body-bold">{question.question}</span>
      {canViewResults &&
        question.responses &&
        (question.responses.length > 0 ? (
          <>
            {question.type === "open" && <OpenAnswerWidget question={question} />}
            {question.type === "yesNo" && <YesNoWidget question={question} />}
            {question.type === "multipleChoice" && <MultipleChoiceWidget question={question} />}
            {question.type === "rating" && <RatingWidget question={question} />}
          </>
        ) : (
          <p className="my-2 text-grey-700">
            {isSurveyEnded
              ? t("page.survey-details.no-responses.finished")
              : t("page.survey-details.no-responses.running")}
          </p>
        ))}
    </div>
  );
}
