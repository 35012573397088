import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { SelfUpdateRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

const useUpdateSelfMutation = (opts: { onError: (error: unknown) => void; onSuccess: () => void }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: (payload: SelfUpdateRequest) => api.putSelfV3(payload),
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    ...opts,
  });
};

const useDeleteUser = ({ onSuccess }: { onSuccess?: () => void }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ userId }: { userId: string }) => api.deleteUsersByIdV1(userId),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("page.user-detail.delete.success") });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, payload.userId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_POSTS(projectId, payload.userId) });

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.user-detail.delete.error") });
    },
  });
};

const useAddUserSignUpReminders = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ userId }: { userId: string }) => api.postUsersUserSignupRemindersV1(userId),
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_SIGNUP_REMINDERS(projectId, payload.userId) });
      showFlashToast({ type: "success", title: t("page.user-detail.invite-user.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.user-detail.invite-user.error") });
    },
  });
};

export const userMutations = {
  useUpdateSelfMutation,
  useAddUserSignUpReminders,
  useDeleteUser,
};
