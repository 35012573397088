import { ToggleButtonGroup } from "components/ToggleButtonGroup/ToggleButtonGroup";
import { useTranslation } from "translations";

export function ToggleCalendarButton({
  isWeek,
  onToggleView,
}: {
  isWeek: boolean;
  onToggleView: () => void;
}): React.ReactNode {
  const { t } = useTranslation();

  const options = [
    { label: t("page.bookings.asset-detail.week-view.title"), value: true },
    { label: t("page.bookings.asset-detail.month-view.title"), value: false },
  ];

  return (
    <ToggleButtonGroup
      options={options}
      selected={isWeek ? options[0] : options[1]}
      onChange={(option) => {
        if (option.value !== isWeek) {
          onToggleView();
        }
      }}
    />
  );
}
