import { twResolve } from "helpers/tw-resolve";

interface TicketStatusProps {
  labelColor: string;
  description: string;
  className?: string;
}

export function TicketStatus({ labelColor, description, className }: TicketStatusProps): React.ReactNode {
  return (
    <span className={twResolve("flex items-center", className)}>
      <span className="mr-2 block size-2.5 shrink-0 rounded-full" style={{ backgroundColor: labelColor }} />
      <span className="whitespace-nowrap">{description}</span>
    </span>
  );
}
