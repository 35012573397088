import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { CommentPayload } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { TicketLeaveCommentModal } from "components/Ticket/TicketLeaveCommentModal";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { useSlug } from "hooks/useSlug";
import { sumBy } from "lodash-es";
import { TicketContent } from "modules/tickets/components/TicketDetails/TicketContent";
import { TicketHistory } from "modules/tickets/components/TicketDetails/TicketHistory";
import { TicketHomeDnaCard } from "modules/tickets/components/TicketDetails/TicketHomeDnaCard";
import { TicketInfoHeader } from "modules/tickets/components/TicketDetails/TicketInfoHeader";
import { TicketLog } from "modules/tickets/components/TicketDetails/TicketLog";
import { TicketReminderButton } from "modules/tickets/components/TicketDetails/TicketReminderButton";
import { TicketRemsCard } from "modules/tickets/components/TicketDetails/TicketRemsCard";
import { TicketResidentInfo } from "modules/tickets/components/TicketDetails/TicketResidentInfo";
import { canSeeServiceHistory, useTicketCommentHandler } from "modules/tickets/helpers";
import { useConfig } from "providers/ConfigProvider";
import { ticketMutations, useTicketQueries } from "queries/tickets";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { Trans, useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

interface Props {
  ticketId: string;
  showNextTicketBtn?: boolean;
  onGoToNextTicket?: () => void;
}

const ACTIVITY_AMOUNT = 20;
const TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY = "ticket-descending-activities";

export function PortfolioTicketDetails({ ticketId, showNextTicketBtn, onGoToNextTicket }: Props): React.ReactNode {
  const [changedAssignee, setChangedAssignee] = useState<UserDto>();
  const [isStatusChangeModalOpened, statusChangeModalOpenHandlers] = useBool();
  const [isLeaveCommentModalOpened, leaveCommentModalOpenHandlers] = useBool();
  const [warningBeforeAssigneeChangeUser, setWarningBeforeAssigneeChangeUser] = useState<UserDto>();

  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const [activitiesAmount, setActivitiesAmount] = useState(ACTIVITY_AMOUNT);
  const [descendingActivities, descendingActivitiesHandlers] = useBool(
    getLocalStorageValue(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, true),
  );

  const isAiTicketResponsibilitySuggestionAvailable = useConfig("isAiTicketResponsibilitySuggestionAvailable");
  useUpdateLocalStorage(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, descendingActivities);

  const ticketQueries = useTicketQueries();
  const {
    data: ticket,
    isPending: isLoadingTicket,
    error: ticketDetailsError,
  } = useQuery(ticketQueries.details(ticketId));
  const { isFetching: isFetchingResponsibilitySuggestion, isFetched: isFetchedResponsibilitySuggestion } = useQuery({
    ...ticketQueries.responsibilitySuggestion(ticketId),
    enabled: Boolean(ticket?.responsibilityAssignee) && isAiTicketResponsibilitySuggestionAvailable,
  });

  const activitiesParams = {
    sortDescending: descendingActivities,
  } satisfies Partial<ApiQueryParams<"getAdminTicketsActivitiesV1", 1>>;
  const activitiesQuery = useInfiniteQuery({
    queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, ticketId, activitiesParams),
    queryFn: ({ pageParam }) =>
      api
        .getAdminTicketsActivitiesV1(ticketId, {
          Offset: pageParam,
          Limit: activitiesAmount,
          ...activitiesParams,
        })
        .then((x) => x.data),
    initialPageParam: 0,
    getNextPageParam: (latestResponse, allResponses) => {
      const newOffset = sumBy(allResponses, (page) => page.items.length);

      return latestResponse?.hasMore ? newOffset : undefined;
    },
  });

  const { createComment, editComment } = useTicketCommentHandler(ticketId);
  const changeAssigneeMutation = ticketMutations.useUpdateAssignee();
  const changeStatusMutation = ticketMutations.useUpdateStatus();

  const activities = useMemo(
    () => activitiesQuery.data?.pages.flatMap((x) => x.items) || [],
    [activitiesQuery.data?.pages],
  );

  useEffect(() => {
    if (isHttpError(ticketDetailsError) && ticketDetailsError.status === 404) {
      void navigate(routes.tickets.list({ slug }));
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.errors.not-found"),
      });
    }
  }, [navigate, showFlashToast, t, ticketDetailsError, slug]);

  // Mark as read called everytime ticket is loaded
  const markAsReadMutation = useMutation({
    mutationFn: () => api.postTicketsReadV1(ticketId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" }),
  });

  const markAsRead = markAsReadMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ticket?.id) {
        markAsRead();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [markAsRead, ticket?.hasUnreadActivity, ticket?.hasUnreadComment, ticket?.id]);

  const onToggleActivitySorting = descendingActivitiesHandlers.toggle;
  const isFetchingMoreActivities = activitiesQuery.isFetchingNextPage || activitiesQuery.isPending;
  const hasMoreActivities = activitiesQuery.hasNextPage || false;
  const fetchMoreActivities = activitiesQuery.fetchNextPage;

  const bottomOfTicketLogRef = useRef<HTMLDivElement>(null);
  const statuses =
    ticket?.possibleStatuses.map((status) => ({
      id: status.id,
      description: status.name,
      labelColor: status.color,
      defaultStatusId: status.type,
    })) || [];

  async function onCreateComment(payload: CommentPayload) {
    await createComment({
      ...payload,
      fetchAllActivities: !descendingActivities,
    }).then(() => {
      if (!descendingActivities) {
        setActivitiesAmount(10000);

        setTimeout(() => {
          bottomOfTicketLogRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 50);
      }
    });

    const isStatusNewOrRejected = ["new", "rejected"].includes(ticket!.status.type);
    if (payload.type !== "internal" && isStatusNewOrRejected && ticket!.canChangeStatus) {
      statusChangeModalOpenHandlers.setTrue();
    }
  }

  const error = ticketDetailsError || activitiesQuery.error;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingTicket || (!isFetchedResponsibilitySuggestion && isFetchingResponsibilitySuggestion)) {
    return (
      <div className="flex h-[800px] max-h-screen-minus-16 w-screen max-w-screen-xl">
        <div className="w-full flex-1">
          <FullSizeLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-screen max-w-screen-xl flex-col gap-4 p-4">
      <div className="flex justify-end">
        <TicketReminderButton ticket={ticket} />
      </div>
      <div className="flex flex-col gap-4 xl:flex-row">
        <div className="flex max-w-6xl flex-1 flex-col gap-4">
          <TicketInfoHeader ticket={ticket} showProject />
          <TicketContent ticket={ticket} />
          <TicketLog
            isActivitiesDescending={descendingActivities}
            onToggleActivitySorting={onToggleActivitySorting}
            isFetchingMoreActivities={isFetchingMoreActivities}
            fetchMoreActivities={fetchMoreActivities}
            hasMoreActivities={hasMoreActivities}
            scrollTargetRef={bottomOfTicketLogRef}
            onCreateComment={onCreateComment}
            onEditComment={editComment}
            {...{ ticket, sessionUser, activities }}
          />
        </div>

        <aside className="flex w-full flex-col gap-4 md:max-w-xs">
          <TicketResidentInfo ticket={ticket} isGray />

          {canSeeServiceHistory(ticket, sessionUser) ? <TicketHistory ticketId={ticket.id} withOutline /> : null}

          {ticket.rems ? (
            <TicketRemsCard
              ticketId={ticket.id}
              ticketIsCollective={ticket.visibility !== "private"}
              rems={ticket.rems}
              withOutline
            />
          ) : null}

          {ticket.homeDna ? <TicketHomeDnaCard ticketId={ticket.id} homeDna={ticket.homeDna} withOutline /> : null}

          {showNextTicketBtn ? (
            <div className="mt-auto flex w-full flex-col items-end">
              <Button className="m-4 self-end" onClick={onGoToNextTicket}>
                {t("page.portfolio-tickets.details.next-button")}
              </Button>
            </div>
          ) : null}
        </aside>
      </div>
      <ConfirmModal
        title={t("page.tickets.before-assignee-change-modal.title")}
        description={t("page.tickets.before-assignee-change-modal.description")}
        isLoading={false}
        isOpened={warningBeforeAssigneeChangeUser !== undefined}
        onReject={() => setWarningBeforeAssigneeChangeUser(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setWarningBeforeAssigneeChangeUser(undefined);
          }
        }}
        onResolve={() => {
          if (!warningBeforeAssigneeChangeUser) {
            return;
          }

          setWarningBeforeAssigneeChangeUser(undefined);
          if (ticket.canAddInternalNote) {
            setChangedAssignee(warningBeforeAssigneeChangeUser);
          } else {
            changeAssigneeMutation.mutate({
              ticketId,
              data: {
                assigneeId: warningBeforeAssigneeChangeUser.id,
              },
            });
          }
        }}
      />
      <TicketLeaveCommentModal
        ticketId={ticketId}
        title={t("page.tickets.after-status-change-modal.title")}
        description={
          <Trans
            i18nKey="page.tickets.after-status-change-modal.description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={ticket.canAddPublicComment}
        isOpened={isLeaveCommentModalOpened}
        onOpenChange={leaveCommentModalOpenHandlers.set}
        onSubmit={async (value, note) => {
          try {
            await createComment({
              content: value,
              images: [],
              videos: [],
              documents: [],
              type: note ? "internal" : "public",
            });
          } finally {
            leaveCommentModalOpenHandlers.setFalse();
          }
        }}
      />
      <TicketLeaveCommentModal
        ticketId={ticketId}
        title={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.title"
            values={{ name: changedAssignee?.fullName || "" }}
            components={{
              bold: <strong className="font-old-semibold" />,
            }}
          />
        }
        description={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.description"
            components={{
              yellow: <span className="text-yellow-700" />,
            }}
          />
        }
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={false}
        isOpened={!!changedAssignee}
        onOpenChange={(state) => {
          if (!state) {
            setChangedAssignee(undefined);
          }
        }}
        onSubmit={async (note) => {
          if (changedAssignee) {
            await changeAssigneeMutation.mutateAsync({
              ticketId,
              data: {
                assigneeId: changedAssignee.id,
                note,
              },
            });
            setChangedAssignee(undefined);
          }
        }}
        assignee={changedAssignee}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpened={isStatusChangeModalOpened}
        onOpenChange={statusChangeModalOpenHandlers.set}
        onSubmit={async (status) => {
          try {
            if (status.id !== ticket.status.id) {
              await changeStatusMutation.mutateAsync({
                ticketId,
                data: {
                  statusId: status.id,
                  silent: true,
                },
              });
            }
          } finally {
            statusChangeModalOpenHandlers.setFalse();
          }
        }}
        targetStatus={statuses[0]}
        possibleStatuses={statuses}
      />
    </div>
  );
}
