import iconCameraPlus from "assets/icons/camera-plus.svg";
import iconFileAttachment01 from "assets/icons/file-attachment-01.svg";
import iconPlayCircle from "assets/icons/play-circle.svg";
import { type FormDocument, useDocumentInput } from "components/DocumentInput/useDocumentFile";
import { Icon } from "components/Icon/Icon";
import { type FormImage, useImageInput } from "components/ImageInput/useImageInput";
import { MediaXButton } from "components/Media/MediaXButton";
import { Pdf } from "components/Pdf/Pdf";
import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { AnimatePresence, motion } from "framer-motion";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import type React from "react";
import { useId, useRef } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

import { buttonVariants } from "./Button";

export type MediaPickerButtonProps<TValue> = {
  name?: string;
  value: TValue;
  disabled?: boolean;
  maxFiles?: number;
  isError?: boolean;
  onChange: (files: TValue) => void;
};

export function ImagePickerButton({
  name,
  value,
  maxFiles = 1,
  disabled,
  isError,
  onChange,
}: MediaPickerButtonProps<FormImage[]>): React.ReactNode {
  const id = useId();
  const refInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const { addImages, removeImage } = useImageInput({
    selectedImages: value,
    maximumFiles: maxFiles,
    onChange,
  });

  const handleAddImages = (files: FileList | null) => {
    if (!files) return;

    addImages(files);
  };

  const handleDeleteImage = (index: number) => {
    removeImage(value[index]);

    // Clear input valueo to maintain single source of truth
    if (refInput.current) {
      refInput.current.value = "";
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {value.length > 0 && (
        <div className="flex flex-wrap gap-2">
          <AnimatePresence>
            {value.map((image, index) => (
              <motion.div
                variants={{
                  initial: { opacity: 0 },
                  animate: { opacity: 1 },
                  exit: { opacity: 0 },
                }}
                transition={{ duration: 0.1, ease: "linear" }}
                initial="initial"
                animate="animate"
                exit="exit"
                key={image.url}
                className="relative h-20 max-w-32"
              >
                <span className="absolute right-0 top-0 -translate-y-1/3 translate-x-1/3">
                  <MediaXButton onClick={() => handleDeleteImage(index)} title={t("common.action.delete")} />
                </span>
                <img src={image.url} className="h-full rounded-md object-cover" />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
      <label
        className={twJoin(
          buttonVariants({
            styling: "secondary",
          }),
          isError && "border-red-600",
        )}
        aria-invalid={isError}
        htmlFor={id}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
      >
        <Icon name={iconCameraPlus} />
        {t("component.media-picker-button.add-image", {
          count: maxFiles,
        })}
      </label>
      <input
        ref={refInput}
        aria-invalid={isError}
        data-testid="image-input"
        className="sr-only"
        type="file"
        tabIndex={-1}
        accept={ALLOWED_FILE_TYPES.IMAGE}
        multiple={maxFiles > 1}
        disabled={disabled}
        onChange={(e) => handleAddImages(e.target.files)}
        {...{ id, name }}
      />
    </div>
  );
}

export function VideoPickerButton({
  name,
  value,
  disabled,
  maxFiles = 1,
  isError,
  onChange,
}: MediaPickerButtonProps<FormVideo[]>): React.ReactNode {
  const id = useId();
  const refInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name: fileName, progress }) => {
      onChange(
        [...value].map((video) => {
          if (!isVideoUploaded(video) && video.file.name === fileName) {
            return { ...video, uploadProgress: progress };
          }

          return video;
        }),
      );
    },
    shouldProcess: true,
  });
  const { addVideos, removeVideo } = useVideoInput({
    selectedVideos: value,
    maximumFiles: maxFiles,
    onChange,
    uploadFn: uploadFormVideo,
  });

  const handleAddVideos = (files: FileList | null) => {
    if (!files) return;

    addVideos(files);
  };

  const handleDeleteVideo = (index: number) => {
    removeVideo(value[index]);

    // Clear input valueo to maintain single source of truth
    if (refInput.current) {
      refInput.current.value = "";
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {value.length > 0 && (
        <div className="flex flex-wrap gap-2">
          <AnimatePresence>
            {value.map((video, index) => (
              <VideoFilePreview key={video.url} video={video} onDelete={() => handleDeleteVideo(index)} isPlayable />
            ))}
          </AnimatePresence>
        </div>
      )}

      <label
        className={twJoin(
          buttonVariants({
            styling: "secondary",
          }),
          isError && "border-red-600",
        )}
        aria-invalid={isError}
        htmlFor={id}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
      >
        <Icon name={iconPlayCircle} />
        {t("component.media-picker-button.add-video", {
          count: maxFiles,
        })}
      </label>
      <input
        ref={refInput}
        aria-invalid={isError}
        data-testid="video-input"
        className="sr-only"
        type="file"
        tabIndex={-1}
        accept={ALLOWED_FILE_TYPES.VIDEO}
        disabled={disabled}
        onChange={(e) => handleAddVideos(e.target.files)}
        {...{ id, name }}
      />
    </div>
  );
}

export function DocumentPickerButton({
  name,
  value,
  disabled,
  maxFiles = 1,
  isError,
  onChange,
}: MediaPickerButtonProps<FormDocument[]>): React.ReactNode {
  const id = useId();
  const refInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const { addDocuments, removeDocument } = useDocumentInput({
    selectedDocuments: value,
    maximumFiles: maxFiles,
    onChange,
  });

  const handleAddDocuments = (files: FileList | null) => {
    if (!files) return;

    addDocuments(files);
  };

  const handleDeleteDocument = (index: number) => {
    removeDocument(value[index]);

    // Clear input valueo to maintain single source of truth
    if (refInput.current) {
      refInput.current.value = "";
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {value.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {value.map((document, index) => {
            const fileName = "file" in document ? document.file.name : document.fileName;
            const fileSize = "file" in document ? document.file.size : undefined;

            return (
              <Pdf
                key={fileName}
                fileName={fileName}
                fileSize={fileSize}
                onDelete={() => handleDeleteDocument(index)}
              />
            );
          })}
        </div>
      )}
      <label
        className={twJoin(
          buttonVariants({
            styling: "secondary",
          }),
          isError && "border-red-600",
        )}
        aria-invalid={isError}
        htmlFor={id}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
      >
        <Icon name={iconFileAttachment01} />
        {t("component.media-picker-button.add-document", {
          count: maxFiles,
        })}
      </label>
      <input
        ref={refInput}
        aria-invalid={isError}
        data-testid="document-input"
        className="sr-only"
        type="file"
        tabIndex={-1}
        accept={ALLOWED_FILE_TYPES.DOCUMENT}
        multiple
        disabled={disabled}
        onChange={(e) => handleAddDocuments(e.target.files)}
        {...{ id, name }}
      />
    </div>
  );
}
