import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type { AssetBookingDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import locationIcon from "assets/icons/marker-pin-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { ContextMenu_LEGACY, type ContextMenuAction_LEGACY } from "components/ContextMenu_LEGACY/ContextMenu_LEGACY";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { parseISO } from "date-fns";
import { timeWithoutSeconds } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canViewSchedule } from "modules/bookings/permissions";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

export function ReservationCard({
  reservation,
  isPast,
}: {
  reservation: AssetBookingDto;
  isPast?: boolean;
}): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const slug = useSlug();
  const navigate = useNavigate();
  const hasPermission = usePermission();
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();
  const showFlashToast = useFlashToast();
  const resolveImage = useImageResolver();

  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-reservation-modal");

  const deleteReservation = useMutation({
    mutationFn: () =>
      api
        .deleteBookableAssetsBookingsByIdV1(reservation.id, reservation.asset.id, { reason: undefined })
        .then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.reservation-details.delete.notification.success"),
      });

      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId),
      });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, reservation.asset.id, reservation.id),
      });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.reservation-details.delete.notification.error"),
      }),
  });

  const actions: ContextMenuAction_LEGACY[] = useMemo(() => {
    const actions = [];
    if (reservation.canEdit && !reservation.cancelledAt) {
      actions.push({
        text: t("page.bookings.reservations.actions.edit"),
        icon: <Icon name={iconEdit05} />,
        dataTestId: "context-menu-edit-btn",
        callback: () =>
          void navigate(routes.bookings.editBooking({ slug, aid: reservation.asset.id, bid: reservation.id })),
      });
    }
    if (reservation.canDelete && !reservation.cancelledAt) {
      actions.push({
        text: t("page.bookings.reservations.actions.cancel"),
        icon: <Icon name={iconTrash02} />,
        dataTestId: "context-menu-delete-btn",
        callback: () => openDeleteModal(reservation.id),
      });
    }

    return actions;
  }, [t, navigate, reservation, openDeleteModal, slug]);

  const isCancelled = !!reservation.cancelledAt;

  return (
    <>
      <div
        data-testid="reservation-card"
        className="relative grid cursor-pointer grid-cols-[7fr_3fr] grid-rows-1 rounded-lg border border-grey-100 bg-white shadow-sm hover:shadow-lg"
        onClick={() =>
          hasPermission(canViewSchedule)
            ? void navigate(
                routes.reservations.reservationDetails({ slug, aid: reservation.asset.id, rid: reservation.id }),
              )
            : void navigate(
                routes.calendar.reservationDetails({ slug, aid: reservation.asset.id, rid: reservation.id }),
              )
        }
      >
        <div className="flex size-full items-center gap-3 py-2.5 pl-4">
          {/* Date */}
          <div className="flex h-full flex-col items-center justify-center gap-1 rounded-lg border border-grey-100 px-2 py-1">
            <span className="text-caption text-grey-500">
              {formatDate(i18n, "weekDayShort", parseISO(reservation.date))}
            </span>
            <span className={twJoin("text-headline3 leading-old-headline4", isPast && "text-grey-600")}>
              {parseISO(reservation.date).getDate()}
            </span>
            <span className="text-body text-grey-500">
              {formatDate(i18n, "monthShort", parseISO(reservation.date))}
            </span>
          </div>
          {/* Info */}
          <div className="flex grow flex-col items-start justify-center gap-1">
            <span className={twJoin("text-body-bold", isPast && "text-grey-600")}>{reservation.asset.name}</span>
            <span className="text-caption text-grey-700">{`${timeWithoutSeconds(reservation.startTime)} - ${timeWithoutSeconds(reservation.endTime)}`}</span>
            {reservation.asset.locationSpecification ? (
              <span className="flex items-center gap-1 text-grey-700">
                <Icon name={locationIcon} />
                <span className="line-clamp-1 text-caption">{reservation.asset.locationSpecification}</span>
              </span>
            ) : null}
          </div>
          <div className="h-full">
            <ContextMenu_LEGACY actions={actions} />
          </div>
        </div>
        {/* Image */}
        <div
          className="relative size-full rounded-r-lg bg-grey-300 bg-cover bg-center"
          style={{
            backgroundImage: `url(${reservation.asset.images[0] ? resolveImage(reservation.asset.images[0], "intrinsic") : ""})`,
          }}
        >
          {isCancelled && <Label theme="red">{t("page.bookings.reservations.reservation-cancelled")}</Label>}
        </div>
      </div>
      <DeleteModal
        title={t("page.bookings.reservation-details.delete.modal.title")}
        description={t("page.bookings.reservation-details.delete.modal.description")}
        onDelete={() => deleteReservation.mutateAsync()}
        rejectBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.keep-reservation"),
          "data-testid": "modal-cancel-delete",
        }}
        deleteBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.cancel-reservation"),
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </>
  );
}
