import type { ProjectOverviewItemDto } from "api/types";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Select } from "components/Select/Select";
import type React from "react";
import { useTranslation } from "translations";

import { getPortfolioFilterOptions } from "../helpers";

export type PortfolioOverviewFilterValues = {
  owner: string | undefined;
  maintenance: string | undefined;
  city: string | undefined;
};

interface PortfolioOverviewFilterProps {
  projects: ProjectOverviewItemDto[];
  filter: PortfolioOverviewFilterValues;
  isOpened: boolean;
  onClose: () => void;
  onChangeFilter: (newFilter: PortfolioOverviewFilterValues) => void;
}

export function PortfolioOverviewFilter({
  projects,
  filter,
  isOpened,
  onClose,
  onChangeFilter,
}: PortfolioOverviewFilterProps): React.ReactNode {
  const { t } = useTranslation();

  const {
    owners: availableProjectOwners,
    maintainers: availableProjectMaintainers,
    cities: availableProjectCities,
  } = getPortfolioFilterOptions(projects);

  if (!isOpened) {
    return null;
  }

  return (
    <div data-testid="portfolio-filter-container" className="flex w-full flex-col border-t border-grey-100">
      <div className="flex w-full flex-col gap-2 py-4">
        <span className="text-caption-bold">{t("page.portfolio.overview.filter.title")}</span>
        <div className="flex flex-wrap items-center gap-3">
          {availableProjectOwners.length > 0 && (
            <div className="w-full md:w-64">
              <Select
                placeholder={t("page.portfolio.overview.filter.project-owner.plalceholder")}
                renderOption={(opt) => opt}
                keySelector={(opt) => opt}
                items={availableProjectOwners}
                selected={filter.owner}
                onChange={(value) => onChangeFilter({ ...filter, owner: value })}
                isClearable
              />
            </div>
          )}
          {availableProjectMaintainers.length > 0 && (
            <div className="w-full md:w-64">
              <Select
                placeholder={t("page.portfolio.overview.filter.project-maintainer.plalceholder")}
                renderOption={(opt) => opt}
                keySelector={(opt) => opt}
                items={availableProjectMaintainers}
                selected={filter.maintenance}
                onChange={(value) => onChangeFilter({ ...filter, maintenance: value })}
                isClearable
              />
            </div>
          )}
          {availableProjectCities.length > 0 && (
            <div className="w-full md:w-64">
              <Select
                placeholder={t("page.portfolio.overview.filter.project-city.plalceholder")}
                renderOption={(opt) => opt}
                keySelector={(opt) => opt}
                items={availableProjectCities}
                selected={filter.city}
                onChange={(value) => onChangeFilter({ ...filter, city: value })}
                isClearable
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex w-full justify-center">
        <Button icon={<Icon name={iconX} />} styling="tertiary" onClick={onClose}>
          {t("page.portfolio.overview.filter.close-filter.btn")}
        </Button>
      </div>
    </div>
  );
}
