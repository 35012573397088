import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ServicePartnerPageDto } from "api/types";
import { CardGrid } from "components/CardGrid/CardGrid";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { LoadingIcon } from "components/Icons/Icons";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import { ServiceCard } from "./ServiceCard";

interface ServicesGridViewProps {
  services: ServicePartnerPageDto[];
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
}

export function ServicesGridView({ services, hasMore, isLoading, onLoadMore }: ServicesGridViewProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-service-modal");
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const api = useApi();
  const queryClient = useQueryClient();

  const mutationDeleteService = useMutation({
    mutationFn: (id: string) => api.deleteServicepartnersByIdV1(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNER(projectId, id) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });
      showFlashToast({
        type: "success",
        title: t("model.service.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.delete.notification.error"),
      });
    },
  });

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoading && hasMore) {
        onLoadMore();
      }
    }, [hasMore, isLoading, onLoadMore]),
  });

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        <CardGrid>
          {services.length === 0 && !isLoading ? (
            <span className="text-body-bold">
              {sessionUser.isAdmin
                ? t("page.bookings.list-services.no-services.admin")
                : t("page.bookings.list-services.no-services.resident")}
            </span>
          ) : (
            services.map((service) => (
              <ServiceCard
                data-testid="service-card"
                onDelete={() => openDeleteModal(service.id)}
                onEdit={() => void navigate(routes.servicePartners.edit({ slug, id: service.id }))}
                key={service.id}
                {...{ service }}
              />
            ))
          )}
        </CardGrid>
        {hasMore && (
          <div className="p-4" ref={loaderRef}>
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          </div>
        )}
      </div>
      <DeleteModal
        title={t("model.service.action.delete.confirmation")}
        description={t("model.service.action.delete.description")}
        onDelete={mutationDeleteService.mutateAsync}
        {...deleteModalProps}
      />
    </>
  );
}
