import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "translations";

interface IqbiDetailsConsentModalProps {
  serviceId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function IqbiDetailsConsentModal({ serviceId, isOpen, onClose }: IqbiDetailsConsentModalProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const mutationGiveConsent = useMutation({
    mutationFn: () => api.postServicepartnersIqbiConsentV1(),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.service.action.iqbi-give-consent.notification.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNER(projectId, serviceId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.iqbi-give-consent.notification.error"),
      });
    },
  });

  return (
    <ConfirmModal
      title={t("page.service-details.iqbi.consent-modal.title")}
      isLoading={mutationGiveConsent.isPending}
      onResolve={() => {
        mutationGiveConsent.mutate();
        onClose();
      }}
      onReject={onClose}
      resolveBtnProps={{
        text: t("page.service-details.iqbi.consent-modal.confirm-btn"),
      }}
      rejectBtnProps={{
        text: t("page.service-details.iqbi.consent-modal.reject-btn"),
      }}
      onOpenChange={(state) => {
        if (!state) {
          onClose();
        }
      }}
      isOpened={isOpen}
    >
      <div className="flex flex-col gap-4 text-center">
        <p>{t("page.service-details.iqbi.consent-modal.description")}</p>
        <p>
          {sessionUser.address ? (
            <>
              {sessionUser.address.location}
              <br />
              {sessionUser.address?.zipCode} {sessionUser.address?.city}
            </>
          ) : (
            sessionUser.company?.name
          )}
        </p>
      </div>
    </ConfirmModal>
  );
}
