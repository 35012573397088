import { ErrorPage } from "components/Error/ErrorPage";
import { useSessionUserInitial } from "hooks/Network/useSessionUserInitial";
import type React from "react";
import { useMemo } from "react";

import type { ProjectId } from "./ProjectContext";
import { ProjectContext } from "./ProjectContext";

interface QuickReplyUserLoaderProps {
  children: React.ReactNode;
  projectId: string;
}

export function QuickReplyUserLoader({ children, projectId }: QuickReplyUserLoaderProps): React.ReactNode {
  const { data: user, isLoading: isLoadingUser, error: errorUser } = useSessionUserInitial(projectId);

  const value = useMemo(() => {
    if (!user) return null;

    return {
      projectId: user.projectId as ProjectId,
      projectSlug: user.project.slug,
      changeProject: () => null,
    };
  }, [user]);

  if (isLoadingUser) {
    return null;
  }

  if (errorUser || !value) {
    return <ErrorPage status={(errorUser as unknown as Response)?.status || 0} />;
  }

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
}
