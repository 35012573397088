import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { twResolve } from "helpers/tw-resolve";
import React from "react";

export const Card = React.forwardRef(function Card<T extends React.ElementType>(
  props: React.ComponentPropsWithRef<"article"> & { className?: string; as?: T },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const Component = props.as || "article";

  return (
    <Component
      {...props}
      ref={ref}
      className={twResolve("flex size-full flex-col gap-4 rounded-lg bg-white p-5", props.className)}
    />
  );
});

export function CardHeader({
  title,
  tooltip,
  children,
}: React.PropsWithChildren<{ title: string; tooltip?: string }>): React.ReactNode {
  return (
    <div className="flex w-full flex-col flex-wrap justify-between gap-2 lg:flex-row">
      <div className="flex h-fit items-center gap-2">
        <h3 className="text-body-bold">{title}</h3>

        {tooltip ? <InfoIcon tooltip={tooltip} /> : null}
      </div>
      <div className="flex items-center gap-3">{children}</div>
    </div>
  );
}
