import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageInformRequest, UserWithAudienceDto } from "api/types";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

interface FormValues {
  type: string;
  content: string;
  adminsToInform: UserWithAudienceDto[];
}

type InformAdminModalProps = ModalBaseProps & {
  messageId: string;
};

const MIN_LENGTH_CONTENT = 10;
const MAX_LENGTH_CONTENT = 1000;

export function InformAdminModal({ messageId, isOpened, onOpenChange }: InformAdminModalProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const api = useApi();
  const query = useQueryClient();
  const form = useForm<FormValues>();

  const { data: adminListData, isLoading } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_INFORM_ADMINS(projectId, messageId),
    queryFn: () => api.getMessagesInformsUsersV1(messageId).then((x) => x.data),
    enabled: isOpened,
  });

  const sharePost = useMutation({
    mutationFn: (payload: MessageInformRequest) => api.postMessagesInformsV1(messageId, payload),
    onSuccess: () => {
      void query.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, messageId) });
      query.removeQueries({ queryKey: QUERY_KEYS.MESSAGES_INFORMED_ADMINS(projectId, messageId) });
      showFlashToast({ type: "success", title: t("component.community-post.inform-admin.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.inform-admin.error") });
    },
  });

  const adminList = useMemo(() => adminListData?.items ?? [], [adminListData]);

  async function handleShare() {
    const formValues = form.getValues();

    await sharePost.mutateAsync({
      content: formValues.content,
      adminsToInform: formValues.adminsToInform.map((admin) => admin.id),
    });
    onOpenChange(false);
  }

  return (
    <Modal.Root
      size="sm"
      title={t("component.community-post.inform-admin.modal.title")}
      description={t("component.community-post.inform-admin.modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-8" data-testid="inform-admin-modal-content">
        {isLoading ? (
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        ) : (
          <Form formMethods={form} onSubmit={handleShare}>
            <FormContent>
              <FormField label={t("component.community-post.inform-admin.modal.input.admins.title")} required>
                <FormMultiSelect<FormValues, "adminsToInform">
                  name="adminsToInform"
                  placeholder={t("component.community-post.inform-admin.modal.input.admins.placeholder")}
                  disabledItemTooltip={t("component.community-post.inform-admin.modal.admin-list.disabled-tooltip")}
                  items={adminList}
                  disabledItems={adminList.filter((x) => !x.canAccess)}
                  keySelector={(x) => x.id}
                  renderOption={(x, selected) => (
                    <div className="flex flex-col">
                      <span>{x.fullName}</span>
                      {!selected && x.email ? (
                        <span className="text-caption italic text-grey-600">({x.email})</span>
                      ) : null}
                    </div>
                  )}
                  searchField={(x) => `${x.fullName}|||${x.email}`}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("component.community-post.inform-admin.modal.input.admins.title"),
                    }),
                  }}
                />
              </FormField>
              <FormField label={t("component.community-post.inform-admin.modal.input.content.title")} required>
                <FormTextArea<FormValues>
                  data-testid="inform-admin-description"
                  name="content"
                  placeholder={t("component.community-post.inform-admin.modal.input.content.placeholder")}
                  rules={{
                    minLength: {
                      message: t("components.form.error.min-length", { length: MIN_LENGTH_CONTENT }),
                      value: MIN_LENGTH_CONTENT,
                    },
                    maxLength: {
                      message: t("components.form.error.max-length", { length: MAX_LENGTH_CONTENT }),
                      value: MAX_LENGTH_CONTENT,
                    },
                    validate: {
                      required: createRequiredStringRule(
                        t,
                        "component.community-post.inform-admin.modal.input.content.title",
                      ),
                    },
                  }}
                />
              </FormField>
            </FormContent>
            <Modal.Actions>
              <Modal.Close>
                <Button styling="secondary">{t("common.action.cancel")}</Button>
              </Modal.Close>
              <Button data-testid="submit-inform-btn" styling="primary" type="submit" isLoading={sharePost.isPending}>
                {t("component.community-post.inform-admin.modal.share-button")}
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </div>
    </Modal.Root>
  );
}
