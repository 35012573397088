import { useQuery } from "@tanstack/react-query";
import { Anchor } from "components/Anchor/Anchor";
import { ErrorPage } from "components/Error/ErrorPage";
import { LoadingIcon } from "components/Icons/Icons";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost/CommunityPost";
import { canListMessages } from "modules/messages/permissions";
import { useCommunityFeedQueries } from "queries/communityFeed";
import type React from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

const AMOUNT_MAX_POST = 5;

export function HomeCommunityPostList(): React.ReactNode {
  const hasPermission = usePermission();
  const slug = useSlug();
  const { t } = useTranslation();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: postsData,
    isPending: isPendingPosts,
    error: errorPosts,
  } = useQuery({
    ...communityFeedQueries.messages({
      Tab: "all",
      Offset: 0,
      Limit: AMOUNT_MAX_POST,
      Connection: "included",
      ExcludeScheduledPosts: true,
      ExcludeArchivedPosts: true,
    }),
    enabled: hasPermission(canListMessages),
  });

  if (isPendingPosts) {
    return (
      <div className="mt-20 flex w-full justify-around">
        <LoadingIcon className="w-8" />
      </div>
    );
  }

  if (errorPosts) {
    return <ErrorPage error={errorPosts} />;
  }

  const posts = postsData.items;

  if (posts.length === 0) {
    return null;
  }

  return (
    <div className="flex w-full grow flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <h2 className="text-headline2">{t("page.home.message-feed", { count: posts.length })}</h2>
        <span className="whitespace-nowrap">
          <Anchor to={routes.messageFeed.list({ slug })}>{t("page.home.message-feed-link")}</Anchor>
        </span>
      </div>
      <div className="flex flex-col gap-5">
        {posts.map((post) => (
          <CommunityPost key={post.id} message={post} />
        ))}
      </div>
    </div>
  );
}
