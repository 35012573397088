import type { Locale } from "date-fns";
import { format as formatDateFns, formatRelative, isToday, isYesterday, parseISO } from "date-fns";
import { fallbackLanguageCode, locales } from "helpers/hardcoded-translations";
import type { i18n, TranslationFunction, TranslationKey, TranslationVariables } from "translations";
import { useTranslation } from "translations";

export interface FormattedDateProps {
  format: DateFormatting;
  date: string | number | Date;
}

export type DateFormatting =
  | "weekDayShort"
  | "weekDay"
  | "date"
  | "dateRelative"
  | "datetime"
  | "datetimeRelative"
  | "datetimeShort"
  | "datetimeShortRelative"
  | "time"
  | "month"
  | "monthShort"
  | "monthYear"
  | "monthYearShort"
  | "dateMonthYearShort";

export function FormattedDate({ format, date }: FormattedDateProps): React.ReactNode {
  const { i18n } = useTranslation();

  const tooltipFormat = format === "time" ? "datetime" : format;
  const tooltip = formatDate(i18n, stripRelativeFromDateFormat(tooltipFormat), date);
  const content = formatDate(i18n, format, date);

  return (
    <time data-testid="date" title={tooltip === content ? "" : tooltip}>
      {content}
    </time>
  );
}

export function formatDate({ t, language }: i18n, format: DateFormatting, date: string | number | Date): string {
  return formatDateForLanguage(t, format, language, date);
}

export function stripRelativeFromDateFormat<T extends DateFormatting>(
  format: T,
): T extends `${infer TK}Relative` ? TK : T {
  return format.replace("Relative", "") as any;
}

function formatDateForLanguage(
  t: TranslationFunction,
  formatting: DateFormatting,
  languageId: string,
  value: string | number | Date,
): string {
  if (!(languageId in locales)) {
    console.warn(
      `Language ${languageId} not found in hardcoded translations, falling back to language id ${languageId}`,
    );
  }

  const locale = locales[languageId as keyof typeof locales] || locales[fallbackLanguageCode];

  const date = typeof value === "string" ? parseISO(value) : new Date(value);

  switch (formatting) {
    case "weekDayShort":
      return formatDateFns(date, "eee", { locale });
    case "weekDay":
      return formatDateFns(date, "eeee", { locale });
    case "date":
      return formatDateFns(date, "PPP", { locale });
    case "dateRelative":
      if (isYesterday(date) || isToday(date)) {
        const dateTokenList = getDateFnsDateTranslations(t);

        return formatRelative(date, new Date(), {
          locale: changeLocaleFormatRelativeFn(locale, dateTokenList, "PPP"),
        });
      }

      return formatDateFns(date, "PPP", { locale });
    case "datetime":
      return formatDateFns(date, "PPPp", { locale });
    case "datetimeRelative":
      if (isYesterday(date) || isToday(date)) {
        const datetimeTokenList = getDateFnsDateTimeTranslations(t);

        return formatRelative(date, new Date(), {
          locale: changeLocaleFormatRelativeFn(locale, datetimeTokenList, "PPPp"),
        });
      }

      return formatDateFns(date, "PPPp", { locale });
    case "datetimeShort":
      // Remove dot for Dutch month abbreviation
      return formatDateFns(date, "d LLL, p", { locale }).replace(".", "");
    case "datetimeShortRelative":
      if (isYesterday(date) || isToday(date)) {
        const datetimeTokenList = getDateFnsDateTimeTranslations(t);

        return formatRelative(date, new Date(), {
          locale: changeLocaleFormatRelativeFn(locale, datetimeTokenList, "d LLL, p"),
        });
      }

      // Remove dot for Dutch month abbreviation
      return formatDateFns(date, "d LLL, p", { locale }).replace(".", "");
    case "time":
      return formatDateFns(date, "p", { locale });
    case "month":
      return formatDateFns(date, "LLLL", { locale });
    case "monthShort":
      return formatDateFns(date, "LLL", { locale });
    case "monthYear":
      return formatDateFns(date, "LLLL', 'yyyy", { locale });
    case "monthYearShort":
      // Remove dot for Dutch month abbreviation
      return formatDateFns(date, "LLL''yy", { locale }).replace(".", "");
    case "dateMonthYearShort":
      // Remove dot for Dutch month abbreviation
      return formatDateFns(date, "d LLL''yy", { locale }).replace(".", "");
    default:
      throw new Error(`Unknown date type ${formatting}`);
  }
}

function changeLocaleFormatRelativeFn(locale: Locale, tokenList: Record<string, string>, dateFormat: string): Locale {
  return {
    ...locale,
    formatRelative: (token, date): string => {
      if (token in tokenList) {
        return tokenList[token];
      }

      return formatDateFns(date, dateFormat, { locale });
    },
  };
}

function getDateFnsDateTimeTranslations(t: TranslationFunction) {
  const dateT = createDateT(t);

  return {
    lastWeek: dateT("common.date-time.relative.day-last-week", { dayName: "eeee", time: "p" }),
    nextWeek: dateT("common.date-time.relative.day-next-week", { dayName: "eeee", time: "p" }),
    yesterday: dateT("common.date-time.relative.yesterday", { time: "p" }),
    today: dateT("common.date-time.relative.today", { time: "p" }),
    tomorrow: dateT("common.date-time.relative.tomorrow", { time: "p" }),
    other: "PPPp",
  };
}

function getDateFnsDateTranslations(t: TranslationFunction) {
  const dateT = createDateT(t);

  return {
    lastWeek: dateT("common.date.relative.day-last-week", { dayName: "eeee" }),
    nextWeek: dateT("common.date.relative.day-next-week", { dayName: "eeee" }),
    yesterday: dateT("common.date.relative.yesterday"),
    today: dateT("common.date.relative.today"),
    tomorrow: dateT("common.date.relative.tomorrow"),
    other: "PPP",
  };
}

/**
 * Creates t function that maps our translation format into the date-fns format
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function createDateT<TFunction extends TranslationFunction>(t: TFunction) {
  return function interpolator<TKey extends TranslationKey>(key: TKey, variables?: TranslationVariables<TKey>) {
    const replaceDict: { key: string; actualValue: string | number; replacementValue: string }[] = [];
    const tempOptions = {} as TranslationVariables<TKey>;

    if (variables && typeof variables === "object") {
      let i = 0;
      for (const optionKey in variables) {
        i++;

        const value = variables[optionKey as keyof typeof variables];
        if (typeof value !== "string" && typeof value !== "number") {
          throw new Error(
            `Unknown translation arg type for translation '${optionKey}' with key '${optionKey}': type '${typeof value}'`,
          );
        }

        const replacementValue = `__$${i}__`; // some unique replacement string that will never naturally occur in POEditor

        replaceDict.push({
          key: optionKey,
          actualValue: value,
          replacementValue,
        });

        tempOptions[optionKey as keyof typeof tempOptions] = replacementValue as any;
      }
    }

    let translation = t(key, tempOptions);
    for (const value of replaceDict) {
      translation = translation.replace(value.replacementValue, `'${value.actualValue}'`);
    }

    return `'${translation}'`.replaceAll("''", "");
  };
}
