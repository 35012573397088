import { Icon } from "components/Icon/Icon";
import type React from "react";

interface AdminCreatePostSectionProps {
  title?: string;
  icon?: string;
  children: React.ReactNode;
}

export function AdminCreatePostSection({ title, icon, children }: AdminCreatePostSectionProps): React.ReactNode {
  return (
    <section className="flex w-full flex-col gap-2 rounded-lg bg-white p-4">
      {title && (
        <div className="flex items-center gap-2">
          {icon && <Icon name={icon} size={24} />}
          <h2 className="text-headline2">{title}</h2>
        </div>
      )}
      {children}
    </section>
  );
}
