import type { SurveyDetailsDto } from "api/types";
import iconMessageSmileSquare from "assets/icons/message-smile-square.svg";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Label } from "components/Label/Label";
import { Notice } from "components/Notice/Notice";
import { differenceInDays, isBefore, parseISO } from "date-fns";
import { useBool } from "hooks/useBool";
import { AnimatePresence, motion } from "motion/react";
import { surveyMutations } from "queries/surveys";
import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "translations";

import { SurveyAuthor } from "./SurveyAuthor";
import { SurveyFollowUpModal } from "./SurveyFollowUpModal";

enum FollowUpState {
  UNKNOWN,
  REACTED,
}

interface SurveyInfoProps {
  survey: SurveyDetailsDto;
}

export function SurveyInfo({ survey }: SurveyInfoProps): React.ReactNode {
  const [isFollowUpModalOpened, followUpModalOpenHandlers] = useBool();
  const [followUpState, setFollowUpState] = useState(FollowUpState.UNKNOWN);

  const { t } = useTranslation();
  const addFollowUpDateMutation = surveyMutations.useAddFollowUpDate();

  const { startDate, endDate, isRunning, isFinished, isUpcoming, days, responses } = useMemo(() => {
    const currStartDate = survey.startsAt ? parseISO(survey.startsAt) : undefined;
    const currEndDate = survey.endsAt ? parseISO(survey.endsAt) : undefined;

    return {
      startDate: currStartDate,
      endDate: currEndDate,
      isRunning:
        currStartDate && currEndDate && isBefore(currStartDate, new Date()) && isBefore(new Date(), currEndDate),
      isUpcoming: currStartDate && isBefore(new Date(), currStartDate),
      isFinished: !!currEndDate && isBefore(currEndDate, new Date()),
      days: currEndDate && currStartDate ? differenceInDays(currEndDate, currStartDate) : undefined,
      responses: new Set(
        survey.questions.flatMap(
          (currQuestion) => currQuestion.responses?.map((currResponse) => currResponse.respondent.id) ?? [],
        ),
      ).size,
    };
  }, [survey]);

  const handleExpandFollowUpNotice = () => {
    followUpModalOpenHandlers.setTrue();

    void addFollowUpDateMutation.mutateAsync({ surveyId: survey.id });
  };

  return (
    <>
      <div className="flex w-full flex-col gap-2 rounded-lg bg-white p-6">
        <div className="flex flex-col gap-2">
          {survey.internal && <Label theme="blue">{t("page.survey-details.info.internal")}</Label>}
          <div>
            <h2 className="text-headline3 leading-old-headline4">{survey.title}</h2>
            <p className="italic">
              {t("page.survey-details.info.group.in", {
                groupName: survey.group ? survey.group.name : t("page.survey-details.info.group.general"),
              })}
            </p>
          </div>
        </div>
        <p className="mt-2 whitespace-pre-wrap">{survey.description}</p>
        <hr className="my-2 text-grey-300" />
        <div className="flex flex-col gap-2">
          <p className="flex gap-1">
            {isFinished && (
              <span className="flex items-center">
                <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-aop-basic-blue-500" />
                <span className="whitespace-nowrap">{t("page.survey-details.info.finished")}</span>
              </span>
            )}
            {isRunning && (
              <span className="flex items-center">
                <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-green-600" />
                <span className="whitespace-nowrap">{t("page.survey-details.info.running")}</span>
              </span>
            )}
            {isUpcoming && (
              <span className="flex items-center">
                <span className="mr-2 block size-2.5 shrink-0 rounded-full bg-yellow-500" />
                <span className="whitespace-nowrap">{t("page.survey-details.info.upcoming")}</span>
              </span>
            )}
            {(isFinished || isRunning) && responses && (
              <strong>({t("page.survey-details.info.responses", { count: responses })})</strong>
            )}
          </p>

          <AnimatePresence>
            {isFinished && (!survey.followedUpAt || followUpState === FollowUpState.REACTED) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.4 }}
              >
                <Notice
                  icon={iconMessageSmileSquare}
                  type={followUpState === FollowUpState.REACTED ? "positive" : "info"}
                  message={
                    followUpState === FollowUpState.REACTED
                      ? t("page.survey-details.follow-up-notice.reacted.title")
                      : t("page.survey-details.follow-up-notice.default.title")
                  }
                  onExpand={followUpState === FollowUpState.REACTED ? undefined : handleExpandFollowUpNotice}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <hr className="my-2 text-grey-300" />

          <table>
            <tbody>
              {startDate && (
                <tr>
                  <td className="whitespace-nowrap pr-1">
                    {isUpcoming ? t("page.survey-details.info.starts-at") : t("page.survey-details.info.started-at")}:{" "}
                  </td>
                  <td className="w-full font-old-semibold">
                    <FormattedDate date={startDate} format="datetime" />
                  </td>
                </tr>
              )}
              {endDate && (
                <tr>
                  <td className="whitespace-nowrap pr-1">
                    {isUpcoming || isRunning
                      ? t("page.survey-details.info.ends-at")
                      : t("page.survey-details.info.ended-at")}
                    :{" "}
                  </td>
                  <td className="w-full font-old-semibold">
                    <FormattedDate date={endDate} format="datetime" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {days ? <p>{t("page.survey-details.info.open-for", { count: days })}</p> : null}
        </div>

        <hr className="my-2 text-grey-300" />

        {survey.createdBy && <SurveyAuthor author={survey.createdBy} />}
      </div>

      <SurveyFollowUpModal
        surveyId={survey.id}
        isOpened={isFollowUpModalOpened}
        onOpenChange={followUpModalOpenHandlers.set}
        onReact={() => setFollowUpState(FollowUpState.REACTED)}
      />
    </>
  );
}
