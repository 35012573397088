import type { MessageCreateRequest } from "api/types";
import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import annoucementIcon from "assets/icons/announcement-03.svg";
import commentListIcon from "assets/icons/message-chat-circle.svg";
import commentIcon from "assets/icons/message-text-circle-01.svg";
import iconPhone01 from "assets/icons/phone-01.svg";
import shareIcon from "assets/icons/share-06.svg";
import likeIcon from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { DocumentMedia } from "components/Media/DocumentMedia";
import { Modal } from "components/Modal/Modal";
import { Notice } from "components/Notice/Notice";
import { Phone } from "components/Phone/Phone";
import { PhoneInner } from "components/Phone/PhoneInner";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import type { FormVideo, InputFile } from "components/VideoInput/useVideoInput";
import { useBool } from "hooks/useBool";
import { CommunityItemGallery } from "modules/messages/pages/List/components/CommunityItemMedia/CommunityItemGallery";
import { CommunityItemVideoForAppPreview } from "modules/messages/pages/List/components/CommunityItemMedia/CommunityItemVideoForAppPreview";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "translations";

interface CreatePostAppPreviewProps {
  title: string;
  content: string;
  images: FormImage[];
  videos: FormVideo[];
  documents: FormDocument[];
  groupName: string | undefined;
  postType: MessageCreateRequest["type"] | undefined;
}

export function CreatePostAppPreview({
  title,
  content,
  images,
  videos,
  documents,
  groupName,
  postType,
}: CreatePostAppPreviewProps): React.ReactNode {
  const [isModalOpened, modalHandler] = useBool(false);
  const [isPostTooLong, postSizeWarningHandler] = useBool(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const screenRef = useRef<HTMLDivElement>(null);
  const postRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const calculatePostSize = useCallback(() => {
    if (!postRef.current || !screenRef.current) {
      return;
    }

    if (postRef.current.clientHeight / screenRef.current.clientHeight > 0.45) {
      postSizeWarningHandler.setTrue();
    } else {
      postSizeWarningHandler.setFalse();
    }
  }, [postRef, screenRef, postSizeWarningHandler]);

  useEffect(() => {
    if (!parentRef.current || !postRef.current || !screenRef.current) {
      return;
    }

    // if the element is hidden (in mobile) then we make the parent visible to calculate the size and hide it again
    // no flashing occurs
    if (screenRef.current.clientHeight === 0) {
      parentRef.current.style.display = "flex";
      calculatePostSize();
      parentRef.current.style.display = "hidden";

      return;
    }

    calculatePostSize();
  }, [parentRef, title, content, calculatePostSize]);

  const previewSection = (
    <div ref={parentRef} className="mx-auto flex w-full max-w-72 flex-col">
      <Phone>
        <PhoneInner noBackground>
          <div ref={screenRef} className="relative flex min-h-full flex-1 flex-col items-center bg-aop-off-white">
            <span className="mt-9 text-body-bold text-aop-dark-blue-500">
              {t("component.create-post-app-preview.community")}
            </span>
            <div className="mt-2 flex w-full flex-col gap-4 overflow-y-hidden bg-white p-4 pb-0 shadow-sm">
              {(postType === "announcement" || postType === "announcementReadOnly") && (
                <div className="flex items-center gap-2">
                  <Label theme="purple" size="sm">
                    <Icon name={annoucementIcon} size={16} />
                    <span>{t("component.create-post-app-preview.announcement")}</span>
                  </Label>
                </div>
              )}

              <div className="flex grow items-center">
                <div className="size-8 shrink-0">
                  <UserAvatar hideDeletedIcon />
                </div>
                <div className="ml-2">
                  <span className="line-clamp-1 flex items-center gap-1">
                    <span className="text-overline-bold text-black">
                      {t("component.create-post-app-preview.username")}
                    </span>
                    {groupName && (
                      <span className="text-overline italic">
                        {t("component.community-post.posted-in-group", { group: groupName })}
                      </span>
                    )}
                  </span>
                  <span className="mt-1 line-clamp-1 text-[10px] text-grey-500">
                    <FormattedDate format="datetime" date={new Date()} />
                  </span>
                </div>
              </div>

              <div ref={postRef} className="flex flex-col gap-4">
                {title && <span className="text-caption-bold">{title}</span>}
                {content && (
                  <span className="whitespace-pre-line text-[10px] leading-[14px]">
                    <LinkFormatter>{content.trim()}</LinkFormatter>
                  </span>
                )}
              </div>
              {images && images.length > 0 && (
                <div className="flex w-full justify-center overflow-hidden rounded-lg [&>div]:overflow-auto [&>div]:rounded-none">
                  <CommunityItemGallery {...{ images }} />
                </div>
              )}
              {videos && videos.length > 0 && <CommunityItemVideoForAppPreview video={videos[0] as InputFile} />}
              {documents && documents.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {documents.map((document, idx) => (
                    <DocumentMedia key={`preview-document-${idx}`} {...{ document }} />
                  ))}
                </div>
              )}
              {content && (
                <span className="text-[10px] text-grey-500">{t("component.create-post-app-preview.translate")}</span>
              )}
              <span className="text-[10px] text-grey-500">{t("component.create-post-app-preview.first-like")}</span>
              <div className="flex w-full items-center justify-between border-t border-grey-100 py-1 text-[10px] text-grey-500">
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-0.5">
                    <Icon name={likeIcon} size={16} />
                    <span>{t("component.create-post-app-preview.like")}</span>
                  </div>
                  {(postType === "announcement" || postType === "undefined") && (
                    <>
                      <div className="flex items-center gap-0.5">
                        <Icon name={commentIcon} size={16} />
                        <span>{t("component.create-post-app-preview.comment")}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <Icon name={commentListIcon} size={16} />
                        <span className=" text-grey-500">0</span>
                      </div>
                    </>
                  )}
                </div>
                <Icon name={shareIcon} size={16} />
              </div>
            </div>
          </div>
        </PhoneInner>
      </Phone>
    </div>
  );

  return (
    <>
      <Button
        styling="tertiary"
        icon={<Icon name={iconPhone01} />}
        className="xl:hidden"
        onClick={modalHandler.setTrue}
      >
        {t("page.admin-create-post.section.preview")}
      </Button>
      <div className="hidden xl:block">
        <div className="flex size-full flex-col gap-4">
          {previewSection}
          {isPostTooLong && (
            <Notice icon={iconAlertTriangle} message={t("component.create-post-app-preview.warning")} />
          )}
        </div>
      </div>

      <Modal.Root isOpened={isModalOpened} onOpenChange={modalHandler.set}>
        {previewSection}
        {isPostTooLong && <Notice icon={iconAlertTriangle} message={t("component.create-post-app-preview.warning")} />}
      </Modal.Root>
    </>
  );
}
