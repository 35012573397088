import type { MessageStatusChangeRequestV2 } from "api/types";
import ImageParty from "assets/images/party.png";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormTagSelect } from "components/Form/FormTagSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { communityFeedMutations } from "queries/communityFeed";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

type CommunityPostClosePostSolvedReasonModalProps = ModalBaseProps & {
  messageId: string;
};

type CommunityPostClosePostSolvedModalFormValues = {
  reason: Extract<MessageStatusChangeRequestV2["reason"], "solvedHelpInsideCommunity" | "solvedOther">[];
  details?: string;
};

export function CommunityPostClosePostSolvedReasonModal({
  messageId,
  isOpened,
  onOpenChange,
}: CommunityPostClosePostSolvedReasonModalProps): React.ReactNode {
  const [isCompleted, setIsCompleted] = useState(false);

  const { t } = useTranslation();
  const form = useForm<CommunityPostClosePostSolvedModalFormValues>({
    defaultValues: {
      reason: [],
      details: undefined,
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpened, form]);

  const selectedReason = useWatch({
    control: form.control,
    name: "reason",
  })[0];
  const updateMessageStatus = communityFeedMutations.useUpdateMessageStatus();

  const handleSubmit = async (data: CommunityPostClosePostSolvedModalFormValues) => {
    if (data.reason.length === 0) return;

    await updateMessageStatus.mutateAsync({
      messageId,
      data: {
        newStatus: "archived",
        reason: data.reason[0],
        details: data.details,
      },
    });

    setIsCompleted(true);
  };

  return (
    <Modal.Root
      title={
        isCompleted
          ? t("component.community-post.close-post-confirm-modal.title.success")
          : t("component.community-post.close-post-confirm-modal.title")
      }
      {...{ isOpened, onOpenChange }}
    >
      {!isCompleted && (
        <Form formMethods={form} onSubmit={handleSubmit}>
          <FormContent maxWidth="none" className="pb-4">
            <FormField label={t("component.community-post.close-post-solved-modal.reason-field.label")} required>
              <FormTagSelect<CommunityPostClosePostSolvedModalFormValues, "reason">
                data-testid="closing-post-solved-modal-options-container"
                name="reason"
                items={[
                  {
                    label: t("component.community-post.close-post-solved-modal.reason-field.option.inside-community"),
                    value: "solvedHelpInsideCommunity",
                  },
                  {
                    label: t("component.community-post.close-post-solved-modal.reason-field.option.other"),
                    value: "solvedOther",
                  },
                ]}
                rules={{
                  validate: {
                    required: (value) => {
                      if (value.length === 0) {
                        return t("component.community-post.close-post-solved-modal.details-field.error");
                      }
                    },
                  },
                }}
              />
            </FormField>
            {selectedReason && (
              <FormField label={t("component.community-post.close-post-solved-modal.details-field.label")}>
                <FormTextArea<CommunityPostClosePostSolvedModalFormValues, "details">
                  data-testid="closing-post-solved-modal-details-textarea"
                  name="details"
                  placeholder={
                    selectedReason === "solvedHelpInsideCommunity"
                      ? t("component.community-post.close-post-solved-modal.details-field.placeholder.inside-community")
                      : t("component.community-post.close-post-solved-modal.details-field.placeholder.other")
                  }
                />
              </FormField>
            )}
          </FormContent>

          <Modal.Actions>
            <Button data-testid="confirm-issue-solved-btn" type="submit" isLoading={updateMessageStatus.isPending}>
              {t("component.community-post.close-post-solved-modal.btn")}
            </Button>
          </Modal.Actions>
        </Form>
      )}
      {isCompleted && (
        <>
          <div className="mx-auto flex flex-col items-center gap-4 py-4">
            <img height={96} width={96} src={ImageParty} alt="Success" />
            <p className="text-center text-grey-700">
              {t("component.community-post.close-post-confirm-modal.body.success")}
            </p>
          </div>
          <Modal.Actions>
            <Modal.Close>
              <Button>{t("common.action.continue")}</Button>
            </Modal.Close>
          </Modal.Actions>
        </>
      )}
    </Modal.Root>
  );
}
