import { twResolve } from "helpers/tw-resolve";
import type React from "react";

interface PageGridGridProps {
  children: React.ReactNode[] | React.ReactNode;
}

function PageGridGrid({ children }: PageGridGridProps): React.ReactNode {
  return <div className="grid w-full grid-cols-12 gap-4 3xl:gap-6">{children}</div>;
}

interface PageGridItemProps {
  children: React.ReactNode[] | React.ReactNode;
  size: "25%" | "33%" | "50%" | "66%" | "75%" | "100%";
  isDesktopIndiffernt?: boolean; // If true, the size prop would remain constant in all desktop sizes (> xl)
}

function PageGridItem({ children, size, isDesktopIndiffernt }: PageGridItemProps): React.ReactNode {
  const commonClassName = "w-full";

  return (
    <section
      className={twResolve(
        "col-span-full xl:col-span-6",
        // 1 / 4
        size === "25%" && "2xl:col-span-3",
        size === "25%" && isDesktopIndiffernt && "xl:col-span-3",
        // 1 / 3
        size === "33%" && "2xl:col-span-4",
        size === "33%" && isDesktopIndiffernt && "xl:col-span-4",
        // 1 / 2
        // Exception: When a 50% size item is the only child, it should take 66% instead
        size === "50%" && "xl:col-span-6 xl:first:last:col-span-8",
        // 2 / 3
        size === "66%" && "2xl:col-span-8",
        size === "66%" && isDesktopIndiffernt && "xl:col-span-8",
        // 3 / 4
        size === "75%" && "2xl:col-span-9",
        size === "75%" && isDesktopIndiffernt && "xl:col-span-9",

        // 1 / 1
        size === "100%" && "xl:col-span-full",
        commonClassName,
      )}
    >
      {children}
    </section>
  );
}

export const PageGrid = {
  Grid: PageGridGrid,
  Item: PageGridItem,
};
