import { useSuspenseQuery } from "@tanstack/react-query";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "translations";

import { FahWidgetSpeedometer } from "./FahWidgetSpeedometer";
import { FahWidgetTrendChart } from "./FahWidgetTrendChart";
import type { FahWidgetStage } from "./types";

type FahWidgetScoreSectionProps = {
  stage: FahWidgetStage;
};

export function FahWidgetScoreSection({ stage }: FahWidgetScoreSectionProps): React.ReactNode {
  const { t } = useTranslation();

  const analyticsQueries = useAnalyticsQueries();
  const { data: fahRecentStats } = useSuspenseQuery(analyticsQueries.getFah());
  const { data: fahLastSixMonthsStats } = useSuspenseQuery(analyticsQueries.getFahMonthlyStats("last6Months"));

  return (
    <div className="flex w-full flex-col items-center justify-center gap-8">
      <div className="flex w-full items-center justify-center gap-1 self-start">
        <h2 className="text-center text-headline2">{t("component.analytics-widget.fah-score.title")}</h2>
        <InfoIcon tooltip={t("component.analytics-widget.fah-score.info.tooltip")} />
      </div>

      {(stage === "average" || stage === "lastMonth") && <FahWidgetSpeedometer data={fahRecentStats} {...{ stage }} />}
      {stage === "lastSixMonths" && <FahWidgetTrendChart data={fahLastSixMonthsStats} />}
    </div>
  );
}
