import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ProjectStylingRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useProjectQueries } from "queries/project";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (data: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const projectQueries = useProjectQueries();
  const {
    data: projectDetails,
    isPending: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useQuery(projectQueries.details());
  const editStyling = useMutation({
    mutationFn: (data: ProjectStylingRequest) => api.putProjectStylingV1(projectId, data),
    onSuccess() {
      showFlashToast({ title: t("page.project-styling.notification.success"), type: "success" });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF() });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONNECTED_PROJECTS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_DETAILS(projectId) });
    },
    onError() {
      showFlashToast({ title: t("page.project-styling.notification.failed"), type: "error" });
    },
  });

  const error = projectDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingProjectDetails;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    defaultValues: {
      logoDark: projectDetails?.logo ? [projectDetails.logo] : [],
      logoLight: projectDetails?.logoLight ? [projectDetails.logoLight] : [],
      background: projectDetails?.backgroundImage ? [projectDetails.backgroundImage] : [],
      splashScreenBackground: projectDetails?.splashScreenBackground ? [projectDetails.splashScreenBackground] : [],
      useBackgroundImageForSplashScreen: projectDetails?.useBackgroundImageForSplashScreen || false,
      selectedSplashScreenLogo: projectDetails?.selectedSplashScreenLogo,
      color1: projectDetails?.styling.tintColor,
      color2: projectDetails?.styling.textLightColor,
      color3: projectDetails?.styling.navigationColor,
      pageTitleUnscrolledColor: projectDetails?.styling.pageTitleUnscrolledColor,
      backgroundColor: projectDetails?.styling.backgroundColor,
    },
    isSubmitting: editStyling.isPending || isUploadingImage,
    async onSubmit(data) {
      const payload: ProjectStylingRequest = {
        useBackgroundImageForSplashScreen: data.useBackgroundImageForSplashScreen,
        selectedSplashScreenLogo: data.selectedSplashScreenLogo,
        color1: data.color1,
        color2: data.color2,
        color3: data.color3,
        pageTitleUnscrolledColor: data.pageTitleUnscrolledColor,
        backgroundColor: data.backgroundColor,
      };

      if (data.logoDark.length > 0) {
        const logoDark = await uploadFormImage(data.logoDark[0]);

        payload.imageLogoDarkId = logoDark?.id;
      }
      if (data.logoLight.length > 0) {
        const logoLight = await uploadFormImage(data.logoLight[0]);

        payload.imageLogoLightId = logoLight?.id;
      }
      if (data.background.length > 0) {
        const background = await uploadFormImage(data.background[0]);

        payload.backgroundImageId = background?.id;
      }
      if (data.splashScreenBackground.length > 0) {
        const splashScreenBackground = await uploadFormImage(data.splashScreenBackground[0]);

        payload.splashScreenBackgroundId = splashScreenBackground?.id;
      }

      editStyling.mutate(payload);
    },
  });
}
