import ImageParty from "assets/images/party.png";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canViewSchedule } from "modules/bookings/permissions";
import type React from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

type BookingSuccessModalProps = ModalBaseProps & {};

export function BookingSuccessModal({ isOpened, onOpenChange }: BookingSuccessModalProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const navigate = useNavigate();

  return (
    <Modal.Root size="sm" title={t("page.bookings.book-asset.success-modal.title")} {...{ isOpened, onOpenChange }}>
      <div className="flex w-full justify-center">
        <img className="w-24" src={ImageParty} alt="success" />
      </div>
      <Modal.Actions>
        <Modal.Close>
          <Button styling="secondary" onClick={() => void navigate(routes.bookings.list({ slug }))}>
            {t("common.action.close")}
          </Button>
        </Modal.Close>
        <Button
          onClick={() =>
            hasPermission(canViewSchedule)
              ? void navigate(routes.reservations.list({ slug }))
              : void navigate({ pathname: routes.calendar.list({ slug }), search: "tab=reservations" })
          }
        >
          {t("page.bookings.book-asset.success-modal.see-details")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}
