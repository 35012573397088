import type { AssetBookingDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { bookableAssetMutations } from "queries/bookableAssets";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

type DeclineReason =
  | "double-booking"
  | "maintenance"
  | "policy-violation"
  | "emergency"
  | "priority"
  | "incorrect-info"
  | "other";

const declineReasonsIds: DeclineReason[] = [
  "double-booking",
  "maintenance",
  "policy-violation",
  "emergency",
  "priority",
  "incorrect-info",
  "other",
];

interface Reason {
  id: DeclineReason;
  label: string;
}

interface FormValues {
  reason: Reason;
  detail?: string;
}

type DeclineBookingModalProps = ModalBaseProps & {
  booking: AssetBookingDto;
};

export function DeclineBookingModal({ booking, isOpened, onOpenChange }: DeclineBookingModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>();

  const reason = useWatch({ control: form.control, name: "reason.id" });

  const deleteBookingMutation = bookableAssetMutations.useDeleteBooking();

  async function onSubmit(values: FormValues) {
    await deleteBookingMutation.mutateAsync({
      bookingId: booking.id,
      assetId: booking.asset.id,
      reason: values.reason.id === "other" ? values.detail! : values.reason.label,
    });

    onOpenChange(false);
  }

  const declineReasons: Reason[] = declineReasonsIds.map((reason) => ({
    id: reason,
    label: t(`page.bookings.asset-detail.decline-booking.reasons.${reason}`),
  }));

  return (
    <Modal.Root
      title={t("page.bookings.asset-detail.decline-booking.title")}
      description={t("page.bookings.asset-detail.decline-booking.description")}
      size="sm"
      isNested
      {...{ isOpened, onOpenChange }}
    >
      <Form className="w-full" formMethods={form} onSubmit={onSubmit}>
        <FormSelect<FormValues, Reason>
          name="reason"
          placeholder={t("page.bookings.asset-detail.decline-booking.reasons.placeholder")}
          items={declineReasons}
          keySelector={(x) => x.id}
          renderOption={(x) => x.label}
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.bookings.asset-detail.decline-booking.reasons.placeholder"),
            }),
          }}
        />
        {reason === "other" ? (
          <FormInput<FormValues, "detail">
            name="detail"
            rules={{
              validate: {
                required: createRequiredStringRule(
                  t,
                  "page.bookings.asset-detail.decline-booking.reasons.other.detail.name",
                ),
              },
            }}
          />
        ) : null}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="secondary" disabled={deleteBookingMutation.isPending}>
              {t("common.action.cancel")}
            </Button>
          </Modal.Close>
          <Button
            data-testid="confirm-booking-decline-btn"
            type="submit"
            styling="danger"
            isLoading={deleteBookingMutation.isPending}
          >
            {t("page.bookings.asset-detail.booking-details.cancel")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
