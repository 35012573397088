import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";

interface Props {
  "data-testid"?: string;
  theme: "red" | "yellow" | "green" | "blue" | "gray" | "purple";
  size?: "sm" | "md";
  center?: boolean;
  children?: React.ReactNode;
  icon?: string;
  isUppercase?: boolean;
}

export function Label({
  theme,
  size = "md",
  children,
  "data-testid": dataTestId,
  isUppercase = true,
  icon,
}: Props): React.ReactNode {
  return (
    <div className="inline-flex size-fit shrink-0 overflow-hidden rounded bg-white">
      <span
        className={twResolve(
          "inline-flex w-full items-center justify-center gap-2 px-2 py-1",
          getLabelStyling(theme),
          size === "sm" && "gap-1 text-overline",
          size === "md" && "text-caption-bold",
          icon && !children && "px-1",
          isUppercase && "uppercase",
        )}
        data-testid={dataTestId}
      >
        {icon && <Icon name={icon} size={size === "sm" ? 16 : 20} />}
        {children}
      </span>
    </div>
  );
}

function getLabelStyling(theme: Props["theme"]) {
  switch (theme) {
    case "red":
      return "bg-red-100 text-red-600";
    case "yellow":
      return "bg-yellow-100 text-yellow-600";
    case "green":
      return "bg-green-100 text-green-600";
    case "blue":
      return "bg-blue-100 text-blue-600";
    case "gray":
      return "bg-grey-100 text-grey-900";
    case "purple":
      return "bg-bright-purple-100 text-bright-purple-500";
    default:
      throw new Error(`Unknown theme: ${theme}`);
  }
}
