import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

interface MyPupFormValues {
  enabled: boolean;
}

export function MyPupForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<MyPupFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: MyPupFormValues) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<MyPupFormValues>({ defaultValues });
  const enabled = useWatch({ control: formMethods.control, name: "enabled" });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        <FormField label={""} htmlFor="enabled">
          <FormCheckbox<MyPupFormValues, "enabled">
            id="enabled"
            name="enabled"
            label={t("page.integration.mypup.form.enabled")}
            onChange={(e) => {
              if (enabled && !e.target.checked) {
                void formMethods.trigger();
              }

              formMethods.setValue("enabled", e.target.checked);
            }}
          />
        </FormField>

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.mypup.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}
