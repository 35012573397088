import FlexedBiceps from "assets/images/flexed-biceps.png";
import { Button } from "components/Button/Button";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import type React from "react";
import { useTranslation } from "translations";

type SessionFinishedModalProps = ModalBaseProps & {
  data?:
    | {
        missedItems: number;
      }
    | undefined;
};

export function SessionFinishedModal({ isOpened, onOpenChange, data }: SessionFinishedModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      title={t("page.portfolio-tickets.session-finished.title")}
      size="sm"
      description={
        data && data.missedItems > 0
          ? t("page.portfolio-tickets.session-finished.missed", { count: data.missedItems })
          : t("page.portfolio-tickets.session-finished.no-missed")
      }
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-1 flex-col items-center justify-center gap-4">
        <img src={FlexedBiceps} alt="" width={80} height={80} />
      </div>
      <Modal.Actions>
        <Modal.Close>
          <Button>{t("common.action.close")}</Button>
        </Modal.Close>
      </Modal.Actions>
    </Modal.Root>
  );
}
