import iconCheck from "assets/icons/check.svg";
import { Icon } from "components/Icon/Icon";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useTranslation } from "translations";

type AdminCreatePostTipsModalProps = ModalBaseProps;

export function AdminCreatePostTipsModal({ isOpened, onOpenChange }: AdminCreatePostTipsModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root title={t("page.message-feed.create.tips.title")} {...{ isOpened, onOpenChange }}>
      <ul className="flex flex-col gap-3 [&>li]:text-caption">
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.1")}</span>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.2")}</span>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.3")}</span>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.4")}</span>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.5")}</span>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} />
          <span>{t("page.message-feed.create.tips.6")}</span>
        </li>
      </ul>
    </Modal.Root>
  );
}
