import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const project = useProject();
  const slug = useSlug();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const query = {
    groupTypes: ["helpCategory"],
    Limit: 10000,
  } satisfies ApiQueryParams<"getGroupsV2">;

  const {
    data: helpCategories = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUPS_QUERY(projectId, query),
    queryFn: () => api.getGroupsV2(query),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const { mutate: follow } = useMutation({
    mutationFn: (id: string) => api.postGroupsFollowV1(id),
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.help-category.action.follow.notification-error"),
      });
    },
  });

  const { mutate: unfollow } = useMutation({
    mutationFn: (id: string) => api.postGroupsUnfollowV1(id),
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.help-category.action.follow.notification-error"),
      });
    },
  });

  const { mutate: markOnboarded, isPending: isMarkingAsOnboarded } = useMutation({
    mutationFn: () => api.postSelfOnboardingV1(),
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.onboarding.help-categories.submit.error"),
      });
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });

      void navigate(routes.messageFeed.list({ slug }));
    },
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    helpCategories: helpCategories ?? [],
    project,
    onFollow: follow,
    onUnfollow: unfollow,
    onMarkOnboarded: markOnboarded,
    isMarkingAsOnboarded: isMarkingAsOnboarded,
  });
}
