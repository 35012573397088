import { cloneElement, Fragment, type ReactElement, type ReactNode } from "react";

import type { TranslationKey, Translations, TranslationVariables } from "./types";
import { useTranslation } from "./useTranslation";

interface TransProps<T extends Record<string, string> = Translations> {
  i18nKey: TranslationKey<T>;
  values?: TranslationVariables<TranslationKey, T>;
  components?: { [key: string]: ReactElement };
}

export function Trans<T extends Record<string, string> = Translations>({
  i18nKey,
  values,
  components,
}: TransProps<T>): ReactNode {
  const { t } = useTranslation();
  const translation = t(i18nKey as any, values);

  // If components prop is provided, parse the translation string to replace tags.
  if (components) {
    return parseTranslation(translation, components);
  }

  return translation;
}

function parseTranslation(text: string, components: { [key: string]: ReactElement }, keyPrefix = ""): ReactNode[] {
  const nodes: ReactNode[] = [];
  let remaining = text;
  let counter = 0;

  // Regex to match either a self-closing tag (like <br />) or a paired tag (like <a>...</a>)
  const regex = /<(\w+)(?:\s*\/>|>(.*?)<\/\1>)/;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = regex.exec(remaining);
    if (!match) {
      if (remaining) {
        nodes.push(<Fragment key={`${keyPrefix}${counter++}`}>{remaining}</Fragment>);
      }
      break;
    }
    const [fullMatch, tag, innerText] = match;
    const index = match.index;
    // Add any text before the match.
    if (index > 0) {
      const before = remaining.slice(0, index);
      nodes.push(<Fragment key={`${keyPrefix}${counter++}`}>{before}</Fragment>);
    }
    const component = components[tag];
    if (component) {
      // If innerText is undefined, it's a self-closing tag.
      if (innerText === undefined) {
        nodes.push(cloneElement(component, { key: `${keyPrefix}${counter++}` }));
      } else {
        // Recursively parse inner text to support nested components.
        const children = parseTranslation(innerText, components, `${keyPrefix}${counter}_`);
        nodes.push(cloneElement(component, { key: `${keyPrefix}${counter++}`, children }));
      }
    } else {
      // If the component isn't provided, leave the tag as plain text.
      nodes.push(<Fragment key={`${keyPrefix}${counter++}`}>{fullMatch}</Fragment>);
    }
    // Update remaining text after this match.
    remaining = remaining.slice(index + fullMatch.length);
  }

  return nodes;
}
