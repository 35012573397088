import type { ChatReplyDto } from "api/types";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { type FormVideo, isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { parseISO } from "date-fns";
import { stripTime } from "helpers/date";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useOnIntersection } from "hooks/useOnIntersection";
import { groupBy, sortBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

import { ChatMessage } from "./ChatMessage";
import { SystemChatMessage } from "./SystemChatMessage";

export type ChatReplyPayload = {
  message: string;
  images: FormImage[];
  videos: FormVideo[];
};

interface ChatWindowProps {
  chatReplies: ChatReplyDto[];
  chatName?: string;
  canChat: boolean;
  hasMoreReplies: boolean | undefined;
  isLoadingMoreReplies: boolean;
  fetchMoreReplies: () => void;
  sendReply: (payload: ChatReplyPayload) => Promise<void>;
}

interface ChatWindowFormValues {
  messageValue: string;
}

const groupChatRepliesByDate = <T extends { sentAt: string }>(chatMessages: T[]): [string, T[]][] => {
  const sortedChatReplies = sortBy(chatMessages, (x) => -parseISO(x.sentAt).valueOf());
  const groupedByDay = Object.entries(groupBy(sortedChatReplies, (x) => stripTime(x.sentAt).valueOf()));

  return sortBy(groupedByDay, ([date]) => -Number(date));
};

export function ChatWindow({
  chatReplies,
  chatName,
  canChat,
  hasMoreReplies,
  isLoadingMoreReplies,
  sendReply,
  fetchMoreReplies,
}: ChatWindowProps): React.ReactNode {
  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);

  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { addImages, removeImage, removeImages } = useImageInput({ selectedImages: images, onChange: setImages });

  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) => {
      setVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          } else {
            return video;
          }
        }),
      );
    },
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: setVideos,
    uploadFn: uploadFormVideo,
  });
  const { getValues, setValue, watch, register, reset } = useForm<ChatWindowFormValues>({
    defaultValues: {
      messageValue: "",
    },
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const hasScrolled = useRef(false);
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreReplies && hasMoreReplies) {
        fetchMoreReplies();
      }
    }, [fetchMoreReplies, hasMoreReplies, isLoadingMoreReplies]),
  });

  useEffect(() => {
    if (chatReplies.length && scrollRef.current && !hasScrolled.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight });
      hasScrolled.current = true;
    }
  }, [chatReplies]);

  useEffect(() => {
    register("messageValue");
  }, [register]);

  const messageValue = watch("messageValue");
  const chatMessagesByDate = useMemo(() => groupChatRepliesByDate(chatReplies), [chatReplies]);

  const onChangeContent = useCallback((value: string) => setValue("messageValue", value), [setValue]);

  const onSendReply = async () => {
    await sendReply({
      message: getValues("messageValue"),
      images,
      videos,
    });

    removeImages();
    removeVideos();
    reset();
  };

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  return (
    <div className="h-[calc(100vh-300px)]">
      <div className="flex h-full flex-col justify-between rounded-lg bg-white">
        {/* chat messages are reversed so scrolling to the top will not move the scrollbar */}
        <div className="flex grow flex-col-reverse gap-4 overflow-y-auto p-5 pb-4" ref={scrollRef}>
          {chatMessagesByDate.map(([date, chatMessages]) => (
            <div key={date.toString()} data-testid="chat-message-date-group">
              <div className="mb-4 flex items-center justify-center whitespace-nowrap">
                <span className="rounded-lg bg-grey-100 p-2 text-caption-bold">
                  <FormattedDate format="date" date={new Date(Number(date)).toISOString()} />
                </span>
              </div>
              <div className="flex flex-col-reverse gap-1">
                {chatMessages.map((chatMessage) =>
                  chatMessage.replyType?.includes("system") ? (
                    <div key={chatMessage.id} className="flex items-center justify-center whitespace-nowrap">
                      <SystemChatMessage
                        chatName={chatName}
                        chatMessage={chatMessage}
                        className="rounded-lg bg-grey-100 p-2"
                      />
                    </div>
                  ) : (
                    <ChatMessage
                      key={chatMessage.id}
                      isLastOfMessageGroup={
                        chatMessages[chatMessages.indexOf(chatMessage) - 1]?.author?.id === chatMessage.author?.id
                      }
                      message={chatMessage}
                    />
                  ),
                )}
              </div>
            </div>
          ))}
          {hasMoreReplies && (
            <div className="p-4" ref={loaderRef}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </div>
        <form className="rounded-b-lg bg-grey-50 px-5 py-4">
          <CommentFieldWithAvatar
            user={sessionUser}
            value={messageValue}
            placeholder={t("page.chat-detail.chat.input.placeholder")}
            onChange={onChangeContent}
            onSubmit={onSendReply}
            allowedAttachments={["image", "video"]}
            images={images}
            onAddImages={addImages}
            onRemoveImage={onRemoveImage}
            videos={videos}
            onAddVideo={addVideos}
            onRemoveVideo={removeVideo}
            tooltips={{
              send: t("page.chat-detail.chat.input-tooltip"),
            }}
            disabled={!sessionUser.chatEnabled || !canChat}
          />
        </form>
      </div>
    </div>
  );
}
