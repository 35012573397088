import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { addDays, addMinutes, parseISO } from "date-fns";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

type TicketReminderModalProps = ModalBaseProps & {
  ticketId: string;
  reminderDate?: string;
  daysToAutoClose: number;
};

interface FormValues {
  reminderDate: Date;
}

export function TicketReminderModal({
  ticketId,
  isOpened,
  onOpenChange,
  reminderDate,
  daysToAutoClose,
}: TicketReminderModalProps): React.ReactNode {
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const detailToken = QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId);
  const createReminder = useMutation({
    mutationFn: (payload: { reminderDate: string }) => api.postAdminTicketsReminderV1(ticketId, payload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO });
      showFlashToast({ type: "success", title: t("page.tickets.details.reminder.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.reminder.error") });
    },
  });

  const deleteReminderMutation = useMutation({
    mutationFn: () => api.deleteAdminTicketsReminderDeleteV1(ticketId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO });
    },
  });

  const isLoading = createReminder.isPending || deleteReminderMutation.isPending;

  const { t } = useTranslation();

  const form = useForm<FormValues>({
    defaultValues: {
      reminderDate: reminderDate ? parseISO(reminderDate) : addDays(new Date(), 1),
    },
  });

  const minScheduled = addMinutes(new Date(), 5);

  const isEdit = !!reminderDate;

  const handleSave = async () => {
    await createReminder.mutateAsync({
      reminderDate: form.getValues("reminderDate").toISOString(),
    });

    onOpenChange(false);
  };

  const handleDelete = async () => {
    await deleteReminderMutation.mutateAsync();

    onOpenChange(false);
  };

  const deleteReminder = deleteReminderMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (reminderDate && parseISO(reminderDate) < new Date()) {
        deleteReminder();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [ticketId, reminderDate, deleteReminder]);

  return (
    <Modal.Root
      title={
        isEdit
          ? t("page.tickets.details.reminder.modal.edit.title")
          : t("page.tickets.details.reminder.modal.create.title")
      }
      description={t("page.tickets.details.reminder.modal.description")}
      size="sm"
      shouldCloseOnClickOutside={false}
      isNested
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col items-center gap-2">
        <Form className="w-full" formMethods={form} onSubmit={handleSave}>
          <FormField label={t("page.tickets.details.reminder.modal.datepicker.name")} required>
            <FormDateAndTimePicker<FormValues>
              name="reminderDate"
              type="datetime"
              min={minScheduled}
              rules={{
                validate: {
                  laterThanMin: (date) => {
                    if (date == null) {
                      return undefined;
                    }

                    return date < minScheduled
                      ? t("page.tickets.details.reminder.modal.datepicker.error.must-be-in-future")
                      : undefined;
                  },
                  earlierThanAutoClose: (date) => {
                    if (date == null || daysToAutoClose == 0) {
                      return undefined;
                    }

                    return date > addDays(new Date(), daysToAutoClose)
                      ? t("page.tickets.details.reminder.modal.datepicker.error.must-be-before-auto-close")
                      : undefined;
                  },
                },
                required: t("components.form.error.required", {
                  inputName: t("page.tickets.details.reminder.modal.datepicker.name"),
                }),
              }}
            />
          </FormField>
          <Modal.Actions>
            {isEdit ? (
              <>
                <Button styling="danger" onClick={handleDelete} disabled={isLoading}>
                  {t("page.tickets.details.reminder.modal.button.delete")}
                </Button>
                <Button styling="primary" type="submit" isLoading={isLoading}>
                  {t("page.tickets.details.reminder.modal.button.edit")}
                </Button>
              </>
            ) : (
              <>
                <Modal.Close>
                  <Button styling="secondary" disabled={isLoading}>
                    {t("common.action.cancel")}
                  </Button>
                </Modal.Close>
                <Button styling="primary" type="submit" isLoading={isLoading}>
                  {t("page.tickets.details.reminder.modal.button.create")}
                </Button>
              </>
            )}
          </Modal.Actions>
        </Form>
      </div>
    </Modal.Root>
  );
}
