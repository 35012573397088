import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Select } from "components/Select/Select";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { LineChart } from "modules/analytics/components/LineChart";
import { NoData } from "modules/analytics/components/NoData";
import { dataColors } from "modules/analytics/theme";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "translations";
import type { NonEmptyArray } from "types/utility-types";

import { Card, CardHeader } from "../../components/Card";

interface Props {
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
}

export function ConversationsStartedCard({ dateRangeOptions }: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t, i18n } = useTranslation();
  const api = useApi();
  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_TICKETS_CONVERSATIONS_STARTED(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsConversationsMonthlyV1({ dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });

  const mappedData = useMemo(
    () =>
      data?.data.map((x) => ({
        x: formatChartDate(x.range),
        y: x.count,
      })) || [],
    [data],
  );

  const noData = !data || data.data.every((x) => x.count === 0);

  return (
    <Card className="h-full">
      <CardHeader
        title={t("page.analytics-overview.chart.conversations-started.title")}
        tooltip={t("page.analytics-overview.chart.conversations-started.title-tooltip")}
      >
        <Select
          items={dateRangeOptions}
          selected={dateRange}
          onChange={setDateRange}
          renderOption={(x) => x.name}
          keySelector={(x) => x.value}
        />
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <LineChart
          data={{ length: mappedData.length, data: [{ dataPoints: mappedData, color: dataColors.primary }] }}
          xLabel={t("page.analytics-overview.chart.conversations-started.x-axis")}
          yLabel={t("page.analytics-overview.chart.conversations-started.y-axis")}
          minTopY={5}
          renderLabel={({ x, y }) => (
            <>
              <span>{formatDate(i18n, "monthYear", x)}</span>
              <br />
              <span>
                {t("page.analytics-overview.chart.conversations-started.datapoint-label", {
                  amount: y,
                })}
              </span>
            </>
          )}
        />
      )}
    </Card>
  );
}
