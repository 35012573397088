import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatReplyRequest, RemoveMemberRequest, UpdateGroupChatRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";
import type { ApiResponseType } from "types/api-types";

const useAddChat = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId }: { userId: string }) => api.postChatsPrivateV2({ userId }).then((x) => x.data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_CHAT_DETAILS(projectId, payload.userId) });
    },
    onError: () => {
      console.error("Error creating private chat!");
    },
  });
};

const useDeleteChat = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ chatId }: { chatId: string }) => api.deleteChatsByIdV2(chatId),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("page.chats.delete-chat.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });

      queryClient.removeQueries({
        queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, payload.chatId),
      });
    },
  });
};

const useAddChatReply = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ chatId, data }: { chatId: string; data: ChatReplyRequest }) =>
      api.postChatsReplyV2(chatId, data).then((x) => x.data),
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, payload.chatId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.user-detail.chat.send-error") });
    },
  });
};

const useLeaveGroupChat = ({ onSuccess }: { onSuccess?: () => void }) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ chatId }: { chatId: string }) => api.postChatsMembersLeaveV2(chatId),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("component.group-chat-leave-modal.success") });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, payload.chatId) });

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-leave-modal.error"),
      });
    },
  });
};

const useUpdateGroupChat = ({ chatId, onSuccess }: { chatId: string; onSuccess?: () => void }) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateGroupChatRequest) => api.putChatsGroupV2(chatId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_DETAILS(projectId, chatId) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

const useRemoveGroupChatMember = ({ chatId, onSuccess }: { chatId: string; onSuccess?: () => void }) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (payload: RemoveMemberRequest) => api.putChatsMembersRemoveV2(chatId, payload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatId) });

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-remove-user-modal.error"),
      });
    },
  });
};

const useUpdateChatState = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ state }: { state: boolean }) => api.postChatsStateV1({ hideChat: !state }).then((x) => x.data),
    onMutate: (payload) => {
      queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(QUERY_KEYS.SELF(projectId), (oldResponse) => {
        if (oldResponse) {
          return {
            ...oldResponse,
            data: {
              ...oldResponse.data,
              chatEnabled: payload.state,
            },
          };
        }
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chats.toggle-chats.error") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });
};

export const chatMutations = {
  useAddChat,
  useDeleteChat,
  useAddChatReply,
  useUpdateGroupChat,
  useUpdateChatState,
  useLeaveGroupChat,
  useRemoveGroupChatMember,
};
