import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityGroupDetailsV2Dto } from "api/types";
import iconUsers01 from "assets/icons/users-01.svg";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { SearchInput } from "components/SearchInput/SearchInput";
import { TagList } from "components/Tag/TagList";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { round } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { Link } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import { Trans, useTranslation } from "translations";

import { canViewGroupMembers } from "../permissions";

const DEBOUNCE_WAIT = 200;
const AVAILABLE_USERS_PAGE = 10;
const MIN_SEARCH_CHARACTERS = 2;

type GroupMembersModalProps = ModalBaseProps & {
  group: CommunityGroupDetailsV2Dto;
};

export function GroupMembersModal({ group, isOpened, onOpenChange }: GroupMembersModalProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDeferredValue(
    useDebounce(query.trim().length < MIN_SEARCH_CHARACTERS ? "" : query, DEBOUNCE_WAIT),
  );
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();
  const {
    data: availableUsersData,
    hasNextPage: hasMoreUsers,
    fetchNextPage: fetchMoreUsers,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isFetchingMoreUsers,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_MEMBERS(projectId, group.id, debouncedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getGroupsMembersV1(group.id, {
          search: debouncedQuery,
          Offset: pageParam * AVAILABLE_USERS_PAGE,
          Limit: AVAILABLE_USERS_PAGE,
        })
        .then(commonAPIDataSelector),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: isOpened,
    staleTime: 30 * 1000, // 30 seconds
  });
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreUsers && hasMoreUsers) {
        void fetchMoreUsers();
      }
    }, [fetchMoreUsers, hasMoreUsers, isFetchingMoreUsers]),
  });

  const availableUsers = useMemo(() => availableUsersData?.pages.flatMap((x) => x.items) ?? [], [availableUsersData]);

  return (
    <Modal.Root title={t("page.group-details.members-modal.title")} {...{ isOpened, onOpenChange }}>
      {sessionUser.isAdmin && (
        <div className="flex flex-col gap-2">
          {hasPermission(canViewGroupMembers) &&
            !group.disabled &&
            group.joinedResidents != null &&
            group.totalResidentsAudience != null && (
              <span className="text-caption text-grey-700">
                <Trans
                  i18nKey={
                    group.type === "helpCategory"
                      ? "model.help-category.audience.details"
                      : "model.interest-group.audience.details"
                  }
                  values={{
                    percentage:
                      group.totalResidentsAudience !== 0
                        ? round((group.joinedResidents / group.totalResidentsAudience) * 100, 1)
                        : 0,
                    current: group.joinedResidents,
                    total: group.totalResidentsAudience,
                  }}
                  components={{
                    bold: <strong className="font-old-semibold" />,
                  }}
                />
              </span>
            )}
          <div className={twJoin("flex items-center gap-2 text-grey-700", group.disabled && "text-grey-400")}>
            <Icon name={iconUsers01} size={16} />
            {group.audience?.length ? (
              <TagList tags={group.audience?.map(({ key, ...item }) => ({ content: key, ...item }))} limit={3} />
            ) : (
              <i>
                {group.type === "helpCategory"
                  ? t("model.help-category.audience.placeholder")
                  : t("model.interest-group.audience.placeholder")}
              </i>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-3" data-testid="group-members-modal">
        <SearchInput
          data-testid="search-input"
          onChange={(e) => setQuery(e.target.value.trim())}
          placeholder={t("page.group-details.members-modal.search-placeholder")}
        />
        <div className="max-h-60 overflow-y-scroll rounded-md">
          {
            // eslint-disable-next-line no-nested-ternary
            isLoadingUsers ? (
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            ) : availableUsers.length === 0 ? (
              <p className="px-3 pb-5 pt-1 text-caption text-grey-600">
                {t("page.group-details.members-modal.no-results")}
              </p>
            ) : (
              <ul>
                {availableUsers.map((availableUser) => (
                  <li key={availableUser.id} data-testid="available-user">
                    <Link to={routes.users.details({ slug, id: availableUser.id })}>
                      <div className="border-b border-b-grey-100 p-3 hover:shadow-lg">
                        <div className="flex grow items-center gap-3">
                          <div className="size-10 shrink-0">
                            <UserAvatar img={availableUser?.avatar} />
                          </div>
                          <div className="text-caption">
                            <span className="font-old-semibold text-black">{availableUser.fullName}</span>
                            <p className="text-grey-500">
                              <span>{availableUser.locatedAt}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            )
          }
          {hasMoreUsers && (
            <div className="p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </div>
      </div>
    </Modal.Root>
  );
}
