import { Menu as ArkMenu, Portal as ArkPortal } from "@ark-ui/react";
import iconDotsVertical from "assets/icons/dots-vertical.svg";
import { IconButton } from "components/Button/IconButton";
import type React from "react";
import { useTranslation } from "translations";

import { ContextMenuContent } from "./ContextMenuContent";
import { ContextMenuListItem } from "./ContextMenuListItem";
import { ContextMenuRoot } from "./ContextMenuRoot";

type ContextMenuItemLabels = {
  default: string;
  disabled?: string;
};

export type ContextMenuItem = {
  labels: ContextMenuItemLabels;
  value: string;
  icon?: string;
  "data-testid"?: string;
  isHidden?: boolean;
  subItems?: ContextMenuItem[];
  isDisabled?: boolean;
} & (
  | {
      type?: "button";
      href?: never;
      callbackFn: () => void;
      subItems?: never;
    }
  | {
      type: "link";
      href: string;
      callbackFn?: never;
      subItems?: never;
    }
  | {
      type: "trigger";
      href?: never;
      callbackFn?: never;
      subItems: ContextMenuItem[];
    }
);

type ContextMenuProps = {
  items: ContextMenuItem[];
  triggerComponent?: React.ReactNode;
};

export function ContextMenu({ items, triggerComponent }: ContextMenuProps): React.ReactNode {
  const { t } = useTranslation();

  const filterItems = (currItems: ContextMenuItem[]): ContextMenuItem[] => {
    return currItems.filter((item) => {
      if (item.subItems) {
        item.subItems = filterItems(item.subItems);
      }

      const hasAnyVisibleSubItem = item.subItems?.some((subItem) => !subItem.isHidden);

      // Hide item if it is hidden or has no visible sub-items
      return !item.isHidden && (!item.subItems || hasAnyVisibleSubItem);
    });
  };

  const filteredItems: ContextMenuItem[] = filterItems(items);

  if (filteredItems.length === 0) {
    return null;
  }

  return (
    <ContextMenuRoot>
      <ArkMenu.Trigger
        onClick={(e) => {
          e.stopPropagation();
        }}
        asChild
      >
        {triggerComponent || (
          <IconButton
            data-testid="context-menu-trigger-btn"
            styling="ghostSecondary"
            title={t("component.context-menu.action.open")}
            withTooltip={false}
            icon={iconDotsVertical}
          />
        )}
      </ArkMenu.Trigger>
      <ArkMenu.Positioner className="!z-50">
        <ContextMenuContent data-testid="context-menu">
          {filteredItems.map((item) => {
            // Nested menu
            if (item.subItems && item.subItems.length > 0) {
              return (
                <ContextMenuRoot key={item.value}>
                  <ContextMenuListItem {...{ item }} />
                  <ArkPortal>
                    <ArkMenu.Positioner className="!z-50">
                      <ContextMenuContent>
                        {item.subItems.map((subItem) => (
                          <ContextMenuListItem key={subItem.value} item={subItem} />
                        ))}
                      </ContextMenuContent>
                    </ArkMenu.Positioner>
                  </ArkPortal>
                </ContextMenuRoot>
              );
            }

            // Regular menu item
            return <ContextMenuListItem {...{ item }} key={item.value} />;
          })}
        </ContextMenuContent>
      </ArkMenu.Positioner>
    </ContextMenuRoot>
  );
}
