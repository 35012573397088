import type { ComponentProps } from "react";
import { lazy, Suspense } from "react";

const InnerRichTextEditor = lazy(() => import("./RichTextEditor"));

export function LazyRichTextEditor(props: ComponentProps<typeof InnerRichTextEditor>): React.ReactNode {
  return (
    <Suspense fallback={null}>
      <InnerRichTextEditor {...props} />
    </Suspense>
  );
}
