import type { FastMenuActionItemTranslationRequest, LanguageDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { getFlagIcon } from "helpers/flags";
import type { FormTranslations } from "helpers/languages";
import { createRequiredStringRule } from "helpers/rules";
import { isValidHttpUrl } from "helpers/validation";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

type CustomUrlModalProps = ModalBaseProps & {
  isLoading: boolean;
  onSubmit: (
    id: string | undefined,
    translations: FastMenuActionItemTranslationRequest[],
    url: string,
  ) => Promise<void>;
  languageIds: LanguageDto["id"][];
  defaultValues?: FormValues;
};

interface FormValues {
  id: string;
  urlTranslations: FormTranslations;
  url: string;
}

export function CustomUrlModal({
  isOpened,
  isLoading,
  onOpenChange,
  onSubmit,
  languageIds,
  defaultValues,
}: CustomUrlModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({ defaultValues });

  async function handleSubmit() {
    const formValues = form.getValues();
    form.reset();
    await onSubmit(
      formValues.id,
      languageIds.map((lng) => ({ languageId: lng, label: formValues.urlTranslations[lng] })),
      formValues.url,
    );

    onOpenChange(false);
  }

  return (
    <Modal.Root size="sm" {...{ isOpened, onOpenChange }}>
      <Form formMethods={form} onSubmit={handleSubmit}>
        <FormHiddenInput<FormValues> name="id" />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2 className="mr-4 text-body-bold">{t("page.plus-button-links.custom-url.modal.translations.title")}</h2>
            <div className="flex w-full flex-col gap-4">
              {languageIds.map((lng) => {
                return (
                  <FormField key={lng} htmlFor={`url-translations-${lng}`} label="" hideLabel required>
                    <FormInput<FormValues>
                      name={`urlTranslations.${lng}`}
                      prefix={getFlagIcon({ languageId: lng })}
                      placeholder={t("page.plus-button-links.custom-url.modal.translations.input-placeholder", {
                        language: lng.toUpperCase(),
                      })}
                      id={`url-translations-${lng}`}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(
                            t,
                            "page.plus-button-links.custom-url.modal.translations.input-name",
                          ),
                        },
                      }}
                    />
                  </FormField>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-body-bold">{t("page.plus-button-links.custom-url.modal.url.title")}</h2>
            <FormField htmlFor="url" label="" hideLabel required>
              <FormInput<FormValues, "url">
                name="url"
                id="url"
                placeholder={t("page.plus-button-links.custom-url.modal.url.input-placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.plus-button-links.custom-url.modal.url.input-name"),
                    urlFormat: (value) => {
                      const isValid = isValidHttpUrl(value);

                      if (!isValid) {
                        return t("page.plus-button-links.maintainer.modal.url.input-error");
                      }
                    },
                  },
                }}
              />
            </FormField>
          </div>
        </div>
        <Modal.Actions>
          <Button type="submit" styling="primary" isLoading={isLoading}>
            {t("common.action.save")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
