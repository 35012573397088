import type { CopilotPromptConfigurationDto } from "api/types";
import iconPlus from "assets/icons/plus.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ContextMenu_LEGACY } from "components/ContextMenu_LEGACY/ContextMenu_LEGACY";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormRadio, FormRadioGroup } from "components/Form/FormRadio";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import type { TranslationFunction } from "translations";
import { useTranslation } from "translations";

const availableConfigurationTypes = ["community", "ticket"] as const;
const availableModels = ["gpt35", "gpt4", "gpt4O"] as const;
const modelNames = {
  gpt35: "GPT-3.5",
  gpt4: "GPT-4",
  gpt4O: "GPT-4o",
} as const;

export function CopilotConfigurationsForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<CopilotConfigurationsFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: CopilotConfigurationsFormValues) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<CopilotConfigurationsFormValues>({ defaultValues });

  const configurations = useWatch({ control: formMethods.control, name: "configurations" });

  const unusedConfigurations = availableConfigurationTypes.filter(
    (type) => !configurations.find((config) => config.type === type),
  );

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        {configurations.length > 0 ? (
          <div className="flex flex-col gap-4">
            {configurations.map((config, index) => (
              <div key={config.type} className="flex flex-col gap-2 rounded-lg border border-grey-100 p-4">
                <div className="flex flex-row justify-between">
                  <p className="font-old-semibold uppercase text-grey-500">{getPromptTypeName(t, config.type)}</p>
                  <IconButton
                    styling="tertiary"
                    title={t("page.integration.copilot-configurations.remove-configuration")}
                    onClick={() =>
                      formMethods.setValue(
                        "configurations",
                        configurations.filter((_, i) => i !== index),
                      )
                    }
                    icon={iconX}
                  />
                </div>
                <FormContent>
                  <FormField
                    label={t("page.integration.copilot-configurations.prompt")}
                    htmlFor={`configurations.${index}.prompt`}
                    required
                  >
                    <FormTextArea<CopilotConfigurationsFormValues, `configurations.${number}.prompt`>
                      id={`configurations.${index}.prompt`}
                      name={`configurations.${index}.prompt`}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.integration.copilot-configurations.prompt"),
                        },
                      }}
                      placeholder={t("page.integration.copilot-configurations.prompt.placeholder")}
                    />
                  </FormField>

                  <FormField
                    label={t("page.integration.copilot-configurations.model")}
                    htmlFor={`configurations.${index}.model`}
                  >
                    <FormRadioGroup>
                      {availableModels.map((model) => (
                        <FormRadio<CopilotConfigurationsFormValues, `configurations.${number}.model`>
                          key={model}
                          name={`configurations.${index}.model`}
                          value={model}
                          displayValue={modelNames[model]}
                        />
                      ))}
                    </FormRadioGroup>
                  </FormField>
                </FormContent>
              </div>
            ))}
          </div>
        ) : null}

        {unusedConfigurations.length > 0 ? (
          <div className="flex items-start">
            <ContextMenu_LEGACY
              actions={unusedConfigurations.map((x) => ({
                callback: () =>
                  formMethods.setValue("configurations", [...configurations, { type: x, prompt: "", model: "gpt35" }]),
                text: getPromptTypeName(t, x),
              }))}
            >
              {(props) => (
                <Button
                  styling="secondary"
                  isPressed={props.isOpen}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.openHandlers.toggle();
                  }}
                >
                  <span className="flex items-center gap-1">
                    <Icon name={iconPlus} size={16} />
                    {t("page.integration.copilot-configurations.add-configuration")}
                  </span>
                </Button>
              )}
            </ContextMenu_LEGACY>
          </div>
        ) : null}

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.copilot-configurations.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}

interface CopilotConfigurationsFormValues {
  configurations: CopilotPromptConfigurationDto[];
}

function getPromptTypeName(t: TranslationFunction, type: CopilotPromptConfigurationDto["type"]) {
  switch (type) {
    case "community":
      return t("page.integration.copilot-configurations.prompt-type.community");
    case "ticket":
      return t("page.integration.copilot-configurations.prompt-type.ticket");
    default:
      return type;
  }
}
