import { useEffect } from "react";
import { useTranslation } from "translations";

import { useProjectId } from "./Network/useProjectId";
import { useSessionUserInitial } from "./Network/useSessionUserInitial";

export const useSetUserLanguage = (isEnabled = true): undefined => {
  const projectId = useProjectId();
  const { data: sessionUser } = useSessionUserInitial(projectId);
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (sessionUser) {
      changeLanguage(sessionUser.language.id);
    }
  }, [isEnabled, sessionUser, changeLanguage]);
};
