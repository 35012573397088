import type { AddressDto, BuildingDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormInputAutocomplete } from "components/Form/FormInputAutocomplete";
import { FormSelect } from "components/Form/FormSelect";
import { prepareSuggestions } from "components/InputAutocomplete/InputAutocomplete";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createRequiredStringRule } from "helpers/rules";
import { isDefined } from "helpers/util";
import { isNumerical } from "helpers/validation";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageBuildings } from "modules/buildings/permissions";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { routes } from "routes";
import { Trans, useTranslation } from "translations";

export interface LayoutProps {
  isEditMode: boolean;
  addressId?: string;
  defaultValues?: Partial<FormValues>;
  isSubmitting: boolean;
  addresses: AddressDto[];
  buildings: BuildingDto[];
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  isEditMode,
  defaultValues,
  addressId,
  isSubmitting,
  buildings,
  addresses,
  onSubmit,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const formMethods = useForm<FormValues>({ defaultValues });
  const { t } = useTranslation();
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.addresses.edit.title") : t("page.addresses.create.title")}
    >
      {buildings.length === 0 ? (
        <p className="max-w-prose">
          {hasPermission(canManageBuildings) ? (
            <Trans
              i18nKey="page.addresses.create.error.no-building"
              components={{ "0": <Anchor key={0} to={routes.buildings.list({ slug })} /> }}
            />
          ) : (
            <Trans i18nKey="page.addresses.create.error.no-building-and-no-permission" />
          )}
        </p>
      ) : (
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <CreateEditForm
            isEditMode={isEditMode}
            addressId={addressId}
            isSubmitting={isSubmitting}
            buildings={buildings}
            addresses={addresses}
          />
        </Form>
      )}
    </DocumentPaper>
  );
}

function CreateEditForm({
  isEditMode,
  addressId,
  isSubmitting,
  buildings,
  addresses,
}: {
  isEditMode: LayoutProps["isEditMode"];
  isSubmitting: LayoutProps["isSubmitting"];
  addressId?: LayoutProps["addressId"];
  buildings: LayoutProps["buildings"];
  addresses: LayoutProps["addresses"];
}) {
  const { t } = useTranslation();

  const otherAddresses = useMemo(
    () => (addressId ? addresses.filter((x) => x.id !== addressId) : addresses),
    [addressId, addresses],
  );
  const citySuggestions = useMemo(() => prepareSuggestions(otherAddresses.map((x) => x.city)), [otherAddresses]);
  const streetnameSuggestions = useMemo(
    () => prepareSuggestions(otherAddresses.map((x) => x.streetName)),
    [otherAddresses],
  );
  const postalCodeSuggestions = useMemo(
    () => prepareSuggestions(otherAddresses.map((x) => x.postalCode)),
    [otherAddresses],
  );
  const floorSuggestions = useMemo(
    () => prepareSuggestions(otherAddresses.map((x) => x.floor).filter(isDefined)),
    [otherAddresses],
  );
  const typeSuggestions = useMemo(
    () => prepareSuggestions(otherAddresses.map((x) => x.typeConstraint?.key).filter(isDefined)),
    [otherAddresses],
  );

  return (
    <FormContent>
      <FormField label={t("page.addresses.form.building")} required htmlFor="building">
        <FormSelect<FormValues, BuildingDto>
          data-testid="building-select"
          id="building"
          name="building"
          items={buildings}
          keySelector={(x) => x.id}
          renderOption={(x) => x.name}
          placeholder={t("page.address.form.building.placeholder")}
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.addresses.form.building"),
            }),
          }}
        />
      </FormField>
      <FormField label={t("page.addresses.form.city")} required htmlFor="name">
        <FormInputAutocomplete<FormValues>
          data-testid="city-input"
          id="city"
          name="city"
          suggestions={citySuggestions}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.addresses.form.city"),
            },
          }}
        />
      </FormField>
      <FormField label={t("page.addresses.form.postal-code")} required htmlFor="postalCode">
        <div className="max-w-[150px]">
          <FormInputAutocomplete<FormValues>
            data-testid="postal-code-input"
            id="postalCode"
            name="postalCode"
            suggestions={postalCodeSuggestions}
            rules={{
              validate: {
                required: createRequiredStringRule(t, "page.addresses.form.postal-code"),
              },
            }}
          />
        </div>
      </FormField>
      <FormField label={t("page.addresses.form.street-name")} required htmlFor="streetName">
        <FormInputAutocomplete<FormValues>
          data-testid="street-name-input"
          id="streetName"
          name="streetName"
          suggestions={streetnameSuggestions}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.addresses.form.street-name"),
            },
          }}
        />
      </FormField>
      <FormSplitContent>
        <div className="max-w-[150px]">
          <FormField label={t("page.addresses.form.house-number")} required htmlFor="housenumber">
            <FormInput<FormValues, "housenumber">
              data-testid="house-number-input"
              id="housenumber"
              name="housenumber"
              // Not type="number" because old addresses can have non numerical house numbers, and we should still show that
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.addresses.form.house-number"),
                  isNumber(value: string) {
                    if (!isNumerical(value)) {
                      return t("components.form.error.is-not-number", {
                        inputName: t("page.addresses.form.house-number"),
                      });
                    }
                  },
                },
              }}
            />
          </FormField>
        </div>

        <FormField label={t("page.addresses.form.house-number-suffix")} htmlFor="housenumberSuffix">
          <div className="max-w-[50px]">
            <FormInput<FormValues> data-testid="house-suffix-input" id="housenumberSuffix" name="housenumberSuffix" />
          </div>
        </FormField>
      </FormSplitContent>
      <FormField label={t("page.addresses.form.floor")} htmlFor="floor">
        <div className="max-w-[150px]">
          <FormInputAutocomplete<FormValues>
            data-testid="address-floor-input"
            id="floor"
            name="floor"
            suggestions={floorSuggestions}
          />
        </div>
      </FormField>
      <FormField label={t("page.addresses.form.type")} htmlFor="name">
        <div className="max-w-[150px]">
          <FormInputAutocomplete<FormValues>
            data-testid="address-type-input"
            id="type"
            name="type"
            suggestions={typeSuggestions}
          />
        </div>
      </FormField>
      <Button type="submit" isLoading={isSubmitting}>
        {isEditMode ? t("page.addresses.edit.submit") : t("page.addresses.create.submit")}
      </Button>
    </FormContent>
  );
}

interface FormValues {
  building: BuildingDto;
  streetName: string;
  housenumber: string;
  housenumberSuffix?: string;
  postalCode: string;
  city: string;
  floor?: string;
  type?: string;
}
