import type { ProjectOverviewItemDto } from "api/types";
import { isDefined } from "helpers/util";

export function optionalNumberSort(a: undefined | number, b: undefined | number): number {
  if (!isDefined(b)) {
    return 1;
  }

  if (!isDefined(a)) {
    return -1;
  }

  return a - b;
}

interface Stat {
  value: number | undefined;
  changePercentage: number | undefined;
}

interface Stats {
  feelingAtHomeRating: Stat;
  averageTicketRating: Stat;
  engagedResidentPercentage: Stat;
  adoptionPercentage: Stat;
}

export function calculateStats(project: ProjectOverviewItemDto): Stats {
  return {
    feelingAtHomeRating: {
      value: project.stats?.feelingAtHomeAverageRatingAllTime,
      changePercentage:
        project.stats?.feelingAtHomeAverageRatingAllTime && project.previousStats?.feelingAtHomeAverageRatingAllTime
          ? Math.round(
              ((project.stats.feelingAtHomeAverageRatingAllTime -
                project.previousStats.feelingAtHomeAverageRatingAllTime) /
                project.stats.feelingAtHomeAverageRatingAllTime) *
                100,
            )
          : undefined,
    },
    averageTicketRating: {
      value: project.stats?.averageTicketRating,
      changePercentage:
        project.stats?.averageTicketRating && project.previousStats?.averageTicketRating
          ? Math.round(
              ((project.stats.averageTicketRating - project.previousStats.averageTicketRating) /
                project.stats.averageTicketRating) *
                100,
            )
          : undefined,
    },
    engagedResidentPercentage: {
      value: project.stats?.engagedResidentPercentage,
      changePercentage:
        project.stats?.engagedResidentPercentage && project.previousStats?.engagedResidentPercentage
          ? Math.round(project.stats.engagedResidentPercentage - project.previousStats.engagedResidentPercentage)
          : undefined,
    },
    adoptionPercentage: {
      value: project.stats?.adoptionPercentage,
      changePercentage:
        project.stats?.adoptionPercentage && project.previousStats?.adoptionPercentage
          ? Math.round(project.stats.adoptionPercentage - project.previousStats.adoptionPercentage)
          : undefined,
    },
  };
}

export const getPortfolioFilterOptions = (
  projects: ProjectOverviewItemDto[],
): {
  owners: string[];
  maintainers: string[];
  cities: string[];
} => {
  const listProjectOwners: string[] = [];
  const listProjectMaintainers: string[] = [];
  const listProjectCities: string[] = [];

  projects.forEach((project) => {
    listProjectOwners.push(project.owner);
    listProjectMaintainers.push(project.maintenance);
    listProjectCities.push(project.city);
  });

  return {
    owners: Array.from(new Set(listProjectOwners)).sort((ownerA, ownerB) => ownerA.localeCompare(ownerB)),
    maintainers: Array.from(new Set(listProjectMaintainers)).sort((maintenanceA, maintenanceB) =>
      maintenanceA.localeCompare(maintenanceB),
    ),
    cities: Array.from(new Set(listProjectCities)).sort((cityA, cityB) => cityA.localeCompare(cityB)),
  };
};
