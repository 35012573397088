import { ColorPicker } from "components/ColorPicker/ColorPicker";
import { isDefined } from "helpers/util";
import { isValidColor } from "helpers/validation";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "translations";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface Props<TPath> {
  name: TPath;
  id?: string;
  "data-testid"?: string;
  disabled?: boolean;
}

export function FormColorPicker<
  TFormValues extends FieldValues,
  TPath extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ name, ...props }: Props<TPath>): React.ReactNode {
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    rules: {
      validate: {
        isValidColor(value) {
          if (!value) {
            return;
          }

          if (!isValidColor(value)) {
            return t("component.color-picker.invalid-color");
          }
        },
      },
    },
  });

  return (
    <FormErrorWrapper name={name}>
      <ColorPicker
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        isError={isDefined(error)}
        readOnly={props.disabled}
        {...props}
      />
    </FormErrorWrapper>
  );
}
