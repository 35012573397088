import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { parseErrorMessage } from "helpers/Network/errors";
import { useTranslation } from "translations";

import { CANCEL_TOKENS } from "./helpers";

type UploadImageRequest = {
  file: File;
  description?: string;
};

const useAddImage = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ file, description }: UploadImageRequest) => {
      const payload: { File: File; Description?: string } = { File: file };
      if (description) {
        payload.Description = description;
      }

      return api.postFilesImagesV1(payload).then(({ data }) => data);
    },
    onError: (error) => {
      showFlashToast({
        type: "error",
        title: t("model.image.action.upload.error"),
        description: parseErrorMessage(error),
      });
    },
  });
};

type UploadVideoRequest = {
  file: File;
};

const useAddVideo = ({
  onProgress,
  shouldProcess,
}: {
  onProgress?: ({ name, progress }: { name: string; progress: number }) => void;
  shouldProcess?: boolean;
}) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    onMutate: ({ file }) => {
      if (onProgress) {
        onProgress({
          name: file.name,
          progress: 0,
        });
      }
    },
    mutationFn: async ({ file }: UploadVideoRequest) => {
      const cancelToken = CANCEL_TOKENS.UPLOAD_VIDEO(file.name);
      const chunkSize = 1024 * 1024 * 5; // 5 MB
      const amountChunks = Math.ceil(file.size / chunkSize);

      const start = await api.postFilesVideosChunkInitiateV1(
        {
          fileName: file.name,
          mimeType: file.type,
          totalChunks: amountChunks,
          skipProcessing: !shouldProcess,
        },
        { cancelToken },
      );
      const uploadId = start.data.uploadId;

      const uploadChunk = async (chunk: Blob, chunkIndex: number) => {
        return api.postFilesVideosChunkV1(
          uploadId,
          chunkIndex,
          // @ts-expect-error: Auto schema generation can't generate correct body type
          { File: chunk },
          {
            cancelToken,
            type: "multipart/form-data",
          },
        );
      };

      for (let i = 0; i < amountChunks; i++) {
        const startByte = i * chunkSize;
        const endByte = Math.min((i + 1) * chunkSize, file.size);
        const chunk = file.slice(startByte, endByte);
        await uploadChunk(chunk, i);

        if (onProgress) {
          onProgress({
            name: file.name,
            progress: Math.min(((i + 1) / amountChunks) * 100, 100),
          });
        }
      }

      return api.postFilesVideosChunkCompleteV1(uploadId).then((res) => res.data);
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.video.action.upload.error"),
      });
    },
  });
};

export type UploadDocumentRequest = {
  file: File;
};

const useAddDocument = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ file }: UploadDocumentRequest) => {
      const payload = { File: file };

      return api.postFilesDocumentsV1(payload).then(({ data }) => data);
    },

    onError: (error) => {
      showFlashToast({
        type: "error",
        title: t("model.document.action.upload.error"),
        description: parseErrorMessage(error),
      });
    },
  });
};

export const fileMutations = {
  useAddImage,
  useAddVideo,
  useAddDocument,
};
