import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { addMonths, differenceInMonths } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

const RANGE = "lastYear";

export function BucketLeadTimeCard(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const minDate = useMemo(() => addMonths(new Date(), -12), []);
  const maxDate = useMemo(() => addMonths(new Date(), -1), []);
  const [date, setDate] = useState(maxDate);
  const [monthIndex, setMonthIndex] = useState(0);
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_TICKETS_BUCKETS_LEAD_TIME(projectId, RANGE),
    queryFn: () => api.getAnalyticsBucketTicketLeadTimeMonthlyV1({ dateRange: RANGE }),
    select: (response) => [...commonAPIDataSelector(response).data].reverse(),
  });

  useEffect(() => {
    setMonthIndex(differenceInMonths(maxDate, date));
  }, [date, maxDate]);

  useEffect(() => {
    if (data && monthIndex >= data.length) {
      setMonthIndex(0);
    }
  }, [data, monthIndex]);

  const currentMonth = data?.[monthIndex];

  const noData =
    !currentMonth ||
    !data ||
    data.every((x) => x.bucket1Percentage === 0 && x.bucket2Percentage === 0 && x.bucket3Percentage === 0);

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.lead-time-benchmark")}
        tooltip={t("page.analytics-overview.chart.lead-time-benchmark.tooltip")}
      >
        <div className="visible lg:invisible">
          <MonthPicker value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} placement="left" />
        </div>
        <div className="invisible lg:visible">
          <MonthPicker value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} placement="right" />
        </div>
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <div className="flex flex-col gap-2 sm:flex-row">
          <Bucket
            header={t("common.date-range.days-or-less", { maxDays: currentMonth.bucket1MaxDays })}
            footer={t("page.analytics-overview.chart.lead-time-benchmark.tier-top.description", {
              percentage: currentMonth.bucket1TierPercentage,
            })}
            tooltip={t("page.analytics-overview.chart.lead-time-benchmark.tier-top.tooltip", {
              maxDays: currentMonth.bucket1MaxDays,
            })}
            percentage={currentMonth.bucket1Percentage}
            type="good"
          />
          <Bucket
            header={t("common.date-range.days", {
              minDays: currentMonth.bucket1MaxDays,
              maxDays: currentMonth.bucket2MaxDays,
            })}
            footer={t("page.analytics-overview.chart.lead-time-benchmark.tier-mid.description", {
              percentage: currentMonth.bucket2TierPercentage,
            })}
            tooltip={t("page.analytics-overview.chart.lead-time-benchmark.tier-mid.tooltip", {
              minDays: currentMonth.bucket1MaxDays,
              maxDays: currentMonth.bucket2MaxDays,
            })}
            percentage={currentMonth.bucket2Percentage}
            type="average"
          />
          <Bucket
            header={t("common.date-range.days-or-more", { minDays: currentMonth.bucket2MaxDays })}
            footer={t("page.analytics-overview.chart.lead-time-benchmark.tier-bottom.description", {
              percentage: currentMonth.bucket3TierPercentage,
            })}
            tooltip={t("page.analytics-overview.chart.lead-time-benchmark.tier-bottom.tooltip", {
              minDays: currentMonth.bucket2MaxDays,
            })}
            percentage={currentMonth.bucket3Percentage}
            type="bad"
          />
        </div>
      )}
    </Card>
  );
}

interface BucketProps {
  header: string;
  percentage: number;
  footer: string;
  tooltip: string;
  type: "good" | "average" | "bad";
}

function Bucket({ header, percentage, tooltip, footer, type }: BucketProps) {
  return (
    <div className="flex flex-col rounded-lg border border-grey-100 px-3 py-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <span
            className={twJoin(
              "size-1.5 rounded-full",
              // eslint-disable-next-line no-nested-ternary
              type === "good" ? "bg-green-500" : type === "average" ? "bg-yellow-500" : "bg-red-500",
            )}
            aria-hidden
          />
          <span className="text-overline-bold text-grey-600">{header}</span>
        </div>
        {!!tooltip && <InfoIcon tooltip={tooltip} grayscale />}
      </div>
      <span className="mt-3 text-headline3 leading-old-headline4">{Math.floor(percentage)}%</span>
      <span className="text-overline text-grey-700">{footer}</span>
    </div>
  );
}
