import iconStar01 from "assets/icons/star-01.svg";
import { Icon } from "components/Icon/Icon";
import { motion, useAnimationControls } from "motion/react";

export interface StarInputProps {
  value: number | undefined;
  onChange: (stars: number) => void;
}

const animations = {
  in: {
    opacity: 1,
    scale: 1.25,
  },
  out: {
    opacity: 0,
    scale: 0.75,
  },
  none: {},
};

export function StarInput({ value, onChange }: StarInputProps): React.ReactNode {
  const controls = useAnimationControls();

  return (
    <span className="group flex gap-1">
      {Array(5)
        .fill(undefined)
        .map((_, i) => (
          <button
            className="relative scale-100 text-transparent transition-all active:scale-110 can-hover:hover:scale-110"
            key={i}
            type="button"
            onClick={() => {
              onChange(i + 1);
            }}
            onPointerDown={() => {
              void controls.start((item) => (i === item ? animations.in : animations.none));
            }}
            onPointerUp={() => {
              // for dragging stars
              onChange(i + 1);
              void controls.start((item) => (i === item ? animations.out : animations.none));
            }}
            onMouseOut={() => {
              void controls.start((item) => (i === item ? animations.out : animations.none));
            }}
          >
            <Icon
              name={iconStar01}
              size={48}
              className={value != null && value > i ? "fill-yellow-500" : "fill-grey-300"}
            />
            <motion.span
              className="absolute inset-0 -z-10 scale-75 rounded-full bg-yellow-200 opacity-0"
              animate={controls}
              custom={i}
            />
          </button>
        ))}
    </span>
  );
}
