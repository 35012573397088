import type { UserRoleDetailsDto } from "api/types";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { List } from "components/List/List";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { User } from "components/User/User";
import { RoleDetails } from "modules/roles/components/Details";
import { useTranslation } from "translations";

export interface LayoutProps {
  roleDetails: UserRoleDetailsDto;
  deleteRole: (id: string) => Promise<unknown>;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const { componentProps, openDeleteModal } = useDeleteModal<string>("role-delete-modal");

  return (
    <>
      <DocumentPaper theme="minimal" title={t("page.role.details.title", { roleName: props.roleDetails.name })}>
        <div className="flex flex-wrap gap-5">
          <div className="min-w-96 flex-1 bg-white px-5 py-4 xs:rounded-lg">
            <RoleDetails roleDetails={props.roleDetails} onDelete={openDeleteModal} />
          </div>

          <div className="min-w-96 flex-1 bg-white px-5 py-4 xs:rounded-lg" data-testid="event-participants">
            <h2 className="block text-headline3">{t("page.role.details.participants.title")}</h2>
            <p className="block text-caption text-grey-700">
              {t("page.role.details.participants.details", { count: props.roleDetails.users.length })}
            </p>

            <div className="mt-4">
              <List getKey={(item) => item.id} list={props.roleDetails.users}>
                {(user) => (
                  <User
                    key={user.id}
                    avatar={user.avatar}
                    title={user.fullName}
                    subtitle={user.email}
                    deletedAt={user.deletedAt}
                  />
                )}
              </List>
            </div>
          </div>
        </div>
      </DocumentPaper>
      <DeleteModal<string>
        title={t("model.role.action.delete.confirmation")}
        description={t("model.role.action.delete.description")}
        onDelete={props.deleteRole}
        {...componentProps}
      />
    </>
  );
}
