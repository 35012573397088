import type { UserDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Notice } from "components/Notice/Notice";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useState } from "react";
import { useTranslation } from "translations";

export type ResidentCreateCommentPayload = {
  content: string;
  images?: FormImage[];
  videos: FormVideo[];
  internal?: boolean;
};

interface Props {
  user: UserDto;
  canComment: boolean;
  onComment: (payload: ResidentCreateCommentPayload) => Promise<unknown>;
  onReject?: () => void;
  onRate?: () => void;
  statusName?: string;
  canRateOrReject?: boolean;
  showOtherUserCanNotCommentMessage?: boolean;
  wasTicketCreatedInWeb?: boolean;
}

export function TicketResidentCommentField({
  user,
  canComment,
  onComment,
  canRateOrReject,
  onRate,
  onReject,
  statusName,
  showOtherUserCanNotCommentMessage,
  wasTicketCreatedInWeb,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);

  const { addImages, removeImage, removeImages } = useImageInput({ selectedImages: images, onChange: setImages });
  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) =>
      setVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          }

          return video;
        }),
      ),
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: setVideos,
    uploadFn: uploadFormVideo,
  });

  const onSubmit = async () => {
    await onComment({
      content: value,
      images,
      videos,
      internal: false,
    });

    removeImages();
    removeVideos();
    setValue("");
  };

  return (
    <div className="flex flex-col">
      {showOtherUserCanNotCommentMessage && (
        <div className="flex w-full justify-around text-grey-600">
          <span className="text-caption">{t("page.tickets.details.comment-field.resident.comment-not-allowed")}</span>
        </div>
      )}
      {canComment && !canRateOrReject && (
        <div className="rounded-lg rounded-tl-none p-2">
          <CommentFieldWithAvatar
            onSubmit={onSubmit}
            user={user}
            allowedAttachments={["image", "video"]}
            tooltips={{
              uploadImage: t("page.tickets.details.comment-field.resident.image-tooltip"),
              send: t("page.tickets.details.comment-field.resident.send-message-tooltip"),
            }}
            images={images}
            onAddImages={addImages}
            onRemoveImage={removeImage}
            videos={videos}
            onAddVideo={addVideos}
            onRemoveVideo={removeVideo}
            placeholder={t("page.tickets.details.comment-field.resident.placeholder-message")}
            commentType="public"
            onChange={setValue}
            value={value}
          />
        </div>
      )}
      {canRateOrReject && (
        <div className="flex w-full flex-wrap items-center gap-2 py-4">
          <div className="flex grow">
            <Notice
              icon={iconStar01}
              type="warning"
              message={t("page.tickets.details.comment-field.resident.ticket-settled", { status: statusName || "" })}
            />
          </div>
          <div className="flex grow-0 gap-2">
            {onReject ? (
              <Button styling="secondary" onClick={onReject}>
                {t("page.tickets.details.comment-field.resident.reject")}
              </Button>
            ) : null}
            {onRate ? (
              <Button styling="primary" onClick={onRate}>
                {t("page.tickets.details.comment-field.resident.rate")}
              </Button>
            ) : null}
          </div>
        </div>
      )}
      {canComment && !canRateOrReject && wasTicketCreatedInWeb && (
        <span className="pt-2 text-overline text-grey-500">
          {t("page.tickets.details.comment-field.resident.notification-reminder")}
        </span>
      )}
    </div>
  );
}
