import type { BookableSlotDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconClock from "assets/icons/clock.svg";
import { Icon } from "components/Icon/Icon";
import { format, isEqual } from "date-fns";
import { twResolve } from "helpers/tw-resolve";
import { isDefined } from "helpers/util";
import type React from "react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

import { getDateFromMinutesOfDay, getTimeslotRange } from "../helpers";
import type { CreateOrEditBookingFormValues } from "../pages/CreateOrEditBookings/Layout";

interface BookingTimeslotButtonProps {
  timeslot: BookableSlotDto;
  potentialEndTime: number | null;
  firstUnavailableTimeslot: BookableSlotDto | undefined;
  isBookMultipleTimeslotsAllowed: boolean;
  isBookedByOtherBooking: boolean;
  onHover: (timeslot: number | null) => void;
}

type BookableSlot = Omit<BookableSlotDto, "startTime" | "endTime"> & { startTime: number; endTime: number };

export function BookingTimeslotButton({
  timeslot,
  potentialEndTime,
  firstUnavailableTimeslot,
  isBookMultipleTimeslotsAllowed,
  isBookedByOtherBooking,
  onHover,
}: BookingTimeslotButtonProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useFormContext<CreateOrEditBookingFormValues>();
  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });
  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  const bookableSlot: BookableSlot = useMemo(() => {
    const { startTime: newStartTime, endTime: newEndTime } = getTimeslotRange(timeslot);

    return {
      ...timeslot,
      startTime: newStartTime,
      endTime: newEndTime,
    };
  }, [timeslot]);

  if (bookableSlot.state === "unavailable") {
    return null;
  }

  // A timeslot is highlighted when a start time is selected and it's inbetween the start time and a potential end time (hovered)
  const isTimeslotHighlighted = (timeslot: BookableSlot): boolean => {
    if (!isDefined(startTime)) return false;

    if (endTime) {
      return timeslot.startTime >= startTime && timeslot.endTime <= endTime;
    }

    if (potentialEndTime) {
      return timeslot.startTime >= startTime && timeslot.endTime <= potentialEndTime;
    }

    return timeslot.startTime === startTime;
  };

  // A timeslot is selected when it is inbetween (inclusive) the selected start and end time
  const isTimeslotSelected = (timeslot: BookableSlot): boolean => {
    return isDefined(startTime) && timeslot.startTime >= startTime && isDefined(endTime) && timeslot.endTime <= endTime;
  };

  // A timeslot is disabled when it is before the selected start time or it's after a booked timeslot
  const isTimeslotDisabled = (timeslot: BookableSlot): boolean => {
    if (!isDefined(startTime)) {
      return bookAllDay;
    } else {
      if (!isBookMultipleTimeslotsAllowed) {
        return timeslot.startTime !== startTime;
      }

      if (firstUnavailableTimeslot) {
        const { startTime: firstUnAvailableSlotStartTime } = getTimeslotRange(firstUnavailableTimeslot);

        return (
          timeslot.startTime < startTime ||
          (!!firstUnavailableTimeslot && timeslot.startTime >= firstUnAvailableSlotStartTime)
        );
      } else {
        return timeslot.startTime < startTime;
      }
    }
  };

  const onClickTimeslot = (timeslot: BookableSlot) => {
    if (isDefined(startTime) && isDefined(endTime)) {
      // If a time range has been selected but the start timeslot is clicked, cancel current selection
      if (isEqual(timeslot.startTime, startTime)) {
        form.setValue("startTime", null);
        form.setValue("endTime", null);

        return;
      } else {
        if (isBookMultipleTimeslotsAllowed) {
          form.setValue("endTime", timeslot.endTime);
        } else {
          form.setValue("endTime", null);
        }
      }
    } else if (!isDefined(startTime)) {
      form.setValue("startTime", timeslot.startTime);

      if (timeslot.isAllDay || !isBookMultipleTimeslotsAllowed) {
        form.setValue("endTime", timeslot.endTime);
      } else {
        form.setValue("endTime", null);
      }
    } else if (isDefined(startTime) && !isDefined(endTime)) {
      form.setValue("endTime", timeslot.endTime);
      form.clearErrors("startTime");
    }
  };

  const labelTimeslot = `${format(getDateFromMinutesOfDay(bookableSlot.startTime), "HH:mm")} - ${format(getDateFromMinutesOfDay(bookableSlot.endTime), "HH:mm")}`;
  let labelButton = labelTimeslot;
  if (bookableSlot.isAllDay) {
    // If the timeslot is meant from 00:00 to 00:00 (next day), then hide timeslot in label
    if (bookableSlot.endTime - bookableSlot.startTime === 1440) {
      labelButton = t("page.bookings.book-asset.form.booking-timeslot.full-day");
    } else {
      labelButton = `${t("page.bookings.book-asset.form.booking-timeslot.full-day")} ${labelTimeslot}`;
    }
  }

  const isHighlighted = isTimeslotHighlighted(bookableSlot);
  const isDisabled = isTimeslotDisabled(bookableSlot);
  const isSelected = isTimeslotSelected(bookableSlot);

  return (
    <button
      data-testid="timeslot-btn"
      type="button"
      className={twResolve(
        "rounded-full bg-green-100 px-2 py-1 text-green-700 transition-colors  hover:bg-green-700 hover:text-white",
        isBookedByOtherBooking && "pointer-events-none bg-red-100 text-red-600",
        isHighlighted && "bg-green-700 text-white hover:bg-green-800",
        isDisabled && "cursor-not-allowed bg-grey-100 text-grey-500 hover:bg-grey-100 hover:text-grey-500",
      )}
      onClick={() => onClickTimeslot(bookableSlot)}
      onMouseEnter={() => onHover(bookableSlot.startTime)}
      onMouseLeave={() => onHover(null)}
      disabled={isDisabled}
    >
      <span className="flex items-center gap-1 text-caption">
        {isSelected ? <Icon name={iconCheck} /> : <Icon name={iconClock} />}
        {labelButton}
      </span>
    </button>
  );
}
