import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { Tab } from "components/ContentTabs/ContentTabs";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { BookableAssetsGridView } from "modules/bookings/components/BookableAssetsGridView";
import { canManageAnyBookableAsset } from "modules/bookings/permissions";
import { ServicesGridView } from "modules/service-partners/components/ServicesGridView";
import { ServicesListAction } from "modules/service-partners/components/ServicesListAction";
import { canCreateNewServicePartner, canViewServicePartners } from "modules/service-partners/permissions";
import { QUERY_KEYS } from "query-keys";
import React from "react";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

const AMOUNT_ITEMS_TO_LOAD = 12;

enum ServiceTab {
  Services = "services",
  Assets = "assets",
}

// Admins always only see services, no tab options are provided
// Non-admins can see both services and assets when they have the right permissions
export function Layout(): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();

  let defaultActiveTab: ServiceTab | null = sessionUser.isAdmin ? null : ServiceTab.Services;
  if (!hasPermission(canViewServicePartners) && !sessionUser.isAdmin) {
    defaultActiveTab = ServiceTab.Assets;
  }
  const [activeTab, setActiveTab] = React.useState<ServiceTab | null>(defaultActiveTab);
  const projectId = useProjectId();
  const api = useApi();

  const {
    data: servicesData,
    isError: isErrorServices,
    error: servicesError,
    isFetching: isFetchingServices,
    isFetchingNextPage: isFetchingMoreServices,
    hasNextPage: hasMoreServices,
    fetchNextPage: fetchMoreServices,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL_INFINITE(projectId),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getServicepartnersV1({
          includeFromConnection: true,
          returnAllowedTypes: true,
          Limit: AMOUNT_ITEMS_TO_LOAD,
          Offset: pageParam * AMOUNT_ITEMS_TO_LOAD,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });
  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };
  const {
    data: assetsData,
    isError: isErrorAssets,
    error: assetsError,
    isFetching: isFetchingAssets,
    hasNextPage: hasMoreAssets,
    fetchNextPage: fetchMoreAssets,
    isFetchingNextPage: isFetchingMoreAssets,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsV1({
          ...assetsQuery,
          Offset: pageParam * AMOUNT_ITEMS_TO_LOAD,
          Limit: AMOUNT_ITEMS_TO_LOAD,
        })
        .then((items) => commonAPIDataSelector(items)),
    enabled: !sessionUser.isAdmin,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  if ((isFetchingAssets && !isFetchingMoreAssets) || (isFetchingServices && !isFetchingMoreServices)) {
    return <FullSizeLoader />;
  }

  if (isErrorServices || isErrorAssets) {
    return <ErrorPage error={servicesError || assetsError} />;
  }

  const services = servicesData?.pages.flatMap((page) => page.items) ?? [];
  const assets = assetsData?.pages.flatMap((page) => page.items) ?? [];
  const amountTotalItems = activeTab === ServiceTab.Services ? services.length : assets.length;
  const labelTotalItems = t("page.service.list.total-message", {
    count: amountTotalItems,
    serviceEntity:
      activeTab === ServiceTab.Services ? t("common.entity.service.plural") : t("common.entity.asset.plural"),
  });
  const tabs: Tab<ServiceTab>[] = [
    {
      id: ServiceTab.Services,
      name: t("common.entity.service.plural"),
      count: services.length,
      isHidden: !hasPermission(canViewServicePartners),
    },
    {
      id: ServiceTab.Assets,
      name: t("common.entity.asset.plural"),
      count: assets.length,
      isHidden: assets.length === 0,
    },
  ];
  const isAnyTabVisible = tabs.some((tab) => !tab.isHidden);
  const isTabMenuVisible = !sessionUser.isAdmin && !!activeTab && isAnyTabVisible;

  const isServiceCtaVisible =
    hasPermission(canCreateNewServicePartner) && (sessionUser.isAdmin || activeTab === ServiceTab.Services);
  const isAssetCtaVisible = hasPermission(canManageAnyBookableAsset) && activeTab === ServiceTab.Assets;
  const isServicesVisible =
    hasPermission(canViewServicePartners) && (sessionUser.isAdmin || activeTab === ServiceTab.Services);
  const isAssetsVisible = activeTab === ServiceTab.Assets;

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.service.list.title")}
      subTitle={t("page.service.list.sub-title")}
      actions={
        <>
          {isServiceCtaVisible && <ServicesListAction entityType="service" />}
          {isAssetCtaVisible && <ServicesListAction entityType="asset" />}
        </>
      }
    >
      <div className="flex w-full flex-col gap-4">
        {isTabMenuVisible && (
          <ContentTabs tabs={tabs} activeTabId={activeTab} onTabChange={(tab) => setActiveTab(tab)}>
            <div className="flex items-center justify-between p-4">
              <span className="text-caption">{labelTotalItems}</span>
            </div>
          </ContentTabs>
        )}

        {/* Services */}
        {isServicesVisible && (
          <ServicesGridView
            onLoadMore={fetchMoreServices}
            isLoading={isFetchingMoreServices || isFetchingServices}
            hasMore={hasMoreServices}
            {...{ services }}
          />
        )}

        {/* Assets */}
        {isAssetsVisible && (
          <BookableAssetsGridView
            onLoadMore={fetchMoreAssets}
            isLoading={isFetchingMoreAssets || isFetchingAssets}
            hasMore={hasMoreAssets}
            {...{ assets }}
          />
        )}
      </div>
    </DocumentPaper>
  );
}
