import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { NoData } from "modules/analytics/components/NoData";
import { motion } from "motion/react";

export function LoadingState(): React.ReactNode {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: "linear", delay: 0.2 }}
      className="absolute inset-0 z-10 bg-white"
    >
      <FullSizeLoader noDelay />
    </motion.div>
  );
}

export function EmptyState(): React.ReactNode {
  return (
    <motion.div
      className="absolute inset-0 z-20 flex items-center justify-center bg-white p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: "linear" }}
    >
      <NoData />
    </motion.div>
  );
}

type ErrorStateProps = {
  error: Error | null;
  onReload: () => void;
};

export function ErrorState({ error, onReload }: ErrorStateProps): React.ReactNode {
  return (
    <motion.div
      className="absolute inset-0 z-20 flex items-center justify-center bg-white p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: "linear" }}
    >
      <NoData {...{ error, onReload }} />
    </motion.div>
  );
}
