import { format as formatDateFns, parse, parseISO } from "date-fns";
import type { TranslationFunction } from "translations";

type AnyDate = number | string | Date;

/**
 * Strip time from any date and return as local date with time set to 00:00:00.
 */
export function stripTime(date: AnyDate): Date {
  date = asDate(date);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Calculate the number of whole days between two dates, can be negative if `start` is after `end`.
 */
export function daysBetween(start: AnyDate, end: AnyDate): number {
  const timeDiff = asDate(end).getTime() - asDate(start).getTime();

  const timeDiffInDays = timeDiff / (1000 * 3600 * 24);
  if (timeDiffInDays < 0) {
    const result = Math.ceil(timeDiffInDays);

    // If the result is -0, return 0
    return result === 0 ? 0 : result;
  }

  return Math.floor(timeDiffInDays);
}

/**
 * Get the index of the day of the week (0-6) for the given date.
 * 0 is Monday, 6 is Sunday.
 */
export function dayOfWeekIndex(date: AnyDate): number {
  const day = asDate(date).getDay();
  if (day === 0) {
    return 6;
  }

  return day - 1;
}

function asDate(date: AnyDate): Date {
  return typeof date === "string" ? parseISO(date) : new Date(date);
}

export function tryParse(format: string, dateInFormat?: string | null): Date | undefined {
  if (!dateInFormat) {
    return undefined;
  }

  try {
    const d = parse(dateInFormat, format, new Date());
    if (isNaN(d.getTime())) {
      return undefined;
    }

    return d;
  } catch (error) {
    return undefined;
  }
}

export function asUtc(date: AnyDate): Date {
  const d = asDate(date);

  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

export function timeWithoutSeconds(time: string): string {
  return formatDateFns(parse(time, "HH:mm:ss", new Date()), "HH:mm");
}

interface DateDistance {
  start: Date;
  end?: Date;
}

export function formatDistance(t: TranslationFunction, distance: DateDistance): string {
  if (!(distance.start instanceof Date) || isNaN(distance.start.getTime())) {
    throw new Error("Invalid start date");
  }

  const startDate = distance.start;
  let endDate = distance.end;
  if (!endDate || !(endDate instanceof Date) || isNaN(endDate.getTime())) {
    endDate = new Date();
  }

  const amountDiffMs = endDate.getTime() - startDate.getTime();
  const amountDiffSeconds = Math.floor(Math.abs(amountDiffMs) / 1000);
  const amountDiffMinutes = Math.floor(amountDiffSeconds / 60);
  const amountDiffHours = Math.floor(amountDiffMinutes / 60);
  const amountDiffDays = Math.floor(amountDiffHours / 24);
  const amountDiffMonths = Math.floor(amountDiffDays / 30);
  const amountDiffYears = Math.floor(amountDiffMonths / 12);
  const isPast = amountDiffMs > 0;

  if (amountDiffSeconds < 30) {
    return isPast ? t("common.date.just-now") : t("common.date.a-moment-from-now");
  } else if (amountDiffSeconds < 60) {
    return isPast
      ? t("common.date.seconds-ago", {
          count: amountDiffSeconds,
        })
      : t("common.date.seconds-from-now", { count: amountDiffSeconds });
  } else if (amountDiffMinutes > 0 && amountDiffMinutes < 60) {
    return isPast
      ? t("common.date.minute-ago", {
          count: amountDiffMinutes,
        })
      : t("common.date.minute-from-now", {
          count: amountDiffMinutes,
        });
  } else if (amountDiffHours > 0 && amountDiffHours < 24) {
    return isPast
      ? t("common.date.hour-ago", {
          count: amountDiffHours,
        })
      : t("common.date.hour-from-now", {
          count: amountDiffHours,
        });
  } else if (amountDiffDays > 0 && amountDiffDays < 30) {
    return isPast
      ? t("common.date.day-ago", {
          count: amountDiffDays,
        })
      : t("common.date.day-from-now", {
          count: amountDiffDays,
        });
  } else if (amountDiffMonths > 0 && amountDiffMonths < 12) {
    return isPast
      ? t("common.date.month-ago", {
          count: amountDiffMonths,
        })
      : t("common.date.month-from-now", {
          count: amountDiffMonths,
        });
  } else {
    return isPast
      ? t("common.date.year-ago", {
          count: amountDiffYears,
        })
      : t("common.date.year-from-now", {
          count: amountDiffYears,
        });
  }
}
