import * as Sentry from "@sentry/react";
import { useSuperTokensUserId } from "authentication/AuthenticationStateProvider";
import { ErrorPage } from "components/Error/ErrorPage";
import { useSessionUserInitial } from "hooks/Network/useSessionUserInitial";
import { useSetUserLanguage } from "hooks/useSetUserLanguage";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { matchPath, useNavigate } from "react-router";
import { routes } from "routes";

import { useProjectContext } from "./ProjectContext";

/**
 * Makes sure a session user is available in all child components.
 * If isRoot is true, it will also redirect to the onboarding flow if the user is not onboarded.
 */
export function SessionUserLoader({
  children,
  isRoot,
}: {
  children: React.ReactNode;
  isRoot?: boolean;
}): React.ReactNode {
  const superTokensUserId = useSuperTokensUserId();
  const navigate = useNavigate();
  const projectContext = useProjectContext();
  const { data: user, error } = useSessionUserInitial(projectContext.projectId);
  const posthog = usePostHog();

  useSetUserLanguage(isRoot);

  useEffect(() => {
    if (!isRoot) {
      return;
    }

    if (user) {
      let role = "Resident";
      if (user.isSuperAdmin) role = "Super admin";
      else if (user.isAdmin) role = "Professional user";

      Sentry.getCurrentScope().setUser({
        email: user.email,
        username: superTokensUserId,
        segment: role,
      });
    }
  }, [isRoot, user, posthog, superTokensUserId]);

  useEffect(() => {
    if (!isRoot) {
      return;
    }

    if (user && superTokensUserId) {
      posthog?.identify(superTokensUserId, {
        userId: user.id,
        projectId: user.projectId,
        email: user.email,
        isAdmin: user.isAdmin,
      });
    }
  }, [isRoot, user, posthog, superTokensUserId]);

  const notOnboarded = isRoot && user && !user.onboardedAt && !matchPath(routes.onboardingFlow.ROOT, location.pathname);

  // Redirect to onboarding
  useEffect(() => {
    if (notOnboarded) {
      void navigate(routes.onboardingFlow.slides({ slug: projectContext.projectSlug }), { replace: true });
    }
  }, [navigate, notOnboarded, projectContext.projectSlug]);

  if (error) {
    return <ErrorPage status={(error as unknown as Response)?.status || 0} />;
  }

  if (!user || notOnboarded) {
    return null;
  }

  return children;
}
