import type { AdminTicketDetailsDto, EditTicketCommentRequest, SelfDto, TicketCommentRequest } from "api/types";
import type { CommentPayload } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { ticketMutations } from "queries/tickets";

export function changingAssigneeWillResultIn403(
  ticket: AdminTicketDetailsDto,
  assignee: { id: string },
  sessionUser: SelfDto,
): boolean {
  if (!ticket) {
    return false;
  }

  if (sessionUser.isSuperAdmin) {
    return false;
  }

  if (ticket.assignee?.id === assignee.id) {
    return false;
  }

  if (ticket.assignee?.id !== sessionUser.id) {
    return false;
  }

  if (ticket.user.id === sessionUser.id) {
    return false;
  }

  if (sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets) {
    return true;
  } else {
    const categoryPermissions = sessionUser.role.permissions.repairCategories.find(
      (x) => x.categoryId === ticket.category.id,
    );

    if (!categoryPermissions) {
      return false;
    }

    if (categoryPermissions.canListAllRequests) {
      return false;
    }

    if (ticket.visibility !== "private" && categoryPermissions.canListCollectiveRequests) {
      return false;
    }

    return true;
  }
}

export function canSeeServiceHistory(ticket: AdminTicketDetailsDto, sessionUser: SelfDto): boolean {
  return (
    sessionUser.project.type === "addressBased" &&
    (sessionUser.isSuperAdmin ||
      (!sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets &&
        sessionUser.role.permissions.repairCategories.some(
          (x) => x.categoryId == ticket?.category.id && x.canListAllRequests,
        )))
  );
}

export function useTicketCommentHandler(ticketId: string): {
  createComment: (payload: CommentPayload & { fetchAllActivities?: boolean }) => Promise<unknown>;
  editComment: (payload: CommentPayload & { commentId: string }) => Promise<void>;
} {
  const postCommentMutation = ticketMutations.useAddComment();
  const editCommentMutation = ticketMutations.useUpdateComment();

  const { uploadFormImage } = useUploadImage();
  const { uploadFormDocument } = useUploadDocument();
  const { uploadFormVideo } = useUploadVideo({});

  async function createComment({
    content,
    images,
    videos,
    documents,
    type,
    fetchAllActivities,
  }: CommentPayload & {
    fetchAllActivities?: boolean;
  }) {
    const payload: TicketCommentRequest = {
      content,
      imageId: undefined,
      videoIds: [],
      documentIds: [],
      accessType: type,
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    const uploadedVideoIds: string[] = [];
    if (videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    const uploadedDocumentIds: string[] = [];
    if (documents.length > 0) {
      for (const document of documents) {
        const uploadedDocument = await uploadFormDocument(document);

        if (uploadedDocument) {
          uploadedDocumentIds.push(uploadedDocument.id);
        }
      }

      payload.documentIds = uploadedDocumentIds;
    }

    await postCommentMutation.mutateAsync({
      ticketId,
      data: payload,
      fetchAllActivities,
    });
  }

  async function editComment({
    commentId,
    content,
    images,
    videos,
    documents,
  }: CommentPayload & { commentId: string }) {
    const payload: EditTicketCommentRequest = {
      content,
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    if (videos.length > 0) {
      const uploadedVideo = await uploadFormVideo(videos[0]);

      if (uploadedVideo) {
        payload.videoIds = [uploadedVideo.id];
      }
    }

    if (documents.length > 0) {
      const uploadedDocument = await uploadFormDocument(documents[0]);

      if (uploadedDocument) {
        payload.documentIds = [uploadedDocument.id];
      }
    }

    await editCommentMutation.mutateAsync({
      ticketId,
      commentId,
      data: payload,
    });
  }

  return { createComment, editComment };
}
