import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  BookableAssetCreateRequest,
  BookableAssetUpdateBookingsToBeCancelledRequest,
  BookableAssetUpdateRequest,
  TranslateRequest,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { CreateOrEditAssetFormValues, LayoutProps } from "./Layout";
import { getBookableAssetRequest, getDefaultFormValues } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export interface AssetObserver {
  type: "project" | "projectConnection";
  id: string;
  name: string;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const { id: assetId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { isUploadingDocument, uploadFormDocument } = useUploadDocument();
  const { data: languages = [] } = useLanguages();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const hasPermission = usePermission();

  const queryClient = useQueryClient();
  const isEditMode = isDefined(assetId);
  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assetDetails,
    isFetching: isFetchingAsset,
    error: assetError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsDetailsV1(assetId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const {
    data: assetStatistics,
    isFetching: isFetchingAssetStatistics,
    error: assetStatisticsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsStatisticsV1(assetId!, { includeToday: true }),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const { mutateAsync: getBookingsToBeCancelled, isPending: isPendingBookingsToBeCancelled } = useMutation({
    mutationFn: (payload: { id: string; data: BookableAssetUpdateBookingsToBeCancelledRequest }) =>
      api.postBookableAssetsBookingsToBeCancelledV1(payload.id, payload.data).then((x) => x.data),
  });
  const { mutateAsync: onTranslate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });
  const deleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: async () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });

      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
      });
      queryClient.removeQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!) });

      void navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });
  const createAsset = useMutation({
    mutationFn: (payload: BookableAssetCreateRequest) => api.postBookableAssetsV1(payload),
    onSuccess: async () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.create-or-edit-asset.create.notification.success"),
      });

      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
      });
      void navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.create-or-edit-asset.create.notification.error"),
      }),
  });
  const editAsset = useMutation({
    mutationFn: (payload: BookableAssetUpdateRequest) => api.putBookableAssetsV1(assetId!, payload),
    onSuccess: async () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.create-or-edit-asset.edit.notification.success"),
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!),
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, assetId!),
        }),
      ]);
      void navigate(routes.bookings.list({ slug }));
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.create-or-edit-asset.edit.notification.error"),
      }),
  });

  async function onSubmit(data: CreateOrEditAssetFormValues) {
    const imageIds: string[] | undefined = [];
    for (const image of data.images) {
      const uploadedImage = await uploadFormImage(image);
      if (uploadedImage) {
        imageIds.push(uploadedImage.id);
      }
    }

    let documentId: string | undefined;
    if (data.regulationDocument && data.regulationDocument.length > 0) {
      const uploadedDocument = await uploadFormDocument(data.regulationDocument[0]);
      if (uploadedDocument) {
        documentId = uploadedDocument.id;
      }
    }

    // If the user is a resident, editing the asset will override the audience that was previously set
    let formattedData = { ...data };
    if (formattedData.audience && !sessionUser.isAdmin) {
      formattedData = {
        ...formattedData,
        audience: [],
      };
    }

    const request = getBookableAssetRequest(languages, imageIds, documentId, formattedData);

    if (isEditMode) {
      await editAsset.mutateAsync(request);
    } else {
      await createAsset.mutateAsync({
        ...request,
        projectConnectionId: data.assetObserver.type === "project" ? undefined : data.assetObserver.id,
      });
    }
  }

  const error = assetError || assetStatisticsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isEditMode && assetDetails && !assetDetails.canEdit) {
    return <ErrorPage status={403} />;
  }

  const loading = isFetchingAsset || isFetchingAssetStatistics;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  // A list of projects or project connections that book this asset
  const assetObservers: AssetObserver[] = [
    { type: "project", id: sessionUser.project.id, name: sessionUser.project.name },
    ...sessionUser.connections.map((x) => ({
      type: "projectConnection" as const,
      id: x.id,
      name: x.name,
    })),
  ];

  return children({
    languages,
    assetObservers,
    onTranslate,
    isTranslating,
    getBookingsToBeCancelled,
    onSubmit,
    isSubmitting:
      createAsset.isPending ||
      editAsset.isPending ||
      isUploadingImage ||
      isUploadingDocument ||
      deleteAsset.isPending ||
      isPendingBookingsToBeCancelled,
    isEditMode,
    canEditTimeslot: !assetStatistics || assetStatistics.futureBookingsCount === 0,
    futureBookings: assetStatistics?.futureBookingsCount ?? 0,
    onDelete: (id: string) => deleteAsset.mutateAsync(id),
    defaultValues: getDefaultFormValues(languages, assetObservers, sessionUser.email, assetDetails),
    assetId,
    isPublished: assetDetails?.publishedAt != null,
  });
}
