import type React from "react";

interface BlockquoteProps {
  children: React.ReactNode;
}

export function Blockquote({ children }: BlockquoteProps): React.ReactNode {
  return (
    <blockquote className="my-2 border-l-4 border-l-grey-300 bg-grey-100 px-3 py-4 text-body-bold">
      {children}
    </blockquote>
  );
}
