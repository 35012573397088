import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { AdminTicketCommentDto, TicketCommentRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { TicketCommentField } from "components/Ticket/TicketCommentField/TicketCommentField";
import type { CommentPayload, CommentType } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { useQueryParam } from "hooks/useQueryParam";
import { useSetUserLanguage } from "hooks/useSetUserLanguage";
import { TicketActivitySection } from "modules/tickets/components/TicketActivitySection";
import { TicketContent } from "modules/tickets/components/TicketDetails/TicketContent";
import { TicketHomeDnaCard } from "modules/tickets/components/TicketDetails/TicketHomeDnaCard";
import { TicketInfoHeader } from "modules/tickets/components/TicketDetails/TicketInfoHeader";
import { TicketRemsCard } from "modules/tickets/components/TicketDetails/TicketRemsCard";
import { TicketResidentInfo } from "modules/tickets/components/TicketDetails/TicketResidentInfo";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { useQuickReplyManager } from "providers/QuickReplyProvider";
import { ticketMutations, useTicketQueries } from "queries/tickets";
import { useMemo, useState } from "react";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

import { QuickReplyMenuBar } from "../components/QuickReplyMenuBar";

export function QuickReplyTicketPage(): React.ReactNode {
  const [editingComment, setEditingComment] = useState<AdminTicketCommentDto | undefined>(undefined);
  const [isChangeStatusModalOpened, changeStatusModalHandler] = useBool();

  const [headerParam] = useQueryParam("header");
  const { t } = useTranslation();
  const { entityId: ticketId } = useQuickReplyManager();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  useSetUserLanguage();
  const ticketQueries = useTicketQueries();
  const {
    data: ticket,
    isPending: isPendingTicket,
    error: errorTicket,
  } = useQuery({
    ...ticketQueries.details(ticketId),
    enabled: Boolean(ticketId),
  });
  const { data: activitiesData } = useInfiniteQuery({
    ...ticketQueries.activitiesInfinite({
      ticketId: ticketId,
      sortDescending: false,
    }),
  });
  const allActivities = useMemo(() => activitiesData?.pages.flatMap((x) => x.items) || [], [activitiesData]);

  const addCommentMutation = ticketMutations.useAddComment();
  const updateCommentMutation = ticketMutations.useUpdateComment();
  const updateStatusMutation = ticketMutations.useUpdateStatus();
  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});
  const { uploadFormDocument } = useUploadDocument();

  const statuses = useMemo(
    () =>
      ticket?.possibleStatuses.map((status) => ({
        id: status.id,
        description: status.name,
        labelColor: status.color,
        defaultStatusId: status.type,
      })) || [],
    [ticket?.possibleStatuses],
  );

  if (isPendingTicket) {
    return null;
  }

  if (errorTicket) {
    return <ErrorPage status={(errorTicket as unknown as Response)?.status || 0} />;
  }

  async function handleNewComment({ content, images, videos, documents, type }: CommentPayload) {
    if (!ticket) return;

    const payload: TicketCommentRequest = {
      content,
      accessType: type,
    };

    if (images.length > 0) {
      const image = images[0];
      const uploadedImage = await uploadFormImage(image);

      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    if (videos.length > 0) {
      const video = videos[0];
      const uploadedVideo = await uploadFormVideo(video);

      if (uploadedVideo) {
        payload.videoIds = [uploadedVideo.id];
      }
    }

    if (documents.length > 0) {
      const document = documents[0];
      const uploadedDocument = await uploadFormDocument(document);

      if (uploadedDocument) {
        payload.documentIds = [uploadedDocument.id];
      }
    }

    if (editingComment) {
      await updateCommentMutation.mutateAsync({
        ticketId: ticket.id,
        commentId: editingComment.id,
        data: payload,
      });
    } else {
      await addCommentMutation.mutateAsync({
        ticketId: ticket.id,
        data: payload,
      });

      const isStatusNewOrRejected = ["new", "rejected"].includes(ticket.status.type);

      if (type !== "internal" && isStatusNewOrRejected && ticket.canChangeStatus) {
        changeStatusModalHandler.setTrue();
      }
    }
  }

  const getLoginUrl = () => {
    if (ticket.requesterHasMultipleProjects) {
      const openTicketValue = btoa(`${projectId}|${ticket.id}`);

      return {
        pathname: routes.portfolio.tickets(),
        search: `openTicket=${openTicketValue}`,
      };
    }

    return routes.tickets.details({
      id: ticket.id,
      slug: sessionUser.project.slug,
    });
  };

  const allowedCommentTypes: CommentType[] = [];
  if (ticket.canAddPublicComment) allowedCommentTypes.push("public");
  if (ticket.canAddInternalNote) allowedCommentTypes.push("internal");

  const showHeader = headerParam !== "false";

  return (
    <>
      <StopGlobalLoadingSpinner />
      {showHeader && <QuickReplyMenuBar user={sessionUser} loginUrl={getLoginUrl()} />}

      <div
        className={twJoin(
          "flex flex-col items-center justify-center gap-4 p-4 xl:flex-row xl:items-start",
          showHeader && "pb-24",
        )}
      >
        <main className="flex max-w-3xl flex-1 flex-col gap-4 xl:flex-1">
          <TicketInfoHeader ticket={ticket} showProject={showHeader} />
          <TicketContent ticket={ticket} />

          <div className="rounded-lg bg-white">
            <div className="px-3">
              <TicketActivitySection activities={allActivities} ticket={ticket} onNoteEdit={setEditingComment} />
            </div>

            {allowedCommentTypes.length > 0 && (
              <form className="p-3">
                <TicketCommentField
                  allowedAIActions={["generation", "responsibilitySuggestion"]}
                  allowedCommentTypes={allowedCommentTypes}
                  onComment={handleNewComment}
                  onEditComment={handleNewComment}
                  editingComment={editingComment}
                  onCancelEditComment={() => setEditingComment(undefined)}
                  ticketId={ticket.id}
                  user={sessionUser}
                />
              </form>
            )}
          </div>
        </main>

        <aside className="flex w-full flex-col gap-4 xl:w-[320px]">
          <TicketResidentInfo {...{ ticket }} />
          {ticket.rems && (
            <TicketRemsCard
              ticketId={ticket.id}
              ticketIsCollective={ticket.visibility !== "private"}
              rems={ticket.rems}
            />
          )}
          {ticket.homeDna && <TicketHomeDnaCard ticketId={ticket.id} homeDna={ticket.homeDna} withOutline />}
        </aside>
      </div>

      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpened={isChangeStatusModalOpened}
        onOpenChange={changeStatusModalHandler.set}
        onSubmit={async (status) => {
          try {
            if (status.id !== ticket.status.id) {
              await updateStatusMutation.mutateAsync({
                ticketId: ticket.id,
                data: {
                  statusId: status.id,
                  silent: true,
                },
              });
            }
          } finally {
            changeStatusModalHandler.setFalse();
          }
        }}
        targetStatus={statuses[0]}
        possibleStatuses={statuses}
      />
    </>
  );
}
