import { ToggleGroup as ArkToggleGroup } from "@ark-ui/react";
import type { MessageCreateRequest } from "api/types";
import iconAnnouncement03 from "assets/icons/announcement-03.svg";
import iconMessageChatSquare from "assets/icons/message-chat-square.svg";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "translations";

import type { AdminCreatePostFormValues } from "../Layout";

type AdminCreatePostFormTypeSelectorProps = {
  selectedType: MessageCreateRequest["type"];
  allowedTypes: MessageCreateRequest["type"][];
  onSelectType: (type: MessageCreateRequest["type"]) => void;
};

export function AdminCreatePostFormTypeSelector({
  selectedType,
  allowedTypes,
  onSelectType,
}: AdminCreatePostFormTypeSelectorProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useFormContext<AdminCreatePostFormValues>();

  useEffect(() => {
    form.register("postType", {
      validate: {
        required: (value) => {
          if (!value || value.length === 0) {
            return t("page.admin-create-post.form.type.error");
          }
        },
      },
    });
  }, [form, t]);

  const intentItems: {
    title: string;
    description: string;
    icon: string;
    value: MessageCreateRequest["type"];
    "data-testid": string;
    isDisabled: boolean;
  }[] = [
    {
      title: t("page.admin-create-post.form.type.inform.title"),
      description: t("page.admin-create-post.form.type.inform.description"),
      icon: iconAnnouncement03,
      value: "announcementReadOnly",
      "data-testid": "post-type-announcement-readonly-btn",
      isDisabled: !allowedTypes.includes("announcementReadOnly"),
    },
    {
      title: t("page.admin-create-post.form.type.conversation.title"),
      description: t("page.admin-create-post.form.type.conversation.description"),
      icon: iconMessageChatSquare,
      value: "announcement",
      "data-testid": "post-type-announcement-btn",
      isDisabled: !allowedTypes.includes("announcement"),
    },
  ];

  const handleChangeType = (newType: MessageCreateRequest["type"] | undefined) => {
    // Forbid deselection once a type is selected
    if (newType === undefined) {
      return;
    }

    onSelectType(newType);
  };

  return (
    <FormField label={t("page.admin-create-post.section.step-1.title")} hideLabel>
      <FormErrorWrapper<AdminCreatePostFormValues> name="postType" encircle>
        <ArkToggleGroup.Root
          className="flex flex-col gap-2 lg:flex-row lg:gap-4"
          value={selectedType && [selectedType]}
          onValueChange={(details) => handleChangeType(details.value[0] as MessageCreateRequest["type"] | undefined)}
        >
          {intentItems.map((item) => (
            <ArkToggleGroup.Item
              className={twResolve(
                "flex w-full cursor-pointer flex-col items-center justify-center overflow-hidden rounded-md bg-grey-100 p-6 transition-colors hover:bg-grey-300/60 [&>*]:text-aop-dark-blue-500",
                selectedType === item.value && "bg-aop-dark-blue-500 hover:bg-aop-dark-blue-500 [&>*]:text-white",
                item.isDisabled && "pointer-events-none bg-grey-50 [&>*]:text-grey-300",
              )}
              key={item.value}
              value={item.value}
              disabled={item.isDisabled}
              data-testid={item["data-testid"]}
            >
              <span className="flex w-full items-center justify-center gap-2">
                <Icon name={item.icon} size={32} />
                <h3 className="truncate text-headline3">{item.title}</h3>
              </span>
              <p className="text-body">{item.description}</p>
            </ArkToggleGroup.Item>
          ))}
        </ArkToggleGroup.Root>
      </FormErrorWrapper>
    </FormField>
  );
}
