import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import type { CommentPayload } from "components/Ticket/TicketCommentField/TicketCommentFieldPure";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { useSlug } from "hooks/useSlug";
import { TicketContent } from "modules/tickets/components/TicketDetails/TicketContent";
import { TicketHistory } from "modules/tickets/components/TicketDetails/TicketHistory";
import { TicketHomeDnaCard } from "modules/tickets/components/TicketDetails/TicketHomeDnaCard";
import { TicketInfoHeader } from "modules/tickets/components/TicketDetails/TicketInfoHeader";
import { TicketLog } from "modules/tickets/components/TicketDetails/TicketLog";
import { TicketReminderButton } from "modules/tickets/components/TicketDetails/TicketReminderButton";
import { TicketRemsCard } from "modules/tickets/components/TicketDetails/TicketRemsCard";
import { TicketResidentInfo } from "modules/tickets/components/TicketDetails/TicketResidentInfo";
import { canSeeServiceHistory, useTicketCommentHandler } from "modules/tickets/helpers";
import { useConfig } from "providers/ConfigProvider";
import { ticketMutations, useTicketQueries } from "queries/tickets";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

interface Props {
  ticketId: string;
}

const TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY = "ticket-descending-activities";

export function Layout({ ticketId }: Props): React.ReactNode {
  const [isChangeStatusModalOpened, changeStatusModalHandler] = useBool();
  const [isActivitiesDescending, descendingActivitiesHandlers] = useBool(
    getLocalStorageValue(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, true),
  );

  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const isAiTicketResponsibilitySuggestionAvailable = useConfig("isAiTicketResponsibilitySuggestionAvailable");
  useUpdateLocalStorage(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, isActivitiesDescending);

  const ticketQueries = useTicketQueries();
  const {
    data: ticket,
    isPending: isLoadingTicket,
    error: ticketDetailsError,
  } = useQuery(ticketQueries.details(ticketId));
  const { isFetching: isFetchingResponsibilitySuggestion, isFetched: isFetchedResponsibilitySuggestion } = useQuery({
    ...ticketQueries.responsibilitySuggestion(ticketId),
    enabled: Boolean(ticket?.responsibilityAssignee) && isAiTicketResponsibilitySuggestionAvailable,
  });
  const activitiesQuery = useInfiniteQuery(
    ticketQueries.activitiesInfinite({ ticketId, sortDescending: isActivitiesDescending }),
  );

  const { createComment, editComment } = useTicketCommentHandler(ticketId);

  // Mark as read called everytime ticket is loaded
  const markAsReadMutation = useMutation({
    mutationFn: () => api.postTicketsReadV1(ticketId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" }),
  });
  const changeStatusMutation = ticketMutations.useUpdateStatus();

  const activities = useMemo(
    () => activitiesQuery.data?.pages.flatMap((x) => x.items) || [],
    [activitiesQuery.data?.pages],
  );

  async function onCreateComment(payload: CommentPayload) {
    await createComment({
      ...payload,
      fetchAllActivities: !isActivitiesDescending,
    }).then(() => {
      if (!isActivitiesDescending) {
        setTimeout(() => {
          bottomOfTicketLogRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 50);
      }
    });

    const isStatusNewOrRejected = ["new", "rejected"].includes(ticket!.status.type);
    if (payload.type !== "internal" && isStatusNewOrRejected && ticket!.canChangeStatus) {
      changeStatusModalHandler.setTrue();
    }
  }

  useEffect(() => {
    if (isHttpError(ticketDetailsError) && ticketDetailsError.status === 404) {
      void navigate(routes.tickets.list({ slug }));
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.errors.not-found"),
      });
    }
  }, [navigate, showFlashToast, t, ticketDetailsError, slug]);

  const markAsRead = markAsReadMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ticket?.id) {
        markAsRead();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [markAsRead, ticket?.hasUnreadActivity, ticket?.hasUnreadComment, ticket?.id]);

  const onToggleActivitySorting = descendingActivitiesHandlers.toggle;
  const isFetchingMoreActivities = activitiesQuery.isFetchingNextPage || activitiesQuery.isPending;
  const hasMoreActivities = activitiesQuery.hasNextPage || false;
  const fetchMoreActivities = activitiesQuery.fetchNextPage;

  const bottomOfTicketLogRef = useRef<HTMLDivElement>(null);
  const possibleStatuses =
    ticket?.possibleStatuses.map((status) => ({
      id: status.id,
      description: status.name,
      labelColor: status.color,
      defaultStatusId: status.type,
    })) || [];
  const error = ticketDetailsError || activitiesQuery.error;

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingTicket || (!isFetchedResponsibilitySuggestion && isFetchingResponsibilitySuggestion)) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      <DocumentPaper
        theme="minimal"
        title={t("page.tickets.details.title")}
        subTitle={
          <Breadcrumbs
            pages={[
              {
                name: t("page.tickets.title"),
                to: routes.tickets.list({ slug: slug }),
              },
              {
                name: t("page.tickets.details.title"),
              },
            ]}
          />
        }
        actions={<TicketReminderButton ticket={ticket} />}
      >
        <div className="flex flex-col gap-4 xl:flex-row">
          <div className="flex max-w-6xl flex-1 flex-col gap-4">
            <TicketInfoHeader ticket={ticket} showProject={false} />
            <TicketContent {...{ ticket }} />
            <TicketLog
              isActivitiesDescending={isActivitiesDescending}
              onToggleActivitySorting={onToggleActivitySorting}
              isFetchingMoreActivities={isFetchingMoreActivities}
              fetchMoreActivities={fetchMoreActivities}
              hasMoreActivities={hasMoreActivities}
              scrollTargetRef={bottomOfTicketLogRef}
              onCreateComment={onCreateComment}
              onEditComment={editComment}
              {...{ activities, ticket, sessionUser }}
            />
          </div>

          <aside className="flex w-full flex-col gap-4 md:max-w-xs">
            <TicketResidentInfo ticket={ticket} />

            {canSeeServiceHistory(ticket, sessionUser) ? <TicketHistory ticketId={ticket.id} /> : null}

            {ticket.rems ? (
              <TicketRemsCard
                ticketId={ticket.id}
                ticketIsCollective={ticket.visibility !== "private"}
                rems={ticket.rems}
              />
            ) : null}

            {ticket.homeDna ? <TicketHomeDnaCard ticketId={ticket.id} homeDna={ticket.homeDna} withOutline /> : null}
          </aside>
        </div>
      </DocumentPaper>
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpened={isChangeStatusModalOpened}
        onOpenChange={changeStatusModalHandler.set}
        onSubmit={async (status) => {
          try {
            if (status.id !== ticket.status.id) {
              await changeStatusMutation.mutateAsync({
                ticketId: ticket.id,
                data: {
                  statusId: status.id,
                  silent: true,
                },
              });
            }
          } finally {
            changeStatusModalHandler.setFalse();
          }
        }}
        targetStatus={possibleStatuses[0]}
        possibleStatuses={possibleStatuses}
      />
    </>
  );
}
