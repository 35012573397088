import { useQuery } from "@tanstack/react-query";
import iconEyeOff from "assets/icons/eye-off.svg";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import imgRemsLogoSmall from "modules/tickets/assets/rems-logo-small.svg";
import { userMutations, useUserQueries } from "queries/users";
import { useMemo } from "react";
import { useTranslation } from "translations";

interface UserInfoCardProps {
  userId: string;
}

export function UserInfoCard({ userId }: UserInfoCardProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t, i18n } = useTranslation();
  const [isInviteModalOpen, inviteModalHandler] = useBool(false);
  const hasPermission = usePermission();

  const userQueries = useUserQueries();
  const { data: user, isPending: isPendingUser, error: errorUser } = useQuery(userQueries.details({ userId }));
  const { data: userProjectsData } = useQuery({
    ...userQueries.listProjects({ userId }),
    enabled: sessionUser.isSuperAdmin,
  });
  const { data: signUpReminders } = useQuery({
    ...userQueries.signUpReminders({ userId }),
    enabled: !user?.registeredAt && !user?.project,
  });
  const { data: deletionUser } = useQuery({
    ...userQueries.details({ userId: user!.deletedByUserId as string }),
    enabled: sessionUser.isSuperAdmin && Boolean(user?.deletedAt) && Boolean(user?.deletedByUserId),
  });
  const addUserSignUpRemindersMutation = userMutations.useAddUserSignUpReminders();

  async function handleSendReminder() {
    await addUserSignUpRemindersMutation.mutateAsync({
      userId,
    });

    inviteModalHandler.setFalse();
  }

  const invitesTooltip = useMemo(
    () =>
      signUpReminders?.reminders
        ? signUpReminders.reminders
            .map((reminder) =>
              t("page.user-detail.invite-user.tooltip", { date: formatDate(i18n, "date", reminder.lastReminded) }),
            )
            .join("\n")
        : undefined,
    [signUpReminders?.reminders, t, i18n],
  );
  const buildingName = useMemo(() => user?.addressTags.find((tag) => tag.type === "building")?.key, [user]);
  const userProjects = useMemo(
    () => userProjectsData?.filter((project) => project.id !== sessionUser.projectId) || [],
    [userProjectsData, sessionUser],
  );

  if (isPendingUser || errorUser) {
    return null;
  }

  const showReminderButton =
    !user.registeredAt &&
    (sessionUser.isSuperAdmin || signUpReminders?.canBeReminded || signUpReminders?.recentlyInvited) &&
    ((user.isAdmin && hasPermission((x) => x.userManagement.canEditAdminInfo)) ||
      (!user.isAdmin && hasPermission((x) => x.userManagement.canEditResidentInfo)));

  return (
    <>
      <div className="flex w-full flex-col gap-5 rounded-lg bg-white px-5 py-10">
        <div className="size-24 self-center">
          <UserAvatar img={user.avatar} isUserDeleted={!!user.deletedAt} big />
        </div>
        <div className="flex flex-col items-center gap-0.5">
          <span data-testid="user-fullname" className="text-center text-body-bold">
            {user.fullName}
          </span>
          <div className="flex flex-wrap items-center gap-1">
            <span data-testid="user-address" className="line-clamp-2 text-center">
              {user.locatedAt}
            </span>
            {user.hideHouseNumber && !sessionUser.isAdmin && sessionUser.id === user.id && (
              <Tooltip tooltip={t("page.user-detail.hide-house-number.tooltip")}>
                <Icon name={iconEyeOff} className="text-grey-500" />
              </Tooltip>
            )}
          </div>
          {buildingName && user.project && (
            <span data-testid="user-building" className="text-center">
              {t("page.user-detail.building", { building: buildingName, project: user.project.name })}
            </span>
          )}
          {(!buildingName || !user.project) && (
            <span data-testid="user-building" className="text-center">
              {buildingName || user.project?.name}
            </span>
          )}
        </div>
        <div className="mb-5 flex flex-col items-center gap-0.5">
          {!!user.createdAt && (
            <span className="flex items-center gap-2 text-caption text-grey-500">
              {user.inviteSource === "rems" ? (
                <>
                  {t("page.user-detail.created-by", {
                    date: formatDate(i18n, "date", user.createdAt),
                    name: t("common.label.rems"),
                  })}
                  <img src={imgRemsLogoSmall} width={16} height={16} alt="Aareon" />
                </>
              ) : (
                t("page.user-detail.created", { date: formatDate(i18n, "date", user.createdAt) })
              )}
            </span>
          )}
          {!!user.registeredAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.registered", { date: formatDate(i18n, "date", user.registeredAt) })}
            </span>
          )}
          {!!user.deleteAfter && !user.deletedAt && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.deleted-after", { date: formatDate(i18n, "date", user.deleteAfter) })}
            </span>
          )}
          {!!user.deletedAt && (!user.deletionSource || user.deletionSource === "areaOfPeople") && (
            <span className="text-caption text-grey-500">
              {t("page.user-detail.deleted", { date: formatDate(i18n, "date", user.deletedAt) })}
              {deletionUser && (
                <>
                  {` ${t("page.user-detail.deleted-by")} ${
                    deletionUser.id === user.id ? t("page.user-detail.deleted-by-themselves") : ""
                  }`}
                  {deletionUser.id !== user.id && (
                    <span className="underline hover:text-grey-700">
                      <UserNameLink user={deletionUser}>{deletionUser.fullName}</UserNameLink>
                    </span>
                  )}
                </>
              )}
            </span>
          )}
          {!!user.deletedAt && user.deletionSource === "rems" && (
            <span className="flex items-center gap-2 text-caption text-grey-500">
              {t("page.user-detail.deleted", { date: formatDate(i18n, "date", user.deletedAt) })}
              {` ${t("page.user-detail.deleted-in")} ${t("common.label.rems")}`}
              <img src={imgRemsLogoSmall} width={16} height={16} alt="Aareon" />
            </span>
          )}
          {showReminderButton && (
            <>
              <span className="text-caption text-grey-500">{t("page.user-detail.not-registered")}</span>
              <Button
                className="mx-auto mt-4"
                styling="primary"
                title={
                  signUpReminders?.recentlyInvited
                    ? `${invitesTooltip}\n\n${t("page.user-detail.invite-user.recently-invited")}`
                    : invitesTooltip
                }
                onClick={inviteModalHandler.setTrue}
                disabled={
                  !signUpReminders?.canBeReminded || (!sessionUser.isSuperAdmin && signUpReminders?.recentlyInvited)
                }
              >
                {t("page.user-detail.invite-user.button")}
              </Button>
            </>
          )}
        </div>
        {(!!user.email || !!user.phoneNumber) && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.contact-details")}
            </span>
            <span className="text-caption">{user.email}</span>
            <span className="text-caption">{user.phoneNumber}</span>
          </div>
        )}
        {!!user.occupation && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.occupation")}
            </span>
            <span className="text-caption">{user.occupation}</span>
          </div>
        )}
        {!!user.biography && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.biography")}
            </span>
            <span className="text-caption">
              <LinkFormatter>{user.biography}</LinkFormatter>
            </span>
          </div>
        )}
        {user.interestGroups?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.groups")}
            </span>
            <ul>
              {user.interestGroups.map((item) => (
                <li key={item.id}>
                  <span key={item.id} className="text-caption">
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {user.helpCategories?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.help-categories")}
            </span>
            <ul>
              {user.helpCategories.map((item) => (
                <li key={item.id}>
                  <span className="text-caption">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {userProjects?.length > 0 && sessionUser.isSuperAdmin && (
          <div className="flex flex-col gap-0.5">
            <span className="mb-2 border-b-[0.5px] border-grey-100 pb-2 text-caption-bold">
              {t("page.user-detail.connected-projects")}
            </span>
            <ul>
              {userProjects.map((userProject) => (
                <li key={userProject.id}>
                  <span className="text-caption">{userProject.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ConfirmModal
        isLoading={false}
        isOpened={isInviteModalOpen}
        onReject={inviteModalHandler.setFalse}
        onOpenChange={inviteModalHandler.set}
        onResolve={handleSendReminder}
        resolveBtnProps={{
          text: t("page.user-detail.invite-user-modal.confirm"),
        }}
        title={t("page.user-detail.invite-user-modal.title")}
        shouldCloseOnEsc
      >
        <div className="flex flex-col gap-2 text-grey-600">
          <span className="text-caption">
            {t("page.user-detail.invite-user-modal.reminder-num", { count: signUpReminders?.reminders.length || 0 })}
          </span>
          <span className="text-caption">
            {t("page.user-detail.invite-user-modal.last-reminder", {
              date: signUpReminders?.reminders.length
                ? formatDate(i18n, "date", signUpReminders.reminders[signUpReminders.reminders.length - 1].lastReminded)
                : "-",
            })}
          </span>
        </div>
      </ConfirmModal>
    </>
  );
}
