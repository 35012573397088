import type { NotificationSettingsDto, NotificationSettingsRequest } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormCheckboxGrid, FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormSelect } from "components/Form/FormSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

export interface LayoutProps {
  canTurnAssigneeOff: boolean;
  defaultValues: { canBeAssigned: boolean; settings: NotificationSettingsDto };
  isSubmitting: boolean;
  onSubmit: (values: FormValues) => void;
}

export function Layout({ canTurnAssigneeOff, defaultValues, onSubmit, isSubmitting }: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({ defaultValues });

  const canBeAssigned = useWatch<FormValues, "canBeAssigned">({ control: formMethods.control, name: "canBeAssigned" });

  const { setValue } = formMethods;
  useEffect(() => {
    if (!canBeAssigned) {
      setValue("settings.ticketAssignedCommentNew.dashboardEnabled", false);
      setValue("settings.ticketAssignedCommentNew.emailEnabled", false);
      setValue("settings.ticketAssignToMe.dashboardEnabled", false);
      setValue("settings.ticketAssignToMe.emailEnabled", false);
    }
  }, [canBeAssigned, setValue]);

  return (
    <DocumentPaper
      title={t("page.notification-settings.title")}
      subTitle={t("page.notification-settings.subtitle")}
      theme="minimal"
    >
      <Form<FormValues> formMethods={formMethods} onSubmit={onSubmit}>
        <SettingsForm
          canTurnAssigneeOff={canTurnAssigneeOff}
          isSubmitting={isSubmitting}
          ticketCategories={defaultValues.settings.ticketUnassignedUpdates}
        />
      </Form>
    </DocumentPaper>
  );
}

function SettingsForm({
  canTurnAssigneeOff,
  isSubmitting,
  ticketCategories,
}: {
  canTurnAssigneeOff: boolean;
  isSubmitting: boolean;
  ticketCategories: { ticketCategoryId: string; ticketCategoryName: string }[];
}) {
  const { t } = useTranslation();

  const canBeAssigned = useWatch<FormValues, "canBeAssigned">({ name: "canBeAssigned" });

  const isAssignedDisabled = canBeAssigned && !canTurnAssigneeOff;

  return (
    <div className="flex flex-col gap-4">
      <GroupWrapper title={t("page.notification-settings.form.messages.title")}>
        <FormField label="">
          <FormCheckboxGrid
            rows={[
              {
                dashboard: <FormCheckbox<FormValues> label="" name="settings.generalMessageNew.dashboardEnabled" />,
                email: <FormCheckbox<FormValues> label="" name="settings.generalMessageNew.emailEnabled" />,
                label: t("page.notification-settings.form.messages.new-general-message"),
              },
              {
                dashboard: (
                  <FormCheckbox<FormValues> label="" name="settings.generalMessageCommentNew.dashboardEnabled" />
                ),
                email: <FormCheckbox<FormValues> label="" name="settings.generalMessageCommentNew.emailEnabled" />,
                label: t("page.notification-settings.form.messages.new-general-message-comment"),
              },
            ]}
            headerLabels={{
              dashboard: t("page.notification-settings.labels.dashboard"),
              email: t("page.notification-settings.labels.email"),
            }}
          />
        </FormField>
      </GroupWrapper>
      <GroupWrapper title={t("page.notification-settings.form.chat.title")}>
        <FormField label="">
          <FormCheckboxGrid
            rows={[
              {
                dashboard: <FormCheckbox<FormValues> label="" name="settings.chatNew.dashboardEnabled" />,
                email: <FormCheckbox<FormValues> label="" name="settings.chatNew.emailEnabled" />,
                label: t("page.notification-settings.form.chat.new-chat"),
              },
            ]}
            headerLabels={{
              dashboard: t("page.notification-settings.labels.dashboard"),
              email: t("page.notification-settings.labels.email"),
            }}
          />
        </FormField>
      </GroupWrapper>
      <GroupWrapper title={t("page.notification-settings.form.email.title")}>
        <FormField label="">
          <div className="flex items-center gap-4">
            <div className="w-full max-w-[200px]">
              <FormSelect<FormValues, (typeof INTERVALS)[number]>
                id="send-community-summary-email-interval"
                name="settings.sendCommunitySummaryEmailInterval"
                items={INTERVALS}
                keySelector={(x) => x}
                renderOption={(x) => {
                  switch (x) {
                    case "everyDay":
                      return t("page.notification-settings.form.email.timed-value.every-day");
                    case "everyHour":
                      return t("page.notification-settings.form.email.timed-value.every-hour");
                    case "everyThreeHours":
                      return t("page.notification-settings.form.email.timed-value.every-three-hours");
                    case "never":
                      return t("page.notification-settings.form.email.timed-value.never");
                    default:
                      return x;
                  }
                }}
              />
            </div>
            <label htmlFor="send-community-summary-email-interval">
              {t("page.notification-settings.form.email.community-summary")}
            </label>
          </div>
        </FormField>

        <FormField label="">
          <FormCheckbox<FormValues>
            name="settings.sendMonthlyReportingEmail"
            label={t("page.notification-settings.form.email.monthly-report")}
          />
        </FormField>
      </GroupWrapper>
      <GroupWrapper title={t("page.notification-settings.form.tickets.title")}>
        <FormField label="">
          <Tooltip
            tooltip={
              isAssignedDisabled ? t("page.notification-settings.form.tickets.self-assigned.disabled") : undefined
            }
          >
            <div className="inline-block">
              <FormCheckbox
                label={t("page.notification-settings.form.tickets.self-assigned.label")}
                name="canBeAssigned"
                disabled={isAssignedDisabled}
              />
            </div>
          </Tooltip>
        </FormField>
        <FormField label={t("page.notification-settings.form.tickets.assigned-to-me.title")}>
          <FormCheckboxGrid
            rows={[
              {
                dashboard: (
                  <FormCheckbox<FormValues>
                    disabled={!canBeAssigned}
                    label=""
                    name="settings.ticketAssignToMe.dashboardEnabled"
                  />
                ),
                email: (
                  <FormCheckbox<FormValues>
                    disabled={!canBeAssigned}
                    label=""
                    name="settings.ticketAssignToMe.emailEnabled"
                  />
                ),
                label: t("page.notification-settings.form.tickets.assigned-to-me.is-assigned"),
              },
              {
                dashboard: (
                  <FormCheckbox<FormValues>
                    disabled={!canBeAssigned}
                    label=""
                    name="settings.ticketAssignedCommentNew.dashboardEnabled"
                  />
                ),
                email: (
                  <FormCheckbox<FormValues>
                    disabled={!canBeAssigned}
                    label=""
                    name="settings.ticketAssignedCommentNew.emailEnabled"
                  />
                ),
                label: t("page.notification-settings.form.tickets.assigned-to-me.is-assigned-new-comment"),
              },
            ]}
            headerLabels={{
              dashboard: t("page.notification-settings.labels.dashboard"),
              email: t("page.notification-settings.labels.email"),
            }}
          />
        </FormField>
        <FormField label={t("page.notification-settings.form.tickets.unassigned.title")}>
          <FormCheckboxGrid
            rows={ticketCategories.map((x, index) => ({
              dashboard: (
                <FormCheckbox<FormValues>
                  label=""
                  name={`settings.ticketUnassignedUpdates.${index}.dashboardEnabled`}
                />
              ),
              email: (
                <FormCheckbox<FormValues> label="" name={`settings.ticketUnassignedUpdates.${index}.emailEnabled`} />
              ),
              label: x.ticketCategoryName,
            }))}
            headerLabels={{
              dashboard: t("page.notification-settings.labels.dashboard"),
              email: t("page.notification-settings.labels.email"),
            }}
          />
        </FormField>
      </GroupWrapper>
      <FormContent>
        <Button type="submit" isLoading={isSubmitting}>
          {t("page.notification-settings.save")}
        </Button>
      </FormContent>
    </div>
  );
}

const INTERVALS = [
  "never",
  "everyHour",
  "everyThreeHours",
  "everyDay",
] as const satisfies readonly NotificationSettingsDto["sendCommunitySummaryEmailInterval"][];

export interface FormValues {
  canBeAssigned: boolean;
  settings: NotificationSettingsRequest;
}

function GroupWrapper({ title, children }: React.PropsWithChildren<{ title: string }>) {
  return (
    <div className="rounded-lg bg-white p-5">
      <h2 className="mb-2 text-body-bold">{title}</h2>
      <div className="flex flex-col gap-4">{children}</div>
    </div>
  );
}
