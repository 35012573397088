import type { UserDto } from "api/types";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import type React from "react";

interface SurveyAuthorProps {
  author: UserDto;
}

export function SurveyAuthor({ author }: SurveyAuthorProps): React.ReactNode {
  return (
    <div className="flex items-center gap-1">
      <div className="mr-2 size-8">
        <UserAvatarLink user={author} />
      </div>
      <UserNameLink user={author}>
        <span className="font-old-semibold text-black">{author.fullName}</span>
      </UserNameLink>
    </div>
  );
}
