import cameraPlusIcon from "assets/icons/camera-plus.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import { IconButton } from "components/Button/IconButton";
import { twResolve } from "helpers/tw-resolve";
import { useTranslation } from "translations";

import type { MediaBaseProps } from "./Media";
import { Media } from "./Media";
import { MediaXButton } from "./MediaXButton";

export interface ImageMediaProps extends MediaBaseProps {
  src: string | undefined;
}

export function ImageMedia({ src, ...props }: ImageMediaProps): React.ReactNode {
  return (
    <Media {...props} type="image">
      {src && <img className="relative size-full rounded-lg object-cover" src={src} alt="" />}
    </Media>
  );
}

export function AvatarMedia({
  src,
  onEdit,
  onDelete,
  onAdd,
  ...props
}: Omit<ImageMediaProps, "theme" | "texts">): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-2">
      <div
        {...props}
        className={twResolve(
          "relative size-32 rounded-full border border-grey-300",
          props["aria-invalid"] && "border-red-600",
          props.disabled && "bg-grey-100",
          props.className,
        )}
      >
        {src && (
          <>
            {onEdit && (
              <IconButton
                title={t("common.action.edit")}
                className="absolute left-0 top-0 z-10 !size-full rounded-full border-none !bg-black/60 text-white opacity-0 transition-opacity focus-visible:opacity-100 focus-visible:ring-1 focus-visible:ring-black hocus:opacity-100"
                data-testid={props["data-edit-testid"]}
                type="button"
                onClick={onEdit}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                withTooltip={false}
                icon={iconEdit05}
              />
            )}
            <img className="relative left-0 top-0 size-full rounded-full object-cover" {...{ src }} />
            {onDelete != null && !props.disabled && (
              <div className="absolute right-2 top-2 z-10 -translate-y-1/2 translate-x-1/2">
                <MediaXButton
                  data-testid={props["data-delete-testid"]}
                  onClick={onDelete}
                  title={t("common.action.delete")}
                />
              </div>
            )}
          </>
        )}
        {!src && (
          <IconButton
            title={t("common.action.add-a-photo")}
            className="absolute left-0 top-0 z-10 !size-full rounded-full border-none text-black/60 focus-visible:ring-1 focus-visible:ring-black hover:text-black"
            data-testid={props["data-add-testid"]}
            type="button"
            onClick={onAdd}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            withTooltip={false}
            icon={cameraPlusIcon}
          />
        )}
      </div>
    </div>
  );
}
