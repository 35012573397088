import type { AutomatedSurveyQueueQuestionDetailsListDto } from "api/types";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { NoData } from "modules/analytics/components/NoData";
import { QueueDetailsModal } from "modules/automated-surveys/components/QueueDetailsModal";
import { usePostHog } from "posthog-js/react";
import { useParams } from "react-router";
import { useTranslation } from "translations";

import {
  SurveyQuestionGroupAnswerCardBarChart,
  SurveyQuestionGroupAnswerCardHeader,
  SurveyQuestionGroupAnswerCardLastResponseSection,
  SurveyQuestionGroupAnswerCardRating,
} from "./SurveyQuestionGroupAnswerCard";
import { SurveyQuestionGroupAnswerSentimentModal } from "./SurveyQuestionGroupAnswerSentimentModal";

interface AutomatedSurveyQuestionGroupAnswerCardProps {
  order: number;
  question: AutomatedSurveyQueueQuestionDetailsListDto;
}

export function AutomatedSurveyQuestionGroupAnswerCard({
  order,
  question,
}: AutomatedSurveyQuestionGroupAnswerCardProps): React.ReactNode {
  const [isQuestionModalOpened, questionModalHandler] = useBool(false);
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);

  const { t } = useTranslation();
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();
  const postHog = usePostHog();
  const { id: surveyId } = useParams<{ id: string }>();

  const onClickSentimentAnalysis = () => {
    postHog?.capture("clicked_automatic_sentiment_analysis", {
      surveyId,
    });

    sentimentModalOpenHandlers.setTrue();
  };

  const onClickOpenDetails = () => {
    questionModalHandler.setTrue();
  };

  const getHistogramData = (data: Record<string, number>) => {
    const graphData: { x: string; y: number }[] = [];

    for (let i = 2; i <= 10; i += 2) {
      graphData.push({ x: String(i), y: data[String(i)] });
    }

    return graphData;
  };

  const histogramData = getHistogramData(question.ratingGroupedNumber);
  const isDataAvailable = histogramData.some((dataPoint) => Boolean(dataPoint.y));

  return (
    <>
      <div className="flex w-full flex-col justify-between gap-8 rounded-lg bg-white p-4 md:p-6">
        <SurveyQuestionGroupAnswerCardHeader
          questionTitle={question.ratingQuestionText || ""}
          category={question.questionGroupType}
          {...{ order }}
        />

        <div className="flex w-full flex-col items-center justify-center gap-2 px-10 md:flex-row md:gap-10">
          <div className="flex flex-[0.4]">
            <SurveyQuestionGroupAnswerCardRating rating={question.averageRating} />
          </div>
          {/* Chart */}
          <div className="flex flex-[0.6]">
            {isDataAvailable ? <SurveyQuestionGroupAnswerCardBarChart data={histogramData} /> : <NoData error={null} />}
          </div>
        </div>

        <SurveyQuestionGroupAnswerCardLastResponseSection
          questionTitle={question.openQuestionText || ""}
          lastResponse={question.latestOpenQuestionResponse}
        />

        <div className="flex w-full flex-col justify-center gap-2 md:flex-row md:justify-end">
          {isDataAvailable && (
            <>
              {isAIToolingAvailable && (
                <AIButton onClick={onClickSentimentAnalysis}>{t("page.automated-surveys.sentiment.btn")}</AIButton>
              )}
              <Button styling="primary" onClick={onClickOpenDetails}>
                {t("component.surveys-question-group-answer.button.details")}
              </Button>
            </>
          )}
        </div>
      </div>

      {isAIToolingAvailable && (
        <SurveyQuestionGroupAnswerSentimentModal
          questionGroupId={question.questionGroupId}
          isOpened={isSentimentModalOpen}
          onOpenChange={sentimentModalOpenHandlers.set}
        />
      )}
      <QueueDetailsModal
        isOpened={isQuestionModalOpened}
        onOpenChange={questionModalHandler.set}
        questionData={{
          questionNum: order,
          ratingQuestion: question.ratingQuestionText!,
          openQuestion: question.openQuestionText!,
        }}
        questionId={question.questionGroupId}
      />
    </>
  );
}
