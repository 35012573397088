import type { MessageStatusChangeRequestV2 } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormTagSelect } from "components/Form/FormTagSelect";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { communityFeedMutations } from "queries/communityFeed";
import type React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

type CommunityPostClosePostSoldReasonModalProps = ModalBaseProps & {
  messageId: string;
};

type CommunityPostClosePostSoldModalFormValues = {
  reason: Extract<
    MessageStatusChangeRequestV2["reason"],
    "marketplaceSoldInsideCommunity" | "marketplaceSoldOutsideCommunity"
  >[];
};

export function CommunityPostClosePostSoldReasonModal({
  messageId,
  isOpened,
  onOpenChange,
}: CommunityPostClosePostSoldReasonModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<CommunityPostClosePostSoldModalFormValues>({
    defaultValues: {
      reason: [],
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpened, form]);

  const updateMessageStatus = communityFeedMutations.useUpdateMessageStatus();

  const handleSubmit = async (data: CommunityPostClosePostSoldModalFormValues) => {
    if (data.reason.length === 0) return;

    await updateMessageStatus.mutateAsync({
      messageId,
      data: {
        newStatus: "deleted",
        reason: data.reason[0],
      },
    });

    onOpenChange(false);
  };

  return (
    <Modal.Root title={t("component.community-post.close-post-confirm-modal.title")} {...{ isOpened, onOpenChange }}>
      <Form formMethods={form} onSubmit={handleSubmit}>
        <FormContent maxWidth="none" className="pb-4">
          <FormField
            label={t("component.community-post.close-post-sold-modal.reason-field.label")}
            description={t("component.community-post.close-post-sold-modal.reason-field.description")}
            required
          >
            <div className="w-full pt-4">
              <FormTagSelect<CommunityPostClosePostSoldModalFormValues, "reason">
                name="reason"
                data-testid="closing-post-sold-modal-options-container"
                items={[
                  {
                    label: t("component.community-post.close-post-sold-modal.reason-field.option.inside-community"),
                    value: "marketplaceSoldInsideCommunity",
                  },
                  {
                    label: t("component.community-post.close-post-sold-modal.reason-field.option.outside-community"),
                    value: "marketplaceSoldOutsideCommunity",
                  },
                ]}
                rules={{
                  validate: {
                    required: (value) => {
                      if (value.length === 0) {
                        return t("component.community-post.close-post-sold-modal.reason-field.error");
                      }
                    },
                  },
                }}
              />
            </div>
          </FormField>
        </FormContent>

        <Modal.Actions>
          <Button data-testid="confirm-deletion-btn" type="submit" isLoading={updateMessageStatus.isPending}>
            {t("component.community-post.close-post-confirm-modal.btn.delete-post")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
