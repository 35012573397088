import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatISO, parse } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const ITEM_AMOUNT = 20;

const QUERY_PARAMS = [] as const;

export type MessageFeedParams = Omit<ApiQueryParams<"getMessagesV2">, "Offset" | "Limit">;
type MessageFeedQueryParams = Pick<MessageFeedParams, (typeof QUERY_PARAMS)[number]>;
type MessageFeedMemoryParams = Omit<MessageFeedParams, keyof MessageFeedQueryParams>;
export type OnUpdateFilters = <T extends "StartDate" | "EndDate" | "GroupIds" | "Query" | "Tab" | "Connection">(
  key: T,
  value: MessageFeedParams[T],
) => void;

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  const [memoryFilters, setMemoryFilters] = useState<MessageFeedMemoryParams>(() => ({
    Tab: sessionUser.isAdmin ? "all" : "myFeed",
    Connection: "included",
  }));

  const onUpdateFilters = useCallback<OnUpdateFilters>((key, value) => {
    setMemoryFilters((oldFilters) => ({
      ...oldFilters,
      [key]: value,
    }));
  }, []);

  const messageFeedQuery = {
    ...memoryFilters,
    StartDate: memoryFilters.StartDate ? formatDateToDateTime(memoryFilters.StartDate, false) : undefined,
    EndDate: memoryFilters.EndDate ? formatDateToDateTime(memoryFilters.EndDate, true) : undefined,
    Connection: memoryFilters.Connection ?? "included",
    FeedTypes: ["messages", "polls", "surveys"],
  } satisfies ApiQueryParams<"getCommunityFeedV2">;
  const {
    data: messageData,
    hasNextPage: hasMoreMessages,
    fetchNextPage: fetchMoreMessages,
    isPending: isPendingMessages,
    isFetchingNextPage: isLoadingMoreMessages,
    error: messagesError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_FEED(projectId, messageFeedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getCommunityFeedV2({
          ...messageFeedQuery,
          Limit: ITEM_AMOUNT,
          Offset: pageParam * ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  function refetchFeed() {
    void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED(projectId, messageFeedQuery) });
  }

  const queryArgs = {
    Limit: 10000,
  } satisfies ApiQueryParams<"getGroupsV2">;

  const {
    data: groups = [],
    isPending: isLoadingGroups,
    error: groupsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUPS_QUERY(projectId, queryArgs),
    queryFn: () => api.getGroupsV2(queryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const { data: stats, error: statsError } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId),
    queryFn: () => api.getMessagesUnreadStatsV1(),
    select: commonAPIDataSelector,
  });

  const markAllAsRead = useMutation({
    mutationFn: () => api.postMessagesMarkAllAsReadV1(),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId) });
      messages.forEach((message) => {
        if (message.type === "message" && message.message) {
          void queryClient.setQueryData(QUERY_KEYS.MESSAGES_DETAILS(projectId, message.message.id), {
            ...message.message,
            isUnread: false,
          });
        }
      });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.mark-all-as-read.error") });
    },
  });

  const messages = useMemo(() => messageData?.pages.flatMap((x) => x.items) ?? [], [messageData]);
  const totalMessages = useMemo(() => messageData?.pages[0].total ?? 0, [messageData]);

  const error = messagesError || groupsError || statsError || undefined;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    sessionUser,
    groups,
    isLoadingGroups,
    stats,
    messages,
    totalMessages,
    hasMoreMessages,
    fetchMoreMessages,
    refetchMessages: refetchFeed,
    markAllMessagesAsRead: markAllAsRead.mutate,
    isLoadingMessages: isPendingMessages,
    isLoadingMoreMessages,
    filters: memoryFilters,
    onUpdateFilters,
  });
}

function formatDateToDateTime(dateStr: string, endOfDay: boolean) {
  const date = parse(dateStr, "yyyy-MM-dd", new Date());
  date.setHours(endOfDay ? 23 : 0);
  date.setMinutes(endOfDay ? 59 : 0);
  date.setSeconds(endOfDay ? 59 : 0);
  date.setMilliseconds(0);

  return formatISO(date);
}
