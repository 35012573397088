import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AddressDto, UserDto } from "api/types";
import iconDownload01 from "assets/icons/download-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconRefreshCcw01 from "assets/icons/refresh-ccw-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ContextMenu, type ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { formatAddress } from "helpers/address";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { AddressesExportModal } from "modules/addresses/components/AddressesExportModal";
import { canEditAddresses, canManageAddresses } from "modules/addresses/permissions";
import { addressMutations } from "queries/addresses/mutations";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

const DEBOUNCE_WAIT = 200;

export function Layout(): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<AddressDto>("address-delete-modal");
  const [locationCodeModalAddressId, setLocationCodeModalAddressId] = useState<string>();
  const [isRefreshingLocationCode, refreshingLocationCodeHandlers] = useBool();
  const [query, setQuery] = useState("");
  const [addressTypeFilter, setAddressTypeFilter] = useState<string[]>([]);
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));
  const [isExportModalOpened, exportModalOpenHandlers] = useBool();
  const { t } = useTranslation();
  const addressQueries = useAddressQueries();
  const { data: addressStats } = useQuery(addressQueries.getAddressStats());
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    isFetchingNextPage: isFetchingMoreAddresses,
    hasNextPage: hasMoreAddresses,
    fetchNextPage: loadMoreAddresses,
    error: addressesError,
  } = useInfiniteQuery(addressQueries.getAddressesInfinite(deferredQuery, addressTypeFilter));
  const { data: addressTypes, error: addressTypesError } = useQuery(addressQueries.getAddressTypes());

  const deleteAddress = addressMutations.useDeleteAddress();
  const refreshLocationCode = addressMutations.useRefreshLocationCode();

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreAddresses && hasMoreAddresses) {
        void loadMoreAddresses();
      }
    }, [isFetchingMoreAddresses, hasMoreAddresses, loadMoreAddresses]),
  });

  const mappedAddresses = useMemo(
    () =>
      addresses?.pages
        .flatMap((x) => x.data.items)
        .map((x) => ({
          ...x,
          address: formatAddress(x),
          registeredUsers: x.users.filter((u) => u.registeredAt),
          notRegisteredUsers: x.users.filter((u) => !u.registeredAt),
        })) || [],
    [addresses],
  );

  const hasPermission = usePermission();
  const canManage = hasPermission(canManageAddresses);
  const canEdit = hasPermission(canEditAddresses);

  type ColumnType = (typeof mappedAddresses)[number];

  const columns = useMemo(() => {
    const helper = createColumnHelper<ColumnType>();

    return [
      helper.accessor("building.name", {
        header: t("page.addresses.list.building"),
      }),
      helper.accessor("address", {
        header: t("page.addresses.list.address"),
        cell: (cell) =>
          canEdit ? (
            <Anchor to={routes.addresses.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <div>{cell.getValue()}</div>
          ),
      }),
      ...(sessionUser.project.type === "addressBased"
        ? [
            helper.accessor("locationCode", {
              header: t("page.addresses.list.location-code"),
              cell: (cell) => (
                <div className="flex items-center justify-between gap-2">
                  <span>{cell.getValue()}</span>
                  {canEdit ? (
                    <IconButton
                      styling="tertiary"
                      className="text-aop-basic-blue-500"
                      title={t("page.addresses.list.refresh-location-code")}
                      onClick={() => setLocationCodeModalAddressId(cell.row.original.id)}
                      icon={iconRefreshCcw01}
                      size="sm"
                    />
                  ) : null}
                </div>
              ),
            }),
          ]
        : []),
      ...(sessionUser.project.type === "addressBased"
        ? [
            helper.accessor("registeredUsers", {
              header: t("page.addresses.list.onboarded-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
            helper.accessor("notRegisteredUsers", {
              header: t("page.addresses.list.invited-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
          ]
        : []),
      helper.accessor("typeConstraint", {
        header: t("page.addresses.list.type"),
        cell: (cell) => <div>{cell.getValue()?.key ?? "-"}</div>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();
          const menuActions: ContextMenuItem[] = [
            {
              labels: {
                default: t("common.action.edit"),
              },
              value: "edit",
              "data-testid": "context-menu-edit-btn",
              icon: iconEdit05,
              type: "link",
              href: routes.addresses.edit({ slug, id }),
              isHidden: !canEdit,
            },
            {
              labels: {
                default: t("common.action.delete"),
                disabled: t("page.addresses.list.actions.delete.forbidden"),
              },
              value: "delete",
              "data-testid": "context-menu-delete-btn",
              icon: iconTrash02,
              type: "button",
              callbackFn: () => openDeleteModal(cell.row.original),
              isHidden: !canManage,
              isDisabled: cell.row.original.users.some((x) => x.deletedAt == null),
            },
          ];

          return (
            <div className="flex justify-end px-2">
              <ContextMenu items={menuActions} />
            </div>
          );
        },
        maxSize: 50,
        minSize: 50,
        size: 50,
      }),
    ];
  }, [canEdit, canManage, slug, openDeleteModal, sessionUser.project.type, t]);

  const tableInstance = useReactTable({
    columns,
    data: mappedAddresses,
    getCoreRowModel: getCoreRowModel(),
  });

  const isSearch = deferredQuery !== "" || addressTypeFilter.length > 0;

  const error = addressesError || addressTypesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.addresses.title")}
      subTitle={t("page.addresses.subtitle")}
      actions={
        <>
          {!isSearch && (
            <Button
              icon={<Icon name={iconDownload01} size={16} />}
              styling="secondary"
              onClick={exportModalOpenHandlers.setTrue}
            >
              {t("page.addresses.download-export")}
            </Button>
          )}
          {canManage && (
            <Button type="link" href={routes.addresses.new({ slug })} data-testid="address-create-link">
              {t("page.addresses.actions.create")}
            </Button>
          )}
        </>
      }
      header={
        <div className="flex flex-col flex-wrap items-center justify-between gap-4 md:flex-row">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <SearchInput
              className="h-10"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("page.addresses.list.search.placeholder")}
            />
            {addressTypes && addressTypes.length > 1 && (
              <div className="min-w-52">
                <MultiSelect
                  placeholder={t("page.addresses.list.filter.placeholder")}
                  selected={addressTypeFilter}
                  items={addressTypes}
                  renderOption={(x) => x}
                  keySelector={(x) => x}
                  onChange={setAddressTypeFilter}
                />
              </div>
            )}
          </div>
          <p className="pl-2 text-right text-grey-700">
            {addresses?.pages[0]?.data.total && (
              <span>
                {t("page.addresses.list.address-count", {
                  count: addresses?.pages[0]?.data.total,
                })}
              </span>
            )}
            {sessionUser.project.type === "addressBased" && addressStats && (
              <>
                <span> </span>
                <span>
                  {t("page.addresses.list.registered", {
                    registered: addressStats.registeredAddresses,
                    addresses: addressStats.totalAddresses === 0 ? 0 : addressStats.totalAddresses - 1,
                    percentage:
                      addressStats.totalAddresses === 0
                        ? 0
                        : Math.min(
                            Math.round((addressStats.registeredAddresses / (addressStats.totalAddresses - 1)) * 100),
                            100,
                          ),
                  })}
                </span>
              </>
            )}
          </p>
        </div>
      }
    >
      <div className="overflow-auto">
        {mappedAddresses.length === 0 ? (
          addressStats &&
          !isLoadingAddresses && (
            <div className="rounded-lg bg-white p-5">
              <p>
                {addressStats.totalAddresses === 0
                  ? t("page.addresses.list.empty")
                  : t("page.addresses.list.no-result")}
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col gap-8 pb-8">
            <Table
              table={tableInstance}
              data-testid="address-list"
              getRowProps={() => ({
                "data-testid": "address-item",
              })}
            />
          </div>
        )}
        {(hasMoreAddresses || isLoadingAddresses) && (
          <div className="p-4" ref={ref}>
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          </div>
        )}
      </div>
      <AddressesExportModal
        isOpened={isExportModalOpened}
        onOpenChange={exportModalOpenHandlers.set}
        {...{ addresses: mappedAddresses }}
      />
      <DeleteModal
        title={t("page.addresses.actions.delete.title")}
        description={t("page.addresses.actions.delete.description")}
        onDelete={deleteAddress.mutateAsync}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        data-testid="location-code-modal"
        title={t("page.addresses.list.location-code.refresh.title")}
        description={t("page.addresses.list.location-code.refresh.description")}
        isOpened={!!locationCodeModalAddressId}
        onOpenChange={(state) => {
          if (!state) {
            setLocationCodeModalAddressId(undefined);
          }
        }}
        shouldCloseOnEsc
        onReject={() => setLocationCodeModalAddressId(undefined)}
        rejectBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.cancel"),
        }}
        onResolve={async () => {
          try {
            if (locationCodeModalAddressId) {
              refreshingLocationCodeHandlers.setTrue();
              await refreshLocationCode.mutateAsync(locationCodeModalAddressId);
            }
          } finally {
            refreshingLocationCodeHandlers.setFalse();

            setLocationCodeModalAddressId(undefined);
          }
        }}
        resolveBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.confirm"),
        }}
        isLoading={isRefreshingLocationCode}
      />
    </DocumentPaper>
  );
}

function UsersList({ users }: { users: UserDto[] }) {
  const slug = useSlug();

  return (
    <div className="flex flex-col py-2">
      {users.map((x) =>
        x.canViewProfile ? (
          <Anchor key={x.id} to={routes.users.details({ slug, id: x.id })}>
            <span className="my-0.5 text-caption">{x.fullName}</span>
          </Anchor>
        ) : (
          <span key={x.id}>{x.fullName}</span>
        ),
      )}
    </div>
  );
}
