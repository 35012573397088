import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

interface RemsFormValues {
  enabled: boolean;
  tenantId: string;
  communityUserContactId: string;
  communityUserRelationId: string;
  defectCode: string;
  complexCode?: string;
}

export function RemsForm({
  defaultValues,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<RemsFormValues>;
  isSubmitting: boolean;
  onSubmit: (data: RemsFormValues) => void;
}): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<RemsFormValues>({ defaultValues });
  const enabled = useWatch({ control: formMethods.control, name: "enabled" });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent maxWidth="3xl">
        <FormField label={""} htmlFor="enabled">
          <FormCheckbox<RemsFormValues, "enabled">
            id="enabled"
            name="enabled"
            label={t("page.integration.rems.form.enabled")}
            onChange={(e) => {
              if (enabled && !e.target.checked) {
                void formMethods.trigger();
              }

              formMethods.setValue("enabled", e.target.checked);
            }}
          />
        </FormField>

        <FormField label={t("page.integration.rems.form.tenant-id")} required htmlFor="tenantId">
          <FormInput<RemsFormValues, "tenantId">
            id="tenantId"
            name="tenantId"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.rems.form.tenant-id")
                  : () => undefined,
              },
            }}
            placeholder="00000000-0000-0000-0000-000000000000"
            className="max-w-[38ch]"
            disabled={!enabled}
          />
        </FormField>

        <FormField
          label={t("page.integration.rems.form.community-user-contact-id")}
          required
          htmlFor="communityUserContactId"
          tooltip={t("page.integration.rems.form.community-user-contact-id.tooltip")}
        >
          <FormInput<RemsFormValues, "communityUserContactId">
            id="communityUserContactId"
            name="communityUserContactId"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.rems.form.community-user-contact-id")
                  : () => undefined,
              },
            }}
            className="max-w-24"
            placeholder="1234"
            disabled={!enabled}
          />
        </FormField>

        <FormField
          label={t("page.integration.rems.form.community-user-relation-id")}
          required
          htmlFor="communityUserRelationId"
          tooltip={t("page.integration.rems.form.community-user-relation-id.tooltip")}
        >
          <FormInput<RemsFormValues, "communityUserRelationId">
            id="communityUserRelationId"
            name="communityUserRelationId"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.rems.form.community-user-relation-id")
                  : () => undefined,
              },
            }}
            className="max-w-24"
            placeholder="1234"
            disabled={!enabled}
          />
        </FormField>

        <FormField
          label={t("page.integration.rems.form.defect-code")}
          required
          htmlFor="defectCode"
          tooltip={t("page.integration.rems.form.defect-code.tooltip")}
        >
          <FormInput<RemsFormValues, "defectCode">
            id="defectCode"
            name="defectCode"
            rules={{
              validate: {
                required: enabled
                  ? createRequiredStringRule(t, "page.integration.rems.form.defect-code")
                  : () => undefined,
              },
            }}
            className="max-w-36"
            placeholder="AA00000"
            disabled={!enabled}
          />
        </FormField>

        <FormField
          label={t("page.integration.rems.form.complex-code")}
          htmlFor="complexCode"
          tooltip={t("page.integration.rems.form.complex-code.tooltip")}
        >
          <FormInput<RemsFormValues, "complexCode">
            id="complexCode"
            name="complexCode"
            className="max-w-36"
            placeholder="0000.0000"
            disabled={!enabled}
          />
        </FormField>

        <Button type="submit" isLoading={isSubmitting}>
          {t("page.integration.rems.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}
