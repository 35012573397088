import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageMultipleProjectsCreateRequest } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";
import { useTranslation } from "translations";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export type OptimizeType = "refresh" | "makeShorter" | "makeMorePositive" | "makeUnderstandable";
export type MessageTypes = Exclude<ApiQueryParams<"getMassMessagesProjectsV1">["MessageType"], undefined>;

export function Loader({ children }: LoaderProps): React.ReactNode {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  const [selectedMessageType, setSelectedMessageType] = useState<MessageTypes>("undefined");
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);

  const {
    data: allowedMassMessageTypes,
    isFetching: isLoadingMassMessageTypes,
    error: massMessageTypesError,
  } = useQuery({
    queryKey: QUERY_KEYS.MASS_MESSAGES_PERMISSION(),
    queryFn: api.getMassMessagesV1,
    select: commonAPIDataSelector,
  });

  const {
    data: groups = [],
    isFetching: isLoadingGroups,
    error: groupsError,
  } = useQuery({
    queryKey: QUERY_KEYS.MASS_MESSAGES_GROUPS(selectedMessageType),
    queryFn: () => api.getMassMessagesGroupsV1({ MessageType: selectedMessageType, Sorting: "name" }),
    select: commonAPIDataSelector,
  });

  const {
    data: allowedProjects,
    isFetching: isLoadingProjects,
    error: allowedProjectsError,
  } = useQuery({
    queryKey: QUERY_KEYS.MASS_MESSAGES_PROJECTS(selectedMessageType, selectedGroupId),
    queryFn: () => api.getMassMessagesProjectsV1({ MessageType: selectedMessageType, GroupId: selectedGroupId }),
    select: commonAPIDataSelector,
  });

  const optimizePost = useMutation({
    mutationFn: ({ input, option }: { input: string; option?: Exclude<OptimizeType, "refresh"> }) =>
      api.postCopilotV1({ userInput: input, copilotOptions: option ? [option] : [] }).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.optimize.copilot-error") });
    },
  });

  const { uploadFormImage } = useUploadImage();
  const { uploadFormDocument } = useUploadDocument();
  const { uploadFormVideo } = useUploadVideo({});

  const createPost = async ({
    payload,
    images,
    documents,
    videos,
  }: {
    payload: MessageMultipleProjectsCreateRequest;
    images: FormImage[];
    documents: FormDocument[];
    videos: FormVideo[];
  }) => {
    setIsSubmitting(true);

    const imageUploadPromises = images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = documents.map((document) => uploadFormDocument(document));
    // Curently only 1 video is allowed
    const videoUploadPromises = videos.map((video) => uploadFormVideo(video));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];
    const videoIds = (await Promise.allSettled(videoUploadPromises.map((video) => video)))
      .map((video) => (video.status === "fulfilled" ? video.value?.id : ""))
      .filter(Boolean) as string[];

    if (
      imageIds.length === images.length &&
      documentIds.length === documents.length &&
      videoIds.length === videos.length
    ) {
      return await createMessage.mutateAsync({
        payload: { ...payload, imageIds, documentIds, videoIds },
      });
    }

    setIsSubmitting(false);
  };

  const createMessage = useMutation({
    mutationFn: ({ payload }: { payload: MessageMultipleProjectsCreateRequest }) =>
      api.postMassMessagesV1(payload).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.create.notifications.error") });
    },
  });

  const error = massMessageTypesError || groupsError || allowedProjectsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingMassMessageTypes;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    setSelectedMessageType,
    allowedMassMessageTypes,
    setSelectedGroupId,
    groups,
    isLoadingGroups,
    allowedProjects: allowedProjects ?? [],
    isLoadingProjects,
    optimizePost: optimizePost.mutateAsync,
    isOptimizingPost: optimizePost.isPending,
    onSubmit: createPost,
    isSubmitting: isSubmitting,
  });
}
