import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useChatQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  return {
    details: ({ chatId }: { chatId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.CHATS_DETAILS(projectId, chatId),
        queryFn: () => api.getChatsDetailsV2(chatId),
        select: commonAPIDataSelector,
      }),
    userDetails: ({ userId }: { userId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.USER_CHAT_DETAILS(projectId, userId),
        queryFn: () => api.getChatsUserDetailsV2(userId),
        select: commonAPIDataSelector,
      }),
    listChatReplies: ({ chatId }: { chatId: string }) => {
      const limit = 10;

      return infiniteQueryOptions({
        queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId),
        queryFn: ({ pageParam = 0 }) =>
          api
            .getChatsRepliesV2(chatId, { Offset: pageParam * limit, Limit: limit })
            .then((items) => commonAPIDataSelector(items)),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
        getPreviousPageParam: () => {
          return 0;
        },
      });
    },
  };
};
