import type { FeelingAtHomeSurveyQuestionV2Dto } from "api/types";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import {
  SurveyQuestionGroupAnswerCardBarChart,
  SurveyQuestionGroupAnswerCardHeader,
  SurveyQuestionGroupAnswerCardRating,
} from "components/SurveyQuestionGroupAnswerCard/SurveyQuestionGroupAnswerCard";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { NoData } from "modules/analytics/components/NoData";
import type React from "react";
import { useTranslation } from "translations";

import { FahHistoryQuestionGroupAnswerSentimentModal } from "./FahHistoryQuestionGroupAnswerSentimentModal";
import { FahHistoryQuestionResponsesModal } from "./FahHistoryQuestionResponsesModal";

interface FahHistoryQuestionGroupCardProps {
  order: number;
  question: FeelingAtHomeSurveyQuestionV2Dto;
}

export function FahHistoryQuestionGroupCard({ order, question }: FahHistoryQuestionGroupCardProps): React.ReactNode {
  const [isResponsesModalOpened, responsesModalHandlers] = useBool();
  const [isSentimentModalOpen, sentimentModalHandlers] = useBool();

  const { t } = useTranslation();
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  const getHistogramData = (data: Record<string, number>) => {
    const graphData: { x: string; y: number }[] = [];

    for (let i = 2; i <= 10; i += 2) {
      graphData.push({ x: String(i), y: data[String(i)] });
    }

    return graphData;
  };

  const histogramData = getHistogramData(question.ratingGroupedNumber);
  const isDataAvailable = histogramData.some((dataPoint) => Boolean(dataPoint.y));

  return (
    <>
      <div className="flex w-full flex-col justify-between gap-10 rounded-lg bg-white p-4 md:gap-6 md:p-6">
        <SurveyQuestionGroupAnswerCardHeader
          questionTitle={question.ratingQuestionText || ""}
          category={question.category}
          {...{ order }}
        />
        <div className="grid w-full grid-cols-1 grid-rows-[auto_1fr] gap-4 md:grid-cols-[0.4fr_0.6fr] md:grid-rows-1 md:gap-10 md:px-10">
          <SurveyQuestionGroupAnswerCardRating rating={question.averageRating} />
          {isDataAvailable ? <SurveyQuestionGroupAnswerCardBarChart data={histogramData} /> : <NoData error={null} />}
        </div>

        {isDataAvailable && (
          <div className="flex w-full flex-wrap justify-end gap-2">
            {isAIToolingAvailable && (
              <AIButton onClick={sentimentModalHandlers.setTrue}>{t("page.automated-surveys.sentiment.btn")}</AIButton>
            )}
            <Button styling="primary" onClick={responsesModalHandlers.setTrue}>
              {t("component.surveys-question-group-answer.button.details")}
            </Button>
          </div>
        )}
      </div>
      <FahHistoryQuestionResponsesModal
        isOpened={isResponsesModalOpened}
        onOpenChange={responsesModalHandlers.set}
        {...{ order, question }}
      />
      <FahHistoryQuestionGroupAnswerSentimentModal
        isOpened={isSentimentModalOpen}
        onOpenChange={sentimentModalHandlers.set}
        questionType={question.category}
      />
    </>
  );
}
