import { useQuery } from "@tanstack/react-query";
import { Button } from "components/Button/Button";
import type { Segment } from "components/SegmentGroup/SegmentGroup";
import { SegmentGroup } from "components/SegmentGroup/SegmentGroup";
import { SUPPORT_EMAIL } from "helpers/constants";
import { AnimatePresence, motion } from "motion/react";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { useCallback, useState } from "react";
import { useTranslation } from "translations";

import { ErrorState, LoadingState } from "../States/States";
import { FahWidgetDistributionSection } from "./FahWidgetDistributionSection";
import { FahWidgetScoreSection } from "./FahWidgetScoreSection";
import { isFahLastMonthAvailable, isFahLastSixMonthsAvailable } from "./helpers";
import type { FahWidgetStage } from "./types";

export function FahWidget(): React.ReactNode {
  const [activeStage, setActiveStage] = useState<FahWidgetStage>("average");

  const { t } = useTranslation();
  const analyticsQueries = useAnalyticsQueries();
  const queryFahRecentStats = useQuery(analyticsQueries.getFah());
  const queryFahLastSixMonthsStats = useQuery(analyticsQueries.getFahMonthlyStats("last6Months"));

  const onReloadData = () => {
    void queryFahRecentStats.refetch();
    void queryFahLastSixMonthsStats.refetch();
  };

  const getSegments = useCallback(() => {
    if (!queryFahLastSixMonthsStats.data || !queryFahRecentStats.data) {
      return [];
    }

    // #1 segment - Average
    const segments: Segment<FahWidgetStage>[] = [
      {
        label: t("component.analytics-widget.fah.segment.average.label"),
        value: "average",
      },
    ];

    // #2 segment - Last 6 months
    if (isFahLastSixMonthsAvailable(queryFahLastSixMonthsStats.data)) {
      segments.push({
        label: t("component.analytics-widget.fah.segment.last-6-months.label"),
        value: "lastSixMonths",
      });
    }

    // #3 segment - Last month
    if (isFahLastMonthAvailable(queryFahRecentStats.data)) {
      segments.push({
        label: t("component.analytics-widget.fah.segment.last-month.label"),
        value: "lastMonth",
      });
    }

    return segments;
  }, [queryFahLastSixMonthsStats.data, queryFahRecentStats.data, t]);

  const isFahNotStarted =
    !queryFahRecentStats.isPending && !queryFahRecentStats.isError && queryFahRecentStats.data.status === "notStarted";

  const isLoading = queryFahRecentStats.isPending || queryFahLastSixMonthsStats.isPending;
  const isError = queryFahRecentStats.isError || queryFahLastSixMonthsStats.isError;
  const error = queryFahRecentStats.error || queryFahLastSixMonthsStats.error;

  return (
    <div className="w-full overflow-hidden rounded-lg bg-aop-dark-blue-500 p-6">
      <div className="relative flex w-full flex-col items-center overflow-hidden rounded-lg bg-white">
        <div className="flex w-full flex-col items-center gap-6 p-6 pb-0">
          <h1 className="w-full text-center text-headline1-medium leading-[32px]">
            {t("component.analytics-widget.fah.title")}
          </h1>
          <SegmentGroup<FahWidgetStage> segments={getSegments()} value={activeStage} onChange={setActiveStage} />
        </div>

        <div className="grid w-full grid-rows-[auto_auto] transition xl:grid-cols-2 xl:grid-rows-1 [&>:first-child]:border-b [&>:first-child]:border-aop-dark-blue-500 xl:[&>:first-child]:border-none [&>div]:px-3 [&>div]:py-6 xl:[&>div]:px-4">
          <FahWidgetScoreSection stage={activeStage} />
          <FahWidgetDistributionSection stage={activeStage} />
        </div>

        <AnimatePresence>
          {isLoading && <LoadingState />}
          {isError && <ErrorState onReload={onReloadData} {...{ error }} />}
          {isFahNotStarted && <EmptyState />}
        </AnimatePresence>
      </div>
    </div>
  );
}

function EmptyState() {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: "linear" }}
      className="absolute inset-0 z-20 flex flex-col items-center justify-between gap-4 bg-white/90 p-4 backdrop-blur-sm"
    >
      <div className="flex flex-col gap-1">
        <h2 className="text-center text-headline2">{t("component.analytics-widget.fah.empty.title")}</h2>
        <p className="text-center">{t("component.analytics-widget.fah.empty.description")}</p>
      </div>
      <Button type="link" href={`mailto:${SUPPORT_EMAIL}?subject=Request to start FAH survey`} isExternal>
        {t("component.analytics-widget.fah.get-help-success.btn")}
      </Button>
    </motion.div>
  );
}
