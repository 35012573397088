import type { MessageCommentReactionDto, MessageReactionDto } from "api/types";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import type React from "react";
import { useTranslation } from "translations";

import { getReactionImage, getReactionLabel } from "./CommunityPost/helpers";

interface ReactionListItemProps {
  reaction: MessageReactionDto | MessageCommentReactionDto;
}

export function ReactionListItem({ reaction }: ReactionListItemProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <li className="flex items-center gap-4">
      <div className="relative size-8">
        <UserAvatarLink user={reaction.author} />
        <span className="absolute bottom-0 right-0 translate-x-1/2 rounded-full border border-grey-100 bg-white">
          <img
            className="h-4"
            src={getReactionImage(reaction.reactionType)}
            alt={getReactionLabel(reaction.reactionType, t)}
          />
        </span>
      </div>
      <UserNameLink user={reaction.author}>
        <span className="text-caption-bold text-black">{reaction.author.fullName}</span>
      </UserNameLink>
    </li>
  );
}
