export function addToHeaders(
  init: RequestInit | undefined,
  headerName: string,
  headerValue: string | undefined,
): RequestInit {
  init = init || {};

  // If only we could decide on a single way to set headers...

  // Logic here:
  // If no headers are passed, we want to add the header.
  // If an empty headerValue is passed (e.g. null, undefined, ""), we don't want to send the header at all. Sometimes needed to get all data.
  if (!init.headers) {
    if (headerValue) {
      init.headers = {
        [headerName]: headerValue,
      };
    }
  } else if (init.headers instanceof Headers) {
    const hasHeader = init.headers.has(headerName);
    if (hasHeader) {
      const value = init.headers.get(headerName);
      if (!value) {
        init.headers.delete(headerName);
      }
    } else if (headerValue) {
      init.headers.append(headerName, headerValue);
    }
  } else if (Array.isArray(init.headers)) {
    const header = init.headers.find(([name]) => name === headerName);
    if (header) {
      if (!header[1]) {
        init.headers = init.headers.filter(([name]) => name !== headerName);
      }
    } else if (headerValue) {
      init.headers.push([headerName, headerValue]);
    }
  } else if (typeof init.headers === "object") {
    const hasHeader = headerName in init.headers;
    if (hasHeader) {
      const value = init.headers[headerName];
      if (!value) {
        delete init.headers[headerName];
      }
    } else if (headerValue) {
      init.headers[headerName] = headerValue;
    }
  }

  return init;
}

export function removeFromHeaders(init: RequestInit, headerName: string): RequestInit {
  if (init.headers instanceof Headers) {
    init.headers.delete(headerName);
  } else if (Array.isArray(init.headers)) {
    init.headers = init.headers.filter(([name]) => name !== headerName);
  } else if (typeof init.headers === "object") {
    delete init.headers[headerName];
  }

  return init;
}
