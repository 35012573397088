import { useQuery } from "@tanstack/react-query";
import type { AdminTicketCommentDto, UserDto } from "api/types";
import iconLock01 from "assets/icons/lock-01.svg";
import { Button } from "components/Button/Button";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import { stripTime } from "helpers/date";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { groupBy } from "lodash-es";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { useTicketQueries } from "queries/tickets";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "translations";

interface TicketActivityWrapperProps {
  postedAt: string;
  author: UserDto;
  withoutBorder?: boolean;
}

export function TicketActivityWrapper({
  postedAt,
  author,
  withoutBorder,
  children,
}: React.PropsWithChildren<TicketActivityWrapperProps>): React.ReactNode {
  return (
    <article className="my-2 flex rounded-3px p-2 pb-0">
      <div className="mr-2 size-8">
        <UserAvatarLink user={author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className="flex flex-wrap items-center gap-1">
          <UserNameLink user={author}>
            <span className="truncate text-body-bold text-black">{author.fullName}</span>
          </UserNameLink>
          {!!author.deletedAt && <UserDeletedTag />}
          <span className="ml-1 text-caption text-grey-500">
            <FormattedDate date={postedAt} format="time" />
          </span>
        </div>
        <p
          className={twJoin(
            "my-1 whitespace-pre-line py-2 italic",
            withoutBorder ? undefined : "rounded-3px border border-grey-300 px-2",
          )}
        >
          {children}
        </p>
      </div>
    </article>
  );
}

interface TicketCommentProps {
  ticketId: string;
  comment: AdminTicketCommentDto;
  onEdit?: (comment: AdminTicketCommentDto) => void;
}

// This is a terrible abstraction, should be fixed some day
export function TicketComment({ ticketId, comment, onEdit }: TicketCommentProps): React.ReactNode {
  const [isTranslated, translationHandler] = useBool(false);

  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const postHog = usePostHog();
  const ticketQueries = useTicketQueries();
  const translation = useQuery({
    ...ticketQueries.activityTranslation({
      ticketId,
      commentId: comment.id,
      languageId: sessionUser.language.id,
    }),
    enabled: isTranslated,
  });

  const onClickDocumentAttachment = () => {
    postHog.capture("clicked_open_comment_document_attachment");
    window.open(comment.documents[0].url, "_blank");
  };

  const isNote = comment.internal;
  const isTranslateButtonVisible =
    !comment.deletedAt &&
    comment.content &&
    comment.languageIsoCode !== sessionUser.language.id &&
    comment.author.id !== sessionUser.id &&
    !isNote &&
    !comment.isAutoReply;

  const getContent = () => {
    if (comment.deletedAt && !comment.content) {
      return isNote
        ? t("component.community-post-comments.note-deleted")
        : t("component.community-post-comments.comment-deleted");
    }

    return translation.data ? (translation.data.content ?? "") : (comment.content ?? "");
  };

  return (
    <motion.article
      className={twJoin(
        "my-2 flex rounded-3px p-2 pb-0",
        comment.deletedAt && "text-grey-400",
        isNote ? "mr-2 bg-yellow-100 pb-2" : "first:pt-0",
      )}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0, marginBottom: "0px" }}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 size-8", comment.deletedAt && "opacity-40")}>
        <UserAvatarLink user={comment.author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className={twJoin("flex items-start", comment.deletedAt && "text-grey-400")}>
          <div className="flex flex-wrap items-center gap-1">
            <UserNameLink user={comment.author}>
              <span className={twJoin("truncate text-body-bold", comment.deletedAt ? "text-grey-400" : "text-black")}>
                {comment.author.fullName}
              </span>
            </UserNameLink>

            {!!comment.author.deletedAt && <UserDeletedTag />}

            <span className="ml-1 text-caption text-grey-500">
              <FormattedDate date={comment.postedAt} format="time" />
            </span>
          </div>

          {isNote && (
            <span className="ml-auto mr-1 flex h-6 items-center text-caption text-yellow-700 md:mr-3">
              <Icon name={iconLock01} className="mr-1 block" />
              <span className="hidden text-caption sm:block">{t("page.tickets.details.activities.note")}</span>
            </span>
          )}
        </div>

        <div className="flex w-full flex-col items-start gap-2">
          <p
            className={twJoin(
              "max-w-prose whitespace-pre-line",
              comment.deletedAt && comment.content && "line-through",
            )}
            data-testid="comment-content"
          >
            <LinkFormatter>{getContent()}</LinkFormatter>
            {isTranslateButtonVisible && (
              <Button styling="ghostPrimary" isLoading={translation.isLoading} onClick={translationHandler.toggle}>
                {translation.data
                  ? t("page.tickets.details.activities.comment.translate.original")
                  : t("page.tickets.details.activities.comment.translate")}
              </Button>
            )}
          </p>
          {/* Attachments */}
          {comment.images.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-image">
              <Gallery images={comment.images} isDeleted={!!comment.deletedAt} />
            </div>
          )}
          {comment.videos.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-video">
              <VideoFilePreview video={comment.videos[0]} />
            </div>
          )}
          {comment.documents.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-document">
              <Pdf
                fileName={comment.documents[0].fileName}
                onClick={onClickDocumentAttachment}
                isInvalid={!!comment.deletedAt}
              />
            </div>
          )}
          {isNote && comment.author.id === sessionUser.id && onEdit && (
            <div className="max-w-full">
              <Button styling="ghostSecondary" size="sm" onClick={() => onEdit(comment)}>
                {t("common.action.edit")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </motion.article>
  );
}

export function groupActivities<T extends { postedAt: string }>(activities: T[]): [string, T[]][] {
  const groupedByDay = Object.entries(groupBy(activities, (x) => stripTime(x.postedAt).valueOf()));

  return groupedByDay;
}
