import { useQuery } from "@tanstack/react-query";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { surveyMutations, useSurveyQueries } from "queries/surveys";
import { useParams } from "react-router";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (data: LayoutProps) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const { id: surveyId } = useParams<{ id: string }>();

  const surveyQueries = useSurveyQueries();
  const {
    data: surveyDetails,
    error: surveysError,
    isPending: isLoadingSurvey,
  } = useQuery({
    ...surveyQueries.details({ surveyId: surveyId! }),
    enabled: !!surveyId,
  });
  const deleteSurvey = surveyMutations.useDeleteSurvey();

  if (isLoadingSurvey) {
    return <FullSizeLoader withPadding />;
  }

  const error = surveysError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    survey: surveyDetails,
    onDelete: deleteSurvey.mutateAsync,
  });
}
