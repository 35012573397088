import iconThumbsDown from "assets/icons/thumbs-down.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import { useTranslation } from "translations";

type SurveyFollowUpModalProps = ModalBaseProps & {
  surveyId: string;
  onReact: () => void;
};

export function SurveyFollowUpModal({
  surveyId,
  isOpened,
  onOpenChange,
  onReact,
}: SurveyFollowUpModalProps): React.ReactNode {
  const { t } = useTranslation();
  const postHog = usePostHog();

  const handleAgree = () => {
    postHog.capture("clicked_survey_follow_up_agree", {
      surveyId,
    });

    onReact();
  };

  const handleDisagree = () => {
    postHog.capture("clicked_survey_follow_up_disagree", {
      surveyId,
    });

    onReact();
  };

  return (
    <Modal.Root
      size="md"
      title={t("page.survey-details.follow-up-modal.title")}
      description={t("page.survey-details.follow-up-modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <pre className="py-2 font-sans text-body">{t("page.survey-details.follow-up-modal.body")}</pre>

      <Modal.Actions>
        <Modal.Close>
          <Button styling="secondary" icon={<Icon name={iconThumbsDown} />} onClick={() => handleDisagree()}>
            {t("page.survey-details.follow-up-modal.disagree.btn")}
          </Button>
        </Modal.Close>
        <Modal.Close>
          <Button icon={<Icon name={iconThumbsUp} />} onClick={handleAgree}>
            {t("page.survey-details.follow-up-modal.agree.btn")}
          </Button>
        </Modal.Close>
      </Modal.Actions>
    </Modal.Root>
  );
}
