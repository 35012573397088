import { twResolve } from "helpers/tw-resolve";
import type { ButtonHTMLAttributes } from "react";

export interface BadgeProps<T>
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> {
  as?: T;
  selected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  isBig?: boolean;
  centerText?: boolean;
}
export function Badge<T extends React.ElementType>({ children, ...props }: BadgeProps<T>): React.ReactNode {
  return (
    <span
      {...props}
      className={twResolve(
        "inline-flex h-6 max-w-full items-center truncate rounded-full border border-grey-700 bg-transparent px-2 py-0.5 text-caption leading-none text-grey-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-grey-400 focus-visible:ring-offset-1",
        // eslint-disable-next-line no-nested-ternary
        props.selected
          ? "bg-grey-900 text-white focus-visible:ring-grey-400"
          : props.onClick
            ? "focus-visible:ring-grey-400 hover:bg-grey-100 hover:text-black"
            : undefined,
        props.disabled ? "pointer-events-none bg-grey-500 text-grey-100" : undefined,
        props.isBig ? "py-4" : undefined,
        props.centerText ? "justify-center" : undefined,
        props.as === "button" ? "cursor-pointer" : undefined,
      )}
    >
      {children}
    </span>
  );
}
