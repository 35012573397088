import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Anchor } from "components/Anchor/Anchor";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { addMonths, differenceInMonths } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { DonutChart } from "modules/analytics/components/DonutChart";
import { NoData } from "modules/analytics/components/NoData";
import { ChartLegend } from "modules/analytics/components/utility/ChartLegend";
import { dataColors } from "modules/analytics/theme";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useState } from "react";
import { routes } from "routes";
import { useTranslation } from "translations";

const CHART_SIZE = 220;
const RANGE = "lastYear";

export function ActiveContributorsOverviewCard(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();

  const minDate = useMemo(() => addMonths(new Date(), -12), []);
  const maxDate = useMemo(() => addMonths(new Date(), -1), []);
  const [date, setDate] = useState(maxDate);
  const [monthIndex, setMonthIndex] = useState(0);

  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_ACTIVE_CONTRIBUTORS(projectId, RANGE),
    queryFn: () => api.getAnalyticsEngagedResidentsMonthlyV2({ dateRange: RANGE }),
    select: (response) => {
      const { data, benchmark } = commonAPIDataSelector(response);

      return {
        data: [...data].reverse(),
        benchmark: [...benchmark].reverse(),
      };
    },
  });

  useEffect(() => {
    setMonthIndex(differenceInMonths(maxDate, date));
  }, [date, maxDate]);

  const currentMonthData = data?.data[monthIndex];
  const currentMonthBenchmark = data?.benchmark[monthIndex];

  const noData =
    !data ||
    !currentMonthData ||
    !currentMonthBenchmark ||
    data.data.every(
      (x) =>
        x.postCount === 0 &&
        x.likeCount === 0 &&
        x.eventCount === 0 &&
        x.commentCount === 0 &&
        x.collectiveTicketCount === 0 &&
        x.engagedResidentPercentage === 0,
    );

  return (
    <Card className="h-full">
      <CardHeader
        title={t("page.analytics-overview.chart.active-contributors.title")}
        tooltip={t("page.analytics-overview.chart.active-contributors.title-tooltip")}
      >
        <span className="text-caption">
          <Anchor isBold to={routes.analytics.contributionDetails({ slug })}>
            {t("page.analytics-details.link")}
          </Anchor>
        </span>
        <MonthPicker value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} placement="left" />
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <div className="flex flex-col items-center xl:flex-row xl:items-start xl:justify-between">
          <div className="grid grid-cols-1 justify-start gap-4 xl:grid-cols-2 xl:justify-center">
            <div className="flex flex-col items-center gap-4">
              <DonutChart size={CHART_SIZE} value={currentMonthData.engagedResidentPercentage} />
              <ChartLegend
                items={[
                  {
                    label: t("page.analytics-overview.chart.active-contributors.chart-legend"),
                    tooltip: t("page.analytics-overview.chart.active-contributors.chart-legend-tooltip"),
                    color: dataColors.primary,
                  },
                ]}
              />
            </div>
            <div className="mt-7 flex size-fit shrink-0 flex-col items-center gap-4 justify-self-center rounded-lg border border-grey-100 px-6 py-4">
              <span className="text-caption-bold">
                {t("page.analytics-overview.chart.active-contributors.benchmark.title")}
              </span>
              <p className="text-[40px] font-old-semibold text-aop-basic-blue-500">
                {currentMonthBenchmark.engagedResidentPercentage}%
              </p>
              <span className="text-overline">
                {t("page.analytics-overview.chart.active-contributors.benchmark.tip")}
              </span>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
