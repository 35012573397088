import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { FeelingAtHomeQuestionResponseDto, FeelingAtHomeSurveyQuestionDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { Table } from "components/Table/Table";
import { useMemo } from "react";
import { useTranslation } from "translations";

type FeelingAtHomeQuetsionDetailsModalProps = ModalBaseProps & {
  question: { data: FeelingAtHomeSurveyQuestionDto; number: number } | null;
};

export function FeelingAtHomeQuetsionDetailsModal({
  question,
  isOpened,
  onOpenChange,
}: FeelingAtHomeQuetsionDetailsModalProps): React.ReactNode {
  const { i18n, t } = useTranslation();

  const responses = useMemo(() => question?.data.responses || [], [question]);

  const columns = useMemo(() => {
    const helper = createColumnHelper<FeelingAtHomeQuestionResponseDto>();

    return [
      helper.accessor("rating", {
        header: t("page.feeling-at-home-details.question-details.table.header.rating"),
        cell: (cell) => (
          <span className="flex items-center gap-1 text-center">
            <span>{cell.getValue()}</span>
            <Icon name={iconStar01} className="text-yellow-500" />
          </span>
        ),
        maxSize: 40,
      }),
      helper.accessor("answer", {
        header: t("page.feeling-at-home-details.question-details.table.header.answer"),
        cell: (cell) => <span className="block py-0.5">{cell.getValue()}</span>,
      }),
      helper.accessor("user", {
        header: t("page.feeling-at-home-details.question-details.table.header.user"),
        cell: (cell) => <span className="block truncate">{cell.getValue().fullName}</span>,
        maxSize: 100,
      }),
      helper.accessor("answeredAt", {
        header: t("page.feeling-at-home-details.question-details.table.header.date"),
        cell: (cell) => <span>{formatDate(i18n, "datetimeShort", cell.getValue())}</span>,
        minSize: 100,
        maxSize: 100,
      }),
    ];
  }, [i18n, t]);

  const tableInstance = useReactTable<FeelingAtHomeQuestionResponseDto>({
    columns,
    data: responses,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal.Root
      title={t("component.surveys-question.details.question-number", { number: question?.number || 0 })}
      size="fullscreen"
      isScrollable
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <span className="text-headline3 leading-old-headline4">{question?.data.ratingQuestionText}</span>
          <span className="text-grey-600">({question?.data.openQuestionText})</span>
        </div>
        <span className="text-caption">
          {t("page.feeling-at-home-details.question-details.responses", {
            count: question?.data.responses.length || 0,
          })}
        </span>

        <Table
          table={tableInstance}
          data-testid="feeling-at-home-answer-list"
          getRowProps={() => ({
            "data-testid": "feeling-at-home-answer-item",
          })}
        />
      </div>
    </Modal.Root>
  );
}
