import type { MessageStatusChangeRequestV2, MessageV2Dto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconFlag01 from "assets/icons/flag-01.svg";
import iconLock01 from "assets/icons/lock-01.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconShare01 from "assets/icons/share-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconZap from "assets/icons/zap.svg";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useViewportCollision } from "modules/messages/helpers";
import { useRef, useState } from "react";
import { useTranslation } from "translations";

import { CommunityItem } from "../CommunityItem";
import { InformAdminModal } from "../InformAdminModal";
import { InformedAdminsModal } from "../InformedAdminsModal";
import { ReportPostModal } from "../ReportPostModal";
import { CommunityPostActivitySection } from "./CommunityPostActivitySection";
import { CommunityPostClosePostConfirmModal } from "./CommunityPostClosePostConfirmModal";
import { CommunityPostClosePostSoldReasonModal } from "./CommunityPostClosePostSoldReasonModal";
import { CommunityPostClosePostSolvedReasonModal } from "./CommunityPostClosePostSolvedReasonModal";
import { CommunityPostContent } from "./CommunityPostContent";
import { CommunityPostEditForm } from "./CommunityPostEditForm";
import { getClosePostReasonLabel, useData } from "./helpers";

interface ExternalProps {
  message: MessageV2Dto;
  isExpanded?: boolean;
  isActionable?: boolean;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
}

export function CommunityPost({
  message,
  isExpanded,
  isActionable = true,
  onJoinGroup,
}: ExternalProps): React.ReactNode {
  const response = useData(message);

  return <CommunityPostWithData {...response} {...{ isExpanded, isActionable, onJoinGroup }} />;
}

function CommunityPostWithData({
  post,
  isMarkingAsRead,
  markAsRead,
  deletePost,
  copyQuickReply,
  isExpanded,
  analyze,
  onAnalyze,
  analysis,
  onCloseAnalyze,
  onJoinGroup,
  isActionable,
}: ReturnType<typeof useData> & Omit<ExternalProps, "message">): React.ReactNode {
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const sessionUser = useSessionUser();
  const refContainer = useRef<HTMLDivElement | null>(null);
  const hasBeenInViewport = useViewportCollision(refContainer);
  const [closePostConfirmationModalState, setClosePostConfirmationModalState] = useState<{
    reason: MessageStatusChangeRequestV2["reason"];
    canArchive: boolean;
    canDelete: boolean;
    isOpened: boolean;
  }>({
    reason: "undefined",
    canArchive: false,
    canDelete: false,
    isOpened: false,
  });

  const [isSoldReasonModalOpened, soldReasonModalHandler] = useBool(false);
  const [isSolvedReasonModalOpened, solvedReasonModalHandler] = useBool(false);
  const [isEditing, editHandler] = useBool(false);
  const [isCancelEditModalOpen, cancelEditModalHandler] = useBool(false);
  const [isDeleteModalOpen, deleteHandler] = useBool(false);
  const [isInformModalOpened, informModalHandler] = useBool(false);
  const [isInformedAdminsModalOpened, informedAdminsModalHandler] = useBool(false);
  const [isReportModalOpened, reportModalHandler] = useBool(false);

  const handleCancelEdit = () => {
    editHandler.setFalse();
    cancelEditModalHandler.setFalse();
  };

  const getPostRing = (currPost: MessageV2Dto) => {
    let ring: "greyed-out" | "unread" | "reported" | undefined = undefined;
    if (currPost.deletedAt || currPost.archivedAt) {
      ring = "greyed-out";
    } else if (currPost.isUnread) {
      ring = "unread";
    } else if (currPost.reportedAt) {
      ring = "reported";
    }

    return ring;
  };

  const getWarningHeaders = (currPost: MessageV2Dto) => {
    const headers = [];

    if (currPost.isHiddenFromAdmins) {
      headers.push({
        icon: <Icon name={iconEyeOff} size={16} />,
        text: t("component.community-post.hidden-from-property-manager"),
      });
    }

    if (!currPost.canComment && sessionUser.role.type !== "readOnly") {
      headers.push({
        icon: <Icon name={iconLock01} size={16} />,
        text: t("component.community-post.no-comment-allowed"),
      });
    }

    return headers;
  };

  if (!post) {
    return null;
  }

  const menuActions: ContextMenuItem[] =
    !post || post?.deletedAt || !isActionable
      ? []
      : [
          {
            labels: {
              default: "Analyze 🤖",
            },
            value: "analyze",
            icon: iconZap,
            "data-testid": "post-context-menu-inform-admin",
            callbackFn: onAnalyze,
            isHidden: !hasPermission((x) => x.isSuperAdmin),
          },
          {
            labels: {
              default: t("component.community-post.actions.quick-reply-link"),
            },
            value: "copy",
            icon: iconCopy01,
            "data-testid": "post-context-menu-copy",
            callbackFn: () => copyQuickReply({ messageId: post.id }),
            isHidden: !hasPermission((x) => x.isSuperAdmin),
          },
          {
            labels: {
              default: t("component.community-post.actions.mark-as-read"),
            },
            value: "mark-read",
            icon: iconCheck,
            "data-testid": "post-context-menu-mark-read",
            callbackFn: markAsRead,
            isHidden: !post.isUnread,
          },
          {
            labels: {
              default: t("component.community-post.actions.edit"),
            },
            value: "edit",
            icon: iconEdit05,
            "data-testid": "context-menu-edit-btn",
            callbackFn: editHandler.toggle,
            isHidden: !post.canEdit,
          },
          {
            labels: {
              default: t("component.community-post.actions.inform"),
            },
            value: "inform",
            icon: iconShare01,
            "data-testid": "post-context-menu-inform",
            callbackFn: informModalHandler.toggle,
            isHidden: !post.canShare,
          },
          {
            labels: {
              default: t("component.community-post.actions.delete"),
            },
            value: "delete",
            icon: iconTrash02,
            "data-testid": "context-menu-delete-btn",
            callbackFn: deleteHandler.toggle,
            isHidden: !post.canDelete || (!sessionUser.isAdmin && post.canArchive),
          },
          {
            labels: {
              default: t("component.community-post.actions.close"),
            },
            value: "archive",
            icon: iconCheck,
            "data-testid": "post-context-menu-archive",
            isHidden: !post.canArchive || sessionUser.isAdmin,
            type: "trigger",
            subItems: [
              {
                labels: {
                  default: t("component.community-post.actions.close.solved"),
                },
                value: "solved",
                "data-testid": "post-context-menu-solved-option-btn",
                callbackFn: solvedReasonModalHandler.setTrue,
                isHidden: post.messageLabel !== "askHelp",
              },
              {
                labels: {
                  default: getClosePostReasonLabel("solvedNotDetailed", t),
                },
                value: "solved-not-detailed",
                "data-testid": "post-context-menu-solved-no-detail-option-btn",
                callbackFn: () =>
                  setClosePostConfirmationModalState({
                    isOpened: true,
                    canArchive: true,
                    canDelete: false,
                    reason: "solvedNotDetailed",
                  }),
                isHidden: post.messageLabel !== "inform" || !post.messageLabel,
              },
              {
                labels: {
                  default: t("component.community-post.actions.close.sold"),
                },
                "data-testid": "post-context-menu-sold-option-btn",
                value: "sold",
                callbackFn: soldReasonModalHandler.setTrue,
                isHidden: post.messageLabel !== "sellItem",
              },
              {
                labels: {
                  default: getClosePostReasonLabel("mistake", t),
                },
                value: "mistake",
                "data-testid": "post-context-menu-mistake-option-btn",
                callbackFn: () =>
                  setClosePostConfirmationModalState({
                    isOpened: true,
                    canArchive: false,
                    canDelete: true,
                    reason: "mistake",
                  }),
              },
              {
                labels: {
                  default: getClosePostReasonLabel("noResponse", t),
                },
                value: "no-response",
                "data-testid": "post-context-menu-no-response-option-btn",
                callbackFn: () =>
                  setClosePostConfirmationModalState({
                    isOpened: true,
                    canArchive: true,
                    canDelete: false,
                    reason: "noResponse",
                  }),
                isHidden: post.messageLabel !== "askHelp",
              },
              {
                labels: {
                  default: getClosePostReasonLabel("irrelevant", t),
                },
                value: "irrelevant",
                "data-testid": "post-context-menu-irrelevant-option-btn",
                callbackFn: () =>
                  setClosePostConfirmationModalState({
                    isOpened: true,
                    canArchive: post.messageLabel !== "sellItem",
                    canDelete: true,
                    reason: "irrelevant",
                  }),
              },
            ],
          },
          {
            labels: {
              default: t("component.community-post.actions.report"),
            },
            value: "report",
            icon: iconFlag01,
            "data-testid": "post-context-menu-report",
            callbackFn: reportModalHandler.toggle,
            isHidden: !post.canReport,
          },
        ];

  return (
    <>
      <CommunityItem
        ring={getPostRing(post)}
        user={post.author}
        actions={menuActions}
        group={post.group}
        labels={[
          post.issueStatus === "solved" ? (
            <Label theme="green" data-testid="post-solved-label">
              {t("component.community-post.label.solved")}
            </Label>
          ) : undefined,
          post.projectConnection ? (
            <Label icon={iconMarkerPin01} theme="blue">
              {post.projectConnection.name}
            </Label>
          ) : undefined,
          post.reportedAt && !post.deletedAt ? (
            <Label data-testid="flagged-label" theme="red">
              {t("component.community-post.label.flagged")}
            </Label>
          ) : undefined,
          post.hasAdminBeenInformed && !post.deletedAt ? (
            <button type="button" onClick={() => informedAdminsModalHandler.toggle()}>
              <Label data-testid="post-informed-label" theme="green">
                {t("component.community-post.label.shared")}
              </Label>
            </button>
          ) : undefined,
          post.type === "announcement" || post.type === "announcementReadOnly" ? (
            <Label data-testid="announcement-label" theme="blue">
              {t("component.community-post.label.announcement")}
            </Label>
          ) : undefined,
          post.deletedAt ? <Label theme="red">{t("component.community-post.label.removed")}</Label> : undefined,
        ].filter(isDefined)}
        dateSubtitle={
          // eslint-disable-next-line no-nested-ternary
          post.scheduledAt ? (
            <span className="text-red-600" data-testid="post-scheduled-date">
              {t("component.community-post.scheduled-for", {
                scheduled: formatDate(i18n, "datetime", post.scheduledAt),
              })}
            </span> // eslint-disable-next-line no-nested-ternary
          ) : post.postedAt ? (
            // eslint-disable-next-line no-nested-ternary
            post.archivedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span data-testid="archived-label" className="text-caption-bold uppercase">
                  {t("component.community-post.archived")}
                </span>
              </p>
            ) : post.updatedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span className="text-caption-bold uppercase">{t("component.community-post.edited")}</span>
              </p>
            ) : (
              <FormattedDate format="datetime" date={post.postedAt} />
            )
          ) : undefined
        }
        onClick={!isMarkingAsRead && post.isUnread ? markAsRead : undefined}
        warningHeaders={getWarningHeaders(post)}
        ref={refContainer}
      >
        <div className="flex w-full flex-col gap-2 md:flex-row">
          <div className="flex w-full flex-1 flex-col gap-2">
            {isDefined(post.deletedAt) && !sessionUser.isSuperAdmin && (
              <p className="whitespace-pre-line text-grey-500">{t("component.community-post.content.removed")}</p>
            )}
            {/* Post editing form */}
            {(!post.deletedAt || sessionUser.isSuperAdmin) && isEditing && (
              <CommunityPostEditForm
                message={post}
                onCompleteEdit={editHandler.setFalse}
                onCancelEdit={handleCancelEdit}
              />
            )}
            {/* Post content */}
            {(!post.deletedAt || sessionUser.isSuperAdmin) && !isEditing && (
              <CommunityPostContent
                message={post}
                isEditing={isEditing}
                isExpanded={isExpanded}
                shouldLoad={hasBeenInViewport}
                onJoinGroup={onJoinGroup}
              />
            )}
          </div>
        </div>

        {/* Post reactions & comments */}
        {!post.scheduledAt && <CommunityPostActivitySection message={post} isExpanded={isExpanded} />}
      </CommunityItem>

      <ConfirmModal
        title={t("component.community-post.cancel-edit.modal.title")}
        description={t("component.community-post.cancel-edit.modal.description")}
        isLoading={false}
        onReject={cancelEditModalHandler.setFalse}
        onOpenChange={cancelEditModalHandler.set}
        rejectBtnProps={{
          "data-testid": "cancel-edit-modal-cancel",
        }}
        onResolve={handleCancelEdit}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "cancel-edit-modal-confirm",
        }}
        isOpened={isCancelEditModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <ConfirmModal
        title={t("component.community-post.delete.modal.title")}
        description={t("component.community-post.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={deleteHandler.setFalse}
        onOpenChange={deleteHandler.set}
        rejectBtnProps={{
          "data-testid": "delete-modal-cancel",
        }}
        onResolve={() => {
          void deletePost({ messageId: post.id, reason: "undefined" });
          deleteHandler.setFalse();
        }}
        resolveBtnProps={{
          "data-testid": "modal-confirm-delete",
          text: t("common.action.delete"),
        }}
        isOpened={isDeleteModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <InformAdminModal isOpened={isInformModalOpened} onOpenChange={informModalHandler.set} messageId={post.id} />
      <InformedAdminsModal
        isOpened={isInformedAdminsModalOpened}
        onOpenChange={informedAdminsModalHandler.set}
        messageId={post.id}
      />
      <ReportPostModal isOpened={isReportModalOpened} onOpenChange={reportModalHandler.set} messageId={post.id} />
      <ConfirmModal
        isOpened={analyze}
        title="Analysis"
        description="An analysis of how good your post is and what topics it covers."
        isLoading={!analysis}
        onResolve={() => onCloseAnalyze()}
        onReject={() => onCloseAnalyze()}
        onOpenChange={(state) => {
          if (!state) {
            onCloseAnalyze();
          }
        }}
      >
        {analysis?.topics && analysis?.sentiment ? (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <span className="text-body-bold">Topics:</span>
              <span>{analysis.topics?.topics?.length > 1 ? analysis.topics?.topics.join(", ") : "-"}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-body-bold">Sentiment:</span>
              <span className="text-headline3">
                {analysis.sentiment?.score || "-"}
                <span className="text-body text-grey-600">/{analysis.sentiment?.outOf || "10"}</span>
              </span>
            </div>
          </div>
        ) : (
          <div className="px-10 py-5">
            <FullSizeLoader />
          </div>
        )}
      </ConfirmModal>

      <CommunityPostClosePostConfirmModal
        messageId={post.id}
        {...closePostConfirmationModalState}
        onOpenChange={(state) => setClosePostConfirmationModalState((currState) => ({ ...currState, isOpened: state }))}
      />
      <CommunityPostClosePostSoldReasonModal
        messageId={post.id}
        isOpened={isSoldReasonModalOpened}
        onOpenChange={soldReasonModalHandler.set}
      />
      <CommunityPostClosePostSolvedReasonModal
        messageId={post.id}
        isOpened={isSolvedReasonModalOpened}
        onOpenChange={solvedReasonModalHandler.set}
      />
    </>
  );
}
