import { zip } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "translations";
import * as V from "victory";

import { useElementScale } from "../hooks/useElementScale";
import { chartTheme, dataColors } from "../theme";
import { createDateTickFormat, createXAxis, createYAxis } from "./utility/Axis";
import { HoverLabel } from "./utility/HoverLabel";

const CHART_HEIGHT = 200;
const CHART_WIDTH = 400;
const LABEL_OFFSET_X = 8;
const LABEL_OFFSET_Y = -20;

interface DataPoint {
  x: string;
  y: number;
}

interface Props {
  data: { length: number; data: { label?: string; dataPoints: DataPoint[]; color: string }[] };
  renderLabel: (
    data: DataPoint & { relatedPoints: { label?: string; dataPoint: DataPoint; color: string }[] },
  ) => React.ReactNode;
  xLabel?: string;
  yLabel?: string;
  minTopY?: number;
}

export function LineChart({ xLabel, yLabel, renderLabel, data, minTopY }: Props): React.ReactNode {
  const { i18n } = useTranslation();
  const { scale, ref } = useElementScale<HTMLDivElement>(CHART_WIDTH);

  const maxItemIndexes = zip(...data.data.map((x) => x.dataPoints)).map(
    (x) =>
      x.reduce(
        (result, next, index) => {
          if (next && next.y > result.max) {
            return { index, max: next.y };
          }

          return result;
        },
        { index: 0, max: 0 },
      ).index,
  );

  const minimumTopY = useMemo(
    () =>
      minTopY
        ? Math.ceil(
            Math.max(
              data.data.reduce((totalOverall, data) => {
                const y = data.dataPoints.reduce((maxInLine, next) => (next.y > maxInLine ? next.y : maxInLine), 0);

                return y > totalOverall ? y : totalOverall;
              }, 0),
              minTopY,
            ) / 10,
          ) * 10
        : undefined,
    [data.data, minTopY],
  );

  return (
    <div ref={ref}>
      <V.VictoryChart
        minDomain={{ y: 0 }}
        maxDomain={{ y: minimumTopY }}
        theme={chartTheme}
        width={scale * CHART_WIDTH}
        height={scale * CHART_HEIGHT}
        padding={{
          top: 12,
          right: 8,
          bottom: 64,
          left: 72,
        }}
        domainPadding={{ x: 16, y: [0, 140] }}
        containerComponent={<V.VictoryVoronoiContainer voronoiDimension="x" />}
      >
        {createYAxis({ tickCount: 4, tickFormat: (x: DataPoint["y"]) => Math.round(x * 10) / 10, label: yLabel })}
        {createXAxis({ tickFormat: createDateTickFormat(i18n, data.length, scale), label: xLabel })}
        {data.data.map((x, groupIndex) => (
          <V.VictoryGroup key={`v_group_${x.label}`}>
            <V.VictoryScatter
              name="hover"
              size={Math.max(scale * 6, 3)}
              style={{
                data: {
                  fill: dataColors.primary,
                  opacity: ({ active }: any) => (active ? 0.3 : 0),
                },
              }}
              labelComponent={
                <V.VictoryTooltip
                  text=""
                  flyoutComponent={
                    <HoverLabel<DataPoint>
                      offsetX={LABEL_OFFSET_X * scale}
                      offsetY={LABEL_OFFSET_Y * scale}
                      containerWidth={CHART_WIDTH * scale}
                      topIndexes={maxItemIndexes}
                      groupIndex={groupIndex}
                    >
                      {(dataPoint, dataPointIndex) =>
                        renderLabel({
                          ...dataPoint,
                          relatedPoints: data.data.map((y) => ({
                            color: y.color,
                            label: y.label,
                            dataPoint: y.dataPoints[dataPointIndex || 0],
                          })),
                        })
                      }
                    </HoverLabel>
                  }
                />
              }
              data={x.dataPoints}
              labels={(d: DataPoint) => d.y}
            />
            <V.VictoryScatter
              name="point"
              size={scale * 3}
              style={{ data: { pointerEvents: "none", fill: x.color } }}
              data={x.dataPoints}
            />
            <V.VictoryLine name="line" style={{ data: { stroke: x.color, strokeWidth: 2 } }} data={x.dataPoints} />
          </V.VictoryGroup>
        ))}
      </V.VictoryChart>
    </div>
  );
}
