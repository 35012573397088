import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { NotificationSettingsRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useLocation, useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import type { FormValues, LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const { userId: userIdParam } = useParams<{ userId: string }>();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const location = useLocation();
  const api = useApi();
  const userId = userIdParam ?? sessionUser.id;
  const navigate = useNavigate();
  const {
    data: notificationSettings,
    isFetching: isLoadingNotificationSettings,
    error: notificationSettingsError,
  } = useQuery({
    queryKey: QUERY_KEYS.NOTIFICATION_SETTINGS_USER(projectId, userId),
    queryFn: () => api.getNotificationSettingsDetailsV1(userId),
    select: commonAPIDataSelector,
  });
  const {
    data: userDetails,
    isFetching: isLoadingUserDetails,
    error: userDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.USER_DETAILS(projectId, userId),
    queryFn: () => api.getUsersDetailsV1(userId),
    select: commonAPIDataSelector,
    enabled: isDefined(userIdParam),
  });
  const { data: ticketCategories, isFetching: isLoadingTicketCategories } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId),
    queryFn: () => api.getTicketCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const updateUserAssignable = useMutation({
    mutationFn: (isAssignable: boolean) => api.putUsersAssignableV1(userId, isAssignable),
    onSettled() {
      if (!isDefined(userIdParam)) {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
      }

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, userId) });
    },
  });
  const showFlashToast = useFlashToast();

  const updateSettings = useMutation({
    mutationFn: (payload: NotificationSettingsRequest) => api.patchNotificationSettingsV1(userId, payload),
    onSuccess() {
      if (location.state?.fromPage) {
        void navigate(location.state.fromPage);
      } else {
        if (userId !== sessionUser.id) {
          void navigate(routes.users.details({ slug, id: userId }));
        } else {
          void navigate(routes.adminNotifications.list({ slug }));
        }
      }
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTIFICATION_SETTINGS_USER(projectId, userId) });
    },
  });

  const isLoading = isLoadingNotificationSettings || isLoadingUserDetails || isLoadingTicketCategories;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = notificationSettingsError || userDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const canBeAssigned = isDefined(userIdParam)
    ? userDetails?.isAssignableToTickets || false
    : sessionUser.isAssignableToTickets;

  async function submit(request: FormValues) {
    try {
      if (request.canBeAssigned !== canBeAssigned) {
        await updateUserAssignable.mutateAsync(request.canBeAssigned);
      }

      await updateSettings.mutateAsync(request.settings);

      showFlashToast({ type: "success", title: t("page.notification-settings.notifications.success") });
    } catch (error) {
      showFlashToast({ type: "error", title: t("page.notification-settings.notifications.failed") });
    }
  }

  return props.children({
    isSubmitting: updateSettings.isPending || updateUserAssignable.isPending,
    onSubmit: (data) => {
      void submit(data);
    },
    canTurnAssigneeOff: !(ticketCategories?.items.some((x) => x.defaultAssignee?.id === userId) || false),
    defaultValues: {
      canBeAssigned,
      settings: notificationSettings!,
    },
  });
}
