import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Carousel } from "components/Carousel/Carousel";
import { ContextMenu, type ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import type React from "react";
import type { ReactNode } from "react";
import { useTranslation } from "translations";

interface EntityDetailsCardProps {
  title: string;
  description: string;
  images?: ImageDto[];
  actions?: ContextMenuItem[];
  children?: ReactNode;
  renderLabels?: () => ReactNode;
  renderBody?: () => ReactNode;
  renderCtaButton?: () => ReactNode;
  renderFooter?: () => ReactNode;
}

export function EntityDetailsCard({
  title,
  description,
  images,
  actions,
  renderLabels,
  renderBody,
  renderCtaButton,
  renderFooter,
}: EntityDetailsCardProps): React.ReactNode {
  const resolveImage = useImageResolver();
  const { t } = useTranslation();

  const isCarousel = images && images.length > 1;

  return (
    <div className="flex w-full flex-col rounded-lg bg-white">
      <div
        className="relative h-52 w-full overflow-hidden rounded-t-lg bg-grey-400 bg-cover bg-center md:h-72 lg:h-96"
        style={
          isCarousel
            ? undefined
            : {
                backgroundImage: `url(${images?.length === 1 ? resolveImage(images[0], "intrinsic") : bgPlaceholder})`,
              }
        }
      >
        {renderLabels && <div className="absolute left-3 top-3 flex gap-2">{renderLabels()}</div>}
        {isCarousel && <Carousel allowZoom {...{ images }} />}
      </div>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex w-full items-center justify-between">
          <h2 className="break-all text-headline3 leading-none">{title}</h2>

          <div className="flex items-center gap-4">
            {renderCtaButton && (
              <div className="flex flex-wrap items-center justify-between gap-1">{renderCtaButton()}</div>
            )}
            {actions && <ContextMenu items={actions} />}
          </div>
        </div>

        {renderBody && renderBody()}

        <div className="flex flex-col gap-1">
          <span className="text-body-bold">{t("model.document.root.description")}</span>
          <LinkFormatter>{description}</LinkFormatter>
        </div>

        {renderFooter && renderFooter()}
      </div>
    </div>
  );
}
