import type { GroupChatInfoDto, UpdateGroupChatRequest, UserDto } from "api/types";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { Form } from "components/Form/Form";
import { type FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { chatMutations } from "queries/chats";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useTranslation } from "translations";

import { EditGroupChatModalAddMembersView } from "./EditGroupChatModalAddMembersView";
import { EditGroupChatModalInfoView } from "./EditGroupChatModalInfoView";

type EditGroupChatModalProps = ModalBaseProps & {
  chatId: string;
  groupInfo: GroupChatInfoDto;
};

export interface EditGroupChatFormValues {
  groupName: string;
  groupImage: FormImage[];
  newMembers: UserDto[];
}

enum Step {
  GroupInfo,
  AddMembers,
}

export function EditGroupChatModal({
  isOpened,
  onOpenChange,
  chatId,
  groupInfo,
}: EditGroupChatModalProps): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();
  const sessionUser = useSessionUser();

  const navigate = useNavigate();

  const [step, setStep] = useState<Step>(Step.GroupInfo);
  const [userToRemove, setUserToRemove] = useState<UserDto | null>(null);
  const [isLeaveModalOpen, leaveModalHandler] = useBool();

  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const formMethods = useForm<EditGroupChatFormValues>({
    defaultValues: { groupName: groupInfo.name, groupImage: groupInfo.image ? [groupInfo.image] : [], newMembers: [] },
  });

  useEffect(() => {
    formMethods.reset({
      groupName: groupInfo.name,
      groupImage: groupInfo.image ? [groupInfo.image] : [],
      newMembers: [],
    });
  }, [formMethods, groupInfo]);

  useEffect(() => {
    if (!isOpened) {
      formMethods.reset();
      leaveModalHandler.setFalse();
      setStep(0);
    }
  }, [isOpened, formMethods, leaveModalHandler]);

  const mutationLeaveGroupChat = chatMutations.useLeaveGroupChat({
    onSuccess: () => navigate(routes.chats.list({ slug: slug })),
  });
  const { mutateAsync: removeMember, isPending: isRemovingMember } = chatMutations.useRemoveGroupChatMember({
    chatId,
    onSuccess: () => setUserToRemove(null),
  });
  const mutationUpdateGroupChat = chatMutations.useUpdateGroupChat({
    chatId,
    onSuccess: () => onOpenChange(false),
  });

  const onSubmit = async (data: EditGroupChatFormValues) => {
    const payload: UpdateGroupChatRequest = { name: data.groupName.trim() };

    if (data.groupImage.length > 0) {
      const uploadedImage = await uploadFormImage(data.groupImage[0]);

      payload.imageId = uploadedImage?.id;
    }

    void mutationUpdateGroupChat.mutateAsync(payload);
  };

  const handlePrevStep = (step: Step) => {
    setStep(step);
  };

  const handleNextStep = (step: Step) => {
    setStep(step);
  };

  let title = "";
  switch (step) {
    case Step.GroupInfo:
      title = t("component.group-chat-info-modal.title");
      break;
    case Step.AddMembers:
      title = t("page.chats.modal.new-chat.group-chat.select-member.title");
      break;
  }

  return (
    <Modal.Root {...{ title, isOpened, onOpenChange }}>
      <Form className="flex max-h-full w-full flex-col" {...{ formMethods, onSubmit }}>
        {step === Step.GroupInfo && (
          <EditGroupChatModalInfoView
            canLeave={groupInfo.canLeave}
            groupChatId={chatId}
            isModalOpen={isOpened}
            isUpdating={mutationUpdateGroupChat.isPending || mutationLeaveGroupChat.isPending || isUploadingImage}
            onAddMembers={() => handleNextStep(Step.AddMembers)}
            onLeaveGroup={leaveModalHandler.setTrue}
          />
        )}
        {step === Step.AddMembers && sessionUser.chatEnabled && (
          <EditGroupChatModalAddMembersView
            onNavigateBack={() => handlePrevStep(Step.GroupInfo)}
            isOpen={isOpened}
            {...{ chatId }}
          />
        )}
      </Form>
      <ConfirmModal
        title={t("component.group-chat-leave-modal.title")}
        description={t("component.group-chat-leave-modal.description")}
        isLoading={mutationLeaveGroupChat.isPending}
        theme="danger"
        onOpenChange={leaveModalHandler.set}
        onReject={leaveModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "leave-group-chat-modal-cancel",
        }}
        onResolve={() => mutationLeaveGroupChat.mutateAsync({ chatId })}
        resolveBtnProps={{
          "data-testid": "leave-group-chat-modal-confirm",
          text: t("common.action.confirm"),
        }}
        isOpened={isLeaveModalOpen}
        shouldCloseOnEsc
      />
      <ConfirmModal
        title={t("component.group-chat-remove-user-modal.title")}
        description={t("component.group-chat-remove-user-modal.description", { user: userToRemove?.fullName ?? "" })}
        isLoading={isRemovingMember}
        theme="danger"
        onOpenChange={(state) => {
          if (!state) {
            setUserToRemove(null);
          }
        }}
        onReject={() => setUserToRemove(null)}
        rejectBtnProps={{
          "data-testid": "chat-remove-user-modal-cancel",
        }}
        onResolve={() => removeMember({ userId: userToRemove!.id })}
        resolveBtnProps={{
          "data-testid": "chat-remove-user-modal-confirm",
          text: t("common.action.remove"),
        }}
        isOpened={!!userToRemove}
        shouldCloseOnEsc
      />
    </Modal.Root>
  );
}
