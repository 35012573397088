import { useImageResolver } from "api/hooks/useImageResolver";
import type {
  CommunityFeedAudienceGroupV2Dto,
  CommunityFeedAudienceProjectV2Dto,
  CommunityFeedAudienceV2Dto,
} from "api/types";
import iconAsterisk01 from "assets/icons/asterisk-01.svg";
import iconBuilding01 from "assets/icons/building-01.svg";
import iconBuilding04 from "assets/icons/building-04.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Button } from "components/Button/Button";
import { Drawer } from "components/Drawer/Drawer";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { Icon } from "components/Icon/Icon";
import { SearchableSelect } from "components/SearchableSelect/SearchableSelect";
import { SearchInput } from "components/SearchInput/SearchInput";
import { formatDistance } from "helpers/date";
import { twResolve } from "helpers/tw-resolve";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

import type { AdminCreatePostFormValues } from "../Layout";
import { AdminCreatePostOptionButton } from "./AdminCreatePostOptionButton";

type AdminCreatePostFormAudienceSelectorProps = {
  audience: CommunityFeedAudienceV2Dto;
  selectedGroup: CommunityFeedAudienceGroupV2Dto | undefined;
  selectedProject: CommunityFeedAudienceProjectV2Dto | undefined;
  onSelectGroup: (group: CommunityFeedAudienceGroupV2Dto) => void;
  onSelectProject: (project: CommunityFeedAudienceProjectV2Dto) => void;
  isError?: boolean;
  isDisabled: boolean;
};

export function AdminCreatePostFormAudienceSelector({
  audience,
  selectedGroup,
  selectedProject,
  onSelectGroup,
  onSelectProject,
  isError,
  isDisabled,
}: AdminCreatePostFormAudienceSelectorProps): React.ReactNode {
  // Interal state to handle temporary selections
  const [selection, setSelection] = useState({
    group: selectedGroup,
    project: selectedProject,
  });
  const [isDrawerOpened, drawerHandler] = useBool();
  const [realEstateGroupSearch, setRealEstateGroupSearch] = useState("");

  const { t } = useTranslation();
  const form = useFormContext<AdminCreatePostFormValues>();
  const currPostType = useWatch({
    name: "postType",
    control: form.control,
  });

  useEffect(() => {
    if (!currPostType) {
      return;
    }

    form.register("audienceType", {
      validate: (value) => {
        if (!value) {
          return t("components.form.error.required", {
            inputName: t("common.entity.audience"),
          });
        }
      },
    });
  }, [form, t, currPostType]);

  const reset = useCallback(() => {
    setRealEstateGroupSearch("");
    setSelection({
      group: selectedGroup,
      project: selectedProject,
    });
  }, [selectedGroup, selectedProject]);

  // Update internal state when prop changes (e.g. Parent form reset)
  useEffect(() => {
    reset();
  }, [reset]);

  const handleSelectGroup = (currGroup: CommunityFeedAudienceGroupV2Dto | undefined) => {
    setSelection({
      group: currGroup,
      project: undefined,
    });
  };

  const handleSelectProject = (currProject: CommunityFeedAudienceProjectV2Dto | undefined) => {
    setSelection({
      group: undefined,
      project: currProject,
    });
  };

  const handleConfirmAudience = () => {
    if (selection.group) {
      onSelectGroup(selection.group);
    } else if (selection.project) {
      onSelectProject(selection.project);
    }

    reset();
  };

  const getAudienceIcon = (type: "project" | "realEstateGroup" | "interestGroup") => {
    switch (type) {
      case "realEstateGroup":
        return iconBuilding01;

      case "interestGroup":
        return iconAsterisk01;

      case "project":
      default:
        return iconBuilding04;
    }
  };

  const filteredRealEstateGroups =
    audience?.realEstateGroups.filter((group) =>
      group.name.toLowerCase().includes(realEstateGroupSearch.toLowerCase()),
    ) || [];

  return (
    <>
      <FormErrorWrapper<AdminCreatePostFormValues> name="audienceType">
        <AdminCreatePostFormAudienceField
          selectedGroup={selectedGroup}
          selectedProject={selectedProject}
          isDisabled={isDisabled}
          isError={isError}
          onClick={drawerHandler.setTrue}
        />
      </FormErrorWrapper>

      <Drawer.Root
        title={t("page.admin-create-post.audience-selector.drawer.title")}
        isOpened={isDrawerOpened}
        onOpenChange={(state) => {
          if (!state) {
            reset();
          }

          drawerHandler.set(state);
        }}
      >
        <Drawer.Body>
          <div className="flex flex-col gap-8">
            {/* Project */}
            {audience.project && (
              <AdminCreatePostOptionButton
                data-testid="audience-project-btn"
                title={t("page.admin-create-post.form.audience.all-residents", {
                  name: audience.project.name,
                })}
                description={
                  isDefined(audience.project.totalAddressCount)
                    ? t("page.admin-create-post.form.audience.group.addresses", {
                        count: `${audience.project.addressWithUsersCount} / ${audience.project.totalAddressCount}`,
                      })
                    : undefined
                }
                icon={getAudienceIcon("project")}
                onClick={() => handleSelectProject(audience.project)}
                isSelected={Boolean(selection.project)}
              />
            )}
            {/* Real estate groups */}
            {audience.realEstateGroups.length > 0 && (
              <div className="flex flex-col gap-4">
                <h4 className="text-headline4">
                  {audience.project
                    ? t("page.admin-create-post.audience-selector.drawer.or-real-estate-groups.label")
                    : t("page.admin-create-post.audience-selector.drawer.real-estate-groups.label")}
                </h4>
                {audience.realEstateGroups.length > 1 && (
                  <SearchInput
                    placeholder={t("page.admin-create-post.audience-selector.drawer.real-estate-groups.placeholder")}
                    onChange={(e) => setRealEstateGroupSearch(e.target.value)}
                  />
                )}
                {filteredRealEstateGroups.length === 0 && (
                  <span className="text-caption">{t("common.label.no-result")}</span>
                )}
                {filteredRealEstateGroups.length > 0 &&
                  filteredRealEstateGroups.map((realEstateGroup) => {
                    const icon = getAudienceIcon("realEstateGroup");
                    const isSelected = selection.group?.id === realEstateGroup.id;
                    const amountAddresses =
                      realEstateGroup.selectableAudience[0].type === "project"
                        ? (realEstateGroup.selectableAudience[0].project?.addressCount ?? 0)
                        : (realEstateGroup.selectableAudience[0].projectConnection?.addressCount ?? 0);
                    const description =
                      realEstateGroup.selectableAudience.length === 1
                        ? t("page.admin-create-post.form.audience.group.addresses", {
                            count: amountAddresses,
                          })
                        : undefined;

                    return (
                      <AdminCreatePostOptionButton
                        data-testid="audience-real-estate-group-btn"
                        key={realEstateGroup.id}
                        title={realEstateGroup.name}
                        description={description}
                        icon={icon}
                        onClick={() => handleSelectGroup(realEstateGroup)}
                        isSelected={isSelected}
                      />
                    );
                  })}
              </div>
            )}
            {/* Interest groups */}
            {audience.interestGroups.length > 0 && (
              <div className="flex flex-col gap-4">
                <h4 className="text-headline4">
                  {audience.project || audience.realEstateGroups.length > 0
                    ? t("page.admin-create-post.audience-selector.drawer.or-interest-groups.label")
                    : t("page.admin-create-post.audience-selector.drawer.interest-groups.label")}
                </h4>
                <AdminCreatePostOptionButton
                  icon={getAudienceIcon("interestGroup")}
                  isSelected={!!audience.interestGroups.find((group) => group.id === selection.group?.id)}
                >
                  <div className="w-full">
                    <SearchableSelect
                      keySelector={(group) => group.id}
                      renderOption={(group) => <AdminCreatePostAudienceSelectorGroupOption group={group} />}
                      renderSelected={(group) => group.name}
                      searchableFieldSelector={(group) => [group.name]}
                      selected={audience.interestGroups.find((group) => group.id === selection.group?.id)}
                      placeholder={t("page.admin-create-post.audience-selector.drawer.interest-groups.placeholder")}
                      items={audience.interestGroups}
                      onChange={handleSelectGroup}
                    />
                  </div>
                </AdminCreatePostOptionButton>
              </div>
            )}
          </div>
        </Drawer.Body>

        <Drawer.Actions>
          <Drawer.Close>
            <Button styling="secondary">{t("common.action.cancel")}</Button>
          </Drawer.Close>
          <Drawer.Close>
            <Button data-testid="confirm-audience-btn" styling="primary" onClick={handleConfirmAudience}>
              {t("common.action.select")}
            </Button>
          </Drawer.Close>
        </Drawer.Actions>
      </Drawer.Root>
    </>
  );
}

type AdminCreatePostFormAudienceFieldProps = {
  selectedGroup: CommunityFeedAudienceGroupV2Dto | undefined;
  selectedProject: CommunityFeedAudienceProjectV2Dto | undefined;
  isDisabled: boolean;
  isError?: boolean;
  onClick: () => void;
};

function AdminCreatePostFormAudienceField({
  selectedGroup,
  selectedProject,
  isDisabled,
  isError,
  onClick,
  ...otherProps
}: AdminCreatePostFormAudienceFieldProps) {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      data-testid="audience-selector-btn"
      disabled={isDisabled}
      onClick={onClick}
      className={twResolve(
        "flex w-full max-w-full cursor-pointer items-center justify-between gap-2 rounded-lg p-2 ring-1 ring-grey-300 transition-opacity focus-visible:outline-none focus-visible:ring-grey-900 hover:ring-grey-700 disabled:cursor-not-allowed disabled:opacity-40",
        isError && "ring-red-600",
      )}
      {...otherProps}
    >
      <div className="flex w-full flex-wrap items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icon name={iconUsersCheck} size={20} />
          <span>{t("page.admin-create-post.audience-selector.label")}:</span>
        </div>

        <div className="flex items-center gap-2">
          <span
            className={twResolve(
              "flex items-center gap-2 rounded-[4px] bg-grey-100 px-2 text-body text-black",
              isError && "bg-red-100 text-red-600",
            )}
          >
            {!selectedGroup && !selectedProject && <span>[...]</span>}
            {selectedGroup && (
              <>
                {<Icon name={iconBuilding01} />}
                <span>{selectedGroup.name}</span>
              </>
            )}
            {selectedProject && (
              <>
                {<Icon name={iconBuilding04} />}
                <span>
                  {t("page.admin-create-post.form.audience.all-residents", {
                    name: selectedProject.name,
                  })}
                </span>
              </>
            )}
          </span>
          <Icon name={iconChevronRight} />
        </div>
      </div>
    </button>
  );
}

type AdminCreatePostAudienceSelectorGroupOptionProps = {
  group: CommunityFeedAudienceGroupV2Dto;
};

function AdminCreatePostAudienceSelectorGroupOption({ group }: AdminCreatePostAudienceSelectorGroupOptionProps) {
  const resolveImage = useImageResolver();
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-start gap-2">
      {group.isResidentGroup && group.image ? (
        <img src={resolveImage(group.image, "sm")} alt={group.name} className="size-5 rounded-full" />
      ) : (
        <GroupIcon icon={group.icon} size={20} />
      )}
      <div className="flex w-full flex-col items-start overflow-hidden">
        <span className="w-full truncate text-left">{group.name}</span>
        <span className="text-caption text-grey-700">
          {group.lastActivityAt
            ? t("page.admin-create-post.form.audience.group.last-activity", {
                time: formatDistance(t, { start: new Date(group.lastActivityAt) }),
              })
            : t("page.admin-create-post.form.audience.group.no-activity")}
        </span>
      </div>
      {group.selectableAudience.length === 1 ? (
        <span className="ml-2 shrink-0 whitespace-nowrap text-caption text-grey-700">
          {t("page.admin-create-post.form.audience.group.members", {
            count:
              group.selectableAudience[0].type === "project"
                ? (group.selectableAudience[0].project?.audienceCount ?? 0)
                : (group.selectableAudience[0].projectConnection?.audienceCount ?? 0),
          })}
        </span>
      ) : null}
    </div>
  );
}
