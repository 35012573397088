import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import iconDotsGrid from "assets/icons/dots-grid.svg";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconSettings01 from "assets/icons/settings-01.svg";
import iconSwitchHorizontal01 from "assets/icons/switch-horizontal-01.svg";
import iconTool02 from "assets/icons/tool-02.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { Icon } from "components/Icon/Icon";
import type { SidebarBaseItemType, SidebarItemType } from "components/Sidebar/types";
import type { SidebarGroupedItemType } from "components/Sidebar/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { QUERY_KEYS } from "query-keys";
import { routes } from "routes";
import { useTranslation } from "translations";

import { canAccessSystemSettings, canAccessUserSearch } from "./permissions";

export const usePortfolioSidebarManager = (): {
  navigationItems: SidebarItemType[];
} => {
  const { t } = useTranslation();
  const [user] = useSessionUserOptional({ keepPreviousData: true });

  const {
    data: connectedProjects,
    isPending: isPendingConnectedProjects,
    isError: isErrorConnectedProjects,
  } = useConnectedProjects();
  const hasPermission = usePermission();
  const api = useApi();

  const {
    data: massMessagePermissions,
    isPending: isPendingMassMessagePermissions,
    isError: isErrorMassMessagePermissions,
  } = useQuery({
    queryKey: QUERY_KEYS.MASS_MESSAGES_PERMISSION(),
    queryFn: api.getMassMessagesV1,
    select: commonAPIDataSelector,
    staleTime: 1000 * 60 * 5,
    gcTime: Infinity,
  });

  if (
    isPendingConnectedProjects ||
    isErrorConnectedProjects ||
    isPendingMassMessagePermissions ||
    isErrorMassMessagePermissions ||
    !user
  ) {
    return {
      navigationItems: [],
    };
  }

  const items: (SidebarBaseItemType | SidebarGroupedItemType)[] = [
    {
      key: "portfolio",
      label: t("navigation.portfolio.portfolio"),
      icon: <Icon name={iconDotsGrid} />,
      to: routes.portfolio.overview(),
      exact: true,
      "data-testid": "navigation-portfolio",
    },
  ];

  if (
    connectedProjects.some(
      (x) =>
        x.userRole !== "resident" &&
        (massMessagePermissions?.canMassMessageGeneralPost || massMessagePermissions?.canMassMessageAnnouncementPost),
    )
  ) {
    items.push({
      key: "mass-message",
      label: t("navigation.portfolio.mass-message"),
      icon: <Icon name={iconMessageSquare02} />,
      "data-testid": "navigation-mass-message",
      to: routes.portfolio.createMassMessage(),
    });
  }

  if (connectedProjects.some((x) => x.userRole !== "resident" && x.userRole !== "servicePartner")) {
    items.push({
      key: "tickets",
      label: t("navigation.portfolio.tickets"),
      icon: <Icon name={iconTool02} />,
      "data-testid": "navigation-portfolio-tickets",
      to: routes.portfolio.tickets(),
    });
  }

  if (connectedProjects.some((x) => x.userRole !== "resident" && x.userRole !== "servicePartner")) {
    items.push({
      key: "alerts",
      label: t("navigation.portfolio.alerts"),
      icon: <Icon name={iconAlertTriangle} />,
      "data-testid": "navigation-alerts",
      to: routes.alerts.list(),
    });
  }

  if (hasPermission(canAccessUserSearch)) {
    items.push({
      key: "user-search",
      label: t("navigation.portfolio.user-search"),
      icon: <Icon name={iconUsers01} />,
      "data-testid": "navigation-user-search",
      to: routes.portfolio.userLookup(),
    });
  }

  items.push({
    key: "integrations",
    label: t("navigation.portfolio.integrations"),
    icon: <Icon name={iconSwitchHorizontal01} />,
    "data-testid": "navigation-integrations",
    to: routes.portfolio.integrations(),
  });

  if (hasPermission(canAccessSystemSettings)) {
    items.push({
      key: "system-settings-group",
      label: t("navigation.portfolio.system-settings"),
      "data-testid": "navigation-system-settings-group",
      icon: <Icon name={iconSettings01} />,
      type: "group",
      items: [
        {
          key: "project-connections",
          label: t("navigation.portfolio.project-connections"),
          to: routes.projectConnections.list(),
          "data-testid": "navigation-project-connections",
        },
        {
          key: "platform-groups",
          label: t("navigation.portfolio.platform-groups"),
          to: routes.platformGroups.list(),
          "data-testid": "navigation-platform-groups",
        },
        {
          key: "automated-surveys",
          label: t("navigation.portfolio.automated-surveys"),
          to: routes.automatedSurveys.list(),
          "data-testid": "navigation-automated-surveys",
        },
        {
          key: "new-project",
          label: t("navigation.portfolio.new-project"),
          to: routes.portfolio.newProject(),
          "data-testid": "navigation-new-project",
        },
      ],
    });
  }

  return {
    navigationItems: items,
  };
};
