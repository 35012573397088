import type { PluralForm, TranslationFunction } from "./types";

export function createT<T extends Record<string, string>>({
  translations,
  language,
  onMissingKey,
  onMissingVariable,
}: {
  translations: T;
  language: string;
  onMissingKey?: (key: string, language: string) => void;
  onMissingVariable?: (key: string, value: string, language: string) => void;
}): TranslationFunction<T> {
  return (key: keyof T, variables?: { count?: number; [key: string]: any }): string => {
    let result: string | undefined = undefined;

    // We determine the plural form based on the count variable.
    // If it's not available we fall back to the non-plural form, and log it.
    if (typeof variables?.count === "number") {
      const pluralForm = getPluralForm(language, variables.count);
      const pluralKey = `${key as string}_${pluralForm}` as keyof typeof translations;
      result = translations[pluralKey];

      if (result == null) {
        result = translations[key];
      }
    } else {
      result = translations[key];
    }

    if (result == null) {
      onMissingKey?.(key as string, language);

      return key as string;
    }

    if (variables) {
      result = substitute(result, variables);
    }

    if (containsVariables(result)) {
      onMissingVariable?.(key as string, result, language);
    }

    return result;
  };
}

const pluralRules: Record<string, Intl.PluralRules> = {};
function getPluralForm(lang: string, count: number): PluralForm {
  let pluralRule = pluralRules[lang];
  if (!pluralRule) {
    pluralRules[lang] = new Intl.PluralRules(lang);
    pluralRule = pluralRules[lang];
  }

  return pluralRule.select(count);
}

const substituteRegex = /{{\s*(\w+)\s*}}/g;
function substitute(translation: string, variables: { [key: string]: any }): string {
  return translation.replace(substituteRegex, (group, variable) => {
    const value = variables[variable];
    if (value == null) {
      return group;
    }

    if (typeof value === "string") {
      return value;
    }

    return String(value);
  });
}

function containsVariables(translation: string): boolean {
  // translation.includes pre-check makes this faster in the common case.
  return translation.includes("{{") && substituteRegex.test(translation);
}
