import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityGroupDetailsDto } from "api/types";
import { CardGrid } from "components/CardGrid/CardGrid";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";
import { useTranslation } from "translations";
import type { ApiQueryParams, ApiResponseType } from "types/api-types";

import { GroupCard } from "./GroupCard";

type ListProps = {
  type: CommunityGroupDetailsDto["type"];
  followLabels: {
    button: string;
    failedNotification: string;
  };
  unfollowLabels: {
    button: string;
    failedNotification: string;
  };
  allAudienceLabel: string;
  deleteLabels: {
    confirmation: string;
    description: string;
    success: string;
    error: string;
  };
  statusLabels: {
    disable: {
      confirmation: string;
      description: string;
      success: string;
      error: string;
    };
    enable: {
      success: string;
      error: string;
    };
  };
};

export function List(props: ListProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();

  const queryArgs = {
    groupTypes: props.type === "interest" ? ["interest", "realEstate"] : ["helpCategory"],
    Limit: 10000,
  } satisfies ApiQueryParams<"getGroupsV2">;
  const groupsQueryKey = QUERY_KEYS.COMMUNITY_GROUPS_QUERY(projectId, queryArgs);
  const {
    data: groups = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: groupsQueryKey,
    queryFn: () => api.getGroupsV2(queryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });
  const { mutate: follow } = useMutation({
    mutationFn: (id: string) => api.postGroupsFollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsV2"> | undefined>(groupsQueryKey, (data) => {
        if (data) {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item) => {
                if (item.id === id) {
                  return { ...item, isMember: true };
                }

                return item;
              }),
            },
          };
        }
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: props.followLabels.failedNotification,
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
  });
  const { mutate: unfollow } = useMutation({
    mutationFn: (id: string) => api.postGroupsUnfollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsV2"> | undefined>(groupsQueryKey, (data) => {
        if (data) {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item) => {
                if (item.id === id) {
                  return { ...item, isMember: false };
                }

                return item;
              }),
            },
          };
        }
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: props.unfollowLabels.failedNotification,
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
  });
  const deleteGroupMutationResult = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: props.deleteLabels.success,
        "data-testid": "toast-group-is-deleted",
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: props.deleteLabels.error,
      });
    },
  });
  const { mutateAsync: enableGroup } = useMutation({
    mutationFn: api.postGroupsEnableV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: props.statusLabels.enable.success,
        "data-testid": "toast-group-is-enabled",
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: props.statusLabels.enable.error,
      });
    },
  });
  const disableGroup = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: props.statusLabels.disable.success,
        "data-testid": "toast-group-is-disabled",
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: props.statusLabels.disable.error,
      });
    },
  });
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("group-delete-modal");
  const [disableGroupId, setDisableGroupId] = useState<string | null>(null);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      <CardGrid>
        {groups.map((group) => (
          <GroupCard
            key={group.id}
            onEnableGroup={() => enableGroup(group.id)}
            followButtonLabel={props.followLabels.button}
            unfollowButtonLabel={props.unfollowLabels.button}
            onFollow={follow}
            onUnfollow={unfollow}
            onDisableGroup={setDisableGroupId}
            onDeleteGroup={openDeleteModal}
            {...{ group }}
          />
        ))}
      </CardGrid>
      <DeleteModal<string>
        title={props.deleteLabels.confirmation}
        description={props.deleteLabels.description}
        onDelete={deleteGroupMutationResult.mutateAsync}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        title={props.statusLabels.disable.confirmation}
        description={props.statusLabels.disable.description}
        theme="danger"
        onReject={() => setDisableGroupId(null)}
        onOpenChange={(state) => {
          if (!state) {
            setDisableGroupId(null);
          }
        }}
        onResolve={async () => {
          await disableGroup.mutateAsync(disableGroupId!);
          setDisableGroupId(null);
        }}
        resolveBtnProps={{
          text: t("common.action.disable"),
          "data-testid": "group-disable-modal-disable",
        }}
        isOpened={disableGroupId != null}
        shouldCloseOnEsc
        isLoading={false}
      />
    </>
  );
}
