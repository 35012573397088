import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Modal } from "components/Modal/Modal";
import { addDays, parseISO } from "date-fns";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "translations";

interface UserSetDeletionDateModalProps {
  isOpened: boolean;
  onOpenChange: (state: boolean) => void;
  deletionDate: string | undefined;
}

export function UserSetDeletionDateModal({
  isOpened,
  onOpenChange,
  deletionDate,
}: UserSetDeletionDateModalProps): React.ReactNode {
  const [currDeletionDate, setCurrDeletionDate] = useState<DatePickerValue>("");

  const api = useApi();
  const { id: userId } = useParams<{ id: string }>();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (!deletionDate) {
      return;
    }

    setCurrDeletionDate(parseISO(deletionDate));
  }, [deletionDate]);

  const setDeletionDate = useMutation({
    mutationFn: (date: Date | undefined) => api.putUsersDeletionDateV1(userId!, { deleteAt: date?.toISOString() }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, userId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_POSTS(projectId, userId!) });
      showFlashToast({ type: "success", title: t("page.user-detail.set-deletion-date.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.user-detail.set-deletion-date.error") });
    },
  });

  return (
    <Modal.Root
      isOpened={isOpened}
      onOpenChange={onOpenChange}
      title={t("page.user-detail.delete-date-modal.title")}
      description={t("page.user-detail.delete-date-modal.description")}
      shouldCloseOnClickOutside={false}
    >
      <div className="flex items-center gap-2 pb-1 pt-2">
        <DateAndTimePicker value={currDeletionDate} onChange={setCurrDeletionDate} min={addDays(new Date(), 1)} />
      </div>
      <Modal.Actions>
        {deletionDate && (
          <Button
            className="mr-auto"
            onClick={async () => {
              await setDeletionDate.mutateAsync(undefined);
              setCurrDeletionDate("");
              onOpenChange(false);
            }}
            styling="danger"
            isLoading={setDeletionDate.isPending}
          >
            {t("page.user-detail.delete-date-modal.action.reset")}
          </Button>
        )}
        <Button onClick={() => onOpenChange(false)} styling="secondary" disabled={setDeletionDate.isPending}>
          {t("common.action.cancel")}
        </Button>

        <Button
          onClick={async () => {
            await setDeletionDate.mutateAsync(currDeletionDate || undefined);

            onOpenChange(false);
          }}
          styling="primary"
          isLoading={setDeletionDate.isPending}
        >
          {t("common.action.confirm")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}
