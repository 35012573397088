import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

export const useUserQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  return {
    list: ({ params }: { params: ApiQueryParams<"getUsersV1"> }) =>
      queryOptions({
        queryKey: QUERY_KEYS.USERS_QUERY(projectId, params),
        queryFn: () => api.getUsersV1(params),
        select: (response) => commonAPIDataSelector(response).items,
      }),
    details: ({ userId }: { userId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.USER_DETAILS(projectId, userId),
        queryFn: () => api.getUsersDetailsV1(userId),
        select: commonAPIDataSelector,
      }),
    listProjects: ({ userId }: { userId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.USER_PROJECTS(projectId, userId),
        queryFn: () => api.getUsersProjectsV1(userId),
        select: commonAPIDataSelector,
      }),
    listMessages: ({ userId }: { userId: string }) => {
      const limit = 2;
      const offset = 0;

      return queryOptions({
        queryKey: QUERY_KEYS.USER_POSTS(projectId, userId),
        queryFn: () =>
          api.getMessagesV2({
            Tab: "all",
            Offset: offset,
            Limit: limit,
            PosterId: userId,
            Connection: "included",
          }),
        select: commonAPIDataSelector,
      });
    },
    signUpReminders: ({ userId }: { userId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.USER_SIGNUP_REMINDERS(projectId, userId),
        queryFn: () => api.getUsersUserSignupRemindersV1(userId),
        select: commonAPIDataSelector,
      }),
  };
};
