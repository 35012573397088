import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AssetBookingDto } from "api/types";
import iconTrash01 from "assets/icons/trash-01.svg";
import { IconButton } from "components/Button/IconButton";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { Table } from "components/Table/Table";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { parseISO } from "date-fns";
import { timeWithoutSeconds } from "helpers/date";
import { useMemo, useState } from "react";
import { useTranslation } from "translations";

import { DeclineBookingModal } from "./DeclineBookingModal";

type BookingListModalProps = ModalBaseProps & {
  bookings: AssetBookingDto[];
};

export function BookingListModal({ isOpened, onOpenChange, bookings }: BookingListModalProps): React.ReactNode {
  const [bookingToBeDeclined, setBookingToBeDeclined] = useState<AssetBookingDto | undefined>();

  const { t } = useTranslation();
  const columns = useMemo(() => {
    const helper = createColumnHelper<AssetBookingDto>();

    return [
      helper.accessor("startTime", {
        header: t("page.bookings.asset-detail.booking-list-modal.timeslot"),
        size: 120,
        cell: ({ row }) => (
          <span className="">
            {timeWithoutSeconds(row.original.startTime)}-{timeWithoutSeconds(row.original.endTime)}
          </span>
        ),
      }),
      helper.accessor("author", {
        header: t("page.bookings.asset-detail.booking-list-modal.author"),
        cell: ({ row }) =>
          row.original.author && (
            <UserNameLink user={row.original.author}>
              <span className="max-w-64 truncate font-old-semibold">{row.original.author.fullName}</span>
            </UserNameLink>
          ),
      }),
      helper.accessor("bookingReason", {
        header: t("page.bookings.asset-detail.booking-list-modal.reason"),
        size: 240,
        cell: ({ row }) => <span className="block py-1">{row.original.bookingReason}</span>,
      }),
      helper.accessor("id", {
        header: "",
        size: 40,
        cell: ({ row }) =>
          row.original.canCancel && (
            <IconButton
              data-testid="decline-booking-btn"
              styling="danger"
              onClick={() => {
                setBookingToBeDeclined(row.original);
              }}
              title={t("page.bookings.asset-detail.booking-list-modal.cancel")}
              icon={iconTrash01}
            />
          ),
      }),
    ];
  }, [t]);

  const tableInstance = useReactTable<AssetBookingDto>({
    columns,
    state: {
      columnVisibility: {
        bookingReason: bookings[0]?.asset?.requireBookingReason,
      },
    },
    data: bookings,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Modal.Root
        title={t("page.bookings.asset-detail.booking-list-modal.title")}
        description={
          bookings.length > 1 && !!bookings[0].date && <FormattedDate date={parseISO(bookings[0].date)} format="date" />
        }
        isScrollable
        size="fullscreen"
        {...{ isOpened, onOpenChange }}
      >
        {bookings.length > 0 && <Table table={tableInstance} />}
      </Modal.Root>
      {bookingToBeDeclined && (
        <DeclineBookingModal
          booking={bookingToBeDeclined}
          isOpened={!!bookingToBeDeclined}
          onOpenChange={(state) => {
            if (!state) {
              setBookingToBeDeclined(undefined);
              onOpenChange(false);
            }
          }}
        />
      )}
    </>
  );
}
