import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ProjectConnectionDetailsDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "translations";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();

  const connectionsQuery = { Limit: 1000, Offset: 0, IncludeAllFromProfile: true };
  const {
    data: connections,
    isPending: isLoadingConnections,
    error: connectionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_LIST_INCLUDE_ALL(projectId, connectionsQuery),
    queryFn: () => api.getProjectConnectionsV1(connectionsQuery),
    select: commonAPIDataSelector,
  });

  const deleteProjectConnection = useMutation({
    mutationFn: (connection: ProjectConnectionDetailsDto) => api.deleteProjectConnectionsByIdV1(connection.id),
    onSuccess() {
      showFlashToast({
        title: t("page.project-connections.actions.delete.notification.success"),
        type: "success",
      });
    },
    onError() {
      showFlashToast({
        title: t("page.project-connections.actions.delete.notification.error"),
        type: "error",
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
    },
  });

  const isLoading = isLoadingConnections;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = connectionsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({ connections: connections?.items ?? [], onDelete: deleteProjectConnection.mutateAsync });
}
