import { Menu as ArkMenu } from "@ark-ui/react";
import type React from "react";

interface MenuRootProps {
  children: React.ReactNode;
}

export function ContextMenuRoot({ children }: MenuRootProps): React.ReactNode {
  return (
    <ArkMenu.Root
      positioning={{
        placement: "right-start",
      }}
      lazyMount
      unmountOnExit
    >
      {children}
    </ArkMenu.Root>
  );
}
