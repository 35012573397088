import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormInput } from "components/Form/FormInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Notice } from "components/Notice/Notice";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "translations";

type SlugModalProps = ModalBaseProps & {
  initialSlug: string;
  takenSlugs: string[];
  onSubmit: (slug: string) => void;
};

export function SlugModal({
  isOpened,
  onOpenChange,
  initialSlug,
  takenSlugs,
  onSubmit,
}: SlugModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<SlugModalValues>({
    defaultValues: {
      slug: initialSlug,
    },
  });

  return (
    <Modal.Root size="sm" title={t("page.projects.slug-modal.title")} {...{ isOpened, onOpenChange }}>
      <Form<SlugModalValues> formMethods={form} onSubmit={(data) => onSubmit(data.slug.trim().toLowerCase())} noPrompt>
        <div className="flex max-w-lg flex-col items-center gap-4 text-left">
          <Notice icon={iconAlertTriangle} message={t("page.projects.slug-modal.warning")} type="warning" />
          <div className="w-full">
            <FormInput<SlugModalValues>
              id="slug"
              name="slug"
              rules={{
                maxLength: {
                  message: t("components.form.error.max-length", {
                    length: 64,
                  }),
                  value: 64,
                },
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.slug"),
                  mustNotContainSpecialCharacters: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    return /[^A-Za-z0-9-]/.test(value) ? t("components.form.error.no-special-characters") : undefined;
                  },
                  mustBeUnique: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    if (value === initialSlug) {
                      return undefined;
                    }

                    return takenSlugs.includes(value) ? t("page.projects.slug-modal.error.not-unique") : undefined;
                  },
                },
              }}
            />
          </div>
          <Modal.Actions>
            <Modal.Close>
              <Button type="button" styling="secondary">
                {t("page.projects.slug-modal.cancel")}
              </Button>
            </Modal.Close>
            <Button type="submit">{t("page.projects.slug-modal.submit")}</Button>
          </Modal.Actions>
        </div>
      </Form>
    </Modal.Root>
  );
}

interface SlugModalValues {
  slug: string;
}
