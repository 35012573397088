import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useTranslation } from "translations";

import type { ResidentCreateCommentPayload } from "./TicketResidentCommentField";
import { TicketResidentCommentField } from "./TicketResidentCommentField";

type TicketResidentRejectModalProps = ModalBaseProps & {
  onSubmit: (payload: ResidentCreateCommentPayload) => Promise<void>;
};

export function TicketResidentRejectModal({
  isOpened,
  onOpenChange,
  onSubmit,
}: TicketResidentRejectModalProps): React.ReactNode {
  const [isSubmitting, submittingHandlers] = useBool();

  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  async function submit(payload: ResidentCreateCommentPayload) {
    if (isSubmitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      if (payload.content) {
        await onSubmit?.(payload);
      } else {
        onOpenChange(false);
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal.Root
      title={t("page.tickets.reject-modal.title")}
      description={t("page.tickets.reject-modal.description")}
      shouldCloseOnEsc={!isSubmitting}
      shouldCloseOnClickOutside={!isSubmitting}
      data-testid="ticket-reject-modal"
      {...{ isOpened, onOpenChange }}
    >
      {isSubmitting ? (
        <LoadingIcon className="mx-auto mb-6 mt-8 w-8" />
      ) : (
        <TicketResidentCommentField canComment user={sessionUser} onComment={(payload) => submit(payload)} />
      )}
    </Modal.Root>
  );
}
