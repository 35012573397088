import { useContext, useMemo } from "react";

import { I18nContext } from "./I18nProvider";
import type { i18n } from "./types";

interface UseTranslationValue {
  t: i18n["t"];
  i18n: i18n;
}

export const useTranslation = (): UseTranslationValue => {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationsProvider");
  }

  return useMemo(
    () => ({
      t: context.t,
      i18n: {
        t: context.t,
        language: context.language,
        changeLanguage: context.changeLanguage,
      },
    }),
    [context.t, context.language, context.changeLanguage],
  );
};
