import iconChevronRight from "assets/icons/chevron-right.svg";
import iconStar01 from "assets/icons/star-01.svg";
import type { LegendDataIconProps } from "components/Charts/LegendDataComponent";
import { LegendDataIcon } from "components/Charts/LegendDataComponent";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { createDateTickFormat, createXAxis, createYAxis } from "modules/analytics/components/utility/Axis";
import { HoverLabel } from "modules/analytics/components/utility/HoverLabel";
import { useElementScale } from "modules/analytics/hooks/useElementScale";
import { chartTheme, dataColors, fontStyling } from "modules/analytics/theme";
import { AnimatePresence, motion } from "motion/react";
import { useTranslation } from "translations";
import * as V from "victory";

interface DataPoint<TCategory = string> {
  x: TCategory;
  y: number | undefined;
}

type NonEmptyDataPoint<T extends DataPoint> = T & { y: NonNullable<T["y"]> };

interface PortfolioOverviewChartProps {
  view: "rating" | "feelAtHome" | "adoption" | "engagement";
  month: Date;
  minY?: number;
  maxY?: number;
  type: "text" | "date";
  primaryData: DataPoint[];
  benchmarkData: DataPoint[];
  benchmarkLabel: string;
  formatYTick: (y: NonEmptyDataPoint<DataPoint>["y"]) => string | number;
  arrowHoverText?: string;
}

const CHART_WIDTH = 400;
const CHART_HEIGHT = 150;
const LABEL_OFFSET_X = 12;
const LABEL_OFFSET_Y = -16;

export function PortfolioOverviewChart({
  view,
  month,
  minY = 0,
  maxY,
  type,
  benchmarkData,
  benchmarkLabel,
  primaryData,
  formatYTick,
  arrowHoverText,
}: PortfolioOverviewChartProps): React.ReactNode {
  const [showScrollIndicator, showScrollIndicatorHandlers] = useBool(true);
  const { t, i18n } = useTranslation();
  const { scale, ref } = useElementScale<HTMLDivElement>(CHART_WIDTH, 1.55);

  const barWidth = primaryData.length > 10 ? 10 : 14;
  const xCategories = primaryData.map((d) => d.x);

  // Convert x values to indices
  const formattedPrimaryData: DataPoint<number>[] = primaryData
    .filter((dataPoint) => isDefined(dataPoint.y))
    .map((dataPoint) => ({
      ...dataPoint,
      // X values need to start at 1
      x: xCategories.indexOf(dataPoint.x) + 1,
    }));
  const formattedBenchmarkData: DataPoint<number>[] = benchmarkData
    .filter((dataPoint) => isDefined(dataPoint.y))
    .map((dataPoint) => ({
      ...dataPoint,
      // X values need to start at 1
      x: xCategories.indexOf(dataPoint.x) + 1,
    }));

  return (
    <div className="relative" ref={ref}>
      <V.VictoryChart
        minDomain={{ y: minY }}
        maxDomain={isDefined(maxY) ? { y: maxY } : undefined}
        theme={chartTheme}
        width={scale * CHART_WIDTH}
        height={scale * CHART_HEIGHT}
        padding={{
          top: 38,
          right: 8,
          bottom: 34,
          left: 42,
        }}
        domainPadding={{ x: 24, y: [0, 120] }}
        categories={{ x: xCategories }}
        containerComponent={
          <V.VictoryZoomContainer
            allowZoom={false}
            allowPan={primaryData.length > 5}
            zoomDimension="x"
            zoomDomain={{
              x: [0, 8],
              y: maxY ? [0, maxY] : undefined,
            }}
            onZoomDomainChange={(event) => {
              if (showScrollIndicator) {
                showScrollIndicatorHandlers.set(event.x[0] === 0);
              }
            }}
          />
        }
      >
        {/* Axis */}
        {createYAxis({ tickCount: 6, tickFormat: formatYTick })}
        {createXAxis({
          tickFormat:
            type === "text"
              ? (x) => (x.length > 19 ? x.substring(0, 16) + "…" : x)
              : createDateTickFormat(i18n, formattedPrimaryData.length, scale),
        })}
        {/* Bar chart */}
        <V.VictoryBar
          barWidth={barWidth * scale}
          data={formattedPrimaryData}
          style={{
            data: {
              fill: dataColors.primary,
              strokeWidth: 18,
              strokeLinejoin: "round",
              transform: "translateY(9px)",
            },
          }}
          labelComponent={
            <V.VictoryTooltip
              text=""
              // eslint-disable-next-line react/jsx-no-useless-fragment
              flyoutComponent={<></>}
              labelComponent={
                <HoverLabel<DataPoint<number>>
                  offsetX={LABEL_OFFSET_X * scale}
                  offsetY={LABEL_OFFSET_Y * scale}
                  containerWidth={CHART_WIDTH * scale}
                >
                  {(dataPoint) => {
                    const currX = xCategories[dataPoint.x - 1];
                    const currPrimaryY = dataPoint.y ?? 0;

                    return (
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <FormattedDate date={month} format="monthYear" />
                            </td>
                          </tr>
                          {(view === "rating" || view === "feelAtHome") && (
                            <>
                              <StarRow
                                title={currX}
                                value={currPrimaryY}
                                icon={{
                                  fill: dataColors.primary,
                                  type: "square",
                                }}
                              />
                              {isDefined(formattedBenchmarkData[dataPoint.x - 1].y) &&
                                formattedBenchmarkData[dataPoint.x - 1].y !== undefined && (
                                  <StarRow
                                    title={t("page.portfolio.analytics.benchmark")}
                                    value={formattedBenchmarkData[dataPoint.x - 1].y!}
                                    icon={{
                                      fill: dataColors.benchmark,
                                      type: "minus",
                                    }}
                                  />
                                )}
                            </>
                          )}
                          {(view === "adoption" || view === "engagement") && (
                            <PercentageRow
                              title={currX}
                              value={currPrimaryY}
                              icon={{
                                fill: dataColors.primary,
                                type: "square",
                              }}
                            />
                          )}
                        </tbody>
                      </table>
                    );
                  }}
                </HoverLabel>
              }
            />
          }
          labels={(d) => d}
        />
        {/* Benchmark */}
        {formattedBenchmarkData.length === 1 && (
          <V.VictoryLine
            style={{ data: { pointerEvents: "none", strokeWidth: 2, stroke: dataColors.benchmark } }}
            data={[
              { x: formattedBenchmarkData[0].x, y: formattedBenchmarkData[0].y },
              { x: "-", y: formattedBenchmarkData[0].y },
            ]}
            labels={[benchmarkLabel]}
            labelComponent={renderBarLabel(scale)}
          />
        )}
        {formattedBenchmarkData.length > 1 && (
          <V.VictoryLine
            style={{ data: { pointerEvents: "none", strokeWidth: 2, stroke: dataColors.benchmark } }}
            data={formattedBenchmarkData.map((d) => (isDefined(d?.y) ? d : undefined)).filter(isDefined)}
            labels={formattedBenchmarkData.map((_, i) => (i === 0 ? benchmarkLabel : ""))}
            labelComponent={renderBarLabel(scale)}
          />
        )}
      </V.VictoryChart>

      {/* Panning overlay */}
      <AnimatePresence>
        {formattedPrimaryData.length > 8 && showScrollIndicator && (
          <motion.div
            className="pointer-events-none absolute inset-y-0 right-0 flex h-full w-32 items-center justify-end bg-gradient-to-r from-transparent to-white p-4 text-grey-500"
            exit={{ opacity: 0 }}
            onMouseDown={() => showScrollIndicatorHandlers.setFalse()}
          >
            <div className="pointer-events-auto">
              <Tooltip tooltip={arrowHoverText}>
                <Icon name={iconChevronRight} />
              </Tooltip>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function renderBarLabel(scale: number) {
  return (
    <V.VictoryLabel
      style={{
        fill: dataColors.benchmark,
        fontFamily: fontStyling.fontFamily,
        fontSize: fontStyling.fontSize,
        stroke: dataColors.benchmarkDark,
        strokeWidth: 0.05,
      }}
      labelPlacement="perpendicular"
      dx={15 * scale}
      dy={-5}
    />
  );
}

function StarRow({ title, icon, value }: { title: string; icon: LegendDataIconProps; value: number }) {
  return (
    <tr>
      <td className="pr-2">
        <LegendDataIcon {...icon} />
      </td>
      <td>{title}</td>
      <td className="pl-2">
        <Icon name={iconStar01} size={16} />
      </td>
      <td>
        <strong>{value}</strong>
      </td>
    </tr>
  );
}

function PercentageRow({ title, icon, value }: { title: string; icon: LegendDataIconProps; value: number }) {
  return (
    <tr>
      <td className="pr-2">
        <LegendDataIcon {...icon} />
      </td>
      <td className="pr-2">{title}</td>
      <td>
        <strong>{value}%</strong>
      </td>
    </tr>
  );
}
