import type { PermissionSelector } from "hooks/usePermission";
import { canListAddresses, canManageAddresses } from "modules/addresses/permissions";
import { canViewSchedule } from "modules/bookings/permissions";
import { canManageBuildings } from "modules/buildings/permissions";
import { canManageCompanies } from "modules/companies/permissions";
import { canListDocuments, canManageAllDocuments } from "modules/documents/permissions";
import { canListMessages } from "modules/messages/permissions";
import {
  canManageIntegrations,
  canManageProjectManagement,
  canViewProjectManagement,
} from "modules/project/permissions";
import { canManageRoles } from "modules/roles/permissions";
import { canListAnySurvey } from "modules/surveys/permissions";
import { canBulkUploadUsers, canInviteUser, canListUsers } from "modules/users/permissions";

export const canAccessHome: PermissionSelector = (x) => x.isAdmin && !x.isMaintenance && !x.isServicePartner;

export const canAccessAnalytics: PermissionSelector = (x) =>
  x.isAdmin && !x.isMaintenance && !x.isCaretaker && !x.isServicePartner;

export const canAccessManageNeighbours: PermissionSelector = (x) => x.isAdmin && canListUsers(x);

export const canAccessManageAddresses: PermissionSelector = (x) => x.isAdmin && canListAddresses(x);

export const canAccessManageCompanies = canManageCompanies;

export const canAccessInterestGroups: PermissionSelector = () => true;

export const canAccessHelpCategories: PermissionSelector = () => true;

export const canAccessCommunityFeed = canListMessages;

export const canAccessChats = canListUsers;

export const canAccessCalendar: PermissionSelector = () => true;

export const canAccessServices: PermissionSelector = () => true;

export const canAccessAssets = canViewSchedule;

export const canAccessReservations = canAccessAssets;

export const canAccessResidentTickets: PermissionSelector = (x) => x.isResident && x.canCreateTicket;

export const canAccessAdminTickets: PermissionSelector = (x) => x.isAdmin && !x.isServicePartner;

export const canAccessPracticalAndMore: PermissionSelector = (x) => canListDocuments(x) || canManageAllDocuments(x);

export const canAccessRegularSurveys = canListAnySurvey;

export const canAccessAutomatedSurveys: PermissionSelector = (x) => x.isSuperAdmin;

export const canAccessProjectBuildings: PermissionSelector = (x) => canManageBuildings(x);

export const canAccessProjectAddressUpload: PermissionSelector = (x) => canManageAddresses(x);

export const canAccessProjectCompanyUpload: PermissionSelector = (x) => canListAddresses(x) && canManageCompanies(x);

export const canAccessProjectUserUpload: PermissionSelector = (x) =>
  canListUsers(x) &&
  canInviteUser(x) &&
  canBulkUploadUsers(x) &&
  ((x.projectType === "addressBased" && canListAddresses(x)) || canManageCompanies(x));

export const canAccessProjectSettings: PermissionSelector = (x) => x.isSuperAdmin;

export const canAccessProjectStyling: PermissionSelector = (x) =>
  canViewProjectManagement(x) || canManageProjectManagement(x);

export const canAccessProjectOnboadingScreens: PermissionSelector = (x) =>
  canViewProjectManagement(x) || canManageProjectManagement(x);

export const canAccessProjectPlusButtonOptions: PermissionSelector = (x) =>
  canViewProjectManagement(x) || canManageProjectManagement(x);

export const canAccessProjectRolesAndPermissions: PermissionSelector = (x) => canManageRoles(x);

export const canAccessProjectIntegrationSettings: PermissionSelector = (x) => canManageIntegrations(x);

export const canAccessAdminNotifications: PermissionSelector = (x) => x.isAdmin;
