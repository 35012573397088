import { FormDocumentPicker } from "components/Form/FormDocumentPicker";
import { FormField } from "components/Form/FormField";
import { FormImagePicker } from "components/Form/FormImagePicker";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { FormVideoPicker } from "components/Form/FormVideoPicker";
import {
  createDocumentSizeRule,
  createDocumentTypeRule,
  createImageSizeRule,
  createImageTypeRule,
  createRequiredStringRule,
  createVideoSizeRule,
  createVideoTypeRule,
} from "helpers/rules";
import { MAX_AMOUNT_IMAGES_PER_POST } from "modules/messages/constants";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "translations";

import type { AdminCreatePostFormValues } from "../Layout";

export function AdminCreatePostFormEditor(): React.ReactNode {
  const { t } = useTranslation();
  const postHog = usePostHog();

  const form = useFormContext<AdminCreatePostFormValues>();
  const currImages = useWatch({ name: "images", control: form.control });
  const currVideos = useWatch({ name: "videos", control: form.control });
  const currDocuments = useWatch({ name: "documents", control: form.control });

  const isAnyAttachmentSelected = currImages.length > 0 || currVideos.length > 0 || currDocuments.length > 0;
  const error =
    form.formState.errors["images"]?.message ||
    form.formState.errors["videos"]?.message ||
    form.formState.errors["documents"]?.message;

  return (
    <div className="flex w-full flex-col gap-2">
      <FormField label={t("page.admin-create-post.form.title")} htmlFor="input-title" required hideLabel>
        <FormInput<AdminCreatePostFormValues, "title">
          name="title"
          id="input-title"
          placeholder={t("page.admin-create-post.form.title.placeholder")}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.admin-create-post.form.title"),
            },
          }}
        />
      </FormField>

      <FormField label={t("page.admin-create-post.form.message")} htmlFor="input-content" hideLabel>
        <FormTextArea<AdminCreatePostFormValues, "content">
          name="content"
          id="input-content"
          placeholder={t("page.admin-create-post.form.message.placeholder")}
          rules={{
            validate: {
              required: createRequiredStringRule(t, "page.admin-create-post.form.message"),
            },
          }}
          size="lg"
          scrollbar
        />
      </FormField>

      <FormField label={t("page.create-post.form.file")} hideLabel>
        <div className="flex w-full flex-col gap-1">
          <div className="flex w-full flex-wrap items-center gap-2">
            {(!isAnyAttachmentSelected || currImages.length > 0) && (
              <FormImagePicker<AdminCreatePostFormValues, "images">
                name="images"
                maxFiles={MAX_AMOUNT_IMAGES_PER_POST}
                value={currImages}
                disabled={currImages.length >= MAX_AMOUNT_IMAGES_PER_POST}
                rules={{
                  validate: {
                    size: createImageSizeRule({ t, onError: () => postHog.capture("form_error_image_size_exceeded") }),
                    type: createImageTypeRule({ t }),
                  },
                }}
              />
            )}
            {(!isAnyAttachmentSelected || currVideos.length > 0) && (
              <FormVideoPicker<AdminCreatePostFormValues, "videos">
                name="videos"
                maxFiles={1}
                value={currVideos}
                disabled={currVideos.length > 0}
                rules={{
                  validate: {
                    size: createVideoSizeRule({ t, onError: () => postHog.capture("form_error_video_size_exceeded") }),
                    type: createVideoTypeRule({ t }),
                  },
                }}
              />
            )}
            {(!isAnyAttachmentSelected || currDocuments.length > 0) && (
              <FormDocumentPicker<AdminCreatePostFormValues, "documents">
                name="documents"
                value={currDocuments}
                maxFiles={1}
                disabled={currDocuments.length > 0}
                rules={{
                  validate: {
                    size: createDocumentSizeRule({
                      t,
                      onError: () => postHog.capture("form_error_document_size_exceed"),
                    }),
                    type: createDocumentTypeRule({ t }),
                  },
                }}
              />
            )}
          </div>
          {Boolean(error) && (
            <span data-testid="form-input-error" role="alert" className="whitespace-nowrap text-overline text-red-600">
              {error}
            </span>
          )}
        </div>
      </FormField>
    </div>
  );
}
