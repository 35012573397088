import { useQuery } from "@tanstack/react-query";
import { ErrorPage } from "components/Error/ErrorPage";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSetUserLanguage } from "hooks/useSetUserLanguage";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost/CommunityPost";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { useQuickReplyManager } from "providers/QuickReplyProvider";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { routes } from "routes";

import { QuickReplyMenuBar } from "../components/QuickReplyMenuBar";

export function QuickReplyMessagePage(): React.ReactNode {
  const sessionUser = useSessionUser();
  const { entityId: messageId } = useQuickReplyManager();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: message,
    isPending: isPendingMessage,
    error: errorMessage,
  } = useQuery({
    ...communityFeedQueries.messageDetails(messageId),
    enabled: Boolean(messageId),
  });

  useSetUserLanguage();

  if (isPendingMessage) {
    return null;
  }

  if (errorMessage || !messageId) {
    return <ErrorPage status={(errorMessage as unknown as Response)?.status || 0} />;
  }

  return (
    <>
      <StopGlobalLoadingSpinner />
      <div className="flex min-h-full w-full flex-col items-center overflow-x-hidden">
        <QuickReplyMenuBar
          user={sessionUser}
          loginUrl={routes.messageFeed.details({ slug: sessionUser.project.slug, id: messageId })}
          isSmall
        />
        <main className="w-full max-w-3xl p-4 xl:px-0 xl:py-4">
          <CommunityPost isActionable={false} isExpanded {...{ message }} />
        </main>
      </div>
    </>
  );
}
