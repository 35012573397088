import iconUndo from "assets/icons/reverse-left.svg";
import iconRedo from "assets/icons/reverse-right.svg";
import { AIButton } from "components/Button/AIButton";
import { IconButton } from "components/Button/IconButton";
import type React from "react";
import { useTranslation } from "translations";

interface AdminCreatePostFormAIHelperProps {
  onGenerate: () => void;
  onUndo: () => void;
  onRedo: () => void;
  isUndoable: boolean;
  isRedoable: boolean;
  isGenerating: boolean;
  isCopilotAvailable: boolean;
}

export function AdminCreatePostFormAIHelper({
  isUndoable,
  isRedoable,
  isCopilotAvailable,
  isGenerating,
  onGenerate,
  onUndo,
  onRedo,
}: AdminCreatePostFormAIHelperProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col items-end justify-between gap-4 rounded-lg bg-bright-purple-50 p-4 md:flex-row md:items-center">
      <p className="text-body-bold">{t("page.admin-create-post.form.notice.ai-copilot")}</p>
      <div className="flex items-center gap-2">
        <AIButton
          onClick={onGenerate}
          isLoading={isGenerating}
          disabled={!isCopilotAvailable}
          data-testid="copilot-generate-btn"
        >
          {isUndoable ? "Regenerate" : "Generate content"}
        </AIButton>
        {(isUndoable || isRedoable) && (
          <>
            <IconButton
              styling="secondary"
              disabled={!isUndoable}
              title={t("common.action.undo")}
              icon={iconUndo}
              data-testid="copilot-undo-btn"
              onClick={onUndo}
            />
            <IconButton
              styling="secondary"
              disabled={!isRedoable}
              title={t("common.action.redo")}
              icon={iconRedo}
              onClick={onRedo}
            />
          </>
        )}
      </div>
    </div>
  );
}
