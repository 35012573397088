import iconCalendar from "assets/icons/calendar.svg";
import iconCalendarCheck01 from "assets/icons/calendar-check-01.svg";
import iconCalendarDate from "assets/icons/calendar-date.svg";
import iconClockCheck from "assets/icons/clock-check.svg";
import { Button } from "components/Button/Button";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { Drawer } from "components/Drawer/Drawer";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { addDays, addHours, isSameSecond, setHours, startOfHour } from "date-fns";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "translations";

import { AdminCreatePostOptionButton } from "./AdminCreatePostOptionButton";

interface AdminCreatePostFormScheduleSelectorProps {
  selectedDate: Date | undefined;
  onSelectDate: (date: Date | undefined) => void;
}

// TODO: Add translations
export function AdminCreatePostFormScheduleSelector({
  selectedDate,
  onSelectDate,
}: AdminCreatePostFormScheduleSelectorProps): React.ReactNode {
  const [activeDate, setActiveDate] = useState<Date | undefined>(selectedDate);
  const [isDrawerOpened, drawerHandler] = useBool(false);
  const [dateNow, setDateNow] = useState<Date>(new Date());

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDateNow(new Date());
  }, [isDrawerOpened]);

  const getUpcomingMorningDateTime = () => {
    if (dateNow.getHours() >= 9) {
      return startOfHour(addDays(dateNow, 1).setHours(9));
    } else {
      return startOfHour(setHours(dateNow, 9));
    }
  };

  const getNextWeekDateTime = () => {
    return startOfHour(addHours(addDays(dateNow, 7), 1));
  };

  const dateUpcomingMorning = getUpcomingMorningDateTime();
  const dateNextWeek = getNextWeekDateTime();
  const isCustomDateTimeSelected =
    isDefined(activeDate) && !isSameSecond(activeDate, dateUpcomingMorning) && !isSameSecond(activeDate, dateNextWeek);

  return (
    <>
      <Button
        styling="tertiary"
        data-testid="schedule-selector-btn"
        onClick={drawerHandler.setTrue}
        icon={<Icon name={iconClockCheck} />}
      >
        {selectedDate
          ? t("page.admin-create-post.form.btn.scheduled-for", {
              date: formatDate(i18n, "date", selectedDate),
            })
          : t("page.admin-create-post.form.btn.schedule")}
      </Button>

      <Drawer.Root
        title={t("page.admin-create-post.audience-selector.drawer.title")}
        isOpened={isDrawerOpened}
        onOpenChange={drawerHandler.set}
      >
        <Drawer.Body>
          <div className="flex flex-col gap-4">
            <AdminCreatePostOptionButton
              icon={iconCalendarCheck01}
              title={t("page.admin-create-post.schedule-selector.drawer.btn.post-now")}
              description={t("page.admin-create-post.schedule-selector.drawer.btn.post-now.description")}
              isSelected={!isDefined(activeDate)}
              onClick={() => setActiveDate(undefined)}
            />
            <AdminCreatePostOptionButton
              data-testid="schedule-next-morning-btn"
              icon={iconCalendar}
              title={t("page.admin-create-post.schedule-selector.drawer.btn.post-incoming-morning")}
              description={formatDate(i18n, "date", dateUpcomingMorning)}
              isSelected={isDefined(activeDate) && isSameSecond(activeDate, dateUpcomingMorning)}
              onClick={() => setActiveDate(dateUpcomingMorning)}
            />
            <AdminCreatePostOptionButton
              data-testid="schedule-next-week-btn"
              icon={iconCalendar}
              title={t("page.admin-create-post.schedule-selector.drawer.btn.post-next-week")}
              description={formatDate(i18n, "datetime", dateNextWeek)}
              isSelected={isDefined(activeDate) && isSameSecond(activeDate, dateNextWeek)}
              onClick={() => setActiveDate(dateNextWeek)}
            />
            <AdminCreatePostOptionButton
              title={t("page.admin-create-post.schedule-selector.drawer.btn.post-custom-datetime")}
              icon={iconCalendarDate}
              isSelected={isCustomDateTimeSelected}
            >
              <DateAndTimePicker
                type="datetime"
                data-testid="schedule-custom-datetime-picker-input"
                minuteStep={15}
                min={startOfHour(addHours(dateNow, 1))}
                onChange={(date) => setActiveDate(date as Date)}
                value={isCustomDateTimeSelected ? activeDate : ""}
              />
            </AdminCreatePostOptionButton>
          </div>
        </Drawer.Body>
        <Drawer.Actions>
          <Drawer.Close>
            <Button styling="secondary">{t("common.action.cancel")}</Button>
          </Drawer.Close>
          <Drawer.Close>
            <Button data-testid="confirm-schedule-btn" onClick={() => onSelectDate(activeDate)}>
              {t("common.action.select")}
            </Button>
          </Drawer.Close>
        </Drawer.Actions>
      </Drawer.Root>
    </>
  );
}
