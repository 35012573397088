import { useInfiniteQuery } from "@tanstack/react-query";
import { Button } from "components/Button/Button";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { useMemo } from "react";
import { useTranslation } from "translations";

import { ReactionListItem } from "./ReactionListItem";

type ReactionListProps = {
  messageId: string;
};

export function ReactionList({ messageId }: ReactionListProps): React.ReactNode {
  const { t } = useTranslation();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: reactionsData,
    fetchNextPage: fetchMoreReactions,
    hasNextPage: hasMoreReactions,
  } = useInfiniteQuery({
    ...communityFeedQueries.reactionsInfinite(messageId),
  });

  const allReactions = useMemo(() => {
    return reactionsData?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [reactionsData?.pages]);

  return (
    <div data-testid="like-list" className="flex flex-col gap-4">
      <ul className="flex flex-col gap-4" data-testid="reactions-section">
        {allReactions.map((reaction) => (
          <ReactionListItem key={reaction.author.id} {...{ reaction }} />
        ))}
      </ul>
      {hasMoreReactions && (
        <Button styling="ghostPrimary" onClick={fetchMoreReactions}>
          <span className="text-caption">{t("component.community-post.likes.see-more")}</span>
        </Button>
      )}
    </div>
  );
}
